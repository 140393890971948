import * as _ from 'lodash';

import { ProcessHistoryFilters } from './models';
import { CONFIG } from './config';
import store from './state/store';

function getEngineId(): any {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const tabId = params.get('tabId');

  if (url && tabId) {
    if (tabId === 'MY_PROCESS' || tabId === 'ADMIN' || tabId === 'VIEWER') {
      return [CONFIG.get().defaultEngineId];
    }

    return [CONFIG.get().internetExposureEngineId];
  }
}

function getTenantId(): any {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const tenantIds = params.get('tenantIds');

  if (url && tenantIds === 'all') return undefined;
  if (url && tenantIds) return tenantIds;
  return undefined;
}

function getViewType() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const tabId = params.get('tabId');

  if (url && tabId)
    switch (tabId) {
      case 'ADMIN':
        return 'ADMIN';
      case 'VIEWER':
        return 'VIEWER';
      case 'PARTNER_ADMIN':
        return 'ADMIN';
      case 'PARTNER_VIEWER':
        return 'VIEWER';
    }
  return 'MY_PROCESS';
}

function getProcessId(processHistoryFilters: ProcessHistoryFilters) {
  // Used for custom filtering links.
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const processId = params.get('processId');

  if (url && processId) return processId;

  return processHistoryFilters!.processId ? processHistoryFilters!.processId : undefined;
}

function getProcessName(processHistoryFilters: ProcessHistoryFilters) {
  // Used for custom filtering links.
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const processName = params.get('processName');

  if (url && processName) return processName;

  return processHistoryFilters!.processName ? processHistoryFilters!.processName : undefined;
}

function getIncidentsFlag(processHistoryFilters: ProcessHistoryFilters): any {
  // Used for custom filtering links.
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const withIncidents = params.get('withIncidents');

  if (url && withIncidents) return withIncidents;
  return processHistoryFilters!.withIncidents ? processHistoryFilters!.withIncidents : false;
}

function getStatus(): any {
  let instanceStatus: Array<'In progress' | 'Completed' | 'Cancelled' | 'Suspended' | 'All'> = [];

  // Used for custom filtering links.
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const status = params.getAll('status');

  if (!_.isEmpty(status) && url) {
    if (status!.includes('all')) {
      instanceStatus.push('All');
    }
    if (status!.includes('active')) {
      instanceStatus.push('In progress');
    }
    if (status!.includes('cancelled')) {
      instanceStatus.push('Cancelled');
    }
    if (status!.includes('completed')) {
      instanceStatus.push('Completed');
    }
    if (status!.includes('suspended')) {
      instanceStatus.push('Suspended');
    }

    return instanceStatus;
  }

  instanceStatus.push('In progress');
  return instanceStatus;
}

function getDate(param: any): any {
  // Used for custom filtering links.
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const startedBefore = params.get('startedBefore');
  const startedAfter = params.get('startedAfter');
  const finishedBefore = params.get('finishedBefore');
  const finishedAfter = params.get('finishedAfter');

  if (url && param === 'startedBefore') return startedBefore;
  if (url && param === 'startedAfter') return startedAfter;
  if (url && param === 'finishedBefore') return finishedBefore;
  if (url && param === 'finishedAfter') return finishedAfter;

  return undefined;
}

function getSortOrder(): any {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const sortOrder = params.get('sortOrder');

  if (url && sortOrder) return sortOrder;
  return 'desc';
}

function getSortDirection(): any {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const sortBy = params.get('sortBy');

  if (url && sortBy) return sortBy;
  return 'startTime';
}

function getEnvironment(): any {
  const env = CONFIG.getConfigId().toLowerCase();

  if (env === 'local') return 'local';
  if (env === 'development') return 'dev';
  if (env === 'homologation') return 'uat';
  if (env === 'iso' || env === 'iso_tyk') return 'preprod';
  if (env === 'production' || env === 'prod_tyk') return 'prod';
  return env;
}

// let isProcessViwer = userRoles && userRoles.includes()
function isUserRoleGranted(role: String): boolean {
  let userRoles = store.getState().userRoles;
  return userRoles && userRoles.includes(role);
}

function hasModelerRole() {
  return isUserRoleGranted('Workflow Modeler');
}

function hasProcessViewerRole() {
  return isUserRoleGranted('Process Viewer');
}

function hasModelViewerRole() {
  return isUserRoleGranted('Model Viewer');
}

export {
  getEngineId,
  getTenantId,
  getSortOrder,
  getSortDirection,
  getViewType,
  getProcessId,
  getProcessName,
  getIncidentsFlag,
  getStatus,
  getDate,
  getEnvironment,
  hasModelerRole,
  hasProcessViewerRole,
  hasModelViewerRole,
};
