// tslint:disable
/**
 * Data Quality Incident Management Services
 * DQHub is providing a repository, APIs, and UI allowing to centralize, analyze, manage, process and report Data Quality issues. <br><br> This API aims at capturing, updating, and searching Data Quality issues : <br> <ul> <li><b>Incidents</b><br>An Incident is a data quality defect observed during normal usage of data. It is a user observation of a single observed defect for a specific data element at a specific time, for a given business chain, in a given system environment within the data elements lifecycle.<br>A data quality incident will require root cause analysis, can have a short-term remediation planning and execution.<br><br></li><li><b>Anomalies</b><br>A Data Quality anomaly is an identified systemic problem with one or more data elements, informed by failed DQ results (testing observations) or Incidents (user observations). A data quality anomaly will require root cause analysis, long-term remediation planning and execution to resolve underlying failed DQ results or incidents.<br>A one-off data break incident, new requirements, lack or weakness of controls and pre-production UAT defects are not data quality anomalies.<br><br></li><li><b>Failed Control Results (study in progress)</b><br>A DQ control is a recurring test performed on a data or a group of data, which can be used to check whether the data or group of data satisfies a quality requirement (completeness, accuracy, timeliness, etc.). It always carries on a specific perimeter.<br>The control result is the observation obtained after the control has been executed on a data or a group of data.<br>A failed DQ Control result is when the quality requirement tested by the control is not satisfied<br></li></ul><br>Json format for incidents is available through this end point : /dataquality/incidents/jsonschema
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: guillaume.landre@sgcib.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Select the file to Upload
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {any}
     * @memberof InlineObject
     */
    file: any;
}

/**
 * IncidentControllerApi - axios parameter creator
 * @export
 */
export const IncidentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get attachments of incident
         * @param {string} id id
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDqHubAttachmentsUsingGET(id: string, fileName: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDqHubAttachmentsUsingGET.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling getDqHubAttachmentsUsingGET.');
            }
            const localVarPath = `/v1/dataquality/incident/{id}/file/{fileName}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get incident by id
         * @param {string} id incident id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncidentUsingGET(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getIncidentUsingGET.');
            }
            const localVarPath = `/v1/dataquality/incidents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary attach document to incident
         * @param {string} id id
         * @param {any} file 
         * @param {string} [category] category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDqHubFileUsingPOST(id: string, file: any, category?: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadDqHubFileUsingPOST.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadDqHubFileUsingPOST.');
            }
            const localVarPath = `/v1/dataquality/incident/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IncidentControllerApi - functional programming interface
 * @export
 */
export const IncidentControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get attachments of incident
         * @param {string} id id
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDqHubAttachmentsUsingGET(id: string, fileName: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = IncidentControllerApiAxiosParamCreator(configuration).getDqHubAttachmentsUsingGET(id, fileName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get incident by id
         * @param {string} id incident id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncidentUsingGET(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = IncidentControllerApiAxiosParamCreator(configuration).getIncidentUsingGET(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary attach document to incident
         * @param {string} id id
         * @param {any} file 
         * @param {string} [category] category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDqHubFileUsingPOST(id: string, file: any, category?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = IncidentControllerApiAxiosParamCreator(configuration).uploadDqHubFileUsingPOST(id, file, category, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * IncidentControllerApi - factory interface
 * @export
 */
export const IncidentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary get attachments of incident
         * @param {string} id id
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDqHubAttachmentsUsingGET(id: string, fileName: string, options?: any): AxiosPromise<any> {
            return IncidentControllerApiFp(configuration).getDqHubAttachmentsUsingGET(id, fileName, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get incident by id
         * @param {string} id incident id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncidentUsingGET(id: string, options?: any): AxiosPromise<object> {
            return IncidentControllerApiFp(configuration).getIncidentUsingGET(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary attach document to incident
         * @param {string} id id
         * @param {any} file 
         * @param {string} [category] category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDqHubFileUsingPOST(id: string, file: any, category?: string, options?: any): AxiosPromise<void> {
            return IncidentControllerApiFp(configuration).uploadDqHubFileUsingPOST(id, file, category, options)(axios, basePath);
        },
    };
};

/**
 * IncidentControllerApi - object-oriented interface
 * @export
 * @class IncidentControllerApi
 * @extends {BaseAPI}
 */
export class IncidentControllerApi extends BaseAPI {
    /**
     * 
     * @summary get attachments of incident
     * @param {string} id id
     * @param {string} fileName fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentControllerApi
     */
    public getDqHubAttachmentsUsingGET(id: string, fileName: string, options?: any) {
        return IncidentControllerApiFp(this.configuration).getDqHubAttachmentsUsingGET(id, fileName, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get incident by id
     * @param {string} id incident id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentControllerApi
     */
    public getIncidentUsingGET(id: string, options?: any) {
        return IncidentControllerApiFp(this.configuration).getIncidentUsingGET(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary attach document to incident
     * @param {string} id id
     * @param {any} file 
     * @param {string} [category] category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentControllerApi
     */
    public uploadDqHubFileUsingPOST(id: string, file: any, category?: string, options?: any) {
        return IncidentControllerApiFp(this.configuration).uploadDqHubFileUsingPOST(id, file, category, options)(this.axios, this.basePath);
    }

}


