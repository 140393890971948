import { Component, h } from 'preact';
import { IWidgetConfigurationContext, IWidgetConfigurationProps, withWidgetConfiguration, WithCss } from '@sgwt-widget/core';
import * as moment from 'moment';
import 'moment/min/locales';
import * as _ from 'lodash';

import connect from '../../common/state/connect';
import store, { ProcessHistoryStateInterface } from '../../common/state/store';
import { getTimelineEvents, updateProcessInstanceId } from '../../common/state/action-process-history';
import { updateAccessToken, updateUserId } from '../../common/state/actions-task';
import { BUS_ACCESS_TOKEN, BUS_SELECTED_TASK_AND_CLUSTER, BUS_TASK_UPDATED, BUS_USER_CONNECTION } from '../../common/state/actions';
import { ApiCallStatus, SelectedTaskInfos, Timeline, IconInfo } from '../../common/models';
import * as historyProcessCss from '../../common/sgworkflow-process-history.less';
import { getAuthorizedEngineIds } from '../../common/state/action-authorization';
import { CONFIG } from '../../common/config';
// @ts-ignore
import Analytics from '../../analytics/analytics.service';
// @ts-ignore
import i18n from '../../common/intl/intl.service.js';

const {
  service: { formatMessage },
} = i18n;

const userInfo = _.get(window, 'SGWTWidgetConfiguration.bus.lastValues["sg-connect.user-info"]');

moment.locale('en');
if (userInfo && userInfo['preferred_language']) {
  userInfo['preferred_language'] ? moment.locale(userInfo['preferred_language']) : moment.locale('en');
}

export interface IProps {
  accessToken?: string;
  user?: string;
  updateAccessToken: (accessToken?: string) => {};
  updateUserId: (userId?: string) => {};
  getAuthorizedEngineIds(): () => {};
  processInstanceId: string;
  engineId: string;
  getTimelineEvents: (processInstanceId: string, engineId: string, taskId?: string) => {};
  updateProcessInstanceId: (processInstanceId?: string) => {};
  timelineEvents?: Timeline[];
  processHistoryLoadingStatus?: ApiCallStatus;
  processHistorySelectedTaskId?: string;
  selectedTaskType?: string;
  activityLoadingStatus?: ApiCallStatus;
  taskId?: string;
}

interface IState {
  processHistoryHoverTaskId?: string;
}

// STORE_PROPERTIES_KEYS register props keys we want to inject from the store
const STORE_PROPERTIES_KEYS: (keyof ProcessHistoryStateInterface)[] = [
  'processInstanceId',
  'timelineEvents',
  'processHistoryLoadingStatus',
  'accessToken',
  'user',
  'processHistorySelectedTaskId',
  'selectedTaskType',
  'activityLoadingStatus',
  'engineId',
];

const SGWorkflowProcessHistoryComp = withWidgetConfiguration(
  class extends Component<IProps & IWidgetConfigurationProps, IState> {
    context!: IWidgetConfigurationContext;
    // The subscription handle - useful when comes the time to unsubscribe...
    tokenSubscription: any;
    userSubscription: any;
    taskUpdateSubscription: any;

    static displayName = 'SgWorkflowProcessHistory';

    constructor() {
      super();
      this.setState({
        processHistoryHoverTaskId: undefined,
      });
    }

    componentWillMount() {
      this.subscribeToSGConnectBusMessages();
      this.props.getAuthorizedEngineIds();
      this.subscribeTaskUpdatedBusMessages();
      // Will change when the v4 endpoint to fetch process tasks will be created

      this.props.getTimelineEvents(this.props.processInstanceId, this.props.engineId, this.props.taskId);

      const pageProcessList = document.getElementById('pageProcessList');
      if (pageProcessList !== null) {
        pageProcessList.classList.add('no-scroll');
      }
    }

    componentDidUpdate(prevProps: Readonly<IProps & IWidgetConfigurationProps>) {
      if (prevProps.processHistorySelectedTaskId !== this.props.processHistorySelectedTaskId) {
        let selectedTask: SelectedTaskInfos = {
          selectedTaskId: this.props.processHistorySelectedTaskId,
          selectedEngineId: this.props.engineId,
          messageSummaryTaskForm: undefined,
        };
        if (this.props.selectedTaskType === 'userTask') {
          this.sendSelectedTask(selectedTask);
        }
      }
      if (prevProps.processInstanceId !== this.props.processInstanceId) {
        this.props.getTimelineEvents(this.props.processInstanceId, this.props.engineId);
      }
    }

    componentWillUnmount() {
      const pageProcessList = document.getElementById('pageProcessList');
      if (pageProcessList !== null) {
        pageProcessList.classList.remove('no-scroll');
      }
      this.unsubscribeFromSGConnectBusMessages();
      this.unsubscribeTaskUpdatedBusMessages();
    }

    selectTask(event: Event) {
      const taskId = (event.currentTarget as HTMLInputElement).getAttribute('data-taskid') as string;
      const engineId = (event.currentTarget as HTMLInputElement).getAttribute('data-taskengineid') as string;
      const taskType = (event.currentTarget as HTMLInputElement).getAttribute('data-tasktype') as string;

      let selectedTask: SelectedTaskInfos = {
        selectedTaskId: taskId,
        selectedEngineId: engineId,
        messageSummaryTaskForm: undefined,
      };
      if (taskId !== this.props.processHistorySelectedTaskId) {
        store.setState({
          selectedTaskType: taskType,
          processHistorySelectedTaskId: taskId,
          activityLoadingStatus: undefined,
        });
        if (taskType === 'userTask') {
          this.sendSelectedTask(selectedTask);
        }
        this.loadProcessViewByTask(selectedTask);
      }
    }

    loadProcessViewByTask(selectedTask: any) {
      // Re-route event
      const eventName = 'load-process-by-task-view';
      const redirectToProcessByTaskView = new CustomEvent(eventName, {
        bubbles: true,
        detail: { selectedTask },
      });

      document.dispatchEvent(redirectToProcessByTaskView);
    }

    changeHoverTask = (hoverTaskId?: string) => {
      if (hoverTaskId !== undefined) {
        this.setState({
          processHistoryHoverTaskId: hoverTaskId,
        });
      } else {
        this.setState({
          processHistoryHoverTaskId: undefined,
        });
      }
    };

    sendSelectedTask(selectedTask: SelectedTaskInfos) {
      const widgetConfiguration = (window as any).SGWTWidgetConfiguration;
      widgetConfiguration.bus.publish(BUS_SELECTED_TASK_AND_CLUSTER, selectedTask);
    }

    generateIconBasedonTaskType(activityType: string) {
      const icons = IconInfo;

      let backgroundClass =
        'rounded-circle d-flex align-items-center justify-content-center activity-icon-sm ' +
        icons[activityType as keyof typeof icons].backgroundSmall;
      let iconClass = 'icon icon-sm line-height-sm ' + icons[activityType as keyof typeof icons].iconColor;

      return (
        <div class={backgroundClass}>
          <i class={iconClass}> {icons[activityType as keyof typeof icons].icon} </i>
        </div>
      );
    }

    subscribeTaskUpdatedBusMessages() {
      this.taskUpdateSubscription = this.context.widgetConfiguration.bus.subscribe(BUS_TASK_UPDATED, (updated?: boolean) => {
        // This callback is called at the subscription and every time the token is changed.
        if (updated) {
          this.props.getTimelineEvents(this.props.processInstanceId, this.props.engineId);
          let selectedTask: SelectedTaskInfos = {
            selectedTaskId: this.props.processHistorySelectedTaskId,
            selectedEngineId: this.props.engineId,
            messageSummaryTaskForm: undefined,
          };
          if (this.props.selectedTaskType === 'userTask') {
            this.sendSelectedTask(selectedTask);
          }
        }
      });
    }

    unsubscribeTaskUpdatedBusMessages() {
      if (this.taskUpdateSubscription) {
        this.context.widgetConfiguration.bus.unsubscribe(this.taskUpdateSubscription);
        this.taskUpdateSubscription = null;
      }
    }

    subscribeToSGConnectBusMessages() {
      // SGConnect : We start the subscription to the corresponding topic.
      this.tokenSubscription = this.context.widgetConfiguration.bus.subscribe(BUS_ACCESS_TOKEN, (token?: string) => {
        // This callback is called at the subscription and every time the token is changed.
        if (token) {
          this.props.updateAccessToken(token);
        }
      });

      this.userSubscription = this.context.widgetConfiguration.bus.subscribe(BUS_USER_CONNECTION, (userConnection?: any) => {
        // This callback is called at the subscription and every time the token is changed.
        if (userConnection) {
          if (CONFIG.get().apmEnabled) {
            // Analytics.load(userConnection.mail);
          }
          this.props.updateUserId(userConnection.mail);
        }
      });
    }

    unsubscribeFromSGConnectBusMessages() {
      // SGConnect: We need to unsubscribe!
      if (this.tokenSubscription) {
        this.context.widgetConfiguration.bus.unsubscribe(this.tokenSubscription);
        this.tokenSubscription = null;
      }
      if (this.userSubscription) {
        this.context.widgetConfiguration.bus.unsubscribe(this.userSubscription);
        this.userSubscription = null;
      }
    }

    getIdForActivity(activity: Timeline) {
      if (activity.type == 'userTask') {
        return activity.taskId;
      } else {
        return activity.id;
      }
    }

    isSelectedActivity(activity: Timeline) {
      if (activity.type == 'userTask') {
        return this.props.processHistorySelectedTaskId === activity.taskId || activity.taskId === this.state.processHistoryHoverTaskId;
      } else {
        return this.props.processHistorySelectedTaskId === activity.id || activity.id === this.state.processHistoryHoverTaskId;
      }
    }

    render(): JSX.Element | null {
      let displayedResult = null;
      switch (this.props.processHistoryLoadingStatus) {
        case 'noresult':
          displayedResult = (
            <div class='container my-5 text-center text-secondary noresult' style='max-width:320px'>
              <i class='icon icon-xl d-inline-block pt-5 mb-2'>list</i>
              <h4>None user task for selected process instance</h4>
            </div>
          );
          break;
        case 'loaded':
          {
            const activityInstance = this.props.timelineEvents;
            const activetimelineEvents = activityInstance!.filter(function(a: Timeline) {
              return a.status !== 'Completed';
            });

            const completedtimelineEvents = activityInstance!.filter(function(a: Timeline) {
              return a.status === 'Completed';
            });

            displayedResult = (
              <div class='col vertical-parent mt-3'>
                {activityInstance ? (
                  <ul class='sgbs-stepper sgbs-stepper-vertical d-inline-block'>
                    {Array.from(activetimelineEvents!.entries()).map((activity: [number, Timeline]) => {
                      return (
                        <li
                          id={this.getIdForActivity(activity[1])}
                          class={
                            activity[1].type === 'incident'
                              ? 'sgbs-stepper-step fill text-danger task-stepper'
                              : 'sgbs-stepper-step current text-info task-stepper'
                          }>
                          <div class='sgbs-stepper-step-inner'>
                            {activity[1].type === 'incident' ? (
                              <button class='sgbs-stepper-indicator'>
                                <i class='icon'>error_outline</i>
                              </button>
                            ) : (
                              <button class='sgbs-stepper-indicator'>
                                <i class='icon'>fiber_manual_record</i>
                              </button>
                            )}
                          </div>
                          <div
                            class={this.isSelectedActivity(activity[1]) ? 'sgbs-stepper-label task-selected' : 'sgbs-stepper-label task'}
                            data-taskid={this.getIdForActivity(activity[1])}
                            data-taskengineid={this.props.engineId}
                            data-tasktype={activity[1].type}
                            onMouseEnter={() => this.changeHoverTask(this.getIdForActivity(activity[1]))}
                            onMouseLeave={() => this.changeHoverTask(undefined)}
                            onClick={this.selectTask.bind(this)}>
                            {this.generateIconBasedonTaskType(activity[1].type)}
                            <div class='ml-2'>
                              <div class={this.isSelectedActivity(activity[1]) ? 'task-selected-name' : 'task-name'}>
                                <p class='task-name-overflow'>{activity[1].name}</p>
                              </div>
                              <div class='task-completion'>
                                {activity[1].type === 'incident' ? (
                                  <div
                                    class={
                                      this.isSelectedActivity(activity[1])
                                        ? 'badge badge-pill badge-discreet-danger text-small task-selected-pill'
                                        : 'badge badge-pill badge-discreet-danger text-small'
                                    }>
                                    {formatMessage({ id: 'process.incident' })}
                                  </div>
                                ) : (
                                  <div
                                    class={
                                      this.isSelectedActivity(activity[1])
                                        ? 'badge badge-pill badge-discreet-info text-small task-selected-pill'
                                        : 'badge badge-pill badge-discreet-info text-small'
                                    }>
                                    {formatMessage({ id: 'process.in.progress' })}
                                  </div>
                                )}
                              </div>
                              {activity[1].type !== 'incident' &&
                                (activity[1].assignee ? (
                                  <div class={this.isSelectedActivity(activity[1]) ? 'task-selected-assignee-history' : 'task-assignee-history'}>
                                    {activity[1].assignee}
                                  </div>
                                ) : (
                                  <div
                                    class={this.isSelectedActivity(activity[1]) ? 'task-selected-no-assignee-history' : 'task-no-assignee-history'}>
                                    {formatMessage({ id: 'process.unassigned' })}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                    {Array.from(completedtimelineEvents!.entries()).map((activity: [number, Timeline]) => {
                      return (
                        <li
                          id={this.getIdForActivity(activity[1])}
                          class={
                            activity[1].cancelled
                              ? 'sgbs-stepper-step fill task-stepper text-primary'
                              : 'sgbs-stepper-step fill task-stepper text-success '
                          }>
                          <div class='sgbs-stepper-step-inner'>
                            <div class='sgbs-stepper-indicator'>{activity[1].cancelled ? <i class='icon'>close</i> : <i class='icon'>check</i>}</div>
                          </div>
                          <div
                            class={this.isSelectedActivity(activity[1]) ? 'sgbs-stepper-label task-selected' : 'sgbs-stepper-label task'}
                            data-taskid={this.getIdForActivity(activity[1])}
                            data-taskengineid={this.props.engineId}
                            data-tasktype={activity[1].type}
                            onMouseEnter={() => this.changeHoverTask(this.getIdForActivity(activity[1]))}
                            onMouseLeave={() => this.changeHoverTask(undefined)}
                            onClick={this.selectTask.bind(this)}>
                            {this.generateIconBasedonTaskType(activity[1].type)}
                            <div class='ml-2'>
                              <div class={this.isSelectedActivity(activity[1]) ? 'task-selected-name' : 'task-name'}>
                                <p class='task-name-overflow'>{activity[1].name}</p>
                              </div>
                              <div class='task-completion'>
                                {activity[1].cancelled ? (
                                  <div
                                    class={
                                      this.isSelectedActivity(activity[1])
                                        ? 'badge badge-pill badge-discreet-primary task-selected-pill text-small'
                                        : 'badge badge-pill badge-discreet-primary text-small'
                                    }>
                                    {formatMessage({ id: 'process.cancelled' })}
                                  </div>
                                ) : (
                                  <div
                                    class={
                                      this.isSelectedActivity(activity[1])
                                        ? 'badge badge-pill badge-discreet-success task-selected-pill text-small'
                                        : 'badge badge-pill badge-discreet-success text-small'
                                    }>
                                    {formatMessage({ id: 'process.completed' })}
                                  </div>
                                )}
                                <div class={this.isSelectedActivity(activity[1]) ? 'task-selected-date' : 'task-date'}>
                                  on{' '}
                                  {moment(activity[1].completionDate)
                                    .utc()
                                    .format('DD MMM YYYY HH:mm')}
                                </div>
                              </div>
                              {activity[1].cancelled ? (
                                <div></div>
                              ) : (
                                <div class={this.isSelectedActivity(activity[1]) ? 'task-selected-assignee-history' : 'task-assignee-history'}>
                                  {activity[1].assignee}
                                </div>
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <div></div>
                )}
              </div>
            );
          }
          break;
        case 'loading':
          displayedResult = (
            <div class='d-flex flex-column align-items-center' style={{ paddingTop: 100 }}>
              <div class='spinner spinner-xl mb-1' role='status'></div>
              <p>{formatMessage({ id: 'process.table.history.load' })}</p>
            </div>
          );
          break;
        case 'error':
          displayedResult = (
            <div class='info-error'>
              <i class='icon icon-md danger spacing-mt-4 mb-4'>info_outline</i>
              <span class='danger'>Loading error</span>
            </div>
          );
          break;
        default:
          displayedResult = <div id='no-content' />;
      }
      return (
        <WithCss styles={historyProcessCss}>
          <div id=''>{displayedResult}</div>
        </WithCss>
      );
    }
  }
);

export default connect(
  { getTimelineEvents, updateAccessToken, updateUserId, getAuthorizedEngineIds, updateProcessInstanceId },
  STORE_PROPERTIES_KEYS
)(SGWorkflowProcessHistoryComp);
