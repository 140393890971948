// tslint:disable
/**
 * Data Quality Incident Management Services
 * DQHub is providing a repository, APIs, and UI allowing to centralize, analyze, manage, process and report Data Quality issues. <br><br> This API aims at capturing, updating, and searching Data Quality issues : <br> <ul> <li><b>Incidents</b><br>An Incident is a data quality defect observed during normal usage of data. It is a user observation of a single observed defect for a specific data element at a specific time, for a given business chain, in a given system environment within the data elements lifecycle.<br>A data quality incident will require root cause analysis, can have a short-term remediation planning and execution.<br><br></li><li><b>Anomalies</b><br>A Data Quality anomaly is an identified systemic problem with one or more data elements, informed by failed DQ results (testing observations) or Incidents (user observations). A data quality anomaly will require root cause analysis, long-term remediation planning and execution to resolve underlying failed DQ results or incidents.<br>A one-off data break incident, new requirements, lack or weakness of controls and pre-production UAT defects are not data quality anomalies.<br><br></li><li><b>Failed Control Results (study in progress)</b><br>A DQ control is a recurring test performed on a data or a group of data, which can be used to check whether the data or group of data satisfies a quality requirement (completeness, accuracy, timeliness, etc.). It always carries on a specific perimeter.<br>The control result is the observation obtained after the control has been executed on a data or a group of data.<br>A failed DQ Control result is when the quality requirement tested by the control is not satisfied<br></li></ul><br>Json format for incidents is available through this end point : /dataquality/incidents/jsonschema
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: guillaume.landre@sgcib.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ConfigurationParameters {
    apiKey?: string | ((name: string) => string);
    username?: string;
    password?: string;
    accessToken?: string | ((name?: string, scopes?: string[]) => string);
    basePath?: string;
    baseOptions?: any;
}

export class Configuration {
    /**
     * parameter for apiKey security
     * @param name security name
     * @memberof Configuration
     */
    apiKey?: string | ((name: string) => string);
    /**
     * parameter for basic security
     * 
     * @type {string}
     * @memberof Configuration
     */
    username?: string;
    /**
     * parameter for basic security
     * 
     * @type {string}
     * @memberof Configuration
     */
    password?: string;
    /**
     * parameter for oauth2 security
     * @param name security name
     * @param scopes oauth2 scope
     * @memberof Configuration
     */
    accessToken?: string | ((name?: string, scopes?: string[]) => string);
    /**
     * override base path
     * 
     * @type {string}
     * @memberof Configuration
     */
    basePath?: string;
    /**
     * base options for axios calls
     *
     * @type {any}
     * @memberof Configuration
     */
    baseOptions?: any;

    constructor(param: ConfigurationParameters = {}) {
        this.apiKey = param.apiKey;
        this.username = param.username;
        this.password = param.password;
        this.accessToken = param.accessToken;
        this.basePath = param.basePath;
        this.baseOptions = param.baseOptions;
    }
}
