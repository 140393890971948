import { init as initApm, apm } from '@elastic/apm-rum';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { CONFIG } from '../common/config';
import { constants } from './constants';
import { getEnvironment } from '../common/utils';
import { version, name } from '../../package.json';

const configuration = CONFIG.get();

const load = () => {
  if (isEmpty(configuration.apmUrl) || !configuration.apmEnabled) return;

  if (apm.isActive()) return; // bail if loaded

  const user = get(window, 'SGWTWidgetConfiguration.bus.lastValues["sg-connect.user-info"]');

  if (user) {
    if (!apm.isActive()) {
      initApm({
        active: true,
        serviceName: CONFIG.getRumServiceName(),
        serverUrl: CONFIG.get().apmUrl,
        environment: getEnvironment(),
      });

      apm.setUserContext({
        username: user.mail,
        email: user.mail,
        // id: user.rc_local_sigle,
      });
    }
  }
};

const track = {
  fetchProcessAction: (...args) => startTransaction(...args),
  loadTask: (...args) => startTransaction(...args),
  loadTasks: (...args) => startTransaction(...args),
  fetchProcessHistoryWithUser: (...args) => startTransaction(...args),
  fetchProcessHistory: (...args) => startTransaction(...args),
  displayDiagram: (...args) => startTransaction(...args),
};

const startTransaction = (...args) => apm && apm.isActive() && apm.startTransaction(...args);

const getInstance = () => {
  if (!isEmpty(apm) && apm.isActive()) return apm;
  return undefined;
};

const endTransaction = (transaction) => transaction && transaction.end();
const endSpan = (span) => span && span.end();

const getCurrentTransaction = () => {
  if (!isEmpty(apm) && apm.isActive()) return apm.getCurrentTransaction();
  return undefined;
};

// Add labels to keep track of additional information in the dashboard
const addLabel = (transaction, key, value) => transaction && transaction.addLabels({ [key]: value });

function getLabels(tag, userMail, userDepartment) {
  return {
    ['host']: location.host,
    ['userName']: userMail,
    ['userDepartment']: userDepartment,
    ['widgetName']: tag,
    ['widgetVersion']: version,
    ['widgetGroup']: name,
    ['environment']: getEnvironment(),
  };
}

function addAnalyticsMetadata(tag, configuration) {
  const user = get(configuration, 'globalConfig.bus.lastValues["sg-connect.user-info"]');
  if (!apm || !user) return;

  if (!apm.isActive()) {
    initApm({
      active: true,
      serviceName: CONFIG.getRumServiceName(),
      serverUrl: CONFIG.get().apmUrl,
      environment: getEnvironment(),
    });

    apm.setUserContext({
      username: user.name,
      email: user.mail,
      // id: user.rc_local_sigle,
    });
  }

  let tr = apm.getCurrentTransaction();
  if (tr) {
    tr.addLabels(getLabels(tag, user.mail, user.rc_local_sigle));
    return;
  }

  // send a custom transaction in case none is found
  tr = apm.startTransaction(tag, 'widgets-use', { managed: true });
  tr.addLabels(getLabels(tag, user.mail, user.rc_local_sigle));
  return;
}

export default {
  addAnalyticsMetadata,
  constants,
  load,
  getInstance,
  track,
  endTransaction,
  endSpan,
  addLabel,
  getCurrentTransaction,
};
