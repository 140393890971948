/**
 * This represents the type of our configuration.
 * The values come from the json files in the `configuration` folder.
 */
export interface SGWorkflowConfig {
  formioCssBasePath: string;
  uiBasePath: string;
  taskApiBasePath: string;
  sgbpmApiBasePath?: string;
  workspaceApiBasePath: string;
  dqhubApiBasePath: string;
  defaultEngineId: string;
  internetExposureEngineId: string;
  unauthorizedPageUrl: string;
  apmEnabled: boolean;
  apmUrl: string;
  auditWorkspaceId: string[];
}

interface SGWorkflowEnvironments {
  test: SGWorkflowConfig;
  development: SGWorkflowConfig;
  bsc_dev: SGWorkflowConfig;
  local: SGWorkflowConfig;
  homologation: SGWorkflowConfig;
  bsc_hom: SGWorkflowConfig;
  iso: SGWorkflowConfig;
  bsc_preprod: SGWorkflowConfig;
  iso_tyk: SGWorkflowConfig;
  production: SGWorkflowConfig;
  bsc_prod: SGWorkflowConfig;
  prod_tyk: SGWorkflowConfig;
  get(): SGWorkflowConfig;
  getConfigId(): string;
  getRumServiceName(): string;
}

/**
 * Avoid asynchronous loading from widgetConfiguration().myConfiguration<SGWorkflowConfig>()
 */
export const CONFIG: SGWorkflowEnvironments = {
  test: {
    formioCssBasePath: 'https://shared.fr.world.socgen/sg-workflow/formiojs/4.11.3',
    uiBasePath: 'http://api-server.com',
    workspaceApiBasePath: 'http://api-server.com/api/workspaces/v1',
    taskApiBasePath: 'http://api-server.com/api/tasks/v1',
    sgbpmApiBasePath: 'http://api-server.com/api/v1',
    dqhubApiBasePath: 'https://dqhub-dev-api.fr.world.socgen',
    defaultEngineId: 'common',
    internetExposureEngineId: 'partners',
    unauthorizedPageUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=http://api-server.com',
    apmEnabled: false,
    apmUrl: '',
    auditWorkspaceId: [],
  },
  local: {
    formioCssBasePath: 'https://shared.fr.world.socgen/sg-workflow/formiojs/4.11.3',
    uiBasePath: 'http://localhost:4200',
    workspaceApiBasePath: 'http://localhost:9543/api/workspaces/v1',
    taskApiBasePath: 'http://localhost:9643/api/tasks/v1',
    sgbpmApiBasePath: 'http://localhost:9443/api',
    dqhubApiBasePath: 'https://dqhub-dev-api.fr.world.socgen',
    defaultEngineId: 'common',
    internetExposureEngineId: 'partners',
    unauthorizedPageUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=http://localhost:4200',
    apmEnabled: true,
    apmUrl: 'https://1b100480d71847759d39a5984c738d53.ece.sgmonitoring.dev.euw.gbis.sg-azure.com',
    auditWorkspaceId: [],
  },
  development: {
    formioCssBasePath: 'https://shared.fr.world.socgen/sg-workflow/formiojs/4.11.3',
    uiBasePath: 'https://sgworkflow-dev.fr.world.socgen',
    workspaceApiBasePath: 'https://sgworkflow-dev.fr.world.socgen/api/workspaces/v1',
    taskApiBasePath: 'https://sgworkflow-dev.fr.world.socgen/api/tasks/v1',
    sgbpmApiBasePath: 'https://sgworkflow-dev.fr.world.socgen/api',
    dqhubApiBasePath: 'https://dqhub-dev-api.fr.world.socgen',
    defaultEngineId: 'common',
    internetExposureEngineId: 'partners',
    unauthorizedPageUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://sgworkflow-dev.fr.world.socgen',
    apmEnabled: true,
    apmUrl: 'https://1b100480d71847759d39a5984c738d53.ece.sgmonitoring.dev.euw.gbis.sg-azure.com',
    auditWorkspaceId: [],
  },
  bsc_dev: {
    formioCssBasePath: 'https://shared.fr.world.socgen/sg-workflow/formiojs/4.11.3',
    uiBasePath: 'https://resg-sgworkflow-dev.group.socgen',
    workspaceApiBasePath: 'https://sgworkflow-dev.fr.world.socgen/api/workspaces/v1',
    taskApiBasePath: 'https://sgworkflow-dev.fr.world.socgen/api/tasks/v1',
    sgbpmApiBasePath: 'https://resg-sgworkflow-sgbpm-dev.group.socgen/api',
    dqhubApiBasePath: 'https://dqhub-dev-api.fr.world.socgen',
    defaultEngineId: 'bsc-emp',
    internetExposureEngineId: 'partners',
    unauthorizedPageUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://resg-sgworkflow-dev.group.socgen',
    apmEnabled: true,
    apmUrl: 'https://1b100480d71847759d39a5984c738d53.ece.sgmonitoring.dev.euw.gbis.sg-azure.com',
    auditWorkspaceId: [],
  },
  homologation: {
    formioCssBasePath: 'https://shared.fr.world.socgen/sg-workflow/formiojs/4.11.3',
    uiBasePath: 'https://sgworkflow-uat.fr.world.socgen',
    workspaceApiBasePath: 'https://sgworkflow-uat.fr.world.socgen/api/workspaces/v1',
    taskApiBasePath: 'https://sgworkflow-uat.fr.world.socgen/api/tasks/v1',
    sgbpmApiBasePath: 'https://sgworkflow-uat.fr.world.socgen/api',
    dqhubApiBasePath: 'https://dqhub-uat-api.fr.world.socgen',
    defaultEngineId: 'common',
    internetExposureEngineId: 'partners',
    unauthorizedPageUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://sgworkflow-uat.fr.world.socgen',
    apmEnabled: true,
    apmUrl: 'https://1b100480d71847759d39a5984c738d53.ece.sgmonitoring.dev.euw.gbis.sg-azure.com',
    auditWorkspaceId: [],
  },
  bsc_hom: {
    formioCssBasePath: 'https://shared.fr.world.socgen/sg-workflow/formiojs/4.11.3',
    uiBasePath: 'https://resg-sgworkflow-uat.group.socgen',
    workspaceApiBasePath: 'https://sgworkflow-uat.fr.world.socgen/api/workspaces/v1',
    taskApiBasePath: 'https://sgworkflow-uat.fr.world.socgen/api/tasks/v1',
    sgbpmApiBasePath: 'https://resg-sgworkflow-sgbpm-uat.group.socgen/api',
    dqhubApiBasePath: 'https://dqhub-uat-api.fr.world.socgen',
    defaultEngineId: 'bsc-emp',
    internetExposureEngineId: 'partners',
    unauthorizedPageUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://resg-sgworkflow-uat.group.socgen',
    apmEnabled: true,
    apmUrl: 'https://1b100480d71847759d39a5984c738d53.ece.sgmonitoring.dev.euw.gbis.sg-azure.com',
    auditWorkspaceId: [],
  },
  iso: {
    formioCssBasePath: 'https://shared.fr.world.socgen/sg-workflow/formiojs/4.11.3',
    uiBasePath: 'https://sgworkflow-preprod.fr.world.socgen',
    workspaceApiBasePath: 'https://sgworkflow-preprod.fr.world.socgen/api/workspaces/v1',
    taskApiBasePath: 'https://sgworkflow-preprod.fr.world.socgen/api/tasks/v1',
    sgbpmApiBasePath: 'https://sgworkflow-preprod.fr.world.socgen/api',
    dqhubApiBasePath: 'https://dqhub-uat-api.fr.world.socgen',
    defaultEngineId: 'common',
    internetExposureEngineId: 'partners',
    unauthorizedPageUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://sgworkflow-preprod.fr.world.socgen',
    apmEnabled: true,
    apmUrl: 'https://1b100480d71847759d39a5984c738d53.ece.sgmonitoring.dev.euw.gbis.sg-azure.com',
    auditWorkspaceId: [],
  },
  bsc_preprod: {
    formioCssBasePath: 'https://shared.fr.world.socgen/sg-workflow/formiojs/4.11.3',
    uiBasePath: 'https://resg-sgworkflow-preprod.group.socgen',
    workspaceApiBasePath: 'https://sgworkflow-preprod.fr.world.socgen/api/workspaces/v1',
    taskApiBasePath: 'https://sgworkflow-preprod.fr.world.socgen/api/tasks/v1',
    sgbpmApiBasePath: 'https://resg-sgworkflow-sgbpm-preprod.group.socgen/api',
    dqhubApiBasePath: 'https://dqhub-uat-api.fr.world.socgen',
    defaultEngineId: 'bsc-emp',
    internetExposureEngineId: 'partners',
    unauthorizedPageUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://resg-sgworkflow-preprod.group.socgen',
    apmEnabled: true,
    apmUrl: 'https://1b100480d71847759d39a5984c738d53.ece.sgmonitoring.dev.euw.gbis.sg-azure.com',
    auditWorkspaceId: [],
  },
  iso_tyk: {
    formioCssBasePath: 'https://shared.sgmarkets.com/sg-workflow/formiojs/4.11.3',
    uiBasePath: 'https://workflow-preprod.sgmarkets.com',
    workspaceApiBasePath: 'https://api-s-uat.sgmarkets.com/services/workflow/workspaces/private/v1',
    taskApiBasePath: 'https://api-s-uat.sgmarkets.com/services/workflow/tasks/private/v1',
    sgbpmApiBasePath: 'https://sgworkflow-preprod.fr.world.socgen/api',
    dqhubApiBasePath: 'https://dqhub-uat-api.fr.world.socgen',
    defaultEngineId: 'common',
    internetExposureEngineId: 'partners',
    unauthorizedPageUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://workflow-preprod.sgmarkets.com',
    apmEnabled: false,
    apmUrl: '',
    auditWorkspaceId: [],
  },
  production: {
    formioCssBasePath: 'https://shared.fr.world.socgen/sg-workflow/formiojs/4.11.3',
    uiBasePath: 'https://sgworkflow.fr.world.socgen',
    workspaceApiBasePath: 'https://sgworkflow.fr.world.socgen/api/workspaces/v1',
    taskApiBasePath: 'https://sgworkflow.fr.world.socgen/api/tasks/v1',
    sgbpmApiBasePath: 'https://sgworkflow.fr.world.socgen/api',
    dqhubApiBasePath: 'https://dqhub-prd-api.fr.world.socgen',
    defaultEngineId: 'common',
    internetExposureEngineId: 'partners',
    unauthorizedPageUrl: 'https://shared.sgmarkets.com/unauthorized/?redirectUri=https://sgworkflow.fr.world.socgen',
    apmEnabled: true,
    apmUrl: 'https://d23bbeb5ce4044bf9d77e89b4aea379e.ece.sgmonitoring.prd.euw.gbis.sg-azure.com/',
    auditWorkspaceId: [],
  },
  bsc_prod: {
    formioCssBasePath: 'https://shared.fr.world.socgen/sg-workflow/formiojs/4.11.3',
    uiBasePath: 'https://resg-sgworkflow.group.socgen',
    workspaceApiBasePath: 'https://sgworkflow.fr.world.socgen/api/workspaces/v1',
    taskApiBasePath: 'https://sgworkflow.fr.world.socgen/api/tasks/v1',
    sgbpmApiBasePath: 'https://resg-sgworkflow-sgbpm.group.socgen/api',
    dqhubApiBasePath: 'https://dqhub-prd-api.fr.world.socgen',
    defaultEngineId: 'bsc-emp',
    internetExposureEngineId: 'partners',
    unauthorizedPageUrl: 'https://shared.sgmarkets.com/unauthorized/?redirectUri=https://resg-sgworkflow.group.socgen',
    apmEnabled: true,
    apmUrl: 'https://d23bbeb5ce4044bf9d77e89b4aea379e.ece.sgmonitoring.prd.euw.gbis.sg-azure.com/',
    auditWorkspaceId: [],
  },
  prod_tyk: {
    formioCssBasePath: 'https://shared.sgmarkets.com/sg-workflow/formiojs/4.11.3',
    uiBasePath: 'https://workflow.sgmarkets.com',
    workspaceApiBasePath: 'https://api-s.sgmarkets.com/services/workflow/workspaces/private/v1',
    taskApiBasePath: 'https://api-s.sgmarkets.com/services/workflow/tasks/private/v1',
    sgbpmApiBasePath: 'https://sgworkflow.fr.world.socgen/api',
    dqhubApiBasePath: 'https://dqhub-prd-api.fr.world.socgen',
    defaultEngineId: 'common',
    internetExposureEngineId: 'partners',
    unauthorizedPageUrl: 'https://shared.sgmarkets.com/unauthorized/?redirectUri=https://workflow.sgmarkets.com',
    apmEnabled: false,
    apmUrl: '',
    auditWorkspaceId: [],
  },
  get(): SGWorkflowConfig {
    const confId: string = CONFIG.getConfigId();
    const conf = CONFIG[confId as keyof SGWorkflowEnvironments] as SGWorkflowConfig;
    // @ts-ignore
    if (window.SGFConfiguration && window.SGFConfiguration.default_engine_id) {
      // @ts-ignore
      conf.defaultEngineId = window.SGFConfiguration.default_engine_id;
    }
    return conf;
  },
  getConfigId(): string {
    // @ts-ignore
    let env: string = window.SGWTWidgetConfiguration.environment;
    // @ts-ignore
    if (window.SGFConfiguration && window.SGFConfiguration.widgets_env) {
      // @ts-ignore
      env = window.SGFConfiguration.widgets_env;
    }

    return env;
  },
  getRumServiceName(): string {
    let serviceName: string = 'external-ui';
    // @ts-ignore
    if (window.SGFConfiguration && window.SGFConfiguration.rum_serviceName) {
      // @ts-ignore
      serviceName = window.SGFConfiguration.rum_serviceName;
    }
    return serviceName;
  },
};
