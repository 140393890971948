import { getSupportContactList, getWorkspaceInfo } from '../state/actions-task';
import store from '../state/store';

export const createHelpMailTemplate = async (task: any, tenantIdentifier: any) => {
  const { sgWorkflowConfig, accessToken } = store.getState();
  // fill store with selected WS info.
  const workSpaceId = task ? task.tenantId : tenantIdentifier;
  await getWorkspaceInfo(workSpaceId);

  const { managers, title } = store.getState().selectedTaskWorkspaceInformation;

  let managerIds = managers.filter((m: any) => m.includes('@')).join('; ');
  let subject = '';
  let body = '';
  if (task) {
    const { id, name, processDefinitionKey, processDefinitionName, processInstanceBusinessKey } = task;

    subject = `[SGWorkflow - ${title}] Needs help on ${processDefinitionKey} - ${processDefinitionName} - ${processInstanceBusinessKey}`;

    body = `Hi, %0D%0A %0D%0AI need help on the task: ${name} (${id}). %0D%0A${window.location.href}%0D%0A %0D%0A ----%0D%0APlease include your question here.%0D%0A %0D%0A %0D%0ARegards,`;
  }

  if (!task) {
    const { processDefinitionKey, processDefinitionId } = store.getState().selectedWorkflow.config;

    subject = `[SGWorkflow - ${title}] Failed to launch ${processDefinitionId} - ${processDefinitionKey}`;

    body = `Hi, %0D%0A %0D%0AI need help to be able to launch the process: ${processDefinitionKey} %0D%0A${window.location.href}%0D%0A %0D%0A ----%0D%0APlease include your question here.%0D%0A %0D%0A %0D%0ARegards,`;
  }

  const mailUrl = `mailto:${managerIds}?subject=${subject}&body=${body}`;

  return window.open(mailUrl);
};

export const createTaskHelpMailTemplate = async (task: any) => {
  const { sgWorkflowConfig, accessToken } = store.getState();
  // fill store with selected WS info.
  const workSpaceId = task && task.tenantId;
  await getWorkspaceInfo(workSpaceId);

  const processInstanceId = task && task.processInstanceId;
  await getSupportContactList({ sgWorkflowConfig, accessToken }, processInstanceId);

  let managers = store.getState().selectedTaskSupportContactInformation;
  const { title } = store.getState().selectedTaskWorkspaceInformation;
  let managerIds = managers.filter((m: any) => m.includes('@')).join('; ');
  let subject = '';
  let body = '';

  if (task) {
    const { id, name, processDefinitionKey, processDefinitionName, processInstanceBusinessKey } = task;
    subject = `[SGWorkflow - ${title}] Needs help on ${processDefinitionKey} - ${processDefinitionName} - ${processInstanceBusinessKey}`;
    body = `Hi, %0D%0A %0D%0AI need help on the task: ${name} (${id}). %0D%0A${window.location.href}%0D%0A %0D%0A ----%0D%0APlease include your question here.%0D%0A %0D%0A %0D%0ARegards,`;

    const mailUrl = `mailto:${managerIds}?subject=${subject}&body=${body}`;
    if (window._paq) {
      window._paq.push(['trackEvent', 'Action', 'Contact support', processDefinitionName]);
    }

    return window.open(mailUrl);
  }
};

export const getFullNameFromEmail = function(email: string): string {
  let fullName = '';
  if (email.includes('@')) {
    fullName = email.includes('-ext@') ? email.split('-ext@')[0] : email.split('@')[0];
    const matchRegEx = /^([a-zA-Z0-9]+)([-_\.]+[a-zA-Z0-9]+)*/;
    const match = fullName.match(matchRegEx);
    if (match !== null && match.length > 1 && match[1].length > 0) {
      const firstName = match[1].charAt(0).toUpperCase() + match[1].slice(1);
      const lastName = match[2] && match[2].length > 0 ? match[2].slice(1).toUpperCase() : '';
      fullName = lastName && lastName.length > 0 ? `${firstName} ${lastName}` : firstName;
    }
  } else {
    fullName = email;
  }
  return fullName;
};

export const getErrorMessage = function(errorData?: any): string {
  if (errorData) {
    if (errorData.message) return errorData.message;

    if (errorData.error) {
      // SG Connect error message
      if (errorData.error === 'invalid_token') return 'There is an issue with the authentication token, reloading the page might solve this.';
    }
  }

  return 'An error occurred';
};
