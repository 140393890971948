// tslint:disable
/**
 * SG|Workflow - Workspaces API
 * SG|Workflow - Workspaces API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: list.world-dds-sgworkflow@socgen.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivityState
 */
export interface ActivityState {
    /**
     * 
     * @type {string}
     * @memberof ActivityState
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityState
     */
    ref?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityState
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityState
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityState
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface AuditLog
 */
export interface AuditLog {
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    eventName?: string;
    /**
     * 
     * @type {Date}
     * @memberof AuditLog
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    engineId?: string;
    /**
     * 
     * @type {object}
     * @memberof AuditLog
     */
    eventDetails?: object;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    eventCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    authId?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    requestId?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    performerId?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface BpmnConfig
 */
export interface BpmnConfig {
    /**
     * 
     * @type {string}
     * @memberof BpmnConfig
     */
    processDefinitionId?: string;
    /**
     * 
     * @type {string}
     * @memberof BpmnConfig
     */
    processDefinitionKey: string;
    /**
     * 
     * @type {string}
     * @memberof BpmnConfig
     */
    version?: string;
    /**
     * Id or key (slug) of the start form
     * @type {string}
     * @memberof BpmnConfig
     */
    startForm?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof BpmnConfig
     */
    embeddedStartForm?: { [key: string]: object; };
    /**
     * Name of the field holding the business key
     * @type {string}
     * @memberof BpmnConfig
     */
    businessKeyFieldName?: string;
    /**
     * 
     * @type {string}
     * @memberof BpmnConfig
     */
    requestorFieldName?: string;
    /**
     * 
     * @type {string}
     * @memberof BpmnConfig
     */
    beneficiaryFieldName?: string;
    /**
     * 
     * @type {string}
     * @memberof BpmnConfig
     */
    aggregatorBusinessDataRegex?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BpmnConfig
     */
    startWorkflowOnExistingCase?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BpmnConfig
     */
    startWorkflowUseLegacySubmission?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BusinessAndServiceUnit {
    ALL = 'ALL',
    AFMO = 'AFMO',
    ALDA = 'ALDA',
    AMER = 'AMER',
    ASIA = 'ASIA',
    ASSU = 'ASSU',
    BOURSORAMA = 'BOURSORAMA',
    COMM = 'COMM',
    CPLE = 'CPLE',
    DFIN = 'DFIN',
    DGLE = 'DGLE',
    EURO = 'EURO',
    GBSU = 'GBSU',
    GLBA = 'GLBA',
    GTPS = 'GTPS',
    HRCO = 'HRCO',
    IGAD = 'IGAD',
    ITIM = 'ITIM',
    MARK = 'MARK',
    PRIV = 'PRIV',
    RESG = 'RESG',
    RISQ = 'RISQ',
    SEGL = 'SEGL',
    SGEF = 'SGEF',
    SGRF = 'SGRF',
    SGSS = 'SGSS'
}

/**
 * 
 * @export
 * @interface CallVolume
 */
export interface CallVolume {
    /**
     * 
     * @type {number}
     * @memberof CallVolume
     */
    count?: number;
    /**
     * 
     * @type {TimePeriod}
     * @memberof CallVolume
     */
    period?: TimePeriod;
}
/**
 * 
 * @export
 * @interface CandidateGroup
 */
export interface CandidateGroup {
    /**
     * The id of the group.
     * @type {string}
     * @memberof CandidateGroup
     */
    id?: string;
    /**
     * The name of the group.
     * @type {string}
     * @memberof CandidateGroup
     */
    name?: string;
    /**
     * The type of the group.
     * @type {string}
     * @memberof CandidateGroup
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface CandidateGroupCreation
 */
export interface CandidateGroupCreation {
    /**
     * The name of the group.
     * @type {string}
     * @memberof CandidateGroupCreation
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CandidateUser
 */
export interface CandidateUser {
    /**
     * The id of the user.
     * @type {string}
     * @memberof CandidateUser
     */
    id?: string;
    /**
     * The firstName of the user.
     * @type {string}
     * @memberof CandidateUser
     */
    firstName?: string;
    /**
     * The lastName of the user.
     * @type {string}
     * @memberof CandidateUser
     */
    lastName?: string;
    /**
     * The email of the user.
     * @type {string}
     * @memberof CandidateUser
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface CandidateUserCreation
 */
export interface CandidateUserCreation {
    /**
     * The name of the group.
     * @type {string}
     * @memberof CandidateUserCreation
     */
    email?: string;
    /**
     * The name of the group.
     * @type {string}
     * @memberof CandidateUserCreation
     */
    firstName?: string;
    /**
     * The name of the group.
     * @type {string}
     * @memberof CandidateUserCreation
     */
    lastName?: string;
}
/**
 * 
 * @export
 * @interface Case
 */
export interface Case {
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface DeployedDecisionDefinition
 */
export interface DeployedDecisionDefinition {
    /**
     * 
     * @type {string}
     * @memberof DeployedDecisionDefinition
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DeployedDecisionDefinition
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof DeployedDecisionDefinition
     */
    version: string;
    /**
     * 
     * @type {string}
     * @memberof DeployedDecisionDefinition
     */
    resource?: string;
    /**
     * 
     * @type {string}
     * @memberof DeployedDecisionDefinition
     */
    versionTag?: string;
}
/**
 * 
 * @export
 * @interface DeployedProcessDefinition
 */
export interface DeployedProcessDefinition {
    /**
     * 
     * @type {string}
     * @memberof DeployedProcessDefinition
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DeployedProcessDefinition
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof DeployedProcessDefinition
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DeployedProcessDefinition
     */
    version: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeployedProcessDefinition
     */
    suspended: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeployedProcessDefinition
     */
    startable: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeployedProcessDefinition
     */
    versionTag?: string;
}
/**
 * 
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    logId?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface EvaluateDecisionDto
 */
export interface EvaluateDecisionDto {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof EvaluateDecisionDto
     */
    submission?: { [key: string]: object; };
}
/**
 * Event Subscription object
 * @export
 * @interface EventSubscription
 */
export interface EventSubscription {
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    businessKey?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    processDefinitionKey?: string;
    /**
     * 
     * @type {number}
     * @memberof EventSubscription
     */
    retry?: number;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface ExternalApiConfiguration
 */
export interface ExternalApiConfiguration extends WorkspaceRuntimeConfiguration {
    /**
     * 
     * @type {string}
     * @memberof ExternalApiConfiguration
     */
    baseUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApiConfiguration
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApiConfiguration
     */
    scopes: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApiConfiguration
     */
    secret: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalApiConfiguration
     */
    useDelegation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalApiConfiguration
     */
    allowSslSelfSigned?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ExternalApiConfiguration
     */
    connectionTimeout?: number;
    /**
     * 
     * @type {number}
     * @memberof ExternalApiConfiguration
     */
    readTimeout?: number;
}
/**
 * 
 * @export
 * @interface ExternalApiConfigurationAllOf
 */
export interface ExternalApiConfigurationAllOf {
    /**
     * 
     * @type {string}
     * @memberof ExternalApiConfigurationAllOf
     */
    baseUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApiConfigurationAllOf
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApiConfigurationAllOf
     */
    scopes: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApiConfigurationAllOf
     */
    secret: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalApiConfigurationAllOf
     */
    useDelegation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalApiConfigurationAllOf
     */
    allowSslSelfSigned?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ExternalApiConfigurationAllOf
     */
    connectionTimeout?: number;
    /**
     * 
     * @type {number}
     * @memberof ExternalApiConfigurationAllOf
     */
    readTimeout?: number;
}
/**
 * 
 * @export
 * @interface FavoriteProcessesPatch
 */
export interface FavoriteProcessesPatch {
    /**
     * 
     * @type {Array<string>}
     * @memberof FavoriteProcessesPatch
     */
    idsToAdd?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FavoriteProcessesPatch
     */
    idsToRemove?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface FileMultiPartBody
 */
export interface FileMultiPartBody {
    /**
     * 
     * @type {any}
     * @memberof FileMultiPartBody
     */
    file: any;
}
/**
 * 
 * @export
 * @interface Form
 */
export interface Form {
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    type?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Form
     */
    spec?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Form
     */
    options?: { [key: string]: object; };
}
/**
 * A historic activity instance
 * @export
 * @interface HistoricActivityInstanceDto
 */
export interface HistoricActivityInstanceDto {
    /**
     * The id of the activity instance.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    id?: string;
    /**
     * The id of the parent activity instance, for example a sub process instance.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    parentActivityInstanceId?: string;
    /**
     * The id of the activity that this object is an instance of.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    activityId?: string;
    /**
     * The name of the activity that this object is an instance of.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    activityName?: string;
    /**
     * The type of the activity that this object is an instance of.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    activityType?: string;
    /**
     * The key of the process definition that this activity instance belongs to.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    processDefinitionKey?: string;
    /**
     * The id of the process definition that this activity instance belongs to.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    processDefinitionId?: string;
    /**
     * The id of the process instance that this activity instance belongs to.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    processInstanceId?: string;
    /**
     * The id of the execution that executed this activity instance.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    executionId?: string;
    /**
     * The id of the task that is associated to this activity instance. Is only set if the activity is a user task.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    taskId?: string;
    /**
     * The assignee of the task that is associated to this activity instance. Is only set if the activity is a user task.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    assignee?: string;
    /**
     * The id of the called process instance. Is only set if the activity is a call activity and the called instance a process instance.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    calledProcessInstanceId?: string;
    /**
     * The id of the called case instance. Is only set if the activity is a call activity and the called instance a case instance.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    calledCaseInstanceId?: string;
    /**
     * The time the instance was started. Default format* yyyy-MM-dd\'T\'HH:mm:ss.SSSZ.
     * @type {Date}
     * @memberof HistoricActivityInstanceDto
     */
    startTime?: Date;
    /**
     * The time the instance ended. Default format* yyyy-MM-dd\'T\'HH:mm:ss.SSSZ.
     * @type {Date}
     * @memberof HistoricActivityInstanceDto
     */
    endTime?: Date;
    /**
     * The time the instance took to finish (in milliseconds).
     * @type {number}
     * @memberof HistoricActivityInstanceDto
     */
    durationInMillis?: number;
    /**
     * If true, this activity instance is canceled.
     * @type {boolean}
     * @memberof HistoricActivityInstanceDto
     */
    canceled?: boolean;
    /**
     * If true, this activity instance did complete a BPMN 2.0 scope.
     * @type {boolean}
     * @memberof HistoricActivityInstanceDto
     */
    completeScope?: boolean;
    /**
     * The tenant id of the activity instance.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    tenantId?: string;
    /**
     * The time after which the activity instance should be removed by the History Cleanup job. Default format* yyyy-MM-dd\'T\'HH:mm:ss.SSSZ.
     * @type {Date}
     * @memberof HistoricActivityInstanceDto
     */
    removalTime?: Date;
    /**
     * The process instance id of the root process instance that initiated the process containing this activity instance.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    rootProcessInstanceId?: string;
    /**
     * The name of the task that is associated to this activity instance. Is only set if the activity is a user task.
     * @type {string}
     * @memberof HistoricActivityInstanceDto
     */
    taskName?: string;
}
/**
 * A historic incident
 * @export
 * @interface HistoricIncidentDto
 */
export interface HistoricIncidentDto {
    /**
     * The id of the incident.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    id?: string;
    /**
     * The key of the process definition this incident is associated with.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    processDefinitionKey?: string;
    /**
     * The id of the process definition this incident is associated with.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    processDefinitionId?: string;
    /**
     * The key of the process definition this incident is associated with.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    processInstanceId?: string;
    /**
     * The id of the execution this incident is associated with.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    executionId?: string;
    /**
     * The time this incident happened. Default format* yyyy-MM-dd\'T\'HH:mm:ss.SSSZ.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    createTime?: string;
    /**
     * The time this incident has been deleted or resolved. Default format* yyyy-MM-dd\'T\'HH:mm:ss.SSSZ.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    endTime?: string;
    /**
     * The type of incident, for example: failedJobs will be returned in case of an incident which identified a failed job during the execution of a process instance. See the User Guide for a list of incident types.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    incidentType?: string;
    /**
     * The id of the activity this incident is associated with.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    activityId?: string;
    /**
     * The id of the activity on which the last exception occurred.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    failedActivityId?: string;
    /**
     * The id of the associated cause incident which has been triggered.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    causeIncidentId?: string;
    /**
     * The id of the associated root cause incident which has been triggered.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    rootCauseIncidentId?: string;
    /**
     * The payload of this incident.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    configuration?: string;
    /**
     * The message of this incident.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    incidentMessage?: string;
    /**
     * The id of the tenant this incident is associated with.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    tenantId?: string;
    /**
     * The job definition id the incident is associated with.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    jobDefinitionId?: string;
    /**
     * If true, this incident is open.
     * @type {boolean}
     * @memberof HistoricIncidentDto
     */
    open?: boolean;
    /**
     * If true, this incident has been deleted.
     * @type {boolean}
     * @memberof HistoricIncidentDto
     */
    deleted?: boolean;
    /**
     * If true, this incident has been resolved.
     * @type {boolean}
     * @memberof HistoricIncidentDto
     */
    resolved?: boolean;
    /**
     * The time after which the incident should be removed by the History Cleanup job. Default format* yyyy-MM-dd\'T\'HH:mm:ss.SSSZ.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    removalTime?: string;
    /**
     * The process instance id of the root process instance that initiated the process containing this incident.
     * @type {string}
     * @memberof HistoricIncidentDto
     */
    rootProcessInstanceId?: string;
}
/**
 * 
 * @export
 * @interface IncidentState
 */
export interface IncidentState {
    /**
     * 
     * @type {Array<string>}
     * @memberof IncidentState
     */
    incidentIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IncidentState
     */
    ref?: string;
    /**
     * 
     * @type {number}
     * @memberof IncidentState
     */
    count?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InformationLevel {
    C0 = 'C0',
    C1 = 'C1',
    C2 = 'C2',
    C3 = 'C3'
}

/**
 * 
 * @export
 * @interface Instance
 */
export interface Instance {
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    processDefinitionKey?: string;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    processDefinitionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    processDefinitionName?: string;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    businessKey?: string;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    workspaceTitle?: string;
    /**
     * 
     * @type {Date}
     * @memberof Instance
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Instance
     */
    endTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    initiator?: string;
    /**
     * Instance variables.
     * @type {Array<InstanceVariable>}
     * @memberof Instance
     */
    variables?: Array<InstanceVariable>;
    /**
     * 
     * @type {number}
     * @memberof Instance
     */
    processDefinitionVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    lastTaskName?: string;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    lastTaskAssignee?: string;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    duration?: string;
    /**
     * 
     * @type {string}
     * @memberof Instance
     */
    engineId: string;
    /**
     * 
     * @type {Array<ActivityState>}
     * @memberof Instance
     */
    activities?: Array<ActivityState>;
    /**
     * 
     * @type {Array<InstanceTaskSummary>}
     * @memberof Instance
     */
    activeTasks?: Array<InstanceTaskSummary>;
    /**
     * 
     * @type {Array<InstanceTaskSummary>}
     * @memberof Instance
     */
    historicTasks?: Array<InstanceTaskSummary>;
    /**
     * 
     * @type {number}
     * @memberof Instance
     */
    numberOfIncidents?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Instance
     */
    viewerMode?: boolean;
}
/**
 * 
 * @export
 * @interface InstanceState
 */
export interface InstanceState {
    /**
     * 
     * @type {Array<ActivityState>}
     * @memberof InstanceState
     */
    activities?: Array<ActivityState>;
    /**
     * 
     * @type {Array<IncidentState>}
     * @memberof InstanceState
     */
    incidents?: Array<IncidentState>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InstanceStatus {
    InProgress = 'In progress',
    Completed = 'Completed',
    Cancelled = 'Cancelled',
    Suspended = 'Suspended',
    All = 'All'
}

/**
 * 
 * @export
 * @interface InstanceTaskSummary
 */
export interface InstanceTaskSummary {
    /**
     * 
     * @type {string}
     * @memberof InstanceTaskSummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceTaskSummary
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceTaskSummary
     */
    taskDefinitionKey: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceTaskSummary
     */
    assignee?: string;
    /**
     * 
     * @type {Date}
     * @memberof InstanceTaskSummary
     */
    createTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof InstanceTaskSummary
     */
    endTime?: Date;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InstanceTypeView {
    MYPROCESS = 'MY_PROCESS',
    ADMIN = 'ADMIN',
    VIEWER = 'VIEWER'
}

/**
 * 
 * @export
 * @interface InstanceUpdateRequest
 */
export interface InstanceUpdateRequest {
    /**
     * Should the Process instance being suspended/resumed.
     * @type {boolean}
     * @memberof InstanceUpdateRequest
     */
    suspended?: boolean;
}
/**
 * 
 * @export
 * @interface InstanceVariable
 */
export interface InstanceVariable {
    /**
     * The variable\'s name.
     * @type {string}
     * @memberof InstanceVariable
     */
    name?: string;
    /**
     * The variable\'s value. Value differs depending on the variable\'s type and on the deserializeValues parameter.
     * @type {string}
     * @memberof InstanceVariable
     */
    value?: string;
    /**
     * The value type of the variable.
     * @type {string}
     * @memberof InstanceVariable
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    id: string;
    /**
     * The process instance id this message is associated with.
     * @type {string}
     * @memberof Message
     */
    processInstanceId: string;
    /**
     * 
     * @type {Date}
     * @memberof Message
     */
    createdAt: Date;
    /**
     * The email of the user.
     * @type {string}
     * @memberof Message
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    text: string;
}
/**
 * 
 * @export
 * @interface MessageCount
 */
export interface MessageCount {
    /**
     * 
     * @type {number}
     * @memberof MessageCount
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface MessageCreation
 */
export interface MessageCreation {
    /**
     * The process instance id this message is associated with.
     * @type {string}
     * @memberof MessageCreation
     */
    processInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof MessageCreation
     */
    text: string;
}
/**
 * Message Event Send Request
 * @export
 * @interface MessageEventSendRequest
 */
export interface MessageEventSendRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageEventSendRequest
     */
    businessKey: string;
    /**
     * 
     * @type {string}
     * @memberof MessageEventSendRequest
     */
    workspaceId: string;
    /**
     * 
     * @type {string}
     * @memberof MessageEventSendRequest
     */
    messageName: string;
}
/**
 * Model metadata required if the model doesn\'t exists
 * @export
 * @interface ModelCreation
 */
export interface ModelCreation {
    /**
     * 
     * @type {string}
     * @memberof ModelCreation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCreation
     */
    description: string;
}
/**
 * One of existingModelId or newModel is mandatory, and one of modelFileName and v3ModelIdentifier is mandatory
 * @export
 * @interface ModelImport
 */
export interface ModelImport {
    /**
     * Corresponding Model id that exists in previous version
     * @type {string}
     * @memberof ModelImport
     */
    existingModelId?: string;
    /**
     * 
     * @type {ModelCreation}
     * @memberof ModelImport
     */
    newModel?: ModelCreation;
    /**
     * 
     * @type {string}
     * @memberof ModelImport
     */
    version: string;
    /**
     * 
     * @type {string}
     * @memberof ModelImport
     */
    description: string;
    /**
     * Required if the model content is imported from the file
     * @type {string}
     * @memberof ModelImport
     */
    fileName?: string;
    /**
     * 
     * @type {V3ModelIdentifier}
     * @memberof ModelImport
     */
    v3Identifier?: V3ModelIdentifier;
}
/**
 * 
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * 
     * @type {Pageable}
     * @memberof Page
     */
    pageable?: Pageable;
    /**
     * 
     * @type {Sort}
     * @memberof Page
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageOfESDataset
 */
export interface PageOfESDataset {
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfESDataset
     */
    pageable?: Pageable;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfESDataset
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfESDataset
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfESDataset
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PageOfESDataset
     */
    content?: Array<string>;
}
/**
 * 
 * @export
 * @interface PageOfESDatasetAllOf
 */
export interface PageOfESDatasetAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof PageOfESDatasetAllOf
     */
    content?: Array<string>;
}
/**
 * 
 * @export
 * @interface PageOfEventSubscription
 */
export interface PageOfEventSubscription {
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfEventSubscription
     */
    pageable?: Pageable;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfEventSubscription
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfEventSubscription
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfEventSubscription
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<EventSubscription>}
     * @memberof PageOfEventSubscription
     */
    content?: Array<EventSubscription>;
}
/**
 * 
 * @export
 * @interface PageOfEventSubscriptionAllOf
 */
export interface PageOfEventSubscriptionAllOf {
    /**
     * 
     * @type {Array<EventSubscription>}
     * @memberof PageOfEventSubscriptionAllOf
     */
    content?: Array<EventSubscription>;
}
/**
 * 
 * @export
 * @interface PageOfInstance
 */
export interface PageOfInstance {
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfInstance
     */
    pageable?: Pageable;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfInstance
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfInstance
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfInstance
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<Instance>}
     * @memberof PageOfInstance
     */
    content?: Array<Instance>;
}
/**
 * 
 * @export
 * @interface PageOfInstanceAllOf
 */
export interface PageOfInstanceAllOf {
    /**
     * 
     * @type {Array<Instance>}
     * @memberof PageOfInstanceAllOf
     */
    content?: Array<Instance>;
}
/**
 * 
 * @export
 * @interface PageOfMessage
 */
export interface PageOfMessage {
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfMessage
     */
    pageable?: Pageable;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfMessage
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfMessage
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfMessage
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<Message>}
     * @memberof PageOfMessage
     */
    content?: Array<Message>;
}
/**
 * 
 * @export
 * @interface PageOfMessageAllOf
 */
export interface PageOfMessageAllOf {
    /**
     * 
     * @type {Array<Message>}
     * @memberof PageOfMessageAllOf
     */
    content?: Array<Message>;
}
/**
 * 
 * @export
 * @interface PageOfStartableProcess
 */
export interface PageOfStartableProcess {
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfStartableProcess
     */
    pageable?: Pageable;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfStartableProcess
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfStartableProcess
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfStartableProcess
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<StartableProcess>}
     * @memberof PageOfStartableProcess
     */
    content?: Array<StartableProcess>;
}
/**
 * 
 * @export
 * @interface PageOfStartableProcessAllOf
 */
export interface PageOfStartableProcessAllOf {
    /**
     * 
     * @type {Array<StartableProcess>}
     * @memberof PageOfStartableProcessAllOf
     */
    content?: Array<StartableProcess>;
}
/**
 * 
 * @export
 * @interface PageOfWorkflow
 */
export interface PageOfWorkflow {
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfWorkflow
     */
    pageable?: Pageable;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfWorkflow
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfWorkflow
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfWorkflow
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<Workflow>}
     * @memberof PageOfWorkflow
     */
    content?: Array<Workflow>;
}
/**
 * 
 * @export
 * @interface PageOfWorkflowAllOf
 */
export interface PageOfWorkflowAllOf {
    /**
     * 
     * @type {Array<Workflow>}
     * @memberof PageOfWorkflowAllOf
     */
    content?: Array<Workflow>;
}
/**
 * 
 * @export
 * @interface PageOfWorkflowJob
 */
export interface PageOfWorkflowJob {
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfWorkflowJob
     */
    pageable?: Pageable;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfWorkflowJob
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfWorkflowJob
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfWorkflowJob
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<WorkflowJob>}
     * @memberof PageOfWorkflowJob
     */
    content?: Array<WorkflowJob>;
}
/**
 * 
 * @export
 * @interface PageOfWorkflowJobAllOf
 */
export interface PageOfWorkflowJobAllOf {
    /**
     * 
     * @type {Array<WorkflowJob>}
     * @memberof PageOfWorkflowJobAllOf
     */
    content?: Array<WorkflowJob>;
}
/**
 * 
 * @export
 * @interface PageOfWorkflowMetadata
 */
export interface PageOfWorkflowMetadata {
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfWorkflowMetadata
     */
    pageable?: Pageable;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfWorkflowMetadata
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfWorkflowMetadata
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfWorkflowMetadata
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<WorkflowMetadata>}
     * @memberof PageOfWorkflowMetadata
     */
    content?: Array<WorkflowMetadata>;
}
/**
 * 
 * @export
 * @interface PageOfWorkflowMetadataAllOf
 */
export interface PageOfWorkflowMetadataAllOf {
    /**
     * 
     * @type {Array<WorkflowMetadata>}
     * @memberof PageOfWorkflowMetadataAllOf
     */
    content?: Array<WorkflowMetadata>;
}
/**
 * 
 * @export
 * @interface PageOfWorkflowVersion
 */
export interface PageOfWorkflowVersion {
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfWorkflowVersion
     */
    pageable?: Pageable;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfWorkflowVersion
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfWorkflowVersion
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfWorkflowVersion
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<WorkflowVersion>}
     * @memberof PageOfWorkflowVersion
     */
    content?: Array<WorkflowVersion>;
}
/**
 * 
 * @export
 * @interface PageOfWorkflowVersionAllOf
 */
export interface PageOfWorkflowVersionAllOf {
    /**
     * 
     * @type {Array<WorkflowVersion>}
     * @memberof PageOfWorkflowVersionAllOf
     */
    content?: Array<WorkflowVersion>;
}
/**
 * 
 * @export
 * @interface PageOfWorkspace
 */
export interface PageOfWorkspace {
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfWorkspace
     */
    pageable?: Pageable;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfWorkspace
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfWorkspace
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfWorkspace
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<Workspace>}
     * @memberof PageOfWorkspace
     */
    content?: Array<Workspace>;
}
/**
 * 
 * @export
 * @interface PageOfWorkspaceAllOf
 */
export interface PageOfWorkspaceAllOf {
    /**
     * 
     * @type {Array<Workspace>}
     * @memberof PageOfWorkspaceAllOf
     */
    content?: Array<Workspace>;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    pageSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    paged?: boolean;
    /**
     * 
     * @type {Sort}
     * @memberof Pageable
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    unpaged?: boolean;
}
/**
 * 
 * @export
 * @interface ProcessConfig
 */
export interface ProcessConfig {
    /**
     * 
     * @type {string}
     * @memberof ProcessConfig
     */
    startFormKey?: string | null;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProcessConfig
     */
    embeddedStartForm?: { [key: string]: object; } | null;
    /**
     * Name of the field holding the business key
     * @type {string}
     * @memberof ProcessConfig
     */
    businessKeyFieldName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessConfig
     */
    requestorFieldName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessConfig
     */
    beneficiaryFieldName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessConfig
     */
    aggregatorBusinessDataRegex?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessConfig
     */
    startWorkflowOnExistingCase?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessConfig
     */
    startWorkflowUseLegacySubmission?: boolean;
}
/**
 * 
 * @export
 * @interface ProcessDefinitionDto
 */
export interface ProcessDefinitionDto {
    /**
     * The id of the process definition
     * @type {string}
     * @memberof ProcessDefinitionDto
     */
    id?: string;
    /**
     * The key of the process definition, i.e., the id of the BPMN 2.0 XML process definition.
     * @type {string}
     * @memberof ProcessDefinitionDto
     */
    key?: string;
    /**
     * The category of the process definition.
     * @type {string}
     * @memberof ProcessDefinitionDto
     */
    category?: string;
    /**
     * The description of the process definition.
     * @type {string}
     * @memberof ProcessDefinitionDto
     */
    description?: string;
    /**
     * The name of the process definition.
     * @type {string}
     * @memberof ProcessDefinitionDto
     */
    name?: string;
    /**
     * The version of the process definition that the engine assigned to it.
     * @type {number}
     * @memberof ProcessDefinitionDto
     */
    version?: number;
    /**
     * The file name of the process definition.
     * @type {string}
     * @memberof ProcessDefinitionDto
     */
    resource?: string;
    /**
     * The deployment id of the process definition.
     * @type {string}
     * @memberof ProcessDefinitionDto
     */
    deploymentId?: string;
    /**
     * The file name of the process definition diagram, if it exists.
     * @type {string}
     * @memberof ProcessDefinitionDto
     */
    diagram?: string;
    /**
     * A flag indicating whether the definition is suspended or not.
     * @type {boolean}
     * @memberof ProcessDefinitionDto
     */
    suspended?: boolean;
    /**
     * The tenant id of the process definition.
     * @type {string}
     * @memberof ProcessDefinitionDto
     */
    tenantId?: string;
    /**
     * The version tag of the process definition.
     * @type {string}
     * @memberof ProcessDefinitionDto
     */
    versionTag?: string;
    /**
     * History time to live value of the process definition. Is used within [History cleanup](https://docs.camunda.org/manual/7.13/user-guide/process-engine/history/#history-cleanup).
     * @type {number}
     * @memberof ProcessDefinitionDto
     */
    historyTimeToLive?: number;
    /**
     * A flag indicating whether the process definition is startable in Tasklist or not.
     * @type {boolean}
     * @memberof ProcessDefinitionDto
     */
    startableInTasklist?: boolean;
}
/**
 * 
 * @export
 * @interface ProcessDefinitionModelData
 */
export interface ProcessDefinitionModelData {
    /**
     * The id of the process definition.
     * @type {string}
     * @memberof ProcessDefinitionModelData
     */
    id?: string;
    /**
     * An escaped XML string containing the XML that this definition was deployed with. Carriage returns, line feeds and quotation marks are escaped.
     * @type {string}
     * @memberof ProcessDefinitionModelData
     */
    processModelData?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProcessLevel {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum SGDomain {
    BANKINGACTIVITIES = 'BANKING_ACTIVITIES',
    BUSINESSANDFUNCTIONSSUPPORT = 'BUSINESS_AND_FUNCTIONS_SUPPORT',
    CRMANDMARKETING = 'CRM_AND_MARKETING',
    REFERENCEDATA = 'REFERENCE_DATA',
    TRANSVERSALSTEERING = 'TRANSVERSAL_STEERING'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum SGSubDomain {
    ASSETMANAGEMENTANDPRIVATEBANKING = 'ASSET_MANAGEMENT_AND_PRIVATE_BANKING',
    FINANCINGSERVICES = 'FINANCING_SERVICES',
    INSURANCE = 'INSURANCE',
    MARKETACTIVITIES = 'MARKET_ACTIVITIES',
    NONBANKINGSERVICES = 'NON_BANKING_SERVICES',
    PAYMENTSERVICES = 'PAYMENT_SERVICES',
    REALESTATE = 'REAL_ESTATE',
    SAVINGANDINVESTMENTS = 'SAVING_AND_INVESTMENTS',
    SECURITIESSERVICES = 'SECURITIES_SERVICES',
    SECURITYISSUANCE = 'SECURITY_ISSUANCE',
    STANDARDBANKINGSERVICES = 'STANDARD_BANKING_SERVICES',
    TRANSVERSALACTIVITIES = 'TRANSVERSAL_ACTIVITIES',
    COMMUNICATION = 'COMMUNICATION',
    COMPANYSTRANSFORMATION = 'COMPANYS_TRANSFORMATION',
    HUMANRESOURCES = 'HUMAN_RESOURCES',
    INFORMATIONSYSTEM = 'INFORMATION_SYSTEM',
    PROPERTY = 'PROPERTY',
    PURCHASE = 'PURCHASE',
    BUSINESSPERFORMANCEANDPROFITABILITY = 'BUSINESS_PERFORMANCE_AND_PROFITABILITY',
    CLIENTRELATIONSHIPMANAGEMENT = 'CLIENT_RELATIONSHIP_MANAGEMENT',
    COMMERCIALMANAGEMENTANDMARKETING = 'COMMERCIAL_MANAGEMENT_AND_MARKETING',
    PARTNERRELATIONSHIPMANAGEMENT = 'PARTNER_RELATIONSHIP_MANAGEMENT',
    ASSETSANDGOODS = 'ASSETS_AND_GOODS',
    CALENDAR = 'CALENDAR',
    CHARTOFACCOUNT = 'CHART_OF_ACCOUNT',
    COMPLIANCELISTS = 'COMPLIANCE_LISTS',
    COMPLIANCENOMENCLATURES = 'COMPLIANCE_NOMENCLATURES',
    CURRENCIES = 'CURRENCIES',
    GENERICAGREEMENT = 'GENERIC_AGREEMENT',
    GEOGRAPHIES = 'GEOGRAPHIES',
    LEGALANDTAX = 'LEGAL_AND_TAX',
    MARKETDATAANDINSTRUMENTS = 'MARKET_DATA_AND_INSTRUMENTS',
    PROCESSESANDACTIVITIES = 'PROCESSES_AND_ACTIVITIES',
    PRODUCTTYPES = 'PRODUCT_TYPES',
    RATINGSCALE = 'RATING_SCALE',
    RESOURCES = 'RESOURCES',
    RISKSTYPES = 'RISKS_TYPES',
    SERVICETYPOLOGY = 'SERVICE_TYPOLOGY',
    STRUCTUREANDORGANIZATION = 'STRUCTURE_AND_ORGANIZATION',
    THIRDPARTY = 'THIRD_PARTY',
    COMPLIANCE = 'COMPLIANCE',
    FINANCE = 'FINANCE',
    GENERALSECRETARIAT = 'GENERAL_SECRETARIAT',
    INTERNALCONTROL = 'INTERNAL_CONTROL',
    RISK = 'RISK'
}

/**
 * 
 * @export
 * @interface SanitizerResult
 */
export interface SanitizerResult {
    /**
     * 
     * @type {string}
     * @memberof SanitizerResult
     */
    sanitizedXml?: string;
    /**
     * 
     * @type {{ [key: string]: Array<ValidationResult>; }}
     * @memberof SanitizerResult
     */
    validationResults?: { [key: string]: Array<ValidationResult>; };
}
/**
 * 
 * @export
 * @interface SecretConfiguration
 */
export interface SecretConfiguration extends WorkspaceRuntimeConfiguration {
    /**
     * 
     * @type {string}
     * @memberof SecretConfiguration
     */
    secret: string;
}
/**
 * 
 * @export
 * @interface SecretConfigurationAllOf
 */
export interface SecretConfigurationAllOf {
    /**
     * 
     * @type {string}
     * @memberof SecretConfigurationAllOf
     */
    secret: string;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    unsorted?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SortByActivityInstanceHistory {
    ActivityInstanceId = 'activityInstanceId',
    InstanceId = 'instanceId',
    ExecutionId = 'executionId',
    ActivityId = 'activityId',
    ActivityName = 'activityName',
    ActivityType = 'activityType',
    StartTime = 'startTime',
    EndTime = 'endTime',
    Duration = 'duration',
    DefinitionId = 'definitionId',
    Occurrence = 'occurrence',
    TenantId = 'tenantId'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum SortByIncidentHistory {
    IncidentId = 'incidentId',
    IncidentMessage = 'incidentMessage',
    CreateTime = 'createTime',
    EndTime = 'endTime',
    IncidentType = 'incidentType',
    ExecutionId = 'executionId',
    ActivityId = 'activityId',
    ProcessInstanceId = 'processInstanceId',
    ProcessDefinitionId = 'processDefinitionId',
    ProcessDefinitionKey = 'processDefinitionKey',
    CauseIncidentId = 'causeIncidentId',
    RootCauseIncidentId = 'rootCauseIncidentId',
    Configuration = 'configuration',
    TenantId = 'tenantId',
    IncidentState = 'incidentState'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum SortByInstanceHistory {
    StartTime = 'startTime',
    EndTime = 'endTime',
    Duration = 'duration',
    DefinitionId = 'definitionId',
    BusinessKey = 'businessKey'
}

/**
 * 
 * @export
 * @interface StartWorkflowSpecification
 */
export interface StartWorkflowSpecification {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StartWorkflowSpecification
     */
    spec: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StartWorkflowSpecification
     */
    submission?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface StartWorkflowSubmission
 */
export interface StartWorkflowSubmission {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StartWorkflowSubmission
     */
    submission: { [key: string]: object; };
    /**
     * 
     * @type {Case}
     * @memberof StartWorkflowSubmission
     */
    _case?: Case;
}
/**
 * 
 * @export
 * @interface StartableProcess
 */
export interface StartableProcess {
    /**
     * 
     * @type {string}
     * @memberof StartableProcess
     */
    id: string;
    /**
     * The key of the process definition, i.e., the id of the BPMN 2.0 XML process definition.
     * @type {string}
     * @memberof StartableProcess
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof StartableProcess
     */
    name?: string | null;
    /**
     * The version of the process definition that the engine assigned to it.
     * @type {number}
     * @memberof StartableProcess
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof StartableProcess
     */
    versionTag?: string;
    /**
     * 
     * @type {string}
     * @memberof StartableProcess
     */
    workspaceId: string;
    /**
     * 
     * @type {string}
     * @memberof StartableProcess
     */
    workspaceName?: string;
    /**
     * 
     * @type {string}
     * @memberof StartableProcess
     */
    workflowId: string;
    /**
     * 
     * @type {string}
     * @memberof StartableProcess
     */
    workflowName?: string;
    /**
     * 
     * @type {number}
     * @memberof StartableProcess
     */
    workflowVersion: number;
    /**
     * 
     * @type {Date}
     * @memberof StartableProcess
     */
    workflowDeploymentDate?: Date;
    /**
     * 
     * @type {ProcessConfig}
     * @memberof StartableProcess
     */
    config?: ProcessConfig;
}
/**
 * 
 * @export
 * @interface StartedWorkflowSubmission
 */
export interface StartedWorkflowSubmission {
    /**
     * Process Instance identifier for the workflow that have been started
     * @type {string}
     * @memberof StartedWorkflowSubmission
     */
    processInstanceId?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StartedWorkflowSubmission
     */
    submission: { [key: string]: object; };
    /**
     * 
     * @type {Case}
     * @memberof StartedWorkflowSubmission
     */
    _case?: Case;
}
/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    taskDefinitionKey?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    assignee?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    owner?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    tenantId: string;
    /**
     * 
     * @type {Date}
     * @memberof Task
     */
    createTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof Task
     */
    dueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    formKey?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    processDefinitionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    processInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof Task
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Task
     */
    ended?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Task
     */
    followUp?: Date;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    executionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    caseExecutionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    caseDefinitionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    caseInstanceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    suspended?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    concernsUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    completed?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Task
     */
    candidateUsers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Task
     */
    candidateGroups?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    processDefinitionKey?: string;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    processVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    processDefinitionName?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    processInstanceBusinessKey?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    deleteReason?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    clusterId?: string;
    /**
     * 
     * @type {Array<TaskVariable>}
     * @memberof Task
     */
    variables?: Array<TaskVariable>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Task
     */
    formSubmission?: { [key: string]: object; };
    /**
     * 
     * @type {Form}
     * @memberof Task
     */
    form?: Form;
    /**
     * 
     * @type {TaskType}
     * @memberof Task
     */
    taskType?: TaskType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TaskType {
    Camunda = 'camunda'
}

/**
 * 
 * @export
 * @interface TaskVariable
 */
export interface TaskVariable {
    /**
     * 
     * @type {string}
     * @memberof TaskVariable
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TaskVariable
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof TaskVariable
     */
    type: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TaskVariable
     */
    valueInfo?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TimePeriod {
    HOUR = 'HOUR',
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

/**
 * 
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof UserSettings
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserSettings
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    updatedBy?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UserSettings
     */
    settings?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UserType {
    CANDIDATESTARTER = 'CANDIDATE_STARTER',
    MANAGER = 'MANAGER'
}

/**
 * Required if the model content is imported from an existing V3 model (SG-BPM)
 * @export
 * @interface V3ModelIdentifier
 */
export interface V3ModelIdentifier {
    /**
     * 
     * @type {string}
     * @memberof V3ModelIdentifier
     */
    engineId: string;
    /**
     * 
     * @type {string}
     * @memberof V3ModelIdentifier
     */
    storeId: string;
    /**
     * 
     * @type {string}
     * @memberof V3ModelIdentifier
     */
    modelName: string;
    /**
     * 
     * @type {number}
     * @memberof V3ModelIdentifier
     */
    modelVersion: number;
}
/**
 * 
 * @export
 * @interface ValidationResult
 */
export interface ValidationResult {
    /**
     * 
     * @type {string}
     * @memberof ValidationResult
     */
    type?: ValidationResultTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ValidationResult
     */
    elementId?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationResult
     */
    elementType?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationResult
     */
    message?: string;
    /**
     * 
     * @type {number}
     * @memberof ValidationResult
     */
    code?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ValidationResultTypeEnum {
    ERROR = 'ERROR',
    WARNING = 'WARNING'
}

/**
 * 
 * @export
 * @interface ValidationResults
 */
export interface ValidationResults {
    /**
     * 
     * @type {{ [key: string]: Array<ValidationResult>; }}
     * @memberof ValidationResults
     */
    results?: { [key: string]: Array<ValidationResult>; };
}
/**
 * 
 * @export
 * @interface Variable
 */
export interface Variable {
    /**
     * The variable\'s value. Value differs depending on the variable\'s type and on the deserializeValues parameter.
     * @type {string}
     * @memberof Variable
     */
    value?: string;
    /**
     * The value type of the variable.
     * @type {string}
     * @memberof Variable
     */
    type?: string;
    /**
     * A JSON object containing additional, value-type-dependent properties. For serialized variables of type Object, the following properties can be provided:  * `objectTypeName`: A string representation of the object\'s type name. * `serializationDataFormat`: The serialization format used to store the variable.  For serialized variables of type File, the following properties can be provided:  * `filename`: The name of the file. This is not the variable name but the name that will be used when downloading the file again. * `mimetype`: The MIME type of the file that is being uploaded. * `encoding`: The encoding of the file that is being uploaded.
     * @type {{ [key: string]: object; }}
     * @memberof Variable
     */
    valueInfo?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface Workflow
 */
export interface Workflow {
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    workspaceId: string;
    /**
     * The scenario type
     * @type {string}
     * @memberof Workflow
     */
    type: WorkflowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    owner?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    ownerBackup?: string;
    /**
     * 
     * @type {Date}
     * @memberof Workflow
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Workflow
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workflow
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    engineId: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    presentation: string;
    /**
     * 
     * @type {boolean}
     * @memberof Workflow
     */
    enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Workflow
     */
    launchable: boolean;
    /**
     * 
     * @type {number}
     * @memberof Workflow
     */
    version: number;
    /**
     * 
     * @type {number}
     * @memberof Workflow
     */
    weight?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Workflow
     */
    data?: { [key: string]: object; };
    /**
     * 
     * @type {WorkflowConfig}
     * @memberof Workflow
     */
    config?: WorkflowConfig;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    exposition?: WorkflowExpositionEnum;
    /**
     * 
     * @type {WorkflowLifeCycle}
     * @memberof Workflow
     */
    lifeCycle: WorkflowLifeCycle;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    producerOrganizationalUnit?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    ownerOrganizationalUnit?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    organizationalUnit?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    uptimePeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    downtimePeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    supportPeriod?: string;
    /**
     * 
     * @type {Date}
     * @memberof Workflow
     */
    releasedAt?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workflow
     */
    initiators?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workflow
     */
    initiatorGroups?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    sourceRepository?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    dataSensitivity?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    businessProcesses?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    criticalityLevel?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workflow
     */
    assetClasses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    mainDomain?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    modeler?: string;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    modelerBackup?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workflow
     */
    supportContacts?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Workflow
     */
    productOwner?: string;
    /**
     * 
     * @type {Array<WorkflowModelVersion>}
     * @memberof Workflow
     */
    models: Array<WorkflowModelVersion>;
    /**
     * 
     * @type {WorkflowDeployment}
     * @memberof Workflow
     */
    deployment?: WorkflowDeployment;
    /**
     * 
     * @type {ProcessLevel}
     * @memberof Workflow
     */
    processCriticality?: ProcessLevel;
    /**
     * 
     * @type {ProcessLevel}
     * @memberof Workflow
     */
    processComplexity?: ProcessLevel;
    /**
     * 
     * @type {CallVolume}
     * @memberof Workflow
     */
    expectedVolume?: CallVolume;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workflow
     */
    calledAPIs?: Array<string>;
    /**
     * 
     * @type {Array<BusinessAndServiceUnit>}
     * @memberof Workflow
     */
    buSuTargetUsers?: Array<BusinessAndServiceUnit>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workflow
     */
    managers?: Array<string>;
    /**
     * 
     * @type {Array<WorldRegion>}
     * @memberof Workflow
     */
    regions?: Array<WorldRegion>;
    /**
     * 
     * @type {Array<InformationLevel>}
     * @memberof Workflow
     */
    informationLevels?: Array<InformationLevel>;
    /**
     * always false for now
     * @type {boolean}
     * @memberof Workflow
     */
    managingPersonalData?: boolean;
    /**
     * in years
     * @type {number}
     * @memberof Workflow
     */
    retentionDuration?: number;
    /**
     * 
     * @type {SGSubDomain}
     * @memberof Workflow
     */
    businessSubDomain?: SGSubDomain;
    /**
     * 
     * @type {boolean}
     * @memberof Workflow
     */
    isPublic?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum WorkflowTypeEnum {
    BPMN = 'BPMN',
    SGBPM = 'SGBPM'
}
/**
    * @export
    * @enum {string}
    */
export enum WorkflowExpositionEnum {
    Hidden = 'hidden',
    Restricted = 'restricted',
    Group = 'group',
    Partner = 'partner',
    Public = 'public'
}

/**
 * @type WorkflowConfig
 * @export
 */
export type WorkflowConfig = BpmnConfig;

/**
 * 
 * @export
 * @interface WorkflowDeployment
 */
export interface WorkflowDeployment {
    /**
     * 
     * @type {string}
     * @memberof WorkflowDeployment
     */
    engineDeploymentId: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDeployment
     */
    engineDeploymentName: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkflowDeployment
     */
    deploymentTime: Date;
    /**
     * 
     * @type {Array<DeployedProcessDefinition>}
     * @memberof WorkflowDeployment
     */
    deployedProcesses: Array<DeployedProcessDefinition>;
    /**
     * 
     * @type {Array<DeployedDecisionDefinition>}
     * @memberof WorkflowDeployment
     */
    deployedDecisions: Array<DeployedDecisionDefinition>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowDeployment
     */
    instanceViewers?: Array<string>;
}
/**
 * Workflow Job
 * @export
 * @interface WorkflowJob
 */
export interface WorkflowJob {
    /**
     * 
     * @type {string}
     * @memberof WorkflowJob
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowJob
     */
    failedActivityId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowJob
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowJob
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowJob
     */
    processDefinitionKey?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowJob
     */
    retry?: number;
    /**
     * 
     * @type {Date}
     * @memberof WorkflowJob
     */
    createDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkflowJob
     */
    dueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof WorkflowJob
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowJob
     */
    type?: string;
}
/**
 * Workflow Job Increment Retries Request
 * @export
 * @interface WorkflowJobIncrementRetries
 */
export interface WorkflowJobIncrementRetries {
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowJobIncrementRetries
     */
    jobIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WorkflowJobIncrementRetries
     */
    workspaceId: string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowJobIncrementRetries
     */
    increment?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkflowJobState {
    RUNNING = 'RUNNING',
    INERROR = 'IN_ERROR'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkflowLifeCycle {
    InPOC = 'InPOC',
    InProgress = 'InProgress',
    Released = 'Released',
    Deprecated = 'Deprecated',
    Decommissioned = 'Decommissioned'
}

/**
 * 
 * @export
 * @interface WorkflowMetadata
 */
export interface WorkflowMetadata {
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    workspaceId: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    workspaceName?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    engineId: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    comment?: string;
    /**
     * 
     * @type {SGDomain}
     * @memberof WorkflowMetadata
     */
    businessDomain?: SGDomain;
    /**
     * 
     * @type {SGSubDomain}
     * @memberof WorkflowMetadata
     */
    businessSubDomain?: SGSubDomain;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowMetadata
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof WorkflowMetadata
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkflowMetadata
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    department: string;
    /**
     * 
     * @type {WorkflowLifeCycle}
     * @memberof WorkflowMetadata
     */
    lifeCycle: WorkflowLifeCycle;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    processOwner: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadata
     */
    processOwnerBackup: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowMetadata
     */
    supportContacts?: Array<string>;
    /**
     * 
     * @type {ProcessLevel}
     * @memberof WorkflowMetadata
     */
    processCriticality?: ProcessLevel;
    /**
     * 
     * @type {ProcessLevel}
     * @memberof WorkflowMetadata
     */
    processComplexity?: ProcessLevel;
    /**
     * 
     * @type {CallVolume}
     * @memberof WorkflowMetadata
     */
    expectedVolume?: CallVolume;
    /**
     * 
     * @type {Array<BusinessAndServiceUnit>}
     * @memberof WorkflowMetadata
     */
    targetUsersBUSU?: Array<BusinessAndServiceUnit>;
    /**
     * 
     * @type {Array<WorldRegion>}
     * @memberof WorkflowMetadata
     */
    targetUsersRegion?: Array<WorldRegion>;
    /**
     * 
     * @type {Array<InformationLevel>}
     * @memberof WorkflowMetadata
     */
    informationLevels?: Array<InformationLevel>;
    /**
     * always false for now
     * @type {boolean}
     * @memberof WorkflowMetadata
     */
    managingPersonalData?: boolean;
    /**
     * in years
     * @type {number}
     * @memberof WorkflowMetadata
     */
    retentionDuration?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowMetadata
     */
    instanceViewers?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowMetadata
     */
    isPublic?: boolean;
}
/**
 * 
 * @export
 * @interface WorkflowMetadataCreation
 */
export interface WorkflowMetadataCreation {
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadataCreation
     */
    workspaceId: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadataCreation
     */
    engineId: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadataCreation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadataCreation
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadataCreation
     */
    comment?: string;
    /**
     * 
     * @type {SGDomain}
     * @memberof WorkflowMetadataCreation
     */
    businessDomain: SGDomain;
    /**
     * 
     * @type {SGSubDomain}
     * @memberof WorkflowMetadataCreation
     */
    businessSubDomain: SGSubDomain;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowMetadataCreation
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof WorkflowMetadataCreation
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadataCreation
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkflowMetadataCreation
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadataCreation
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadataCreation
     */
    department: string;
    /**
     * 
     * @type {WorkflowLifeCycle}
     * @memberof WorkflowMetadataCreation
     */
    lifeCycle: WorkflowLifeCycle;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadataCreation
     */
    processOwner: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMetadataCreation
     */
    processOwnerBackup: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowMetadataCreation
     */
    supportContacts?: Array<string>;
    /**
     * 
     * @type {ProcessLevel}
     * @memberof WorkflowMetadataCreation
     */
    processCriticality?: ProcessLevel;
    /**
     * 
     * @type {ProcessLevel}
     * @memberof WorkflowMetadataCreation
     */
    processComplexity?: ProcessLevel;
    /**
     * 
     * @type {CallVolume}
     * @memberof WorkflowMetadataCreation
     */
    expectedVolume?: CallVolume;
    /**
     * 
     * @type {Array<BusinessAndServiceUnit>}
     * @memberof WorkflowMetadataCreation
     */
    targetUsersBUSU?: Array<BusinessAndServiceUnit>;
    /**
     * 
     * @type {Array<WorldRegion>}
     * @memberof WorkflowMetadataCreation
     */
    targetUsersRegion?: Array<WorldRegion>;
    /**
     * 
     * @type {Array<InformationLevel>}
     * @memberof WorkflowMetadataCreation
     */
    informationLevels?: Array<InformationLevel>;
    /**
     * always false for now
     * @type {boolean}
     * @memberof WorkflowMetadataCreation
     */
    managingPersonalData?: boolean;
    /**
     * in years
     * @type {number}
     * @memberof WorkflowMetadataCreation
     */
    retentionDuration?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowMetadataCreation
     */
    instanceViewers?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowMetadataCreation
     */
    isPublic?: boolean;
}
/**
 * 
 * @export
 * @interface WorkflowModelVersion
 */
export interface WorkflowModelVersion {
    /**
     * 
     * @type {string}
     * @memberof WorkflowModelVersion
     */
    modelId: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowModelVersion
     */
    versionId: string;
}
/**
 * 
 * @export
 * @interface WorkflowUpdateData
 */
export interface WorkflowUpdateData {
    /**
     * 
     * @type {string}
     * @memberof WorkflowUpdateData
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowUpdateData
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowUpdateData
     */
    presentation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowUpdateData
     */
    launchable?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof WorkflowUpdateData
     */
    data?: { [key: string]: object; };
    /**
     * 
     * @type {Array<WorkflowModelVersion>}
     * @memberof WorkflowUpdateData
     */
    models?: Array<WorkflowModelVersion>;
    /**
     * 
     * @type {string}
     * @memberof WorkflowUpdateData
     */
    modeler?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowUpdateData
     */
    modelerBackup?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowUpdateData
     */
    supportContacts?: Array<string>;
}
/**
 * 
 * @export
 * @interface WorkflowUpdateMetadata
 */
export interface WorkflowUpdateMetadata {
    /**
     * 
     * @type {string}
     * @memberof WorkflowUpdateMetadata
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowUpdateMetadata
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowUpdateMetadata
     */
    comment?: string;
    /**
     * 
     * @type {SGDomain}
     * @memberof WorkflowUpdateMetadata
     */
    businessDomain?: SGDomain;
    /**
     * 
     * @type {SGSubDomain}
     * @memberof WorkflowUpdateMetadata
     */
    businessSubDomain?: SGSubDomain;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowUpdateMetadata
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WorkflowUpdateMetadata
     */
    department?: string;
    /**
     * 
     * @type {WorkflowLifeCycle}
     * @memberof WorkflowUpdateMetadata
     */
    lifeCycle?: WorkflowLifeCycle;
    /**
     * 
     * @type {string}
     * @memberof WorkflowUpdateMetadata
     */
    processOwner?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowUpdateMetadata
     */
    processOwnerBackup?: string;
    /**
     * 
     * @type {ProcessLevel}
     * @memberof WorkflowUpdateMetadata
     */
    processCriticality?: ProcessLevel;
    /**
     * 
     * @type {ProcessLevel}
     * @memberof WorkflowUpdateMetadata
     */
    processComplexity?: ProcessLevel;
    /**
     * 
     * @type {CallVolume}
     * @memberof WorkflowUpdateMetadata
     */
    expectedVolume?: CallVolume;
    /**
     * 
     * @type {Array<BusinessAndServiceUnit>}
     * @memberof WorkflowUpdateMetadata
     */
    targetUsersBUSU?: Array<BusinessAndServiceUnit>;
    /**
     * 
     * @type {Array<WorldRegion>}
     * @memberof WorkflowUpdateMetadata
     */
    targetUsersRegion?: Array<WorldRegion>;
    /**
     * 
     * @type {Array<InformationLevel>}
     * @memberof WorkflowUpdateMetadata
     */
    informationLevels?: Array<InformationLevel>;
    /**
     * in years
     * @type {number}
     * @memberof WorkflowUpdateMetadata
     */
    retentionDuration?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowUpdateMetadata
     */
    supportContacts?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowUpdateMetadata
     */
    instanceViewers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowUpdateMetadata
     */
    instanceViewersToAdd?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkflowUpdateMetadata
     */
    instanceViewersToRemove?: Array<string>;
}
/**
 * 
 * @export
 * @interface WorkflowVersion
 */
export interface WorkflowVersion {
    /**
     * 
     * @type {string}
     * @memberof WorkflowVersion
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowVersion
     */
    workflowId: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkflowVersion
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkflowVersion
     */
    updatedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowVersion
     */
    enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowVersion
     */
    launchable: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkflowVersion
     */
    version: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof WorkflowVersion
     */
    data?: { [key: string]: object; };
    /**
     * 
     * @type {WorkflowConfig}
     * @memberof WorkflowVersion
     */
    config?: WorkflowConfig;
    /**
     * 
     * @type {WorkflowLifeCycle}
     * @memberof WorkflowVersion
     */
    lifeCycle: WorkflowLifeCycle;
    /**
     * 
     * @type {string}
     * @memberof WorkflowVersion
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowVersion
     */
    updatedBy?: string;
    /**
     * 
     * @type {Array<WorkflowModelVersion>}
     * @memberof WorkflowVersion
     */
    models: Array<WorkflowModelVersion>;
    /**
     * 
     * @type {WorkflowDeployment}
     * @memberof WorkflowVersion
     */
    deployment?: WorkflowDeployment;
}
/**
 * 
 * @export
 * @interface WorkflowVersionCreation
 */
export interface WorkflowVersionCreation {
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowVersionCreation
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowVersionCreation
     */
    launchable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkflowVersionCreation
     */
    version: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof WorkflowVersionCreation
     */
    data?: { [key: string]: object; };
    /**
     * 
     * @type {WorkflowConfig}
     * @memberof WorkflowVersionCreation
     */
    config?: WorkflowConfig;
    /**
     * 
     * @type {WorkflowLifeCycle}
     * @memberof WorkflowVersionCreation
     */
    lifeCycle: WorkflowLifeCycle;
    /**
     * 
     * @type {Array<WorkflowModelVersion>}
     * @memberof WorkflowVersionCreation
     */
    models: Array<WorkflowModelVersion>;
}
/**
 * Data required to create the Workflow Version if it doesn\'t exists
 * @export
 * @interface WorkflowVersionCreationFromImportData
 */
export interface WorkflowVersionCreationFromImportData {
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowVersionCreationFromImportData
     */
    enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowVersionCreationFromImportData
     */
    launchable: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkflowVersionCreationFromImportData
     */
    version: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof WorkflowVersionCreationFromImportData
     */
    data?: { [key: string]: object; };
    /**
     * 
     * @type {WorkflowConfig}
     * @memberof WorkflowVersionCreationFromImportData
     */
    config?: WorkflowConfig;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowVersionCreationFromImportData
     */
    importDataFromPrevious?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowVersionCreationFromImportData
     */
    importConfigFromPrevious?: boolean;
}
/**
 * 
 * @export
 * @interface WorkflowVersionImport
 */
export interface WorkflowVersionImport {
    /**
     * 
     * @type {WorkflowVersionImportParams}
     * @memberof WorkflowVersionImport
     */
    params: WorkflowVersionImportParams;
    /**
     * Required if the model content is imported from a file (single file or zip)
     * @type {any}
     * @memberof WorkflowVersionImport
     */
    file?: any;
}
/**
 * 
 * @export
 * @interface WorkflowVersionImportParams
 */
export interface WorkflowVersionImportParams {
    /**
     * Id of an existing Workflow Version, where we should import the models
     * @type {string}
     * @memberof WorkflowVersionImportParams
     */
    existingVersionId?: string;
    /**
     * 
     * @type {WorkflowVersionCreationFromImportData}
     * @memberof WorkflowVersionImportParams
     */
    newVersion?: WorkflowVersionCreationFromImportData;
    /**
     * 
     * @type {Array<ModelImport>}
     * @memberof WorkflowVersionImportParams
     */
    modelImports: Array<ModelImport>;
    /**
     * Should the Workflow version be deployed after import. By default false.
     * @type {boolean}
     * @memberof WorkflowVersionImportParams
     */
    shouldDeployAfter?: boolean;
}
/**
 * 
 * @export
 * @interface WorkflowVersionUpdateData
 */
export interface WorkflowVersionUpdateData {
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowVersionUpdateData
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowVersionUpdateData
     */
    launchable?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof WorkflowVersionUpdateData
     */
    data?: { [key: string]: object; };
    /**
     * 
     * @type {Array<WorkflowModelVersion>}
     * @memberof WorkflowVersionUpdateData
     */
    models?: Array<WorkflowModelVersion>;
}
/**
 * 
 * @export
 * @interface Workspace
 */
export interface Workspace {
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    owner: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    ownerBackup: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    ownerBusinessLine: string;
    /**
     * 
     * @type {Date}
     * @memberof Workspace
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Workspace
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workspace
     */
    tags: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    presentation: string;
    /**
     * 
     * @type {boolean}
     * @memberof Workspace
     */
    enabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    weight?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Workspace
     */
    data?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Workspace
     */
    config?: { [key: string]: object; };
    /**
     * 
     * @type {Array<string>}
     * @memberof Workspace
     */
    managers: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    exposition: WorkspaceExpositionEnum;
    /**
     * Engine ID where the workflow is/will be deployed
     * @type {string}
     * @memberof Workspace
     */
    engineId: string;
    /**
     * Defines if the Workspace handles a single Workflow
     * @type {boolean}
     * @memberof Workspace
     */
    isWorkflow: boolean;
    /**
     * Defines if tasks of these workspace processes to be hidden on the SG-Workflow UI
     * @type {boolean}
     * @memberof Workspace
     */
    isHiddenInDefaultUI: boolean;
    /**
     * Level 3 = SGBPM and V4 & Level 4 = Only V4
     * @type {number}
     * @memberof Workspace
     */
    apiCompatibilityLevel: number;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    updatedBy?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workspace
     */
    viewers?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum WorkspaceExpositionEnum {
    Hidden = 'hidden',
    Restricted = 'restricted',
    Group = 'group',
    Partner = 'partner',
    Public = 'public'
}

/**
 * 
 * @export
 * @interface WorkspaceRuntimeConfiguration
 */
export interface WorkspaceRuntimeConfiguration {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceRuntimeConfiguration
     */
    type: string;
}
/**
 * 
 * @export
 * @interface WorkspaceStatisticsAggregation
 */
export interface WorkspaceStatisticsAggregation {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceStatisticsAggregation
     */
    workspaceName?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceStatisticsAggregation
     */
    workspaceId?: string;
    /**
     * 
     * @type {Array<WorkspaceStatisticsAggregationContent>}
     * @memberof WorkspaceStatisticsAggregation
     */
    content?: Array<WorkspaceStatisticsAggregationContent>;
}
/**
 * 
 * @export
 * @interface WorkspaceStatisticsAggregationContent
 */
export interface WorkspaceStatisticsAggregationContent {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceStatisticsAggregationContent
     */
    workflowName?: string;
    /**
     * 
     * @type {WorkspaceStatisticsInstanceEntity}
     * @memberof WorkspaceStatisticsAggregationContent
     */
    instance?: WorkspaceStatisticsInstanceEntity;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkspaceStatisticsAggregationType {
    Workflow = 'workflow',
    Workspace = 'workspace'
}

/**
 * 
 * @export
 * @interface WorkspaceStatisticsInstanceEntity
 */
export interface WorkspaceStatisticsInstanceEntity {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceStatisticsInstanceEntity
     */
    processInstances?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceStatisticsInstanceEntity
     */
    processInstancesInProgress?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceStatisticsInstanceEntity
     */
    processInstancesCompleted?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceStatisticsInstanceEntity
     */
    processInstancesPaused?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceStatisticsInstanceEntity
     */
    processInstancesCancelled?: number;
    /**
     * 
     * @type {Date}
     * @memberof WorkspaceStatisticsInstanceEntity
     */
    startTimeMinDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkspaceStatisticsInstanceEntity
     */
    startTimeMaxDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceStatisticsInstanceEntity
     */
    tasks?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceStatisticsInstanceEntity
     */
    tasksInProgress?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceStatisticsInstanceEntity
     */
    tasksCompleted?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceStatisticsInstanceEntity
     */
    tasksPaused?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceStatisticsInstanceEntity
     */
    tasksCancelled?: number;
}
/**
 * 
 * @export
 * @interface WorkspaceUpdateData
 */
export interface WorkspaceUpdateData {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUpdateData
     */
    owner?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUpdateData
     */
    ownerBackup?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUpdateData
     */
    ownerBusinessLine?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUpdateData
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUpdateData
     */
    presentation?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUpdateData
     */
    slug?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof WorkspaceUpdateData
     */
    data?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof WorkspaceUpdateData
     */
    config?: { [key: string]: object; };
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspaceUpdateData
     */
    tagsToAdd?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspaceUpdateData
     */
    tagsToRemove?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspaceUpdateData
     */
    managersToAdd?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspaceUpdateData
     */
    managersToRemove?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspaceUpdateData
     */
    viewersToAdd?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspaceUpdateData
     */
    viewersToRemove?: Array<string>;
}
/**
 * 
 * @export
 * @interface WorkspacesFindPostRequest
 */
export interface WorkspacesFindPostRequest {
    /**
     * Number of the page
     * @type {number}
     * @memberof WorkspacesFindPostRequest
     */
    pageNumber?: number;
    /**
     * Number of elements in the page
     * @type {number}
     * @memberof WorkspacesFindPostRequest
     */
    pageSize?: number;
    /**
     * Sorting criteria in the format: [-]property. Default sort order is ascending, `-` prefix indicate descending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof WorkspacesFindPostRequest
     */
    sort?: Array<string>;
    /**
     * Keep only workspaces with a slug (URL friendly name) present in that list
     * @type {Array<string>}
     * @memberof WorkspacesFindPostRequest
     */
    slugIn?: Array<string>;
    /**
     * Workspace enabled (true|false). By Default retrieves only enabled workspaces
     * @type {boolean}
     * @memberof WorkspacesFindPostRequest
     */
    showDisabled?: boolean;
    /**
     * Workspace of tasks hidden on the SG-Workflow UI
     * @type {boolean}
     * @memberof WorkspacesFindPostRequest
     */
    showHiddenInDefaultUI?: boolean;
    /**
     * Should the user be synchronized
     * @type {boolean}
     * @memberof WorkspacesFindPostRequest
     */
    syncUser?: boolean;
    /**
     * Workspace managed by user (true|false). By Default retrieves all workspaces
     * @type {boolean}
     * @memberof WorkspacesFindPostRequest
     */
    showManagedWorkspaces?: boolean;
    /**
     * Keep only workspaces with a workspaceIds present in that list
     * @type {Array<string>}
     * @memberof WorkspacesFindPostRequest
     */
    workspaceIdIn?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WorldRegion {
    ALL = 'ALL',
    AMER = 'AMER',
    ASIA = 'ASIA',
    EUROPE = 'EUROPE'
}


/**
 * ActivityInstanceApi - axios parameter creator
 * @export
 */
export const ActivityInstanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Queries for historic activity instances that fulfill the given parameters
         * @param {string} processInstanceId Filter by process instance id.
         * @param {string} engineId Restricts to incidents that belong to a given engine id.
         * @param {string} [activityInstanceId] Filter by activity instance id.
         * @param {string} [processDefinitionId] Filter by process definition id.
         * @param {string} [executionId] Filter by the id of the execution that executed the activity instance.
         * @param {string} [activityId] Filter by the activity id (according to BPMN 2.0 XML).
         * @param {string} [activityName] Filter by the activity name (according to BPMN 2.0 XML).
         * @param {string} [activityType] Filter by activity type.
         * @param {string} [taskAssignee] Only include activity instances that are user tasks and assigned to a given user.
         * @param {boolean} [finished] Only include finished activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [unfinished] Only include unfinished activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [canceled] Only include canceled activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [completeScope] Only include activity instances which completed a scope. Value may only be true, as false behaves the same as when the property is not set.
         * @param {Date} [startedBefore] Restrict to instances that were started before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [startedAfter] Restrict to instances that were started after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [finishedBefore] Restrict to instances that were finished before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [finishedAfter] Restrict to instances that were finished after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
         * @param {boolean} [withoutTenantId] Only include historic activity instances that belong to no tenant. Value may only be true, as false is the default behavior.
         * @param {SortByActivityInstanceHistory} [sortBy] Sort the results by a given criterion. Valid values are activityInstanceId, instanceId, executionId, activityId, activityName, activityType, startTime, endTime, duration, definitionId, occurrence and tenantId. Must be used in conjunction with the sortOrder parameter.
         * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
         * @param {number} [firstResult] Pagination of results. Specifies the index of the first result to return.
         * @param {number} [maxResults] Pagination of results. Specifies the maximum number of results to return. Will return less results if there are no more results left.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeprecatedHistoricActivityInstance(processInstanceId: string, engineId: string, activityInstanceId?: string, processDefinitionId?: string, executionId?: string, activityId?: string, activityName?: string, activityType?: string, taskAssignee?: string, finished?: boolean, unfinished?: boolean, canceled?: boolean, completeScope?: boolean, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, tenantIdIn?: Array<string>, withoutTenantId?: boolean, sortBy?: SortByActivityInstanceHistory, sortOrder?: 'asc' | 'desc', firstResult?: number, maxResults?: number, options: any = {}): RequestArgs {
            // verify required parameter 'processInstanceId' is not null or undefined
            if (processInstanceId === null || processInstanceId === undefined) {
                throw new RequiredError('processInstanceId','Required parameter processInstanceId was null or undefined when calling getDeprecatedHistoricActivityInstance.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling getDeprecatedHistoricActivityInstance.');
            }
            const localVarPath = `/activity-instance/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (activityInstanceId !== undefined) {
                localVarQueryParameter['activityInstanceId'] = activityInstanceId;
            }

            if (processInstanceId !== undefined) {
                localVarQueryParameter['processInstanceId'] = processInstanceId;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (processDefinitionId !== undefined) {
                localVarQueryParameter['processDefinitionId'] = processDefinitionId;
            }

            if (executionId !== undefined) {
                localVarQueryParameter['executionId'] = executionId;
            }

            if (activityId !== undefined) {
                localVarQueryParameter['activityId'] = activityId;
            }

            if (activityName !== undefined) {
                localVarQueryParameter['activityName'] = activityName;
            }

            if (activityType !== undefined) {
                localVarQueryParameter['activityType'] = activityType;
            }

            if (taskAssignee !== undefined) {
                localVarQueryParameter['taskAssignee'] = taskAssignee;
            }

            if (finished !== undefined) {
                localVarQueryParameter['finished'] = finished;
            }

            if (unfinished !== undefined) {
                localVarQueryParameter['unfinished'] = unfinished;
            }

            if (canceled !== undefined) {
                localVarQueryParameter['canceled'] = canceled;
            }

            if (completeScope !== undefined) {
                localVarQueryParameter['completeScope'] = completeScope;
            }

            if (startedBefore !== undefined) {
                localVarQueryParameter['startedBefore'] = (startedBefore as any instanceof Date) ?
                    (startedBefore as any).toISOString() :
                    startedBefore;
            }

            if (startedAfter !== undefined) {
                localVarQueryParameter['startedAfter'] = (startedAfter as any instanceof Date) ?
                    (startedAfter as any).toISOString() :
                    startedAfter;
            }

            if (finishedBefore !== undefined) {
                localVarQueryParameter['finishedBefore'] = (finishedBefore as any instanceof Date) ?
                    (finishedBefore as any).toISOString() :
                    finishedBefore;
            }

            if (finishedAfter !== undefined) {
                localVarQueryParameter['finishedAfter'] = (finishedAfter as any instanceof Date) ?
                    (finishedAfter as any).toISOString() :
                    finishedAfter;
            }

            if (tenantIdIn) {
                localVarQueryParameter['tenantIdIn'] = tenantIdIn;
            }

            if (withoutTenantId !== undefined) {
                localVarQueryParameter['withoutTenantId'] = withoutTenantId;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (firstResult !== undefined) {
                localVarQueryParameter['firstResult'] = firstResult;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Queries for historic activity instances that fulfill the given parameters
         * @param {string} processInstanceId Filter by process instance id.
         * @param {string} engineId Restricts to incidents that belong to a given engine id.
         * @param {string} [activityInstanceId] Filter by activity instance id.
         * @param {string} [processDefinitionId] Filter by process definition id.
         * @param {string} [executionId] Filter by the id of the execution that executed the activity instance.
         * @param {string} [activityId] Filter by the activity id (according to BPMN 2.0 XML).
         * @param {string} [activityName] Filter by the activity name (according to BPMN 2.0 XML).
         * @param {string} [activityType] Filter by activity type.
         * @param {string} [taskAssignee] Only include activity instances that are user tasks and assigned to a given user.
         * @param {boolean} [finished] Only include finished activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [unfinished] Only include unfinished activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [canceled] Only include canceled activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [completeScope] Only include activity instances which completed a scope. Value may only be true, as false behaves the same as when the property is not set.
         * @param {Date} [startedBefore] Restrict to instances that were started before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [startedAfter] Restrict to instances that were started after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [finishedBefore] Restrict to instances that were finished before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [finishedAfter] Restrict to instances that were finished after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
         * @param {boolean} [withoutTenantId] Only include historic activity instances that belong to no tenant. Value may only be true, as false is the default behavior.
         * @param {SortByActivityInstanceHistory} [sortBy] Sort the results by a given criterion. Valid values are activityInstanceId, instanceId, executionId, activityId, activityName, activityType, startTime, endTime, duration, definitionId, occurrence and tenantId. Must be used in conjunction with the sortOrder parameter.
         * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
         * @param {number} [firstResult] Pagination of results. Specifies the index of the first result to return.
         * @param {number} [maxResults] Pagination of results. Specifies the maximum number of results to return. Will return less results if there are no more results left.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricActivityInstance(processInstanceId: string, engineId: string, activityInstanceId?: string, processDefinitionId?: string, executionId?: string, activityId?: string, activityName?: string, activityType?: string, taskAssignee?: string, finished?: boolean, unfinished?: boolean, canceled?: boolean, completeScope?: boolean, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, tenantIdIn?: Array<string>, withoutTenantId?: boolean, sortBy?: SortByActivityInstanceHistory, sortOrder?: 'asc' | 'desc', firstResult?: number, maxResults?: number, options: any = {}): RequestArgs {
            // verify required parameter 'processInstanceId' is not null or undefined
            if (processInstanceId === null || processInstanceId === undefined) {
                throw new RequiredError('processInstanceId','Required parameter processInstanceId was null or undefined when calling getHistoricActivityInstance.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling getHistoricActivityInstance.');
            }
            const localVarPath = `/activity-instances/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (activityInstanceId !== undefined) {
                localVarQueryParameter['activityInstanceId'] = activityInstanceId;
            }

            if (processInstanceId !== undefined) {
                localVarQueryParameter['processInstanceId'] = processInstanceId;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (processDefinitionId !== undefined) {
                localVarQueryParameter['processDefinitionId'] = processDefinitionId;
            }

            if (executionId !== undefined) {
                localVarQueryParameter['executionId'] = executionId;
            }

            if (activityId !== undefined) {
                localVarQueryParameter['activityId'] = activityId;
            }

            if (activityName !== undefined) {
                localVarQueryParameter['activityName'] = activityName;
            }

            if (activityType !== undefined) {
                localVarQueryParameter['activityType'] = activityType;
            }

            if (taskAssignee !== undefined) {
                localVarQueryParameter['taskAssignee'] = taskAssignee;
            }

            if (finished !== undefined) {
                localVarQueryParameter['finished'] = finished;
            }

            if (unfinished !== undefined) {
                localVarQueryParameter['unfinished'] = unfinished;
            }

            if (canceled !== undefined) {
                localVarQueryParameter['canceled'] = canceled;
            }

            if (completeScope !== undefined) {
                localVarQueryParameter['completeScope'] = completeScope;
            }

            if (startedBefore !== undefined) {
                localVarQueryParameter['startedBefore'] = (startedBefore as any instanceof Date) ?
                    (startedBefore as any).toISOString() :
                    startedBefore;
            }

            if (startedAfter !== undefined) {
                localVarQueryParameter['startedAfter'] = (startedAfter as any instanceof Date) ?
                    (startedAfter as any).toISOString() :
                    startedAfter;
            }

            if (finishedBefore !== undefined) {
                localVarQueryParameter['finishedBefore'] = (finishedBefore as any instanceof Date) ?
                    (finishedBefore as any).toISOString() :
                    finishedBefore;
            }

            if (finishedAfter !== undefined) {
                localVarQueryParameter['finishedAfter'] = (finishedAfter as any instanceof Date) ?
                    (finishedAfter as any).toISOString() :
                    finishedAfter;
            }

            if (tenantIdIn) {
                localVarQueryParameter['tenantIdIn'] = tenantIdIn;
            }

            if (withoutTenantId !== undefined) {
                localVarQueryParameter['withoutTenantId'] = withoutTenantId;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (firstResult !== undefined) {
                localVarQueryParameter['firstResult'] = firstResult;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityInstanceApi - functional programming interface
 * @export
 */
export const ActivityInstanceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Queries for historic activity instances that fulfill the given parameters
         * @param {string} processInstanceId Filter by process instance id.
         * @param {string} engineId Restricts to incidents that belong to a given engine id.
         * @param {string} [activityInstanceId] Filter by activity instance id.
         * @param {string} [processDefinitionId] Filter by process definition id.
         * @param {string} [executionId] Filter by the id of the execution that executed the activity instance.
         * @param {string} [activityId] Filter by the activity id (according to BPMN 2.0 XML).
         * @param {string} [activityName] Filter by the activity name (according to BPMN 2.0 XML).
         * @param {string} [activityType] Filter by activity type.
         * @param {string} [taskAssignee] Only include activity instances that are user tasks and assigned to a given user.
         * @param {boolean} [finished] Only include finished activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [unfinished] Only include unfinished activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [canceled] Only include canceled activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [completeScope] Only include activity instances which completed a scope. Value may only be true, as false behaves the same as when the property is not set.
         * @param {Date} [startedBefore] Restrict to instances that were started before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [startedAfter] Restrict to instances that were started after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [finishedBefore] Restrict to instances that were finished before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [finishedAfter] Restrict to instances that were finished after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
         * @param {boolean} [withoutTenantId] Only include historic activity instances that belong to no tenant. Value may only be true, as false is the default behavior.
         * @param {SortByActivityInstanceHistory} [sortBy] Sort the results by a given criterion. Valid values are activityInstanceId, instanceId, executionId, activityId, activityName, activityType, startTime, endTime, duration, definitionId, occurrence and tenantId. Must be used in conjunction with the sortOrder parameter.
         * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
         * @param {number} [firstResult] Pagination of results. Specifies the index of the first result to return.
         * @param {number} [maxResults] Pagination of results. Specifies the maximum number of results to return. Will return less results if there are no more results left.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeprecatedHistoricActivityInstance(processInstanceId: string, engineId: string, activityInstanceId?: string, processDefinitionId?: string, executionId?: string, activityId?: string, activityName?: string, activityType?: string, taskAssignee?: string, finished?: boolean, unfinished?: boolean, canceled?: boolean, completeScope?: boolean, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, tenantIdIn?: Array<string>, withoutTenantId?: boolean, sortBy?: SortByActivityInstanceHistory, sortOrder?: 'asc' | 'desc', firstResult?: number, maxResults?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistoricActivityInstanceDto>> {
            const localVarAxiosArgs = ActivityInstanceApiAxiosParamCreator(configuration).getDeprecatedHistoricActivityInstance(processInstanceId, engineId, activityInstanceId, processDefinitionId, executionId, activityId, activityName, activityType, taskAssignee, finished, unfinished, canceled, completeScope, startedBefore, startedAfter, finishedBefore, finishedAfter, tenantIdIn, withoutTenantId, sortBy, sortOrder, firstResult, maxResults, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Queries for historic activity instances that fulfill the given parameters
         * @param {string} processInstanceId Filter by process instance id.
         * @param {string} engineId Restricts to incidents that belong to a given engine id.
         * @param {string} [activityInstanceId] Filter by activity instance id.
         * @param {string} [processDefinitionId] Filter by process definition id.
         * @param {string} [executionId] Filter by the id of the execution that executed the activity instance.
         * @param {string} [activityId] Filter by the activity id (according to BPMN 2.0 XML).
         * @param {string} [activityName] Filter by the activity name (according to BPMN 2.0 XML).
         * @param {string} [activityType] Filter by activity type.
         * @param {string} [taskAssignee] Only include activity instances that are user tasks and assigned to a given user.
         * @param {boolean} [finished] Only include finished activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [unfinished] Only include unfinished activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [canceled] Only include canceled activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [completeScope] Only include activity instances which completed a scope. Value may only be true, as false behaves the same as when the property is not set.
         * @param {Date} [startedBefore] Restrict to instances that were started before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [startedAfter] Restrict to instances that were started after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [finishedBefore] Restrict to instances that were finished before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [finishedAfter] Restrict to instances that were finished after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
         * @param {boolean} [withoutTenantId] Only include historic activity instances that belong to no tenant. Value may only be true, as false is the default behavior.
         * @param {SortByActivityInstanceHistory} [sortBy] Sort the results by a given criterion. Valid values are activityInstanceId, instanceId, executionId, activityId, activityName, activityType, startTime, endTime, duration, definitionId, occurrence and tenantId. Must be used in conjunction with the sortOrder parameter.
         * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
         * @param {number} [firstResult] Pagination of results. Specifies the index of the first result to return.
         * @param {number} [maxResults] Pagination of results. Specifies the maximum number of results to return. Will return less results if there are no more results left.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricActivityInstance(processInstanceId: string, engineId: string, activityInstanceId?: string, processDefinitionId?: string, executionId?: string, activityId?: string, activityName?: string, activityType?: string, taskAssignee?: string, finished?: boolean, unfinished?: boolean, canceled?: boolean, completeScope?: boolean, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, tenantIdIn?: Array<string>, withoutTenantId?: boolean, sortBy?: SortByActivityInstanceHistory, sortOrder?: 'asc' | 'desc', firstResult?: number, maxResults?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistoricActivityInstanceDto>> {
            const localVarAxiosArgs = ActivityInstanceApiAxiosParamCreator(configuration).getHistoricActivityInstance(processInstanceId, engineId, activityInstanceId, processDefinitionId, executionId, activityId, activityName, activityType, taskAssignee, finished, unfinished, canceled, completeScope, startedBefore, startedAfter, finishedBefore, finishedAfter, tenantIdIn, withoutTenantId, sortBy, sortOrder, firstResult, maxResults, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ActivityInstanceApi - factory interface
 * @export
 */
export const ActivityInstanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Queries for historic activity instances that fulfill the given parameters
         * @param {string} processInstanceId Filter by process instance id.
         * @param {string} engineId Restricts to incidents that belong to a given engine id.
         * @param {string} [activityInstanceId] Filter by activity instance id.
         * @param {string} [processDefinitionId] Filter by process definition id.
         * @param {string} [executionId] Filter by the id of the execution that executed the activity instance.
         * @param {string} [activityId] Filter by the activity id (according to BPMN 2.0 XML).
         * @param {string} [activityName] Filter by the activity name (according to BPMN 2.0 XML).
         * @param {string} [activityType] Filter by activity type.
         * @param {string} [taskAssignee] Only include activity instances that are user tasks and assigned to a given user.
         * @param {boolean} [finished] Only include finished activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [unfinished] Only include unfinished activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [canceled] Only include canceled activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [completeScope] Only include activity instances which completed a scope. Value may only be true, as false behaves the same as when the property is not set.
         * @param {Date} [startedBefore] Restrict to instances that were started before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [startedAfter] Restrict to instances that were started after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [finishedBefore] Restrict to instances that were finished before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [finishedAfter] Restrict to instances that were finished after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
         * @param {boolean} [withoutTenantId] Only include historic activity instances that belong to no tenant. Value may only be true, as false is the default behavior.
         * @param {SortByActivityInstanceHistory} [sortBy] Sort the results by a given criterion. Valid values are activityInstanceId, instanceId, executionId, activityId, activityName, activityType, startTime, endTime, duration, definitionId, occurrence and tenantId. Must be used in conjunction with the sortOrder parameter.
         * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
         * @param {number} [firstResult] Pagination of results. Specifies the index of the first result to return.
         * @param {number} [maxResults] Pagination of results. Specifies the maximum number of results to return. Will return less results if there are no more results left.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeprecatedHistoricActivityInstance(processInstanceId: string, engineId: string, activityInstanceId?: string, processDefinitionId?: string, executionId?: string, activityId?: string, activityName?: string, activityType?: string, taskAssignee?: string, finished?: boolean, unfinished?: boolean, canceled?: boolean, completeScope?: boolean, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, tenantIdIn?: Array<string>, withoutTenantId?: boolean, sortBy?: SortByActivityInstanceHistory, sortOrder?: 'asc' | 'desc', firstResult?: number, maxResults?: number, options?: any): AxiosPromise<Array<HistoricActivityInstanceDto>> {
            return ActivityInstanceApiFp(configuration).getDeprecatedHistoricActivityInstance(processInstanceId, engineId, activityInstanceId, processDefinitionId, executionId, activityId, activityName, activityType, taskAssignee, finished, unfinished, canceled, completeScope, startedBefore, startedAfter, finishedBefore, finishedAfter, tenantIdIn, withoutTenantId, sortBy, sortOrder, firstResult, maxResults, options)(axios, basePath);
        },
        /**
         * 
         * @summary Queries for historic activity instances that fulfill the given parameters
         * @param {string} processInstanceId Filter by process instance id.
         * @param {string} engineId Restricts to incidents that belong to a given engine id.
         * @param {string} [activityInstanceId] Filter by activity instance id.
         * @param {string} [processDefinitionId] Filter by process definition id.
         * @param {string} [executionId] Filter by the id of the execution that executed the activity instance.
         * @param {string} [activityId] Filter by the activity id (according to BPMN 2.0 XML).
         * @param {string} [activityName] Filter by the activity name (according to BPMN 2.0 XML).
         * @param {string} [activityType] Filter by activity type.
         * @param {string} [taskAssignee] Only include activity instances that are user tasks and assigned to a given user.
         * @param {boolean} [finished] Only include finished activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [unfinished] Only include unfinished activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [canceled] Only include canceled activity instances. Value may only be true, as false behaves the same as when the property is not set.
         * @param {boolean} [completeScope] Only include activity instances which completed a scope. Value may only be true, as false behaves the same as when the property is not set.
         * @param {Date} [startedBefore] Restrict to instances that were started before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [startedAfter] Restrict to instances that were started after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [finishedBefore] Restrict to instances that were finished before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [finishedAfter] Restrict to instances that were finished after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
         * @param {boolean} [withoutTenantId] Only include historic activity instances that belong to no tenant. Value may only be true, as false is the default behavior.
         * @param {SortByActivityInstanceHistory} [sortBy] Sort the results by a given criterion. Valid values are activityInstanceId, instanceId, executionId, activityId, activityName, activityType, startTime, endTime, duration, definitionId, occurrence and tenantId. Must be used in conjunction with the sortOrder parameter.
         * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
         * @param {number} [firstResult] Pagination of results. Specifies the index of the first result to return.
         * @param {number} [maxResults] Pagination of results. Specifies the maximum number of results to return. Will return less results if there are no more results left.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricActivityInstance(processInstanceId: string, engineId: string, activityInstanceId?: string, processDefinitionId?: string, executionId?: string, activityId?: string, activityName?: string, activityType?: string, taskAssignee?: string, finished?: boolean, unfinished?: boolean, canceled?: boolean, completeScope?: boolean, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, tenantIdIn?: Array<string>, withoutTenantId?: boolean, sortBy?: SortByActivityInstanceHistory, sortOrder?: 'asc' | 'desc', firstResult?: number, maxResults?: number, options?: any): AxiosPromise<Array<HistoricActivityInstanceDto>> {
            return ActivityInstanceApiFp(configuration).getHistoricActivityInstance(processInstanceId, engineId, activityInstanceId, processDefinitionId, executionId, activityId, activityName, activityType, taskAssignee, finished, unfinished, canceled, completeScope, startedBefore, startedAfter, finishedBefore, finishedAfter, tenantIdIn, withoutTenantId, sortBy, sortOrder, firstResult, maxResults, options)(axios, basePath);
        },
    };
};

/**
 * ActivityInstanceApi - object-oriented interface
 * @export
 * @class ActivityInstanceApi
 * @extends {BaseAPI}
 */
export class ActivityInstanceApi extends BaseAPI {
    /**
     * 
     * @summary Queries for historic activity instances that fulfill the given parameters
     * @param {string} processInstanceId Filter by process instance id.
     * @param {string} engineId Restricts to incidents that belong to a given engine id.
     * @param {string} [activityInstanceId] Filter by activity instance id.
     * @param {string} [processDefinitionId] Filter by process definition id.
     * @param {string} [executionId] Filter by the id of the execution that executed the activity instance.
     * @param {string} [activityId] Filter by the activity id (according to BPMN 2.0 XML).
     * @param {string} [activityName] Filter by the activity name (according to BPMN 2.0 XML).
     * @param {string} [activityType] Filter by activity type.
     * @param {string} [taskAssignee] Only include activity instances that are user tasks and assigned to a given user.
     * @param {boolean} [finished] Only include finished activity instances. Value may only be true, as false behaves the same as when the property is not set.
     * @param {boolean} [unfinished] Only include unfinished activity instances. Value may only be true, as false behaves the same as when the property is not set.
     * @param {boolean} [canceled] Only include canceled activity instances. Value may only be true, as false behaves the same as when the property is not set.
     * @param {boolean} [completeScope] Only include activity instances which completed a scope. Value may only be true, as false behaves the same as when the property is not set.
     * @param {Date} [startedBefore] Restrict to instances that were started before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {Date} [startedAfter] Restrict to instances that were started after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {Date} [finishedBefore] Restrict to instances that were finished before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {Date} [finishedAfter] Restrict to instances that were finished after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
     * @param {boolean} [withoutTenantId] Only include historic activity instances that belong to no tenant. Value may only be true, as false is the default behavior.
     * @param {SortByActivityInstanceHistory} [sortBy] Sort the results by a given criterion. Valid values are activityInstanceId, instanceId, executionId, activityId, activityName, activityType, startTime, endTime, duration, definitionId, occurrence and tenantId. Must be used in conjunction with the sortOrder parameter.
     * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
     * @param {number} [firstResult] Pagination of results. Specifies the index of the first result to return.
     * @param {number} [maxResults] Pagination of results. Specifies the maximum number of results to return. Will return less results if there are no more results left.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityInstanceApi
     */
    public getDeprecatedHistoricActivityInstance(processInstanceId: string, engineId: string, activityInstanceId?: string, processDefinitionId?: string, executionId?: string, activityId?: string, activityName?: string, activityType?: string, taskAssignee?: string, finished?: boolean, unfinished?: boolean, canceled?: boolean, completeScope?: boolean, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, tenantIdIn?: Array<string>, withoutTenantId?: boolean, sortBy?: SortByActivityInstanceHistory, sortOrder?: 'asc' | 'desc', firstResult?: number, maxResults?: number, options?: any) {
        return ActivityInstanceApiFp(this.configuration).getDeprecatedHistoricActivityInstance(processInstanceId, engineId, activityInstanceId, processDefinitionId, executionId, activityId, activityName, activityType, taskAssignee, finished, unfinished, canceled, completeScope, startedBefore, startedAfter, finishedBefore, finishedAfter, tenantIdIn, withoutTenantId, sortBy, sortOrder, firstResult, maxResults, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Queries for historic activity instances that fulfill the given parameters
     * @param {string} processInstanceId Filter by process instance id.
     * @param {string} engineId Restricts to incidents that belong to a given engine id.
     * @param {string} [activityInstanceId] Filter by activity instance id.
     * @param {string} [processDefinitionId] Filter by process definition id.
     * @param {string} [executionId] Filter by the id of the execution that executed the activity instance.
     * @param {string} [activityId] Filter by the activity id (according to BPMN 2.0 XML).
     * @param {string} [activityName] Filter by the activity name (according to BPMN 2.0 XML).
     * @param {string} [activityType] Filter by activity type.
     * @param {string} [taskAssignee] Only include activity instances that are user tasks and assigned to a given user.
     * @param {boolean} [finished] Only include finished activity instances. Value may only be true, as false behaves the same as when the property is not set.
     * @param {boolean} [unfinished] Only include unfinished activity instances. Value may only be true, as false behaves the same as when the property is not set.
     * @param {boolean} [canceled] Only include canceled activity instances. Value may only be true, as false behaves the same as when the property is not set.
     * @param {boolean} [completeScope] Only include activity instances which completed a scope. Value may only be true, as false behaves the same as when the property is not set.
     * @param {Date} [startedBefore] Restrict to instances that were started before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {Date} [startedAfter] Restrict to instances that were started after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {Date} [finishedBefore] Restrict to instances that were finished before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {Date} [finishedAfter] Restrict to instances that were finished after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
     * @param {boolean} [withoutTenantId] Only include historic activity instances that belong to no tenant. Value may only be true, as false is the default behavior.
     * @param {SortByActivityInstanceHistory} [sortBy] Sort the results by a given criterion. Valid values are activityInstanceId, instanceId, executionId, activityId, activityName, activityType, startTime, endTime, duration, definitionId, occurrence and tenantId. Must be used in conjunction with the sortOrder parameter.
     * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
     * @param {number} [firstResult] Pagination of results. Specifies the index of the first result to return.
     * @param {number} [maxResults] Pagination of results. Specifies the maximum number of results to return. Will return less results if there are no more results left.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityInstanceApi
     */
    public getHistoricActivityInstance(processInstanceId: string, engineId: string, activityInstanceId?: string, processDefinitionId?: string, executionId?: string, activityId?: string, activityName?: string, activityType?: string, taskAssignee?: string, finished?: boolean, unfinished?: boolean, canceled?: boolean, completeScope?: boolean, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, tenantIdIn?: Array<string>, withoutTenantId?: boolean, sortBy?: SortByActivityInstanceHistory, sortOrder?: 'asc' | 'desc', firstResult?: number, maxResults?: number, options?: any) {
        return ActivityInstanceApiFp(this.configuration).getHistoricActivityInstance(processInstanceId, engineId, activityInstanceId, processDefinitionId, executionId, activityId, activityName, activityType, taskAssignee, finished, unfinished, canceled, completeScope, startedBefore, startedAfter, finishedBefore, finishedAfter, tenantIdIn, withoutTenantId, sortBy, sortOrder, firstResult, maxResults, options)(this.axios, this.basePath);
    }

}


/**
 * CandidateGroupApi - axios parameter creator
 * @export
 */
export const CandidateGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new CandidateGroup in the engine
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {CandidateGroupCreation} candidateGroupCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateGroup(engineId: string, candidateGroupCreation: CandidateGroupCreation, options: any = {}): RequestArgs {
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling createCandidateGroup.');
            }
            // verify required parameter 'candidateGroupCreation' is not null or undefined
            if (candidateGroupCreation === null || candidateGroupCreation === undefined) {
                throw new RequiredError('candidateGroupCreation','Required parameter candidateGroupCreation was null or undefined when calling createCandidateGroup.');
            }
            const localVarPath = `/engines/{engineId}/groups`
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof candidateGroupCreation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(candidateGroupCreation !== undefined ? candidateGroupCreation : {}) : (candidateGroupCreation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add members to a group
         * @param {string} groupId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMembership(groupId: string, engineId: string, requestBody: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling createMembership.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling createMembership.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling createMembership.');
            }
            const localVarPath = `/engines/{engineId}/groups/{groupId}/members`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a CandidateGroup 
         * @param {string} groupId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateGroup(groupId: string, engineId: string, options: any = {}): RequestArgs {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling deleteCandidateGroup.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling deleteCandidateGroup.');
            }
            const localVarPath = `/engines/{engineId}/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a member from a group
         * @param {string} groupId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMembership(groupId: string, engineId: string, requestBody: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling deleteMembership.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling deleteMembership.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling deleteMembership.');
            }
            const localVarPath = `/engines/{engineId}/groups/{groupId}/members`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Audit details of a group
         * @param {string} engineId 
         * @param {string} groupId 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateGroupAudit(engineId: string, groupId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, clientId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling getCandidateGroupAudit.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling getCandidateGroupAudit.');
            }
            const localVarPath = `/engines/{engineId}/groups/{groupId}/audits`
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dateAfter !== undefined) {
                localVarQueryParameter['dateAfter'] = (dateAfter as any instanceof Date) ?
                    (dateAfter as any).toISOString() :
                    dateAfter;
            }

            if (dateBefore !== undefined) {
                localVarQueryParameter['dateBefore'] = (dateBefore as any instanceof Date) ?
                    (dateBefore as any).toISOString() :
                    dateBefore;
            }

            if (eventName !== undefined) {
                localVarQueryParameter['eventName'] = eventName;
            }

            if (performerId !== undefined) {
                localVarQueryParameter['performerId'] = performerId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a CandidateGroup 
         * @param {string} groupId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCandidateGroupById(groupId: string, engineId: string, options: any = {}): RequestArgs {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling retrieveCandidateGroupById.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling retrieveCandidateGroupById.');
            }
            const localVarPath = `/engines/{engineId}/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a list of groups based on user
         * @param {string} engineId Engine ID: The ID of the engine that should receive the request
         * @param {string} [userId] The user Id
         * @param {boolean} [triggerMembershipSynchro] If true, it triggers the membership synchronization for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveGroupsByMember(engineId: string, userId?: string, triggerMembershipSynchro?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling retrieveGroupsByMember.');
            }
            const localVarPath = `/engines/{engineId}/groups`
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (triggerMembershipSynchro !== undefined) {
                localVarQueryParameter['triggerMembershipSynchro'] = triggerMembershipSynchro;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a list of CandidateGroup based on the search key word
         * @param {string} key search keyword (minimum 3 characters)
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCandidateGroup(key: string, engineId: string, options: any = {}): RequestArgs {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling searchCandidateGroup.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling searchCandidateGroup.');
            }
            const localVarPath = `/engines/{engineId}/groups/search`
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CandidateGroupApi - functional programming interface
 * @export
 */
export const CandidateGroupApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new CandidateGroup in the engine
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {CandidateGroupCreation} candidateGroupCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateGroup(engineId: string, candidateGroupCreation: CandidateGroupCreation, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateGroup> {
            const localVarAxiosArgs = CandidateGroupApiAxiosParamCreator(configuration).createCandidateGroup(engineId, candidateGroupCreation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add members to a group
         * @param {string} groupId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMembership(groupId: string, engineId: string, requestBody: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CandidateGroupApiAxiosParamCreator(configuration).createMembership(groupId, engineId, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a CandidateGroup 
         * @param {string} groupId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateGroup(groupId: string, engineId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CandidateGroupApiAxiosParamCreator(configuration).deleteCandidateGroup(groupId, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a member from a group
         * @param {string} groupId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMembership(groupId: string, engineId: string, requestBody: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CandidateGroupApiAxiosParamCreator(configuration).deleteMembership(groupId, engineId, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Audit details of a group
         * @param {string} engineId 
         * @param {string} groupId 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateGroupAudit(engineId: string, groupId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, clientId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditLog>> {
            const localVarAxiosArgs = CandidateGroupApiAxiosParamCreator(configuration).getCandidateGroupAudit(engineId, groupId, dateAfter, dateBefore, eventName, performerId, clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a CandidateGroup 
         * @param {string} groupId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCandidateGroupById(groupId: string, engineId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateGroup> {
            const localVarAxiosArgs = CandidateGroupApiAxiosParamCreator(configuration).retrieveCandidateGroupById(groupId, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a list of groups based on user
         * @param {string} engineId Engine ID: The ID of the engine that should receive the request
         * @param {string} [userId] The user Id
         * @param {boolean} [triggerMembershipSynchro] If true, it triggers the membership synchronization for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveGroupsByMember(engineId: string, userId?: string, triggerMembershipSynchro?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateGroup>> {
            const localVarAxiosArgs = CandidateGroupApiAxiosParamCreator(configuration).retrieveGroupsByMember(engineId, userId, triggerMembershipSynchro, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieves a list of CandidateGroup based on the search key word
         * @param {string} key search keyword (minimum 3 characters)
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCandidateGroup(key: string, engineId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateGroup>> {
            const localVarAxiosArgs = CandidateGroupApiAxiosParamCreator(configuration).searchCandidateGroup(key, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CandidateGroupApi - factory interface
 * @export
 */
export const CandidateGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a new CandidateGroup in the engine
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {CandidateGroupCreation} candidateGroupCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateGroup(engineId: string, candidateGroupCreation: CandidateGroupCreation, options?: any): AxiosPromise<CandidateGroup> {
            return CandidateGroupApiFp(configuration).createCandidateGroup(engineId, candidateGroupCreation, options)(axios, basePath);
        },
        /**
         * 
         * @summary Add members to a group
         * @param {string} groupId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMembership(groupId: string, engineId: string, requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return CandidateGroupApiFp(configuration).createMembership(groupId, engineId, requestBody, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a CandidateGroup 
         * @param {string} groupId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateGroup(groupId: string, engineId: string, options?: any): AxiosPromise<void> {
            return CandidateGroupApiFp(configuration).deleteCandidateGroup(groupId, engineId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a member from a group
         * @param {string} groupId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMembership(groupId: string, engineId: string, requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return CandidateGroupApiFp(configuration).deleteMembership(groupId, engineId, requestBody, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get Audit details of a group
         * @param {string} engineId 
         * @param {string} groupId 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateGroupAudit(engineId: string, groupId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, clientId?: string, options?: any): AxiosPromise<Array<AuditLog>> {
            return CandidateGroupApiFp(configuration).getCandidateGroupAudit(engineId, groupId, dateAfter, dateBefore, eventName, performerId, clientId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve a CandidateGroup 
         * @param {string} groupId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCandidateGroupById(groupId: string, engineId: string, options?: any): AxiosPromise<CandidateGroup> {
            return CandidateGroupApiFp(configuration).retrieveCandidateGroupById(groupId, engineId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve a list of groups based on user
         * @param {string} engineId Engine ID: The ID of the engine that should receive the request
         * @param {string} [userId] The user Id
         * @param {boolean} [triggerMembershipSynchro] If true, it triggers the membership synchronization for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveGroupsByMember(engineId: string, userId?: string, triggerMembershipSynchro?: boolean, options?: any): AxiosPromise<Array<CandidateGroup>> {
            return CandidateGroupApiFp(configuration).retrieveGroupsByMember(engineId, userId, triggerMembershipSynchro, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieves a list of CandidateGroup based on the search key word
         * @param {string} key search keyword (minimum 3 characters)
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCandidateGroup(key: string, engineId: string, options?: any): AxiosPromise<Array<CandidateGroup>> {
            return CandidateGroupApiFp(configuration).searchCandidateGroup(key, engineId, options)(axios, basePath);
        },
    };
};

/**
 * CandidateGroupApi - object-oriented interface
 * @export
 * @class CandidateGroupApi
 * @extends {BaseAPI}
 */
export class CandidateGroupApi extends BaseAPI {
    /**
     * 
     * @summary Create a new CandidateGroup in the engine
     * @param {string} engineId Engine ID: The ID of the engine
     * @param {CandidateGroupCreation} candidateGroupCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateGroupApi
     */
    public createCandidateGroup(engineId: string, candidateGroupCreation: CandidateGroupCreation, options?: any) {
        return CandidateGroupApiFp(this.configuration).createCandidateGroup(engineId, candidateGroupCreation, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Add members to a group
     * @param {string} groupId 
     * @param {string} engineId Engine ID: The ID of the engine
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateGroupApi
     */
    public createMembership(groupId: string, engineId: string, requestBody: Array<string>, options?: any) {
        return CandidateGroupApiFp(this.configuration).createMembership(groupId, engineId, requestBody, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a CandidateGroup 
     * @param {string} groupId 
     * @param {string} engineId Engine ID: The ID of the engine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateGroupApi
     */
    public deleteCandidateGroup(groupId: string, engineId: string, options?: any) {
        return CandidateGroupApiFp(this.configuration).deleteCandidateGroup(groupId, engineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a member from a group
     * @param {string} groupId 
     * @param {string} engineId Engine ID: The ID of the engine
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateGroupApi
     */
    public deleteMembership(groupId: string, engineId: string, requestBody: Array<string>, options?: any) {
        return CandidateGroupApiFp(this.configuration).deleteMembership(groupId, engineId, requestBody, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get Audit details of a group
     * @param {string} engineId 
     * @param {string} groupId 
     * @param {Date} [dateAfter] 
     * @param {Date} [dateBefore] 
     * @param {string} [eventName] 
     * @param {string} [performerId] 
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateGroupApi
     */
    public getCandidateGroupAudit(engineId: string, groupId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, clientId?: string, options?: any) {
        return CandidateGroupApiFp(this.configuration).getCandidateGroupAudit(engineId, groupId, dateAfter, dateBefore, eventName, performerId, clientId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve a CandidateGroup 
     * @param {string} groupId 
     * @param {string} engineId Engine ID: The ID of the engine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateGroupApi
     */
    public retrieveCandidateGroupById(groupId: string, engineId: string, options?: any) {
        return CandidateGroupApiFp(this.configuration).retrieveCandidateGroupById(groupId, engineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve a list of groups based on user
     * @param {string} engineId Engine ID: The ID of the engine that should receive the request
     * @param {string} [userId] The user Id
     * @param {boolean} [triggerMembershipSynchro] If true, it triggers the membership synchronization for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateGroupApi
     */
    public retrieveGroupsByMember(engineId: string, userId?: string, triggerMembershipSynchro?: boolean, options?: any) {
        return CandidateGroupApiFp(this.configuration).retrieveGroupsByMember(engineId, userId, triggerMembershipSynchro, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieves a list of CandidateGroup based on the search key word
     * @param {string} key search keyword (minimum 3 characters)
     * @param {string} engineId Engine ID: The ID of the engine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateGroupApi
     */
    public searchCandidateGroup(key: string, engineId: string, options?: any) {
        return CandidateGroupApiFp(this.configuration).searchCandidateGroup(key, engineId, options)(this.axios, this.basePath);
    }

}


/**
 * CandidateUserApi - axios parameter creator
 * @export
 */
export const CandidateUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new CandidateUser in the engine
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {CandidateUserCreation} candidateUserCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateUser(engineId: string, candidateUserCreation: CandidateUserCreation, options: any = {}): RequestArgs {
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling createCandidateUser.');
            }
            // verify required parameter 'candidateUserCreation' is not null or undefined
            if (candidateUserCreation === null || candidateUserCreation === undefined) {
                throw new RequiredError('candidateUserCreation','Required parameter candidateUserCreation was null or undefined when calling createCandidateUser.');
            }
            const localVarPath = `/engines/{engineId}/users`
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof candidateUserCreation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(candidateUserCreation !== undefined ? candidateUserCreation : {}) : (candidateUserCreation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a CandidateUser 
         * @param {string} userId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateUser(userId: string, engineId: string, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteCandidateUser.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling deleteCandidateUser.');
            }
            const localVarPath = `/engines/{engineId}/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Audit details of a user
         * @param {string} engineId 
         * @param {string} userId 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateUserAudit(engineId: string, userId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, clientId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling getCandidateUserAudit.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getCandidateUserAudit.');
            }
            const localVarPath = `/engines/{engineId}/users/{userId}/audits`
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dateAfter !== undefined) {
                localVarQueryParameter['dateAfter'] = (dateAfter as any instanceof Date) ?
                    (dateAfter as any).toISOString() :
                    dateAfter;
            }

            if (dateBefore !== undefined) {
                localVarQueryParameter['dateBefore'] = (dateBefore as any instanceof Date) ?
                    (dateBefore as any).toISOString() :
                    dateBefore;
            }

            if (eventName !== undefined) {
                localVarQueryParameter['eventName'] = eventName;
            }

            if (performerId !== undefined) {
                localVarQueryParameter['performerId'] = performerId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a list of Users by group
         * @param {string} groupName Filter for users which are members of a group.
         * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByGroup(groupName: string, engineId: string, options: any = {}): RequestArgs {
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getUsersByGroup.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling getUsersByGroup.');
            }
            const localVarPath = `/engines/{engineId}/users`
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (groupName !== undefined) {
                localVarQueryParameter['groupName'] = groupName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a list of Users by group, deprecated use /engines/{engineId}/users instead
         * @param {string} groupName Filter for users which are members of a group.
         * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByGroupDeprecated(groupName: string, engineId: string, options: any = {}): RequestArgs {
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getUsersByGroupDeprecated.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling getUsersByGroupDeprecated.');
            }
            const localVarPath = `/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (groupName !== undefined) {
                localVarQueryParameter['groupName'] = groupName;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a CandidateUser 
         * @param {string} userId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCandidateUserById(userId: string, engineId: string, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling retrieveCandidateUserById.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling retrieveCandidateUserById.');
            }
            const localVarPath = `/engines/{engineId}/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a list of groups of a user
         * @param {string} userId The user Id
         * @param {string} engineId Engine ID: The ID of the engine that should receive the request
         * @param {boolean} [triggerMembershipSynchro] If true, it triggers the membership synchronization for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveGroupsForUser(userId: string, engineId: string, triggerMembershipSynchro?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling retrieveGroupsForUser.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling retrieveGroupsForUser.');
            }
            const localVarPath = `/engines/{engineId}/users/{userId}/groups`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (triggerMembershipSynchro !== undefined) {
                localVarQueryParameter['triggerMembershipSynchro'] = triggerMembershipSynchro;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a list of CandidateUser based on the search key word
         * @param {string} key search keyword (minimum 3 characters)
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCandidateUser(key: string, engineId: string, options: any = {}): RequestArgs {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling searchCandidateUser.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling searchCandidateUser.');
            }
            const localVarPath = `/engines/{engineId}/users/search`
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CandidateUserApi - functional programming interface
 * @export
 */
export const CandidateUserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new CandidateUser in the engine
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {CandidateUserCreation} candidateUserCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateUser(engineId: string, candidateUserCreation: CandidateUserCreation, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateUser> {
            const localVarAxiosArgs = CandidateUserApiAxiosParamCreator(configuration).createCandidateUser(engineId, candidateUserCreation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a CandidateUser 
         * @param {string} userId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateUser(userId: string, engineId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CandidateUserApiAxiosParamCreator(configuration).deleteCandidateUser(userId, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Audit details of a user
         * @param {string} engineId 
         * @param {string} userId 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateUserAudit(engineId: string, userId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, clientId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditLog>> {
            const localVarAxiosArgs = CandidateUserApiAxiosParamCreator(configuration).getCandidateUserAudit(engineId, userId, dateAfter, dateBefore, eventName, performerId, clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a list of Users by group
         * @param {string} groupName Filter for users which are members of a group.
         * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByGroup(groupName: string, engineId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateUser>> {
            const localVarAxiosArgs = CandidateUserApiAxiosParamCreator(configuration).getUsersByGroup(groupName, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a list of Users by group, deprecated use /engines/{engineId}/users instead
         * @param {string} groupName Filter for users which are members of a group.
         * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByGroupDeprecated(groupName: string, engineId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateUser>> {
            const localVarAxiosArgs = CandidateUserApiAxiosParamCreator(configuration).getUsersByGroupDeprecated(groupName, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a CandidateUser 
         * @param {string} userId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCandidateUserById(userId: string, engineId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateUser> {
            const localVarAxiosArgs = CandidateUserApiAxiosParamCreator(configuration).retrieveCandidateUserById(userId, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a list of groups of a user
         * @param {string} userId The user Id
         * @param {string} engineId Engine ID: The ID of the engine that should receive the request
         * @param {boolean} [triggerMembershipSynchro] If true, it triggers the membership synchronization for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveGroupsForUser(userId: string, engineId: string, triggerMembershipSynchro?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateGroup>> {
            const localVarAxiosArgs = CandidateUserApiAxiosParamCreator(configuration).retrieveGroupsForUser(userId, engineId, triggerMembershipSynchro, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieves a list of CandidateUser based on the search key word
         * @param {string} key search keyword (minimum 3 characters)
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCandidateUser(key: string, engineId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateUser>> {
            const localVarAxiosArgs = CandidateUserApiAxiosParamCreator(configuration).searchCandidateUser(key, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CandidateUserApi - factory interface
 * @export
 */
export const CandidateUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a new CandidateUser in the engine
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {CandidateUserCreation} candidateUserCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateUser(engineId: string, candidateUserCreation: CandidateUserCreation, options?: any): AxiosPromise<CandidateUser> {
            return CandidateUserApiFp(configuration).createCandidateUser(engineId, candidateUserCreation, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a CandidateUser 
         * @param {string} userId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateUser(userId: string, engineId: string, options?: any): AxiosPromise<void> {
            return CandidateUserApiFp(configuration).deleteCandidateUser(userId, engineId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get Audit details of a user
         * @param {string} engineId 
         * @param {string} userId 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateUserAudit(engineId: string, userId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, clientId?: string, options?: any): AxiosPromise<Array<AuditLog>> {
            return CandidateUserApiFp(configuration).getCandidateUserAudit(engineId, userId, dateAfter, dateBefore, eventName, performerId, clientId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve a list of Users by group
         * @param {string} groupName Filter for users which are members of a group.
         * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByGroup(groupName: string, engineId: string, options?: any): AxiosPromise<Array<CandidateUser>> {
            return CandidateUserApiFp(configuration).getUsersByGroup(groupName, engineId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve a list of Users by group, deprecated use /engines/{engineId}/users instead
         * @param {string} groupName Filter for users which are members of a group.
         * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByGroupDeprecated(groupName: string, engineId: string, options?: any): AxiosPromise<Array<CandidateUser>> {
            return CandidateUserApiFp(configuration).getUsersByGroupDeprecated(groupName, engineId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve a CandidateUser 
         * @param {string} userId 
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCandidateUserById(userId: string, engineId: string, options?: any): AxiosPromise<CandidateUser> {
            return CandidateUserApiFp(configuration).retrieveCandidateUserById(userId, engineId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve a list of groups of a user
         * @param {string} userId The user Id
         * @param {string} engineId Engine ID: The ID of the engine that should receive the request
         * @param {boolean} [triggerMembershipSynchro] If true, it triggers the membership synchronization for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveGroupsForUser(userId: string, engineId: string, triggerMembershipSynchro?: boolean, options?: any): AxiosPromise<Array<CandidateGroup>> {
            return CandidateUserApiFp(configuration).retrieveGroupsForUser(userId, engineId, triggerMembershipSynchro, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieves a list of CandidateUser based on the search key word
         * @param {string} key search keyword (minimum 3 characters)
         * @param {string} engineId Engine ID: The ID of the engine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCandidateUser(key: string, engineId: string, options?: any): AxiosPromise<Array<CandidateUser>> {
            return CandidateUserApiFp(configuration).searchCandidateUser(key, engineId, options)(axios, basePath);
        },
    };
};

/**
 * CandidateUserApi - object-oriented interface
 * @export
 * @class CandidateUserApi
 * @extends {BaseAPI}
 */
export class CandidateUserApi extends BaseAPI {
    /**
     * 
     * @summary Create a new CandidateUser in the engine
     * @param {string} engineId Engine ID: The ID of the engine
     * @param {CandidateUserCreation} candidateUserCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateUserApi
     */
    public createCandidateUser(engineId: string, candidateUserCreation: CandidateUserCreation, options?: any) {
        return CandidateUserApiFp(this.configuration).createCandidateUser(engineId, candidateUserCreation, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a CandidateUser 
     * @param {string} userId 
     * @param {string} engineId Engine ID: The ID of the engine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateUserApi
     */
    public deleteCandidateUser(userId: string, engineId: string, options?: any) {
        return CandidateUserApiFp(this.configuration).deleteCandidateUser(userId, engineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get Audit details of a user
     * @param {string} engineId 
     * @param {string} userId 
     * @param {Date} [dateAfter] 
     * @param {Date} [dateBefore] 
     * @param {string} [eventName] 
     * @param {string} [performerId] 
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateUserApi
     */
    public getCandidateUserAudit(engineId: string, userId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, clientId?: string, options?: any) {
        return CandidateUserApiFp(this.configuration).getCandidateUserAudit(engineId, userId, dateAfter, dateBefore, eventName, performerId, clientId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve a list of Users by group
     * @param {string} groupName Filter for users which are members of a group.
     * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateUserApi
     */
    public getUsersByGroup(groupName: string, engineId: string, options?: any) {
        return CandidateUserApiFp(this.configuration).getUsersByGroup(groupName, engineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve a list of Users by group, deprecated use /engines/{engineId}/users instead
     * @param {string} groupName Filter for users which are members of a group.
     * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateUserApi
     */
    public getUsersByGroupDeprecated(groupName: string, engineId: string, options?: any) {
        return CandidateUserApiFp(this.configuration).getUsersByGroupDeprecated(groupName, engineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve a CandidateUser 
     * @param {string} userId 
     * @param {string} engineId Engine ID: The ID of the engine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateUserApi
     */
    public retrieveCandidateUserById(userId: string, engineId: string, options?: any) {
        return CandidateUserApiFp(this.configuration).retrieveCandidateUserById(userId, engineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve a list of groups of a user
     * @param {string} userId The user Id
     * @param {string} engineId Engine ID: The ID of the engine that should receive the request
     * @param {boolean} [triggerMembershipSynchro] If true, it triggers the membership synchronization for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateUserApi
     */
    public retrieveGroupsForUser(userId: string, engineId: string, triggerMembershipSynchro?: boolean, options?: any) {
        return CandidateUserApiFp(this.configuration).retrieveGroupsForUser(userId, engineId, triggerMembershipSynchro, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieves a list of CandidateUser based on the search key word
     * @param {string} key search keyword (minimum 3 characters)
     * @param {string} engineId Engine ID: The ID of the engine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateUserApi
     */
    public searchCandidateUser(key: string, engineId: string, options?: any) {
        return CandidateUserApiFp(this.configuration).searchCandidateUser(key, engineId, options)(this.axios, this.basePath);
    }

}


/**
 * DecisionApi - axios parameter creator
 * @export
 */
export const DecisionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Evaluates the latest version of a decision definition which belongs to a tenant. The input values of the decision have to be supplied in the request body.
         * @summary Evaluates the latest version of a decision definition
         * @param {string} workspaceId 
         * @param {string} decisionDefKey 
         * @param {EvaluateDecisionDto} evaluateDecisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evaluateDecisionByKey(workspaceId: string, decisionDefKey: string, evaluateDecisionDto: EvaluateDecisionDto, options: any = {}): RequestArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling evaluateDecisionByKey.');
            }
            // verify required parameter 'decisionDefKey' is not null or undefined
            if (decisionDefKey === null || decisionDefKey === undefined) {
                throw new RequiredError('decisionDefKey','Required parameter decisionDefKey was null or undefined when calling evaluateDecisionByKey.');
            }
            // verify required parameter 'evaluateDecisionDto' is not null or undefined
            if (evaluateDecisionDto === null || evaluateDecisionDto === undefined) {
                throw new RequiredError('evaluateDecisionDto','Required parameter evaluateDecisionDto was null or undefined when calling evaluateDecisionByKey.');
            }
            const localVarPath = `/workspaces/{workspaceId}/decisions/{decisionDefKey}/evaluate`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"decisionDefKey"}}`, encodeURIComponent(String(decisionDefKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1", "api.sg-workflow-workspaces.run-decisions"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof evaluateDecisionDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(evaluateDecisionDto !== undefined ? evaluateDecisionDto : {}) : (evaluateDecisionDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DecisionApi - functional programming interface
 * @export
 */
export const DecisionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Evaluates the latest version of a decision definition which belongs to a tenant. The input values of the decision have to be supplied in the request body.
         * @summary Evaluates the latest version of a decision definition
         * @param {string} workspaceId 
         * @param {string} decisionDefKey 
         * @param {EvaluateDecisionDto} evaluateDecisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evaluateDecisionByKey(workspaceId: string, decisionDefKey: string, evaluateDecisionDto: EvaluateDecisionDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<{ [key: string]: object; }>> {
            const localVarAxiosArgs = DecisionApiAxiosParamCreator(configuration).evaluateDecisionByKey(workspaceId, decisionDefKey, evaluateDecisionDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DecisionApi - factory interface
 * @export
 */
export const DecisionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Evaluates the latest version of a decision definition which belongs to a tenant. The input values of the decision have to be supplied in the request body.
         * @summary Evaluates the latest version of a decision definition
         * @param {string} workspaceId 
         * @param {string} decisionDefKey 
         * @param {EvaluateDecisionDto} evaluateDecisionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evaluateDecisionByKey(workspaceId: string, decisionDefKey: string, evaluateDecisionDto: EvaluateDecisionDto, options?: any): AxiosPromise<Array<{ [key: string]: object; }>> {
            return DecisionApiFp(configuration).evaluateDecisionByKey(workspaceId, decisionDefKey, evaluateDecisionDto, options)(axios, basePath);
        },
    };
};

/**
 * DecisionApi - object-oriented interface
 * @export
 * @class DecisionApi
 * @extends {BaseAPI}
 */
export class DecisionApi extends BaseAPI {
    /**
     * Evaluates the latest version of a decision definition which belongs to a tenant. The input values of the decision have to be supplied in the request body.
     * @summary Evaluates the latest version of a decision definition
     * @param {string} workspaceId 
     * @param {string} decisionDefKey 
     * @param {EvaluateDecisionDto} evaluateDecisionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DecisionApi
     */
    public evaluateDecisionByKey(workspaceId: string, decisionDefKey: string, evaluateDecisionDto: EvaluateDecisionDto, options?: any) {
        return DecisionApiFp(this.configuration).evaluateDecisionByKey(workspaceId, decisionDefKey, evaluateDecisionDto, options)(this.axios, this.basePath);
    }

}


/**
 * EngineApi - axios parameter creator
 * @export
 */
export const EngineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get linting errors/warning of a BPMN
         * @param {string} engineId EngineId to communicate with
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lint(engineId: string, file: any, options: any = {}): RequestArgs {
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling lint.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling lint.');
            }
            const localVarPath = `/engines/{engineId}/lint`
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the list of Engines a user has access to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEngines(options: any = {}): RequestArgs {
            const localVarPath = `/engines`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sanitized bpmn
         * @param {string} engineId EngineId to communicate with
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sanitize(engineId: string, file: any, options: any = {}): RequestArgs {
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling sanitize.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling sanitize.');
            }
            const localVarPath = `/engines/{engineId}/sanitize`
                .replace(`{${"engineId"}}`, encodeURIComponent(String(engineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EngineApi - functional programming interface
 * @export
 */
export const EngineApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get linting errors/warning of a BPMN
         * @param {string} engineId EngineId to communicate with
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lint(engineId: string, file: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidationResults> {
            const localVarAxiosArgs = EngineApiAxiosParamCreator(configuration).lint(engineId, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve the list of Engines a user has access to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEngines(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = EngineApiAxiosParamCreator(configuration).listEngines(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get sanitized bpmn
         * @param {string} engineId EngineId to communicate with
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sanitize(engineId: string, file: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SanitizerResult> {
            const localVarAxiosArgs = EngineApiAxiosParamCreator(configuration).sanitize(engineId, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EngineApi - factory interface
 * @export
 */
export const EngineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get linting errors/warning of a BPMN
         * @param {string} engineId EngineId to communicate with
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lint(engineId: string, file: any, options?: any): AxiosPromise<ValidationResults> {
            return EngineApiFp(configuration).lint(engineId, file, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve the list of Engines a user has access to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEngines(options?: any): AxiosPromise<Array<string>> {
            return EngineApiFp(configuration).listEngines(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get sanitized bpmn
         * @param {string} engineId EngineId to communicate with
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sanitize(engineId: string, file: any, options?: any): AxiosPromise<SanitizerResult> {
            return EngineApiFp(configuration).sanitize(engineId, file, options)(axios, basePath);
        },
    };
};

/**
 * EngineApi - object-oriented interface
 * @export
 * @class EngineApi
 * @extends {BaseAPI}
 */
export class EngineApi extends BaseAPI {
    /**
     * 
     * @summary Get linting errors/warning of a BPMN
     * @param {string} engineId EngineId to communicate with
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngineApi
     */
    public lint(engineId: string, file: any, options?: any) {
        return EngineApiFp(this.configuration).lint(engineId, file, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve the list of Engines a user has access to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngineApi
     */
    public listEngines(options?: any) {
        return EngineApiFp(this.configuration).listEngines(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get sanitized bpmn
     * @param {string} engineId EngineId to communicate with
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngineApi
     */
    public sanitize(engineId: string, file: any, options?: any) {
        return EngineApiFp(this.configuration).sanitize(engineId, file, options)(this.axios, this.basePath);
    }

}


/**
 * IncidentApi - axios parameter creator
 * @export
 */
export const IncidentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Queries for historic incidents that fulfill given parameters
         * @param {string} processInstanceId Restricts to incidents that belong to a process instance with the given id.
         * @param {string} engineId Restricts to incidents that belong to a given engine id.
         * @param {string} [incidentId] Restricts to incidents that have the given id.
         * @param {string} [incidentType] Restricts to incidents that belong to the given incident type. See the User Guide for a list of incident types.
         * @param {string} [incidentMessage] Restricts to incidents that have the given incident message.
         * @param {string} [incidentMessageLike] Restricts to incidents that incidents message is a substring of the given value. The string can include the wildcard character \&#39;%\&#39; to express like-strategy: starts with (string%), ends with (%string) or contains (%string%).
         * @param {string} [processDefinitionId] Restricts to incidents that belong to a process definition with the given id.
         * @param {string} [processDefinitionKey] Restricts to incidents that have the given processDefinitionKey.
         * @param {Array<string>} [processDefinitionKeyIn] Restricts to incidents that have one of the given process definition keys.
         * @param {string} [executionId] Restricts to incidents that belong to an execution with the given id.
         * @param {Date} [createTimeBefore] Restricts to incidents that have a createTime date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [createTimeAfter] Restricts to incidents that have a createTime date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [endTimeBefore] Restricts to incidents that have an endTimeBefore date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [endTimeAfter] Restricts to incidents that have an endTimeAfter date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {string} [activityId] Restricts to incidents that belong to an activity with the given id.
         * @param {string} [failedActivityId] Restricts to incidents that were created due to the failure of an activity with the given id.
         * @param {string} [causeIncidentId] Restricts to incidents that have the given incident id as cause incident.
         * @param {string} [rootCauseIncidentId] Restricts to incidents that have the given incident id as root cause incident.
         * @param {string} [config] Restricts to incidents that have the given parameter set as configuration.
         * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
         * @param {boolean} [withoutTenantId] Only include deployments which belong to no tenant. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
         * @param {Array<string>} [jobDefinitionIdIn] Restricts to incidents that have one of the given job definition ids.
         * @param {boolean} [open] Restricts to incidents that are open.
         * @param {boolean} [deleted] Restricts to incidents that are deleted.
         * @param {boolean} [resolved] Restricts to incidents that are resolved.
         * @param {SortByIncidentHistory} [sortBy] Sort the results lexicographically by a given criterion. Valid values are incidentId, incidentMessage, createTime, endTime, incidentType, executionId, activityId, processInstanceId, processDefinitionId, processDefinitionKey, causeIncidentId, rootCauseIncidentId, configuration, tenantId and incidentState. Must be used in conjunction with the sortOrder parameter.
         * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeprecatedHistoricIncidents(processInstanceId: string, engineId: string, incidentId?: string, incidentType?: string, incidentMessage?: string, incidentMessageLike?: string, processDefinitionId?: string, processDefinitionKey?: string, processDefinitionKeyIn?: Array<string>, executionId?: string, createTimeBefore?: Date, createTimeAfter?: Date, endTimeBefore?: Date, endTimeAfter?: Date, activityId?: string, failedActivityId?: string, causeIncidentId?: string, rootCauseIncidentId?: string, config?: string, tenantIdIn?: Array<string>, withoutTenantId?: boolean, jobDefinitionIdIn?: Array<string>, open?: boolean, deleted?: boolean, resolved?: boolean, sortBy?: SortByIncidentHistory, sortOrder?: 'asc' | 'desc', options: any = {}): RequestArgs {
            // verify required parameter 'processInstanceId' is not null or undefined
            if (processInstanceId === null || processInstanceId === undefined) {
                throw new RequiredError('processInstanceId','Required parameter processInstanceId was null or undefined when calling getDeprecatedHistoricIncidents.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling getDeprecatedHistoricIncidents.');
            }
            const localVarPath = `/incident/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (incidentId !== undefined) {
                localVarQueryParameter['incidentId'] = incidentId;
            }

            if (incidentType !== undefined) {
                localVarQueryParameter['incidentType'] = incidentType;
            }

            if (incidentMessage !== undefined) {
                localVarQueryParameter['incidentMessage'] = incidentMessage;
            }

            if (incidentMessageLike !== undefined) {
                localVarQueryParameter['incidentMessageLike'] = incidentMessageLike;
            }

            if (processDefinitionId !== undefined) {
                localVarQueryParameter['processDefinitionId'] = processDefinitionId;
            }

            if (processDefinitionKey !== undefined) {
                localVarQueryParameter['processDefinitionKey'] = processDefinitionKey;
            }

            if (processDefinitionKeyIn) {
                localVarQueryParameter['processDefinitionKeyIn'] = processDefinitionKeyIn;
            }

            if (processInstanceId !== undefined) {
                localVarQueryParameter['processInstanceId'] = processInstanceId;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (executionId !== undefined) {
                localVarQueryParameter['executionId'] = executionId;
            }

            if (createTimeBefore !== undefined) {
                localVarQueryParameter['createTimeBefore'] = (createTimeBefore as any instanceof Date) ?
                    (createTimeBefore as any).toISOString() :
                    createTimeBefore;
            }

            if (createTimeAfter !== undefined) {
                localVarQueryParameter['createTimeAfter'] = (createTimeAfter as any instanceof Date) ?
                    (createTimeAfter as any).toISOString() :
                    createTimeAfter;
            }

            if (endTimeBefore !== undefined) {
                localVarQueryParameter['endTimeBefore'] = (endTimeBefore as any instanceof Date) ?
                    (endTimeBefore as any).toISOString() :
                    endTimeBefore;
            }

            if (endTimeAfter !== undefined) {
                localVarQueryParameter['endTimeAfter'] = (endTimeAfter as any instanceof Date) ?
                    (endTimeAfter as any).toISOString() :
                    endTimeAfter;
            }

            if (activityId !== undefined) {
                localVarQueryParameter['activityId'] = activityId;
            }

            if (failedActivityId !== undefined) {
                localVarQueryParameter['failedActivityId'] = failedActivityId;
            }

            if (causeIncidentId !== undefined) {
                localVarQueryParameter['causeIncidentId'] = causeIncidentId;
            }

            if (rootCauseIncidentId !== undefined) {
                localVarQueryParameter['rootCauseIncidentId'] = rootCauseIncidentId;
            }

            if (config !== undefined) {
                localVarQueryParameter['config'] = config;
            }

            if (tenantIdIn) {
                localVarQueryParameter['tenantIdIn'] = tenantIdIn;
            }

            if (withoutTenantId !== undefined) {
                localVarQueryParameter['withoutTenantId'] = withoutTenantId;
            }

            if (jobDefinitionIdIn) {
                localVarQueryParameter['jobDefinitionIdIn'] = jobDefinitionIdIn;
            }

            if (open !== undefined) {
                localVarQueryParameter['open'] = open;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (resolved !== undefined) {
                localVarQueryParameter['resolved'] = resolved;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Queries for historic incidents that fulfill given parameters
         * @param {string} processInstanceId Restricts to incidents that belong to a process instance with the given id.
         * @param {string} engineId Restricts to incidents that belong to a given engine id.
         * @param {string} [incidentId] Restricts to incidents that have the given id.
         * @param {string} [incidentType] Restricts to incidents that belong to the given incident type. See the User Guide for a list of incident types.
         * @param {string} [incidentMessage] Restricts to incidents that have the given incident message.
         * @param {string} [incidentMessageLike] Restricts to incidents that incidents message is a substring of the given value. The string can include the wildcard character \&#39;%\&#39; to express like-strategy: starts with (string%), ends with (%string) or contains (%string%).
         * @param {string} [processDefinitionId] Restricts to incidents that belong to a process definition with the given id.
         * @param {string} [processDefinitionKey] Restricts to incidents that have the given processDefinitionKey.
         * @param {Array<string>} [processDefinitionKeyIn] Restricts to incidents that have one of the given process definition keys.
         * @param {string} [executionId] Restricts to incidents that belong to an execution with the given id.
         * @param {Date} [createTimeBefore] Restricts to incidents that have a createTime date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [createTimeAfter] Restricts to incidents that have a createTime date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [endTimeBefore] Restricts to incidents that have an endTimeBefore date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [endTimeAfter] Restricts to incidents that have an endTimeAfter date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {string} [activityId] Restricts to incidents that belong to an activity with the given id.
         * @param {string} [failedActivityId] Restricts to incidents that were created due to the failure of an activity with the given id.
         * @param {string} [causeIncidentId] Restricts to incidents that have the given incident id as cause incident.
         * @param {string} [rootCauseIncidentId] Restricts to incidents that have the given incident id as root cause incident.
         * @param {string} [config] Restricts to incidents that have the given parameter set as configuration.
         * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
         * @param {boolean} [withoutTenantId] Only include deployments which belong to no tenant. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
         * @param {Array<string>} [jobDefinitionIdIn] Restricts to incidents that have one of the given job definition ids.
         * @param {boolean} [opened] Restricts to incidents that are open.
         * @param {boolean} [deleted] Restricts to incidents that are deleted.
         * @param {boolean} [resolved] Restricts to incidents that are resolved.
         * @param {SortByIncidentHistory} [sortBy] Sort the results lexicographically by a given criterion. Valid values are incidentId, incidentMessage, createTime, endTime, incidentType, executionId, activityId, processInstanceId, processDefinitionId, processDefinitionKey, causeIncidentId, rootCauseIncidentId, configuration, tenantId and incidentState. Must be used in conjunction with the sortOrder parameter.
         * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricIncidents(processInstanceId: string, engineId: string, incidentId?: string, incidentType?: string, incidentMessage?: string, incidentMessageLike?: string, processDefinitionId?: string, processDefinitionKey?: string, processDefinitionKeyIn?: Array<string>, executionId?: string, createTimeBefore?: Date, createTimeAfter?: Date, endTimeBefore?: Date, endTimeAfter?: Date, activityId?: string, failedActivityId?: string, causeIncidentId?: string, rootCauseIncidentId?: string, config?: string, tenantIdIn?: Array<string>, withoutTenantId?: boolean, jobDefinitionIdIn?: Array<string>, opened?: boolean, deleted?: boolean, resolved?: boolean, sortBy?: SortByIncidentHistory, sortOrder?: 'asc' | 'desc', options: any = {}): RequestArgs {
            // verify required parameter 'processInstanceId' is not null or undefined
            if (processInstanceId === null || processInstanceId === undefined) {
                throw new RequiredError('processInstanceId','Required parameter processInstanceId was null or undefined when calling getHistoricIncidents.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling getHistoricIncidents.');
            }
            const localVarPath = `/incidents/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (incidentId !== undefined) {
                localVarQueryParameter['incidentId'] = incidentId;
            }

            if (incidentType !== undefined) {
                localVarQueryParameter['incidentType'] = incidentType;
            }

            if (incidentMessage !== undefined) {
                localVarQueryParameter['incidentMessage'] = incidentMessage;
            }

            if (incidentMessageLike !== undefined) {
                localVarQueryParameter['incidentMessageLike'] = incidentMessageLike;
            }

            if (processDefinitionId !== undefined) {
                localVarQueryParameter['processDefinitionId'] = processDefinitionId;
            }

            if (processDefinitionKey !== undefined) {
                localVarQueryParameter['processDefinitionKey'] = processDefinitionKey;
            }

            if (processDefinitionKeyIn) {
                localVarQueryParameter['processDefinitionKeyIn'] = processDefinitionKeyIn;
            }

            if (processInstanceId !== undefined) {
                localVarQueryParameter['processInstanceId'] = processInstanceId;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (executionId !== undefined) {
                localVarQueryParameter['executionId'] = executionId;
            }

            if (createTimeBefore !== undefined) {
                localVarQueryParameter['createTimeBefore'] = (createTimeBefore as any instanceof Date) ?
                    (createTimeBefore as any).toISOString() :
                    createTimeBefore;
            }

            if (createTimeAfter !== undefined) {
                localVarQueryParameter['createTimeAfter'] = (createTimeAfter as any instanceof Date) ?
                    (createTimeAfter as any).toISOString() :
                    createTimeAfter;
            }

            if (endTimeBefore !== undefined) {
                localVarQueryParameter['endTimeBefore'] = (endTimeBefore as any instanceof Date) ?
                    (endTimeBefore as any).toISOString() :
                    endTimeBefore;
            }

            if (endTimeAfter !== undefined) {
                localVarQueryParameter['endTimeAfter'] = (endTimeAfter as any instanceof Date) ?
                    (endTimeAfter as any).toISOString() :
                    endTimeAfter;
            }

            if (activityId !== undefined) {
                localVarQueryParameter['activityId'] = activityId;
            }

            if (failedActivityId !== undefined) {
                localVarQueryParameter['failedActivityId'] = failedActivityId;
            }

            if (causeIncidentId !== undefined) {
                localVarQueryParameter['causeIncidentId'] = causeIncidentId;
            }

            if (rootCauseIncidentId !== undefined) {
                localVarQueryParameter['rootCauseIncidentId'] = rootCauseIncidentId;
            }

            if (config !== undefined) {
                localVarQueryParameter['config'] = config;
            }

            if (tenantIdIn) {
                localVarQueryParameter['tenantIdIn'] = tenantIdIn;
            }

            if (withoutTenantId !== undefined) {
                localVarQueryParameter['withoutTenantId'] = withoutTenantId;
            }

            if (jobDefinitionIdIn) {
                localVarQueryParameter['jobDefinitionIdIn'] = jobDefinitionIdIn;
            }

            if (opened !== undefined) {
                localVarQueryParameter['opened'] = opened;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (resolved !== undefined) {
                localVarQueryParameter['resolved'] = resolved;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IncidentApi - functional programming interface
 * @export
 */
export const IncidentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Queries for historic incidents that fulfill given parameters
         * @param {string} processInstanceId Restricts to incidents that belong to a process instance with the given id.
         * @param {string} engineId Restricts to incidents that belong to a given engine id.
         * @param {string} [incidentId] Restricts to incidents that have the given id.
         * @param {string} [incidentType] Restricts to incidents that belong to the given incident type. See the User Guide for a list of incident types.
         * @param {string} [incidentMessage] Restricts to incidents that have the given incident message.
         * @param {string} [incidentMessageLike] Restricts to incidents that incidents message is a substring of the given value. The string can include the wildcard character \&#39;%\&#39; to express like-strategy: starts with (string%), ends with (%string) or contains (%string%).
         * @param {string} [processDefinitionId] Restricts to incidents that belong to a process definition with the given id.
         * @param {string} [processDefinitionKey] Restricts to incidents that have the given processDefinitionKey.
         * @param {Array<string>} [processDefinitionKeyIn] Restricts to incidents that have one of the given process definition keys.
         * @param {string} [executionId] Restricts to incidents that belong to an execution with the given id.
         * @param {Date} [createTimeBefore] Restricts to incidents that have a createTime date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [createTimeAfter] Restricts to incidents that have a createTime date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [endTimeBefore] Restricts to incidents that have an endTimeBefore date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [endTimeAfter] Restricts to incidents that have an endTimeAfter date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {string} [activityId] Restricts to incidents that belong to an activity with the given id.
         * @param {string} [failedActivityId] Restricts to incidents that were created due to the failure of an activity with the given id.
         * @param {string} [causeIncidentId] Restricts to incidents that have the given incident id as cause incident.
         * @param {string} [rootCauseIncidentId] Restricts to incidents that have the given incident id as root cause incident.
         * @param {string} [config] Restricts to incidents that have the given parameter set as configuration.
         * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
         * @param {boolean} [withoutTenantId] Only include deployments which belong to no tenant. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
         * @param {Array<string>} [jobDefinitionIdIn] Restricts to incidents that have one of the given job definition ids.
         * @param {boolean} [open] Restricts to incidents that are open.
         * @param {boolean} [deleted] Restricts to incidents that are deleted.
         * @param {boolean} [resolved] Restricts to incidents that are resolved.
         * @param {SortByIncidentHistory} [sortBy] Sort the results lexicographically by a given criterion. Valid values are incidentId, incidentMessage, createTime, endTime, incidentType, executionId, activityId, processInstanceId, processDefinitionId, processDefinitionKey, causeIncidentId, rootCauseIncidentId, configuration, tenantId and incidentState. Must be used in conjunction with the sortOrder parameter.
         * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeprecatedHistoricIncidents(processInstanceId: string, engineId: string, incidentId?: string, incidentType?: string, incidentMessage?: string, incidentMessageLike?: string, processDefinitionId?: string, processDefinitionKey?: string, processDefinitionKeyIn?: Array<string>, executionId?: string, createTimeBefore?: Date, createTimeAfter?: Date, endTimeBefore?: Date, endTimeAfter?: Date, activityId?: string, failedActivityId?: string, causeIncidentId?: string, rootCauseIncidentId?: string, config?: string, tenantIdIn?: Array<string>, withoutTenantId?: boolean, jobDefinitionIdIn?: Array<string>, open?: boolean, deleted?: boolean, resolved?: boolean, sortBy?: SortByIncidentHistory, sortOrder?: 'asc' | 'desc', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistoricIncidentDto>> {
            const localVarAxiosArgs = IncidentApiAxiosParamCreator(configuration).getDeprecatedHistoricIncidents(processInstanceId, engineId, incidentId, incidentType, incidentMessage, incidentMessageLike, processDefinitionId, processDefinitionKey, processDefinitionKeyIn, executionId, createTimeBefore, createTimeAfter, endTimeBefore, endTimeAfter, activityId, failedActivityId, causeIncidentId, rootCauseIncidentId, config, tenantIdIn, withoutTenantId, jobDefinitionIdIn, open, deleted, resolved, sortBy, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Queries for historic incidents that fulfill given parameters
         * @param {string} processInstanceId Restricts to incidents that belong to a process instance with the given id.
         * @param {string} engineId Restricts to incidents that belong to a given engine id.
         * @param {string} [incidentId] Restricts to incidents that have the given id.
         * @param {string} [incidentType] Restricts to incidents that belong to the given incident type. See the User Guide for a list of incident types.
         * @param {string} [incidentMessage] Restricts to incidents that have the given incident message.
         * @param {string} [incidentMessageLike] Restricts to incidents that incidents message is a substring of the given value. The string can include the wildcard character \&#39;%\&#39; to express like-strategy: starts with (string%), ends with (%string) or contains (%string%).
         * @param {string} [processDefinitionId] Restricts to incidents that belong to a process definition with the given id.
         * @param {string} [processDefinitionKey] Restricts to incidents that have the given processDefinitionKey.
         * @param {Array<string>} [processDefinitionKeyIn] Restricts to incidents that have one of the given process definition keys.
         * @param {string} [executionId] Restricts to incidents that belong to an execution with the given id.
         * @param {Date} [createTimeBefore] Restricts to incidents that have a createTime date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [createTimeAfter] Restricts to incidents that have a createTime date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [endTimeBefore] Restricts to incidents that have an endTimeBefore date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [endTimeAfter] Restricts to incidents that have an endTimeAfter date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {string} [activityId] Restricts to incidents that belong to an activity with the given id.
         * @param {string} [failedActivityId] Restricts to incidents that were created due to the failure of an activity with the given id.
         * @param {string} [causeIncidentId] Restricts to incidents that have the given incident id as cause incident.
         * @param {string} [rootCauseIncidentId] Restricts to incidents that have the given incident id as root cause incident.
         * @param {string} [config] Restricts to incidents that have the given parameter set as configuration.
         * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
         * @param {boolean} [withoutTenantId] Only include deployments which belong to no tenant. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
         * @param {Array<string>} [jobDefinitionIdIn] Restricts to incidents that have one of the given job definition ids.
         * @param {boolean} [opened] Restricts to incidents that are open.
         * @param {boolean} [deleted] Restricts to incidents that are deleted.
         * @param {boolean} [resolved] Restricts to incidents that are resolved.
         * @param {SortByIncidentHistory} [sortBy] Sort the results lexicographically by a given criterion. Valid values are incidentId, incidentMessage, createTime, endTime, incidentType, executionId, activityId, processInstanceId, processDefinitionId, processDefinitionKey, causeIncidentId, rootCauseIncidentId, configuration, tenantId and incidentState. Must be used in conjunction with the sortOrder parameter.
         * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricIncidents(processInstanceId: string, engineId: string, incidentId?: string, incidentType?: string, incidentMessage?: string, incidentMessageLike?: string, processDefinitionId?: string, processDefinitionKey?: string, processDefinitionKeyIn?: Array<string>, executionId?: string, createTimeBefore?: Date, createTimeAfter?: Date, endTimeBefore?: Date, endTimeAfter?: Date, activityId?: string, failedActivityId?: string, causeIncidentId?: string, rootCauseIncidentId?: string, config?: string, tenantIdIn?: Array<string>, withoutTenantId?: boolean, jobDefinitionIdIn?: Array<string>, opened?: boolean, deleted?: boolean, resolved?: boolean, sortBy?: SortByIncidentHistory, sortOrder?: 'asc' | 'desc', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistoricIncidentDto>> {
            const localVarAxiosArgs = IncidentApiAxiosParamCreator(configuration).getHistoricIncidents(processInstanceId, engineId, incidentId, incidentType, incidentMessage, incidentMessageLike, processDefinitionId, processDefinitionKey, processDefinitionKeyIn, executionId, createTimeBefore, createTimeAfter, endTimeBefore, endTimeAfter, activityId, failedActivityId, causeIncidentId, rootCauseIncidentId, config, tenantIdIn, withoutTenantId, jobDefinitionIdIn, opened, deleted, resolved, sortBy, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * IncidentApi - factory interface
 * @export
 */
export const IncidentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Queries for historic incidents that fulfill given parameters
         * @param {string} processInstanceId Restricts to incidents that belong to a process instance with the given id.
         * @param {string} engineId Restricts to incidents that belong to a given engine id.
         * @param {string} [incidentId] Restricts to incidents that have the given id.
         * @param {string} [incidentType] Restricts to incidents that belong to the given incident type. See the User Guide for a list of incident types.
         * @param {string} [incidentMessage] Restricts to incidents that have the given incident message.
         * @param {string} [incidentMessageLike] Restricts to incidents that incidents message is a substring of the given value. The string can include the wildcard character \&#39;%\&#39; to express like-strategy: starts with (string%), ends with (%string) or contains (%string%).
         * @param {string} [processDefinitionId] Restricts to incidents that belong to a process definition with the given id.
         * @param {string} [processDefinitionKey] Restricts to incidents that have the given processDefinitionKey.
         * @param {Array<string>} [processDefinitionKeyIn] Restricts to incidents that have one of the given process definition keys.
         * @param {string} [executionId] Restricts to incidents that belong to an execution with the given id.
         * @param {Date} [createTimeBefore] Restricts to incidents that have a createTime date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [createTimeAfter] Restricts to incidents that have a createTime date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [endTimeBefore] Restricts to incidents that have an endTimeBefore date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [endTimeAfter] Restricts to incidents that have an endTimeAfter date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {string} [activityId] Restricts to incidents that belong to an activity with the given id.
         * @param {string} [failedActivityId] Restricts to incidents that were created due to the failure of an activity with the given id.
         * @param {string} [causeIncidentId] Restricts to incidents that have the given incident id as cause incident.
         * @param {string} [rootCauseIncidentId] Restricts to incidents that have the given incident id as root cause incident.
         * @param {string} [config] Restricts to incidents that have the given parameter set as configuration.
         * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
         * @param {boolean} [withoutTenantId] Only include deployments which belong to no tenant. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
         * @param {Array<string>} [jobDefinitionIdIn] Restricts to incidents that have one of the given job definition ids.
         * @param {boolean} [open] Restricts to incidents that are open.
         * @param {boolean} [deleted] Restricts to incidents that are deleted.
         * @param {boolean} [resolved] Restricts to incidents that are resolved.
         * @param {SortByIncidentHistory} [sortBy] Sort the results lexicographically by a given criterion. Valid values are incidentId, incidentMessage, createTime, endTime, incidentType, executionId, activityId, processInstanceId, processDefinitionId, processDefinitionKey, causeIncidentId, rootCauseIncidentId, configuration, tenantId and incidentState. Must be used in conjunction with the sortOrder parameter.
         * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeprecatedHistoricIncidents(processInstanceId: string, engineId: string, incidentId?: string, incidentType?: string, incidentMessage?: string, incidentMessageLike?: string, processDefinitionId?: string, processDefinitionKey?: string, processDefinitionKeyIn?: Array<string>, executionId?: string, createTimeBefore?: Date, createTimeAfter?: Date, endTimeBefore?: Date, endTimeAfter?: Date, activityId?: string, failedActivityId?: string, causeIncidentId?: string, rootCauseIncidentId?: string, config?: string, tenantIdIn?: Array<string>, withoutTenantId?: boolean, jobDefinitionIdIn?: Array<string>, open?: boolean, deleted?: boolean, resolved?: boolean, sortBy?: SortByIncidentHistory, sortOrder?: 'asc' | 'desc', options?: any): AxiosPromise<Array<HistoricIncidentDto>> {
            return IncidentApiFp(configuration).getDeprecatedHistoricIncidents(processInstanceId, engineId, incidentId, incidentType, incidentMessage, incidentMessageLike, processDefinitionId, processDefinitionKey, processDefinitionKeyIn, executionId, createTimeBefore, createTimeAfter, endTimeBefore, endTimeAfter, activityId, failedActivityId, causeIncidentId, rootCauseIncidentId, config, tenantIdIn, withoutTenantId, jobDefinitionIdIn, open, deleted, resolved, sortBy, sortOrder, options)(axios, basePath);
        },
        /**
         * 
         * @summary Queries for historic incidents that fulfill given parameters
         * @param {string} processInstanceId Restricts to incidents that belong to a process instance with the given id.
         * @param {string} engineId Restricts to incidents that belong to a given engine id.
         * @param {string} [incidentId] Restricts to incidents that have the given id.
         * @param {string} [incidentType] Restricts to incidents that belong to the given incident type. See the User Guide for a list of incident types.
         * @param {string} [incidentMessage] Restricts to incidents that have the given incident message.
         * @param {string} [incidentMessageLike] Restricts to incidents that incidents message is a substring of the given value. The string can include the wildcard character \&#39;%\&#39; to express like-strategy: starts with (string%), ends with (%string) or contains (%string%).
         * @param {string} [processDefinitionId] Restricts to incidents that belong to a process definition with the given id.
         * @param {string} [processDefinitionKey] Restricts to incidents that have the given processDefinitionKey.
         * @param {Array<string>} [processDefinitionKeyIn] Restricts to incidents that have one of the given process definition keys.
         * @param {string} [executionId] Restricts to incidents that belong to an execution with the given id.
         * @param {Date} [createTimeBefore] Restricts to incidents that have a createTime date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [createTimeAfter] Restricts to incidents that have a createTime date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [endTimeBefore] Restricts to incidents that have an endTimeBefore date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {Date} [endTimeAfter] Restricts to incidents that have an endTimeAfter date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
         * @param {string} [activityId] Restricts to incidents that belong to an activity with the given id.
         * @param {string} [failedActivityId] Restricts to incidents that were created due to the failure of an activity with the given id.
         * @param {string} [causeIncidentId] Restricts to incidents that have the given incident id as cause incident.
         * @param {string} [rootCauseIncidentId] Restricts to incidents that have the given incident id as root cause incident.
         * @param {string} [config] Restricts to incidents that have the given parameter set as configuration.
         * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
         * @param {boolean} [withoutTenantId] Only include deployments which belong to no tenant. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
         * @param {Array<string>} [jobDefinitionIdIn] Restricts to incidents that have one of the given job definition ids.
         * @param {boolean} [opened] Restricts to incidents that are open.
         * @param {boolean} [deleted] Restricts to incidents that are deleted.
         * @param {boolean} [resolved] Restricts to incidents that are resolved.
         * @param {SortByIncidentHistory} [sortBy] Sort the results lexicographically by a given criterion. Valid values are incidentId, incidentMessage, createTime, endTime, incidentType, executionId, activityId, processInstanceId, processDefinitionId, processDefinitionKey, causeIncidentId, rootCauseIncidentId, configuration, tenantId and incidentState. Must be used in conjunction with the sortOrder parameter.
         * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricIncidents(processInstanceId: string, engineId: string, incidentId?: string, incidentType?: string, incidentMessage?: string, incidentMessageLike?: string, processDefinitionId?: string, processDefinitionKey?: string, processDefinitionKeyIn?: Array<string>, executionId?: string, createTimeBefore?: Date, createTimeAfter?: Date, endTimeBefore?: Date, endTimeAfter?: Date, activityId?: string, failedActivityId?: string, causeIncidentId?: string, rootCauseIncidentId?: string, config?: string, tenantIdIn?: Array<string>, withoutTenantId?: boolean, jobDefinitionIdIn?: Array<string>, opened?: boolean, deleted?: boolean, resolved?: boolean, sortBy?: SortByIncidentHistory, sortOrder?: 'asc' | 'desc', options?: any): AxiosPromise<Array<HistoricIncidentDto>> {
            return IncidentApiFp(configuration).getHistoricIncidents(processInstanceId, engineId, incidentId, incidentType, incidentMessage, incidentMessageLike, processDefinitionId, processDefinitionKey, processDefinitionKeyIn, executionId, createTimeBefore, createTimeAfter, endTimeBefore, endTimeAfter, activityId, failedActivityId, causeIncidentId, rootCauseIncidentId, config, tenantIdIn, withoutTenantId, jobDefinitionIdIn, opened, deleted, resolved, sortBy, sortOrder, options)(axios, basePath);
        },
    };
};

/**
 * IncidentApi - object-oriented interface
 * @export
 * @class IncidentApi
 * @extends {BaseAPI}
 */
export class IncidentApi extends BaseAPI {
    /**
     * 
     * @summary Queries for historic incidents that fulfill given parameters
     * @param {string} processInstanceId Restricts to incidents that belong to a process instance with the given id.
     * @param {string} engineId Restricts to incidents that belong to a given engine id.
     * @param {string} [incidentId] Restricts to incidents that have the given id.
     * @param {string} [incidentType] Restricts to incidents that belong to the given incident type. See the User Guide for a list of incident types.
     * @param {string} [incidentMessage] Restricts to incidents that have the given incident message.
     * @param {string} [incidentMessageLike] Restricts to incidents that incidents message is a substring of the given value. The string can include the wildcard character \&#39;%\&#39; to express like-strategy: starts with (string%), ends with (%string) or contains (%string%).
     * @param {string} [processDefinitionId] Restricts to incidents that belong to a process definition with the given id.
     * @param {string} [processDefinitionKey] Restricts to incidents that have the given processDefinitionKey.
     * @param {Array<string>} [processDefinitionKeyIn] Restricts to incidents that have one of the given process definition keys.
     * @param {string} [executionId] Restricts to incidents that belong to an execution with the given id.
     * @param {Date} [createTimeBefore] Restricts to incidents that have a createTime date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {Date} [createTimeAfter] Restricts to incidents that have a createTime date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {Date} [endTimeBefore] Restricts to incidents that have an endTimeBefore date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {Date} [endTimeAfter] Restricts to incidents that have an endTimeAfter date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {string} [activityId] Restricts to incidents that belong to an activity with the given id.
     * @param {string} [failedActivityId] Restricts to incidents that were created due to the failure of an activity with the given id.
     * @param {string} [causeIncidentId] Restricts to incidents that have the given incident id as cause incident.
     * @param {string} [rootCauseIncidentId] Restricts to incidents that have the given incident id as root cause incident.
     * @param {string} [config] Restricts to incidents that have the given parameter set as configuration.
     * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
     * @param {boolean} [withoutTenantId] Only include deployments which belong to no tenant. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
     * @param {Array<string>} [jobDefinitionIdIn] Restricts to incidents that have one of the given job definition ids.
     * @param {boolean} [open] Restricts to incidents that are open.
     * @param {boolean} [deleted] Restricts to incidents that are deleted.
     * @param {boolean} [resolved] Restricts to incidents that are resolved.
     * @param {SortByIncidentHistory} [sortBy] Sort the results lexicographically by a given criterion. Valid values are incidentId, incidentMessage, createTime, endTime, incidentType, executionId, activityId, processInstanceId, processDefinitionId, processDefinitionKey, causeIncidentId, rootCauseIncidentId, configuration, tenantId and incidentState. Must be used in conjunction with the sortOrder parameter.
     * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentApi
     */
    public getDeprecatedHistoricIncidents(processInstanceId: string, engineId: string, incidentId?: string, incidentType?: string, incidentMessage?: string, incidentMessageLike?: string, processDefinitionId?: string, processDefinitionKey?: string, processDefinitionKeyIn?: Array<string>, executionId?: string, createTimeBefore?: Date, createTimeAfter?: Date, endTimeBefore?: Date, endTimeAfter?: Date, activityId?: string, failedActivityId?: string, causeIncidentId?: string, rootCauseIncidentId?: string, config?: string, tenantIdIn?: Array<string>, withoutTenantId?: boolean, jobDefinitionIdIn?: Array<string>, open?: boolean, deleted?: boolean, resolved?: boolean, sortBy?: SortByIncidentHistory, sortOrder?: 'asc' | 'desc', options?: any) {
        return IncidentApiFp(this.configuration).getDeprecatedHistoricIncidents(processInstanceId, engineId, incidentId, incidentType, incidentMessage, incidentMessageLike, processDefinitionId, processDefinitionKey, processDefinitionKeyIn, executionId, createTimeBefore, createTimeAfter, endTimeBefore, endTimeAfter, activityId, failedActivityId, causeIncidentId, rootCauseIncidentId, config, tenantIdIn, withoutTenantId, jobDefinitionIdIn, open, deleted, resolved, sortBy, sortOrder, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Queries for historic incidents that fulfill given parameters
     * @param {string} processInstanceId Restricts to incidents that belong to a process instance with the given id.
     * @param {string} engineId Restricts to incidents that belong to a given engine id.
     * @param {string} [incidentId] Restricts to incidents that have the given id.
     * @param {string} [incidentType] Restricts to incidents that belong to the given incident type. See the User Guide for a list of incident types.
     * @param {string} [incidentMessage] Restricts to incidents that have the given incident message.
     * @param {string} [incidentMessageLike] Restricts to incidents that incidents message is a substring of the given value. The string can include the wildcard character \&#39;%\&#39; to express like-strategy: starts with (string%), ends with (%string) or contains (%string%).
     * @param {string} [processDefinitionId] Restricts to incidents that belong to a process definition with the given id.
     * @param {string} [processDefinitionKey] Restricts to incidents that have the given processDefinitionKey.
     * @param {Array<string>} [processDefinitionKeyIn] Restricts to incidents that have one of the given process definition keys.
     * @param {string} [executionId] Restricts to incidents that belong to an execution with the given id.
     * @param {Date} [createTimeBefore] Restricts to incidents that have a createTime date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {Date} [createTimeAfter] Restricts to incidents that have a createTime date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {Date} [endTimeBefore] Restricts to incidents that have an endTimeBefore date before the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {Date} [endTimeAfter] Restricts to incidents that have an endTimeAfter date after the given date. By default*, the date must have the format yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ, e.g., 2013-01-23T14:42:45.000+0200.
     * @param {string} [activityId] Restricts to incidents that belong to an activity with the given id.
     * @param {string} [failedActivityId] Restricts to incidents that were created due to the failure of an activity with the given id.
     * @param {string} [causeIncidentId] Restricts to incidents that have the given incident id as cause incident.
     * @param {string} [rootCauseIncidentId] Restricts to incidents that have the given incident id as root cause incident.
     * @param {string} [config] Restricts to incidents that have the given parameter set as configuration.
     * @param {Array<string>} [tenantIdIn] Filter by list of tenant ids. An activity instance must have one of the given tenant ids
     * @param {boolean} [withoutTenantId] Only include deployments which belong to no tenant. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
     * @param {Array<string>} [jobDefinitionIdIn] Restricts to incidents that have one of the given job definition ids.
     * @param {boolean} [opened] Restricts to incidents that are open.
     * @param {boolean} [deleted] Restricts to incidents that are deleted.
     * @param {boolean} [resolved] Restricts to incidents that are resolved.
     * @param {SortByIncidentHistory} [sortBy] Sort the results lexicographically by a given criterion. Valid values are incidentId, incidentMessage, createTime, endTime, incidentType, executionId, activityId, processInstanceId, processDefinitionId, processDefinitionKey, causeIncidentId, rootCauseIncidentId, configuration, tenantId and incidentState. Must be used in conjunction with the sortOrder parameter.
     * @param {'asc' | 'desc'} [sortOrder] Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IncidentApi
     */
    public getHistoricIncidents(processInstanceId: string, engineId: string, incidentId?: string, incidentType?: string, incidentMessage?: string, incidentMessageLike?: string, processDefinitionId?: string, processDefinitionKey?: string, processDefinitionKeyIn?: Array<string>, executionId?: string, createTimeBefore?: Date, createTimeAfter?: Date, endTimeBefore?: Date, endTimeAfter?: Date, activityId?: string, failedActivityId?: string, causeIncidentId?: string, rootCauseIncidentId?: string, config?: string, tenantIdIn?: Array<string>, withoutTenantId?: boolean, jobDefinitionIdIn?: Array<string>, opened?: boolean, deleted?: boolean, resolved?: boolean, sortBy?: SortByIncidentHistory, sortOrder?: 'asc' | 'desc', options?: any) {
        return IncidentApiFp(this.configuration).getHistoricIncidents(processInstanceId, engineId, incidentId, incidentType, incidentMessage, incidentMessageLike, processDefinitionId, processDefinitionKey, processDefinitionKeyIn, executionId, createTimeBefore, createTimeAfter, endTimeBefore, endTimeAfter, activityId, failedActivityId, causeIncidentId, rootCauseIncidentId, config, tenantIdIn, withoutTenantId, jobDefinitionIdIn, opened, deleted, resolved, sortBy, sortOrder, options)(this.axios, this.basePath);
    }

}


/**
 * InstanceApi - axios parameter creator
 * @export
 */
export const InstanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete (Cancel) an instance whether the user is the initiator or admin of the process
         * @param {string} instanceId The id of the process instance.
         * @param {string} deleteReason The reason to delete the instance
         * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProcessInstance(instanceId: string, deleteReason: string, engineId: string, options: any = {}): RequestArgs {
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling deleteProcessInstance.');
            }
            // verify required parameter 'deleteReason' is not null or undefined
            if (deleteReason === null || deleteReason === undefined) {
                throw new RequiredError('deleteReason','Required parameter deleteReason was null or undefined when calling deleteProcessInstance.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling deleteProcessInstance.');
            }
            const localVarPath = `/instances/{instanceId}`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (deleteReason !== undefined) {
                localVarQueryParameter['deleteReason'] = deleteReason;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the html export the entire process by process instance id
         * @param {string} instanceId Filtering by process instance id
         * @param {string} engineId Filtering by engine id
         * @param {'sglan' | 'sgmarkets'} [realmType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProcessAsHtml(instanceId: string, engineId: string, realmType?: 'sglan' | 'sgmarkets', options: any = {}): RequestArgs {
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling exportProcessAsHtml.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling exportProcessAsHtml.');
            }
            const localVarPath = `/instances/{instanceId}/exportAsHtml`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (realmType !== undefined) {
                localVarQueryParameter['realmType'] = realmType;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Audit details of a instance
         * @param {string} instanceId 
         * @param {string} tenantId 
         * @param {string} [engineId] 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceAudit(instanceId: string, tenantId: string, engineId?: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling getInstanceAudit.');
            }
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling getInstanceAudit.');
            }
            const localVarPath = `/instances/{instanceId}/audits`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (dateAfter !== undefined) {
                localVarQueryParameter['dateAfter'] = (dateAfter as any instanceof Date) ?
                    (dateAfter as any).toISOString() :
                    dateAfter;
            }

            if (dateBefore !== undefined) {
                localVarQueryParameter['dateBefore'] = (dateBefore as any instanceof Date) ?
                    (dateBefore as any).toISOString() :
                    dateBefore;
            }

            if (eventName !== undefined) {
                localVarQueryParameter['eventName'] = eventName;
            }

            if (performerId !== undefined) {
                localVarQueryParameter['performerId'] = performerId;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve current state of an instance (activities status, current tasks, incidents...)
         * @param {string} instanceId The id of the process instance.
         * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceStateById(instanceId: string, engineId: string, options: any = {}): RequestArgs {
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling getInstanceStateById.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling getInstanceStateById.');
            }
            const localVarPath = `/instances/{instanceId}/state`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves all historic process instances (accepts filters)
         * @param {InstanceTypeView} typeView Filtering by view
         * @param {Array<InstanceStatus>} status Filtering by process instance status (only usable for ADMIN typeview)
         * @param {number} page Offset for the first Result
         * @param {number} pageSize Number of instances return 
         * @param {SortByInstanceHistory} sortBy Sort instances by criteria
         * @param {'asc' | 'desc'} sortOrder Sort instances Ascending or Descending
         * @param {string} [processNameLike] Filtering by process name which contains the search
         * @param {string} [processInstanceBusinessKey] Filtering by process instance business key
         * @param {string} [processInstanceId] Filtering by process instance business key (use processInstanceBusinessKey instead)
         * @param {string} [processDefinitionKey] Filtering by process definition key
         * @param {Array<string>} [processInstanceIds] Filtering by process instance ids
         * @param {Date} [startedBefore] Filtering by instances started before specified date
         * @param {Date} [startedAfter] Filtering by instances started before specified date
         * @param {Date} [finishedBefore] Filtering by instances finished before specified date
         * @param {Date} [finishedAfter] Filtering by instances finished after specified date
         * @param {Array<string>} [engineIds] Engine IDs filter (IMPORTANT: If engineIds size is different from 1, sort and pagination will not be applied)
         * @param {Array<string>} [tenantIds] Tenant Ids filter (only usable in ADMIN typeview)
         * @param {Array<string>} [variableNames] Attach Variables with specific name
         * @param {Array<string>} [variableNamesLike] Attach Variables with name contains. Deprecated for performance reason.
         * @param {boolean} [withIncidents] 
         * @param {'open' | 'resolved' | 'deleted'} [incidentState] Sort by incident state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstancesHistory(typeView: InstanceTypeView, status: Array<InstanceStatus>, page: number, pageSize: number, sortBy: SortByInstanceHistory, sortOrder: 'asc' | 'desc', processNameLike?: string, processInstanceBusinessKey?: string, processInstanceId?: string, processDefinitionKey?: string, processInstanceIds?: Array<string>, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, engineIds?: Array<string>, tenantIds?: Array<string>, variableNames?: Array<string>, variableNamesLike?: Array<string>, withIncidents?: boolean, incidentState?: 'open' | 'resolved' | 'deleted', options: any = {}): RequestArgs {
            // verify required parameter 'typeView' is not null or undefined
            if (typeView === null || typeView === undefined) {
                throw new RequiredError('typeView','Required parameter typeView was null or undefined when calling getInstancesHistory.');
            }
            // verify required parameter 'status' is not null or undefined
            if (status === null || status === undefined) {
                throw new RequiredError('status','Required parameter status was null or undefined when calling getInstancesHistory.');
            }
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getInstancesHistory.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (pageSize === null || pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling getInstancesHistory.');
            }
            // verify required parameter 'sortBy' is not null or undefined
            if (sortBy === null || sortBy === undefined) {
                throw new RequiredError('sortBy','Required parameter sortBy was null or undefined when calling getInstancesHistory.');
            }
            // verify required parameter 'sortOrder' is not null or undefined
            if (sortOrder === null || sortOrder === undefined) {
                throw new RequiredError('sortOrder','Required parameter sortOrder was null or undefined when calling getInstancesHistory.');
            }
            const localVarPath = `/instances/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (typeView !== undefined) {
                localVarQueryParameter['typeView'] = typeView;
            }

            if (processNameLike !== undefined) {
                localVarQueryParameter['processNameLike'] = processNameLike;
            }

            if (processInstanceBusinessKey !== undefined) {
                localVarQueryParameter['processInstanceBusinessKey'] = processInstanceBusinessKey;
            }

            if (processInstanceId !== undefined) {
                localVarQueryParameter['processInstanceId'] = processInstanceId;
            }

            if (processDefinitionKey !== undefined) {
                localVarQueryParameter['processDefinitionKey'] = processDefinitionKey;
            }

            if (processInstanceIds) {
                localVarQueryParameter['processInstanceIds'] = processInstanceIds;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (startedBefore !== undefined) {
                localVarQueryParameter['startedBefore'] = (startedBefore as any instanceof Date) ?
                    (startedBefore as any).toISOString() :
                    startedBefore;
            }

            if (startedAfter !== undefined) {
                localVarQueryParameter['startedAfter'] = (startedAfter as any instanceof Date) ?
                    (startedAfter as any).toISOString() :
                    startedAfter;
            }

            if (finishedBefore !== undefined) {
                localVarQueryParameter['finishedBefore'] = (finishedBefore as any instanceof Date) ?
                    (finishedBefore as any).toISOString() :
                    finishedBefore;
            }

            if (finishedAfter !== undefined) {
                localVarQueryParameter['finishedAfter'] = (finishedAfter as any instanceof Date) ?
                    (finishedAfter as any).toISOString() :
                    finishedAfter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (engineIds) {
                localVarQueryParameter['engineIds'] = engineIds;
            }

            if (tenantIds) {
                localVarQueryParameter['tenantIds'] = tenantIds;
            }

            if (variableNames) {
                localVarQueryParameter['variableNames'] = variableNames;
            }

            if (variableNamesLike) {
                localVarQueryParameter['variableNamesLike'] = variableNamesLike;
            }

            if (withIncidents !== undefined) {
                localVarQueryParameter['withIncidents'] = withIncidents;
            }

            if (incidentState !== undefined) {
                localVarQueryParameter['incidentState'] = incidentState;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves all historic process instances (accepts filters)
         * @param {InstanceTypeView} typeView Filtering by view
         * @param {Array<InstanceStatus>} status Filtering by process instance status (only usable for ADMIN typeview)
         * @param {SortByInstanceHistory} sortBy Sort instances by criteria
         * @param {'asc' | 'desc'} sortOrder Sort instances Ascending or Descending
         * @param {string} [processNameLike] Filtering by process name which contains the search
         * @param {string} [processInstanceBusinessKey] Filtering by process instance business key
         * @param {string} [processInstanceId] Filtering by process instance business key (use processInstanceBusinessKey instead)
         * @param {string} [processDefinitionKey] Filtering by process definition key
         * @param {Array<string>} [processInstanceIds] Filtering by process instance ids
         * @param {Date} [startedBefore] Filtering by instances started before specified date
         * @param {Date} [startedAfter] Filtering by instances started before specified date
         * @param {Date} [finishedBefore] Filtering by instances finished before specified date
         * @param {Date} [finishedAfter] Filtering by instances finished after specified date
         * @param {number} [page] Offset for the first Result
         * @param {number} [pageSize] Number of instances return 
         * @param {Array<string>} [engineIds] Engine IDs filter
         * @param {Array<string>} [tenantIds] Tenant Ids filter (only usable in ADMIN typeview)
         * @param {Array<string>} [variableNames] Attach Variables with specific name
         * @param {Array<string>} [variableNamesLike] Attach Variables with name contains. Deprecated for performance reason.
         * @param {boolean} [withIncidents] 
         * @param {'open' | 'resolved' | 'deleted'} [incidentState] Sort by incident state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstancesHistoryAsCsv(typeView: InstanceTypeView, status: Array<InstanceStatus>, sortBy: SortByInstanceHistory, sortOrder: 'asc' | 'desc', processNameLike?: string, processInstanceBusinessKey?: string, processInstanceId?: string, processDefinitionKey?: string, processInstanceIds?: Array<string>, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, page?: number, pageSize?: number, engineIds?: Array<string>, tenantIds?: Array<string>, variableNames?: Array<string>, variableNamesLike?: Array<string>, withIncidents?: boolean, incidentState?: 'open' | 'resolved' | 'deleted', options: any = {}): RequestArgs {
            // verify required parameter 'typeView' is not null or undefined
            if (typeView === null || typeView === undefined) {
                throw new RequiredError('typeView','Required parameter typeView was null or undefined when calling getInstancesHistoryAsCsv.');
            }
            // verify required parameter 'status' is not null or undefined
            if (status === null || status === undefined) {
                throw new RequiredError('status','Required parameter status was null or undefined when calling getInstancesHistoryAsCsv.');
            }
            // verify required parameter 'sortBy' is not null or undefined
            if (sortBy === null || sortBy === undefined) {
                throw new RequiredError('sortBy','Required parameter sortBy was null or undefined when calling getInstancesHistoryAsCsv.');
            }
            // verify required parameter 'sortOrder' is not null or undefined
            if (sortOrder === null || sortOrder === undefined) {
                throw new RequiredError('sortOrder','Required parameter sortOrder was null or undefined when calling getInstancesHistoryAsCsv.');
            }
            const localVarPath = `/instances/history/csv`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (typeView !== undefined) {
                localVarQueryParameter['typeView'] = typeView;
            }

            if (processNameLike !== undefined) {
                localVarQueryParameter['processNameLike'] = processNameLike;
            }

            if (processInstanceBusinessKey !== undefined) {
                localVarQueryParameter['processInstanceBusinessKey'] = processInstanceBusinessKey;
            }

            if (processInstanceId !== undefined) {
                localVarQueryParameter['processInstanceId'] = processInstanceId;
            }

            if (processDefinitionKey !== undefined) {
                localVarQueryParameter['processDefinitionKey'] = processDefinitionKey;
            }

            if (processInstanceIds) {
                localVarQueryParameter['processInstanceIds'] = processInstanceIds;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (startedBefore !== undefined) {
                localVarQueryParameter['startedBefore'] = (startedBefore as any instanceof Date) ?
                    (startedBefore as any).toISOString() :
                    startedBefore;
            }

            if (startedAfter !== undefined) {
                localVarQueryParameter['startedAfter'] = (startedAfter as any instanceof Date) ?
                    (startedAfter as any).toISOString() :
                    startedAfter;
            }

            if (finishedBefore !== undefined) {
                localVarQueryParameter['finishedBefore'] = (finishedBefore as any instanceof Date) ?
                    (finishedBefore as any).toISOString() :
                    finishedBefore;
            }

            if (finishedAfter !== undefined) {
                localVarQueryParameter['finishedAfter'] = (finishedAfter as any instanceof Date) ?
                    (finishedAfter as any).toISOString() :
                    finishedAfter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (engineIds) {
                localVarQueryParameter['engineIds'] = engineIds;
            }

            if (tenantIds) {
                localVarQueryParameter['tenantIds'] = tenantIds;
            }

            if (variableNames) {
                localVarQueryParameter['variableNames'] = variableNames;
            }

            if (variableNamesLike) {
                localVarQueryParameter['variableNamesLike'] = variableNamesLike;
            }

            if (withIncidents !== undefined) {
                localVarQueryParameter['withIncidents'] = withIncidents;
            }

            if (incidentState !== undefined) {
                localVarQueryParameter['incidentState'] = incidentState;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check instance whether the user is candidate (User or/and Group) of at least one of the task in the instance or admin of the tenant
         * @param {string} instanceId The id of the process instance.
         * @param {string} [engineId] Engine ID: The ID of the engine
         * @param {boolean} [includeActiveTasks] Should the active tasks of the instance be included.
         * @param {boolean} [includeHistoricTasks] Should the historic tasks of the instance be included.
         * @param {Array<string>} [includedActivityTypes] Which activity types of the instance be included.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessInstance(instanceId: string, engineId?: string, includeActiveTasks?: boolean, includeHistoricTasks?: boolean, includedActivityTypes?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling getProcessInstance.');
            }
            const localVarPath = `/instances/{instanceId}`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (includeActiveTasks !== undefined) {
                localVarQueryParameter['includeActiveTasks'] = includeActiveTasks;
            }

            if (includeHistoricTasks !== undefined) {
                localVarQueryParameter['includeHistoricTasks'] = includeHistoricTasks;
            }

            if (includedActivityTypes) {
                localVarQueryParameter['includedActivityTypes'] = includedActivityTypes;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves support contact list of a process instance.
         * @param {string} instanceId The id of the process instance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessSupportContactList(instanceId: string, options: any = {}): RequestArgs {
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling getProcessSupportContactList.');
            }
            const localVarPath = `/instances/{instanceId}/support-contacts`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a process instance
         * @param {string} instanceId The id of the process instance.
         * @param {string} [engineId] Engine ID: The ID of the engine
         * @param {InstanceUpdateRequest} [instanceUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProcessInstance(instanceId: string, engineId?: string, instanceUpdateRequest?: InstanceUpdateRequest, options: any = {}): RequestArgs {
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling updateProcessInstance.');
            }
            const localVarPath = `/instances/{instanceId}`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof instanceUpdateRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(instanceUpdateRequest !== undefined ? instanceUpdateRequest : {}) : (instanceUpdateRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate user access to instance from provided processDefinitionId
         * @param {string} procDefId ProcessDefinitionId to validate the request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAccesByProcDefId(procDefId: string, options: any = {}): RequestArgs {
            // verify required parameter 'procDefId' is not null or undefined
            if (procDefId === null || procDefId === undefined) {
                throw new RequiredError('procDefId','Required parameter procDefId was null or undefined when calling validateAccesByProcDefId.');
            }
            const localVarPath = `/instances/do-validate-access`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (procDefId !== undefined) {
                localVarQueryParameter['procDefId'] = procDefId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstanceApi - functional programming interface
 * @export
 */
export const InstanceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete (Cancel) an instance whether the user is the initiator or admin of the process
         * @param {string} instanceId The id of the process instance.
         * @param {string} deleteReason The reason to delete the instance
         * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProcessInstance(instanceId: string, deleteReason: string, engineId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InstanceApiAxiosParamCreator(configuration).deleteProcessInstance(instanceId, deleteReason, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the html export the entire process by process instance id
         * @param {string} instanceId Filtering by process instance id
         * @param {string} engineId Filtering by engine id
         * @param {'sglan' | 'sgmarkets'} [realmType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProcessAsHtml(instanceId: string, engineId: string, realmType?: 'sglan' | 'sgmarkets', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = InstanceApiAxiosParamCreator(configuration).exportProcessAsHtml(instanceId, engineId, realmType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Audit details of a instance
         * @param {string} instanceId 
         * @param {string} tenantId 
         * @param {string} [engineId] 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceAudit(instanceId: string, tenantId: string, engineId?: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditLog>> {
            const localVarAxiosArgs = InstanceApiAxiosParamCreator(configuration).getInstanceAudit(instanceId, tenantId, engineId, dateAfter, dateBefore, eventName, performerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve current state of an instance (activities status, current tasks, incidents...)
         * @param {string} instanceId The id of the process instance.
         * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceStateById(instanceId: string, engineId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceState> {
            const localVarAxiosArgs = InstanceApiAxiosParamCreator(configuration).getInstanceStateById(instanceId, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieves all historic process instances (accepts filters)
         * @param {InstanceTypeView} typeView Filtering by view
         * @param {Array<InstanceStatus>} status Filtering by process instance status (only usable for ADMIN typeview)
         * @param {number} page Offset for the first Result
         * @param {number} pageSize Number of instances return 
         * @param {SortByInstanceHistory} sortBy Sort instances by criteria
         * @param {'asc' | 'desc'} sortOrder Sort instances Ascending or Descending
         * @param {string} [processNameLike] Filtering by process name which contains the search
         * @param {string} [processInstanceBusinessKey] Filtering by process instance business key
         * @param {string} [processInstanceId] Filtering by process instance business key (use processInstanceBusinessKey instead)
         * @param {string} [processDefinitionKey] Filtering by process definition key
         * @param {Array<string>} [processInstanceIds] Filtering by process instance ids
         * @param {Date} [startedBefore] Filtering by instances started before specified date
         * @param {Date} [startedAfter] Filtering by instances started before specified date
         * @param {Date} [finishedBefore] Filtering by instances finished before specified date
         * @param {Date} [finishedAfter] Filtering by instances finished after specified date
         * @param {Array<string>} [engineIds] Engine IDs filter (IMPORTANT: If engineIds size is different from 1, sort and pagination will not be applied)
         * @param {Array<string>} [tenantIds] Tenant Ids filter (only usable in ADMIN typeview)
         * @param {Array<string>} [variableNames] Attach Variables with specific name
         * @param {Array<string>} [variableNamesLike] Attach Variables with name contains. Deprecated for performance reason.
         * @param {boolean} [withIncidents] 
         * @param {'open' | 'resolved' | 'deleted'} [incidentState] Sort by incident state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstancesHistory(typeView: InstanceTypeView, status: Array<InstanceStatus>, page: number, pageSize: number, sortBy: SortByInstanceHistory, sortOrder: 'asc' | 'desc', processNameLike?: string, processInstanceBusinessKey?: string, processInstanceId?: string, processDefinitionKey?: string, processInstanceIds?: Array<string>, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, engineIds?: Array<string>, tenantIds?: Array<string>, variableNames?: Array<string>, variableNamesLike?: Array<string>, withIncidents?: boolean, incidentState?: 'open' | 'resolved' | 'deleted', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfInstance> {
            const localVarAxiosArgs = InstanceApiAxiosParamCreator(configuration).getInstancesHistory(typeView, status, page, pageSize, sortBy, sortOrder, processNameLike, processInstanceBusinessKey, processInstanceId, processDefinitionKey, processInstanceIds, startedBefore, startedAfter, finishedBefore, finishedAfter, engineIds, tenantIds, variableNames, variableNamesLike, withIncidents, incidentState, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieves all historic process instances (accepts filters)
         * @param {InstanceTypeView} typeView Filtering by view
         * @param {Array<InstanceStatus>} status Filtering by process instance status (only usable for ADMIN typeview)
         * @param {SortByInstanceHistory} sortBy Sort instances by criteria
         * @param {'asc' | 'desc'} sortOrder Sort instances Ascending or Descending
         * @param {string} [processNameLike] Filtering by process name which contains the search
         * @param {string} [processInstanceBusinessKey] Filtering by process instance business key
         * @param {string} [processInstanceId] Filtering by process instance business key (use processInstanceBusinessKey instead)
         * @param {string} [processDefinitionKey] Filtering by process definition key
         * @param {Array<string>} [processInstanceIds] Filtering by process instance ids
         * @param {Date} [startedBefore] Filtering by instances started before specified date
         * @param {Date} [startedAfter] Filtering by instances started before specified date
         * @param {Date} [finishedBefore] Filtering by instances finished before specified date
         * @param {Date} [finishedAfter] Filtering by instances finished after specified date
         * @param {number} [page] Offset for the first Result
         * @param {number} [pageSize] Number of instances return 
         * @param {Array<string>} [engineIds] Engine IDs filter
         * @param {Array<string>} [tenantIds] Tenant Ids filter (only usable in ADMIN typeview)
         * @param {Array<string>} [variableNames] Attach Variables with specific name
         * @param {Array<string>} [variableNamesLike] Attach Variables with name contains. Deprecated for performance reason.
         * @param {boolean} [withIncidents] 
         * @param {'open' | 'resolved' | 'deleted'} [incidentState] Sort by incident state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstancesHistoryAsCsv(typeView: InstanceTypeView, status: Array<InstanceStatus>, sortBy: SortByInstanceHistory, sortOrder: 'asc' | 'desc', processNameLike?: string, processInstanceBusinessKey?: string, processInstanceId?: string, processDefinitionKey?: string, processInstanceIds?: Array<string>, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, page?: number, pageSize?: number, engineIds?: Array<string>, tenantIds?: Array<string>, variableNames?: Array<string>, variableNamesLike?: Array<string>, withIncidents?: boolean, incidentState?: 'open' | 'resolved' | 'deleted', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InstanceApiAxiosParamCreator(configuration).getInstancesHistoryAsCsv(typeView, status, sortBy, sortOrder, processNameLike, processInstanceBusinessKey, processInstanceId, processDefinitionKey, processInstanceIds, startedBefore, startedAfter, finishedBefore, finishedAfter, page, pageSize, engineIds, tenantIds, variableNames, variableNamesLike, withIncidents, incidentState, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Check instance whether the user is candidate (User or/and Group) of at least one of the task in the instance or admin of the tenant
         * @param {string} instanceId The id of the process instance.
         * @param {string} [engineId] Engine ID: The ID of the engine
         * @param {boolean} [includeActiveTasks] Should the active tasks of the instance be included.
         * @param {boolean} [includeHistoricTasks] Should the historic tasks of the instance be included.
         * @param {Array<string>} [includedActivityTypes] Which activity types of the instance be included.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessInstance(instanceId: string, engineId?: string, includeActiveTasks?: boolean, includeHistoricTasks?: boolean, includedActivityTypes?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instance> {
            const localVarAxiosArgs = InstanceApiAxiosParamCreator(configuration).getProcessInstance(instanceId, engineId, includeActiveTasks, includeHistoricTasks, includedActivityTypes, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieves support contact list of a process instance.
         * @param {string} instanceId The id of the process instance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessSupportContactList(instanceId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = InstanceApiAxiosParamCreator(configuration).getProcessSupportContactList(instanceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a process instance
         * @param {string} instanceId The id of the process instance.
         * @param {string} [engineId] Engine ID: The ID of the engine
         * @param {InstanceUpdateRequest} [instanceUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProcessInstance(instanceId: string, engineId?: string, instanceUpdateRequest?: InstanceUpdateRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InstanceApiAxiosParamCreator(configuration).updateProcessInstance(instanceId, engineId, instanceUpdateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Validate user access to instance from provided processDefinitionId
         * @param {string} procDefId ProcessDefinitionId to validate the request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAccesByProcDefId(procDefId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = InstanceApiAxiosParamCreator(configuration).validateAccesByProcDefId(procDefId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InstanceApi - factory interface
 * @export
 */
export const InstanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete (Cancel) an instance whether the user is the initiator or admin of the process
         * @param {string} instanceId The id of the process instance.
         * @param {string} deleteReason The reason to delete the instance
         * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProcessInstance(instanceId: string, deleteReason: string, engineId: string, options?: any): AxiosPromise<void> {
            return InstanceApiFp(configuration).deleteProcessInstance(instanceId, deleteReason, engineId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the html export the entire process by process instance id
         * @param {string} instanceId Filtering by process instance id
         * @param {string} engineId Filtering by engine id
         * @param {'sglan' | 'sgmarkets'} [realmType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProcessAsHtml(instanceId: string, engineId: string, realmType?: 'sglan' | 'sgmarkets', options?: any): AxiosPromise<any> {
            return InstanceApiFp(configuration).exportProcessAsHtml(instanceId, engineId, realmType, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get Audit details of a instance
         * @param {string} instanceId 
         * @param {string} tenantId 
         * @param {string} [engineId] 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceAudit(instanceId: string, tenantId: string, engineId?: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, options?: any): AxiosPromise<Array<AuditLog>> {
            return InstanceApiFp(configuration).getInstanceAudit(instanceId, tenantId, engineId, dateAfter, dateBefore, eventName, performerId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve current state of an instance (activities status, current tasks, incidents...)
         * @param {string} instanceId The id of the process instance.
         * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceStateById(instanceId: string, engineId: string, options?: any): AxiosPromise<InstanceState> {
            return InstanceApiFp(configuration).getInstanceStateById(instanceId, engineId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieves all historic process instances (accepts filters)
         * @param {InstanceTypeView} typeView Filtering by view
         * @param {Array<InstanceStatus>} status Filtering by process instance status (only usable for ADMIN typeview)
         * @param {number} page Offset for the first Result
         * @param {number} pageSize Number of instances return 
         * @param {SortByInstanceHistory} sortBy Sort instances by criteria
         * @param {'asc' | 'desc'} sortOrder Sort instances Ascending or Descending
         * @param {string} [processNameLike] Filtering by process name which contains the search
         * @param {string} [processInstanceBusinessKey] Filtering by process instance business key
         * @param {string} [processInstanceId] Filtering by process instance business key (use processInstanceBusinessKey instead)
         * @param {string} [processDefinitionKey] Filtering by process definition key
         * @param {Array<string>} [processInstanceIds] Filtering by process instance ids
         * @param {Date} [startedBefore] Filtering by instances started before specified date
         * @param {Date} [startedAfter] Filtering by instances started before specified date
         * @param {Date} [finishedBefore] Filtering by instances finished before specified date
         * @param {Date} [finishedAfter] Filtering by instances finished after specified date
         * @param {Array<string>} [engineIds] Engine IDs filter (IMPORTANT: If engineIds size is different from 1, sort and pagination will not be applied)
         * @param {Array<string>} [tenantIds] Tenant Ids filter (only usable in ADMIN typeview)
         * @param {Array<string>} [variableNames] Attach Variables with specific name
         * @param {Array<string>} [variableNamesLike] Attach Variables with name contains. Deprecated for performance reason.
         * @param {boolean} [withIncidents] 
         * @param {'open' | 'resolved' | 'deleted'} [incidentState] Sort by incident state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstancesHistory(typeView: InstanceTypeView, status: Array<InstanceStatus>, page: number, pageSize: number, sortBy: SortByInstanceHistory, sortOrder: 'asc' | 'desc', processNameLike?: string, processInstanceBusinessKey?: string, processInstanceId?: string, processDefinitionKey?: string, processInstanceIds?: Array<string>, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, engineIds?: Array<string>, tenantIds?: Array<string>, variableNames?: Array<string>, variableNamesLike?: Array<string>, withIncidents?: boolean, incidentState?: 'open' | 'resolved' | 'deleted', options?: any): AxiosPromise<PageOfInstance> {
            return InstanceApiFp(configuration).getInstancesHistory(typeView, status, page, pageSize, sortBy, sortOrder, processNameLike, processInstanceBusinessKey, processInstanceId, processDefinitionKey, processInstanceIds, startedBefore, startedAfter, finishedBefore, finishedAfter, engineIds, tenantIds, variableNames, variableNamesLike, withIncidents, incidentState, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieves all historic process instances (accepts filters)
         * @param {InstanceTypeView} typeView Filtering by view
         * @param {Array<InstanceStatus>} status Filtering by process instance status (only usable for ADMIN typeview)
         * @param {SortByInstanceHistory} sortBy Sort instances by criteria
         * @param {'asc' | 'desc'} sortOrder Sort instances Ascending or Descending
         * @param {string} [processNameLike] Filtering by process name which contains the search
         * @param {string} [processInstanceBusinessKey] Filtering by process instance business key
         * @param {string} [processInstanceId] Filtering by process instance business key (use processInstanceBusinessKey instead)
         * @param {string} [processDefinitionKey] Filtering by process definition key
         * @param {Array<string>} [processInstanceIds] Filtering by process instance ids
         * @param {Date} [startedBefore] Filtering by instances started before specified date
         * @param {Date} [startedAfter] Filtering by instances started before specified date
         * @param {Date} [finishedBefore] Filtering by instances finished before specified date
         * @param {Date} [finishedAfter] Filtering by instances finished after specified date
         * @param {number} [page] Offset for the first Result
         * @param {number} [pageSize] Number of instances return 
         * @param {Array<string>} [engineIds] Engine IDs filter
         * @param {Array<string>} [tenantIds] Tenant Ids filter (only usable in ADMIN typeview)
         * @param {Array<string>} [variableNames] Attach Variables with specific name
         * @param {Array<string>} [variableNamesLike] Attach Variables with name contains. Deprecated for performance reason.
         * @param {boolean} [withIncidents] 
         * @param {'open' | 'resolved' | 'deleted'} [incidentState] Sort by incident state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstancesHistoryAsCsv(typeView: InstanceTypeView, status: Array<InstanceStatus>, sortBy: SortByInstanceHistory, sortOrder: 'asc' | 'desc', processNameLike?: string, processInstanceBusinessKey?: string, processInstanceId?: string, processDefinitionKey?: string, processInstanceIds?: Array<string>, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, page?: number, pageSize?: number, engineIds?: Array<string>, tenantIds?: Array<string>, variableNames?: Array<string>, variableNamesLike?: Array<string>, withIncidents?: boolean, incidentState?: 'open' | 'resolved' | 'deleted', options?: any): AxiosPromise<void> {
            return InstanceApiFp(configuration).getInstancesHistoryAsCsv(typeView, status, sortBy, sortOrder, processNameLike, processInstanceBusinessKey, processInstanceId, processDefinitionKey, processInstanceIds, startedBefore, startedAfter, finishedBefore, finishedAfter, page, pageSize, engineIds, tenantIds, variableNames, variableNamesLike, withIncidents, incidentState, options)(axios, basePath);
        },
        /**
         * 
         * @summary Check instance whether the user is candidate (User or/and Group) of at least one of the task in the instance or admin of the tenant
         * @param {string} instanceId The id of the process instance.
         * @param {string} [engineId] Engine ID: The ID of the engine
         * @param {boolean} [includeActiveTasks] Should the active tasks of the instance be included.
         * @param {boolean} [includeHistoricTasks] Should the historic tasks of the instance be included.
         * @param {Array<string>} [includedActivityTypes] Which activity types of the instance be included.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessInstance(instanceId: string, engineId?: string, includeActiveTasks?: boolean, includeHistoricTasks?: boolean, includedActivityTypes?: Array<string>, options?: any): AxiosPromise<Instance> {
            return InstanceApiFp(configuration).getProcessInstance(instanceId, engineId, includeActiveTasks, includeHistoricTasks, includedActivityTypes, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieves support contact list of a process instance.
         * @param {string} instanceId The id of the process instance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessSupportContactList(instanceId: string, options?: any): AxiosPromise<Array<string>> {
            return InstanceApiFp(configuration).getProcessSupportContactList(instanceId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update a process instance
         * @param {string} instanceId The id of the process instance.
         * @param {string} [engineId] Engine ID: The ID of the engine
         * @param {InstanceUpdateRequest} [instanceUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProcessInstance(instanceId: string, engineId?: string, instanceUpdateRequest?: InstanceUpdateRequest, options?: any): AxiosPromise<void> {
            return InstanceApiFp(configuration).updateProcessInstance(instanceId, engineId, instanceUpdateRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Validate user access to instance from provided processDefinitionId
         * @param {string} procDefId ProcessDefinitionId to validate the request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAccesByProcDefId(procDefId: string, options?: any): AxiosPromise<boolean> {
            return InstanceApiFp(configuration).validateAccesByProcDefId(procDefId, options)(axios, basePath);
        },
    };
};

/**
 * InstanceApi - object-oriented interface
 * @export
 * @class InstanceApi
 * @extends {BaseAPI}
 */
export class InstanceApi extends BaseAPI {
    /**
     * 
     * @summary Delete (Cancel) an instance whether the user is the initiator or admin of the process
     * @param {string} instanceId The id of the process instance.
     * @param {string} deleteReason The reason to delete the instance
     * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceApi
     */
    public deleteProcessInstance(instanceId: string, deleteReason: string, engineId: string, options?: any) {
        return InstanceApiFp(this.configuration).deleteProcessInstance(instanceId, deleteReason, engineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the html export the entire process by process instance id
     * @param {string} instanceId Filtering by process instance id
     * @param {string} engineId Filtering by engine id
     * @param {'sglan' | 'sgmarkets'} [realmType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceApi
     */
    public exportProcessAsHtml(instanceId: string, engineId: string, realmType?: 'sglan' | 'sgmarkets', options?: any) {
        return InstanceApiFp(this.configuration).exportProcessAsHtml(instanceId, engineId, realmType, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get Audit details of a instance
     * @param {string} instanceId 
     * @param {string} tenantId 
     * @param {string} [engineId] 
     * @param {Date} [dateAfter] 
     * @param {Date} [dateBefore] 
     * @param {string} [eventName] 
     * @param {string} [performerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceApi
     */
    public getInstanceAudit(instanceId: string, tenantId: string, engineId?: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, options?: any) {
        return InstanceApiFp(this.configuration).getInstanceAudit(instanceId, tenantId, engineId, dateAfter, dateBefore, eventName, performerId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve current state of an instance (activities status, current tasks, incidents...)
     * @param {string} instanceId The id of the process instance.
     * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceApi
     */
    public getInstanceStateById(instanceId: string, engineId: string, options?: any) {
        return InstanceApiFp(this.configuration).getInstanceStateById(instanceId, engineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieves all historic process instances (accepts filters)
     * @param {InstanceTypeView} typeView Filtering by view
     * @param {Array<InstanceStatus>} status Filtering by process instance status (only usable for ADMIN typeview)
     * @param {number} page Offset for the first Result
     * @param {number} pageSize Number of instances return 
     * @param {SortByInstanceHistory} sortBy Sort instances by criteria
     * @param {'asc' | 'desc'} sortOrder Sort instances Ascending or Descending
     * @param {string} [processNameLike] Filtering by process name which contains the search
     * @param {string} [processInstanceBusinessKey] Filtering by process instance business key
     * @param {string} [processInstanceId] Filtering by process instance business key (use processInstanceBusinessKey instead)
     * @param {string} [processDefinitionKey] Filtering by process definition key
     * @param {Array<string>} [processInstanceIds] Filtering by process instance ids
     * @param {Date} [startedBefore] Filtering by instances started before specified date
     * @param {Date} [startedAfter] Filtering by instances started before specified date
     * @param {Date} [finishedBefore] Filtering by instances finished before specified date
     * @param {Date} [finishedAfter] Filtering by instances finished after specified date
     * @param {Array<string>} [engineIds] Engine IDs filter (IMPORTANT: If engineIds size is different from 1, sort and pagination will not be applied)
     * @param {Array<string>} [tenantIds] Tenant Ids filter (only usable in ADMIN typeview)
     * @param {Array<string>} [variableNames] Attach Variables with specific name
     * @param {Array<string>} [variableNamesLike] Attach Variables with name contains. Deprecated for performance reason.
     * @param {boolean} [withIncidents] 
     * @param {'open' | 'resolved' | 'deleted'} [incidentState] Sort by incident state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceApi
     */
    public getInstancesHistory(typeView: InstanceTypeView, status: Array<InstanceStatus>, page: number, pageSize: number, sortBy: SortByInstanceHistory, sortOrder: 'asc' | 'desc', processNameLike?: string, processInstanceBusinessKey?: string, processInstanceId?: string, processDefinitionKey?: string, processInstanceIds?: Array<string>, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, engineIds?: Array<string>, tenantIds?: Array<string>, variableNames?: Array<string>, variableNamesLike?: Array<string>, withIncidents?: boolean, incidentState?: 'open' | 'resolved' | 'deleted', options?: any) {
        return InstanceApiFp(this.configuration).getInstancesHistory(typeView, status, page, pageSize, sortBy, sortOrder, processNameLike, processInstanceBusinessKey, processInstanceId, processDefinitionKey, processInstanceIds, startedBefore, startedAfter, finishedBefore, finishedAfter, engineIds, tenantIds, variableNames, variableNamesLike, withIncidents, incidentState, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieves all historic process instances (accepts filters)
     * @param {InstanceTypeView} typeView Filtering by view
     * @param {Array<InstanceStatus>} status Filtering by process instance status (only usable for ADMIN typeview)
     * @param {SortByInstanceHistory} sortBy Sort instances by criteria
     * @param {'asc' | 'desc'} sortOrder Sort instances Ascending or Descending
     * @param {string} [processNameLike] Filtering by process name which contains the search
     * @param {string} [processInstanceBusinessKey] Filtering by process instance business key
     * @param {string} [processInstanceId] Filtering by process instance business key (use processInstanceBusinessKey instead)
     * @param {string} [processDefinitionKey] Filtering by process definition key
     * @param {Array<string>} [processInstanceIds] Filtering by process instance ids
     * @param {Date} [startedBefore] Filtering by instances started before specified date
     * @param {Date} [startedAfter] Filtering by instances started before specified date
     * @param {Date} [finishedBefore] Filtering by instances finished before specified date
     * @param {Date} [finishedAfter] Filtering by instances finished after specified date
     * @param {number} [page] Offset for the first Result
     * @param {number} [pageSize] Number of instances return 
     * @param {Array<string>} [engineIds] Engine IDs filter
     * @param {Array<string>} [tenantIds] Tenant Ids filter (only usable in ADMIN typeview)
     * @param {Array<string>} [variableNames] Attach Variables with specific name
     * @param {Array<string>} [variableNamesLike] Attach Variables with name contains. Deprecated for performance reason.
     * @param {boolean} [withIncidents] 
     * @param {'open' | 'resolved' | 'deleted'} [incidentState] Sort by incident state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceApi
     */
    public getInstancesHistoryAsCsv(typeView: InstanceTypeView, status: Array<InstanceStatus>, sortBy: SortByInstanceHistory, sortOrder: 'asc' | 'desc', processNameLike?: string, processInstanceBusinessKey?: string, processInstanceId?: string, processDefinitionKey?: string, processInstanceIds?: Array<string>, startedBefore?: Date, startedAfter?: Date, finishedBefore?: Date, finishedAfter?: Date, page?: number, pageSize?: number, engineIds?: Array<string>, tenantIds?: Array<string>, variableNames?: Array<string>, variableNamesLike?: Array<string>, withIncidents?: boolean, incidentState?: 'open' | 'resolved' | 'deleted', options?: any) {
        return InstanceApiFp(this.configuration).getInstancesHistoryAsCsv(typeView, status, sortBy, sortOrder, processNameLike, processInstanceBusinessKey, processInstanceId, processDefinitionKey, processInstanceIds, startedBefore, startedAfter, finishedBefore, finishedAfter, page, pageSize, engineIds, tenantIds, variableNames, variableNamesLike, withIncidents, incidentState, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Check instance whether the user is candidate (User or/and Group) of at least one of the task in the instance or admin of the tenant
     * @param {string} instanceId The id of the process instance.
     * @param {string} [engineId] Engine ID: The ID of the engine
     * @param {boolean} [includeActiveTasks] Should the active tasks of the instance be included.
     * @param {boolean} [includeHistoricTasks] Should the historic tasks of the instance be included.
     * @param {Array<string>} [includedActivityTypes] Which activity types of the instance be included.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceApi
     */
    public getProcessInstance(instanceId: string, engineId?: string, includeActiveTasks?: boolean, includeHistoricTasks?: boolean, includedActivityTypes?: Array<string>, options?: any) {
        return InstanceApiFp(this.configuration).getProcessInstance(instanceId, engineId, includeActiveTasks, includeHistoricTasks, includedActivityTypes, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieves support contact list of a process instance.
     * @param {string} instanceId The id of the process instance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceApi
     */
    public getProcessSupportContactList(instanceId: string, options?: any) {
        return InstanceApiFp(this.configuration).getProcessSupportContactList(instanceId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update a process instance
     * @param {string} instanceId The id of the process instance.
     * @param {string} [engineId] Engine ID: The ID of the engine
     * @param {InstanceUpdateRequest} [instanceUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceApi
     */
    public updateProcessInstance(instanceId: string, engineId?: string, instanceUpdateRequest?: InstanceUpdateRequest, options?: any) {
        return InstanceApiFp(this.configuration).updateProcessInstance(instanceId, engineId, instanceUpdateRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Validate user access to instance from provided processDefinitionId
     * @param {string} procDefId ProcessDefinitionId to validate the request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceApi
     */
    public validateAccesByProcDefId(procDefId: string, options?: any) {
        return InstanceApiFp(this.configuration).validateAccesByProcDefId(procDefId, options)(this.axios, this.basePath);
    }

}


/**
 * JobApi - axios parameter creator
 * @export
 */
export const JobApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Synchronize a Workflow from Engine
         * @param {string} [engineId] Engine ID to synchronize from
         * @param {string} [workspaceId] Workspace id (aka tenantId)
         * @param {string} [deploymentId] Deployment id to synchronize
         * @param {string} [processDefinitionKey] processDefinitionKey to synchronize
         * @param {string} [processDefinitionVersion] processDefinitionVersion  to synchronize
         * @param {boolean} [forceUpdate] Should we force the update of the workflow if it\&#39;s already existing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWorkflow(engineId?: string, workspaceId?: string, deploymentId?: string, processDefinitionKey?: string, processDefinitionVersion?: string, forceUpdate?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/jobs/sync-workflow`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspaceId'] = workspaceId;
            }

            if (deploymentId !== undefined) {
                localVarQueryParameter['deploymentId'] = deploymentId;
            }

            if (processDefinitionKey !== undefined) {
                localVarQueryParameter['processDefinitionKey'] = processDefinitionKey;
            }

            if (processDefinitionVersion !== undefined) {
                localVarQueryParameter['processDefinitionVersion'] = processDefinitionVersion;
            }

            if (forceUpdate !== undefined) {
                localVarQueryParameter['forceUpdate'] = forceUpdate;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Synchronize a Workspace from Engine
         * @param {string} [engineId] Engine ID to synchronize from
         * @param {Array<string>} [workspaceIds] Workspace id(s) (aka tenantId) to synchronize
         * @param {boolean} [forceUpdate] Should we force the update of the workflow if it\&#39;s already existing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWorkspace(engineId?: string, workspaceIds?: Array<string>, forceUpdate?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/jobs/sync-workspace`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (workspaceIds) {
                localVarQueryParameter['workspaceIds'] = workspaceIds;
            }

            if (forceUpdate !== undefined) {
                localVarQueryParameter['forceUpdate'] = forceUpdate;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobApi - functional programming interface
 * @export
 */
export const JobApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Synchronize a Workflow from Engine
         * @param {string} [engineId] Engine ID to synchronize from
         * @param {string} [workspaceId] Workspace id (aka tenantId)
         * @param {string} [deploymentId] Deployment id to synchronize
         * @param {string} [processDefinitionKey] processDefinitionKey to synchronize
         * @param {string} [processDefinitionVersion] processDefinitionVersion  to synchronize
         * @param {boolean} [forceUpdate] Should we force the update of the workflow if it\&#39;s already existing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWorkflow(engineId?: string, workspaceId?: string, deploymentId?: string, processDefinitionKey?: string, processDefinitionVersion?: string, forceUpdate?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = JobApiAxiosParamCreator(configuration).syncWorkflow(engineId, workspaceId, deploymentId, processDefinitionKey, processDefinitionVersion, forceUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Synchronize a Workspace from Engine
         * @param {string} [engineId] Engine ID to synchronize from
         * @param {Array<string>} [workspaceIds] Workspace id(s) (aka tenantId) to synchronize
         * @param {boolean} [forceUpdate] Should we force the update of the workflow if it\&#39;s already existing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWorkspace(engineId?: string, workspaceIds?: Array<string>, forceUpdate?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = JobApiAxiosParamCreator(configuration).syncWorkspace(engineId, workspaceIds, forceUpdate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * JobApi - factory interface
 * @export
 */
export const JobApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Synchronize a Workflow from Engine
         * @param {string} [engineId] Engine ID to synchronize from
         * @param {string} [workspaceId] Workspace id (aka tenantId)
         * @param {string} [deploymentId] Deployment id to synchronize
         * @param {string} [processDefinitionKey] processDefinitionKey to synchronize
         * @param {string} [processDefinitionVersion] processDefinitionVersion  to synchronize
         * @param {boolean} [forceUpdate] Should we force the update of the workflow if it\&#39;s already existing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWorkflow(engineId?: string, workspaceId?: string, deploymentId?: string, processDefinitionKey?: string, processDefinitionVersion?: string, forceUpdate?: boolean, options?: any): AxiosPromise<void> {
            return JobApiFp(configuration).syncWorkflow(engineId, workspaceId, deploymentId, processDefinitionKey, processDefinitionVersion, forceUpdate, options)(axios, basePath);
        },
        /**
         * 
         * @summary Synchronize a Workspace from Engine
         * @param {string} [engineId] Engine ID to synchronize from
         * @param {Array<string>} [workspaceIds] Workspace id(s) (aka tenantId) to synchronize
         * @param {boolean} [forceUpdate] Should we force the update of the workflow if it\&#39;s already existing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWorkspace(engineId?: string, workspaceIds?: Array<string>, forceUpdate?: boolean, options?: any): AxiosPromise<void> {
            return JobApiFp(configuration).syncWorkspace(engineId, workspaceIds, forceUpdate, options)(axios, basePath);
        },
    };
};

/**
 * JobApi - object-oriented interface
 * @export
 * @class JobApi
 * @extends {BaseAPI}
 */
export class JobApi extends BaseAPI {
    /**
     * 
     * @summary Synchronize a Workflow from Engine
     * @param {string} [engineId] Engine ID to synchronize from
     * @param {string} [workspaceId] Workspace id (aka tenantId)
     * @param {string} [deploymentId] Deployment id to synchronize
     * @param {string} [processDefinitionKey] processDefinitionKey to synchronize
     * @param {string} [processDefinitionVersion] processDefinitionVersion  to synchronize
     * @param {boolean} [forceUpdate] Should we force the update of the workflow if it\&#39;s already existing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public syncWorkflow(engineId?: string, workspaceId?: string, deploymentId?: string, processDefinitionKey?: string, processDefinitionVersion?: string, forceUpdate?: boolean, options?: any) {
        return JobApiFp(this.configuration).syncWorkflow(engineId, workspaceId, deploymentId, processDefinitionKey, processDefinitionVersion, forceUpdate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Synchronize a Workspace from Engine
     * @param {string} [engineId] Engine ID to synchronize from
     * @param {Array<string>} [workspaceIds] Workspace id(s) (aka tenantId) to synchronize
     * @param {boolean} [forceUpdate] Should we force the update of the workflow if it\&#39;s already existing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public syncWorkspace(engineId?: string, workspaceIds?: Array<string>, forceUpdate?: boolean, options?: any) {
        return JobApiFp(this.configuration).syncWorkspace(engineId, workspaceIds, forceUpdate, options)(this.axios, this.basePath);
    }

}


/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve a count of available messages
         * @param {string} processInstanceId Filter by process instance id.
         * @param {string} engineId Engine ID: The ID of the engine for the message task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMessages(processInstanceId: string, engineId: string, options: any = {}): RequestArgs {
            // verify required parameter 'processInstanceId' is not null or undefined
            if (processInstanceId === null || processInstanceId === undefined) {
                throw new RequiredError('processInstanceId','Required parameter processInstanceId was null or undefined when calling countMessages.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling countMessages.');
            }
            const localVarPath = `/messages/count`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (processInstanceId !== undefined) {
                localVarQueryParameter['processInstanceId'] = processInstanceId;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Message
         * @param {string} engineId Engine ID: The ID of the engine for the message task
         * @param {MessageCreation} messageCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage(engineId: string, messageCreation: MessageCreation, options: any = {}): RequestArgs {
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling createMessage.');
            }
            // verify required parameter 'messageCreation' is not null or undefined
            if (messageCreation === null || messageCreation === undefined) {
                throw new RequiredError('messageCreation','Required parameter messageCreation was null or undefined when calling createMessage.');
            }
            const localVarPath = `/messages`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof messageCreation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(messageCreation !== undefined ? messageCreation : {}) : (messageCreation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the list of messages
         * @param {string} processInstanceId Filter by process instance id.
         * @param {string} engineId Engine ID: The ID of the engine for the message task
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [emailIn] Keep only emails present in that list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMessages(processInstanceId: string, engineId: string, pageNumber?: number, pageSize?: number, emailIn?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'processInstanceId' is not null or undefined
            if (processInstanceId === null || processInstanceId === undefined) {
                throw new RequiredError('processInstanceId','Required parameter processInstanceId was null or undefined when calling listMessages.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling listMessages.');
            }
            const localVarPath = `/messages`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (processInstanceId !== undefined) {
                localVarQueryParameter['processInstanceId'] = processInstanceId;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (emailIn) {
                localVarQueryParameter['emailIn'] = emailIn;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate that the user is allowed to create messages
         * @param {string} processInstanceId Filter by process instance id.
         * @param {string} engineId Engine ID: The ID of the engine for the message task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCanCreateMessage(processInstanceId: string, engineId: string, options: any = {}): RequestArgs {
            // verify required parameter 'processInstanceId' is not null or undefined
            if (processInstanceId === null || processInstanceId === undefined) {
                throw new RequiredError('processInstanceId','Required parameter processInstanceId was null or undefined when calling validateCanCreateMessage.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling validateCanCreateMessage.');
            }
            const localVarPath = `/messages/do-validate-can-create-message`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (processInstanceId !== undefined) {
                localVarQueryParameter['processInstanceId'] = processInstanceId;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve a count of available messages
         * @param {string} processInstanceId Filter by process instance id.
         * @param {string} engineId Engine ID: The ID of the engine for the message task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMessages(processInstanceId: string, engineId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageCount> {
            const localVarAxiosArgs = MessageApiAxiosParamCreator(configuration).countMessages(processInstanceId, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a Message
         * @param {string} engineId Engine ID: The ID of the engine for the message task
         * @param {MessageCreation} messageCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage(engineId: string, messageCreation: MessageCreation, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message> {
            const localVarAxiosArgs = MessageApiAxiosParamCreator(configuration).createMessage(engineId, messageCreation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve the list of messages
         * @param {string} processInstanceId Filter by process instance id.
         * @param {string} engineId Engine ID: The ID of the engine for the message task
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [emailIn] Keep only emails present in that list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMessages(processInstanceId: string, engineId: string, pageNumber?: number, pageSize?: number, emailIn?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfMessage> {
            const localVarAxiosArgs = MessageApiAxiosParamCreator(configuration).listMessages(processInstanceId, engineId, pageNumber, pageSize, emailIn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Validate that the user is allowed to create messages
         * @param {string} processInstanceId Filter by process instance id.
         * @param {string} engineId Engine ID: The ID of the engine for the message task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCanCreateMessage(processInstanceId: string, engineId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = MessageApiAxiosParamCreator(configuration).validateCanCreateMessage(processInstanceId, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Retrieve a count of available messages
         * @param {string} processInstanceId Filter by process instance id.
         * @param {string} engineId Engine ID: The ID of the engine for the message task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMessages(processInstanceId: string, engineId: string, options?: any): AxiosPromise<MessageCount> {
            return MessageApiFp(configuration).countMessages(processInstanceId, engineId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Create a Message
         * @param {string} engineId Engine ID: The ID of the engine for the message task
         * @param {MessageCreation} messageCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage(engineId: string, messageCreation: MessageCreation, options?: any): AxiosPromise<Message> {
            return MessageApiFp(configuration).createMessage(engineId, messageCreation, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve the list of messages
         * @param {string} processInstanceId Filter by process instance id.
         * @param {string} engineId Engine ID: The ID of the engine for the message task
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [emailIn] Keep only emails present in that list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMessages(processInstanceId: string, engineId: string, pageNumber?: number, pageSize?: number, emailIn?: Array<string>, options?: any): AxiosPromise<PageOfMessage> {
            return MessageApiFp(configuration).listMessages(processInstanceId, engineId, pageNumber, pageSize, emailIn, options)(axios, basePath);
        },
        /**
         * 
         * @summary Validate that the user is allowed to create messages
         * @param {string} processInstanceId Filter by process instance id.
         * @param {string} engineId Engine ID: The ID of the engine for the message task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCanCreateMessage(processInstanceId: string, engineId: string, options?: any): AxiosPromise<boolean> {
            return MessageApiFp(configuration).validateCanCreateMessage(processInstanceId, engineId, options)(axios, basePath);
        },
    };
};

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve a count of available messages
     * @param {string} processInstanceId Filter by process instance id.
     * @param {string} engineId Engine ID: The ID of the engine for the message task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public countMessages(processInstanceId: string, engineId: string, options?: any) {
        return MessageApiFp(this.configuration).countMessages(processInstanceId, engineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Create a Message
     * @param {string} engineId Engine ID: The ID of the engine for the message task
     * @param {MessageCreation} messageCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public createMessage(engineId: string, messageCreation: MessageCreation, options?: any) {
        return MessageApiFp(this.configuration).createMessage(engineId, messageCreation, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve the list of messages
     * @param {string} processInstanceId Filter by process instance id.
     * @param {string} engineId Engine ID: The ID of the engine for the message task
     * @param {number} [pageNumber] Number of the page
     * @param {number} [pageSize] Number of elements in the page
     * @param {Array<string>} [emailIn] Keep only emails present in that list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public listMessages(processInstanceId: string, engineId: string, pageNumber?: number, pageSize?: number, emailIn?: Array<string>, options?: any) {
        return MessageApiFp(this.configuration).listMessages(processInstanceId, engineId, pageNumber, pageSize, emailIn, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Validate that the user is allowed to create messages
     * @param {string} processInstanceId Filter by process instance id.
     * @param {string} engineId Engine ID: The ID of the engine for the message task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public validateCanCreateMessage(processInstanceId: string, engineId: string, options?: any) {
        return MessageApiFp(this.configuration).validateCanCreateMessage(processInstanceId, engineId, options)(this.axios, this.basePath);
    }

}


/**
 * ProcessApi - axios parameter creator
 * @export
 */
export const ProcessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate process definition (all versions matching filters) and optionally related process instances
         * @param {string} workspaceId 
         * @param {string} engineId 
         * @param {string} [deploymentId] 
         * @param {string} [processDefinitionKey] 
         * @param {string} [processDefinitionVersion] 
         * @param {boolean} [activateProcessInstances] If child process instances should be activated too
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateProcessDefinition(workspaceId: string, engineId: string, deploymentId?: string, processDefinitionKey?: string, processDefinitionVersion?: string, activateProcessInstances?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling activateProcessDefinition.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling activateProcessDefinition.');
            }
            const localVarPath = `/process/activate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspaceId'] = workspaceId;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (deploymentId !== undefined) {
                localVarQueryParameter['deploymentId'] = deploymentId;
            }

            if (processDefinitionKey !== undefined) {
                localVarQueryParameter['processDefinitionKey'] = processDefinitionKey;
            }

            if (processDefinitionVersion !== undefined) {
                localVarQueryParameter['processDefinitionVersion'] = processDefinitionVersion;
            }

            if (activateProcessInstances !== undefined) {
                localVarQueryParameter['activateProcessInstances'] = activateProcessInstances;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Audit details of a process
         * @param {string} processDefinitionKey 
         * @param {string} tenantId 
         * @param {string} [engineId] 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {string} [processDefinitionVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessAudit(processDefinitionKey: string, tenantId: string, engineId?: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, processDefinitionVersion?: string, options: any = {}): RequestArgs {
            // verify required parameter 'processDefinitionKey' is not null or undefined
            if (processDefinitionKey === null || processDefinitionKey === undefined) {
                throw new RequiredError('processDefinitionKey','Required parameter processDefinitionKey was null or undefined when calling getProcessAudit.');
            }
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling getProcessAudit.');
            }
            const localVarPath = `/process/{processDefinitionKey}/audits`
                .replace(`{${"processDefinitionKey"}}`, encodeURIComponent(String(processDefinitionKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (dateAfter !== undefined) {
                localVarQueryParameter['dateAfter'] = (dateAfter as any instanceof Date) ?
                    (dateAfter as any).toISOString() :
                    dateAfter;
            }

            if (dateBefore !== undefined) {
                localVarQueryParameter['dateBefore'] = (dateBefore as any instanceof Date) ?
                    (dateBefore as any).toISOString() :
                    dateBefore;
            }

            if (eventName !== undefined) {
                localVarQueryParameter['eventName'] = eventName;
            }

            if (performerId !== undefined) {
                localVarQueryParameter['performerId'] = performerId;
            }

            if (processDefinitionVersion !== undefined) {
                localVarQueryParameter['processDefinitionVersion'] = processDefinitionVersion;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the BPMN 2.0 XML of a process definition.
         * @param {string} processDefinitionId The id of the process definition.
         * @param {string} engineId EngineId to communicate with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessDefinitionXml(processDefinitionId: string, engineId: string, options: any = {}): RequestArgs {
            // verify required parameter 'processDefinitionId' is not null or undefined
            if (processDefinitionId === null || processDefinitionId === undefined) {
                throw new RequiredError('processDefinitionId','Required parameter processDefinitionId was null or undefined when calling getProcessDefinitionXml.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling getProcessDefinitionXml.');
            }
            const localVarPath = `/processes/{processDefinitionId}/process-diagram`
                .replace(`{${"processDefinitionId"}}`, encodeURIComponent(String(processDefinitionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve process\' start form
         * @param {string} workspaceId the workspace where the process is
         * @param {string} processDefKey the process definition key
         * @param {number} [procDefVersion] the process definition version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessStartForm(workspaceId: string, processDefKey: string, procDefVersion?: number, options: any = {}): RequestArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling getProcessStartForm.');
            }
            // verify required parameter 'processDefKey' is not null or undefined
            if (processDefKey === null || processDefKey === undefined) {
                throw new RequiredError('processDefKey','Required parameter processDefKey was null or undefined when calling getProcessStartForm.');
            }
            const localVarPath = `/workspaces/{workspaceId}/processes/{processDefKey}/start`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"processDefKey"}}`, encodeURIComponent(String(processDefKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (procDefVersion !== undefined) {
                localVarQueryParameter['procDefVersion'] = procDefVersion;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary search startable processes. Whenever version is not set, it will return the latest versions.
         * @param {string} [defKey] look for processes with the given key
         * @param {number} [defVersion] look for a specific process version. If set, the defKey parameter must be set too
         * @param {string} [workspaceId] look only for processes within a specific workspace
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchStartableProcesses(defKey?: string, defVersion?: number, workspaceId?: string, pageNumber?: number, pageSize?: number, options: any = {}): RequestArgs {
            const localVarPath = `/processes`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (defKey !== undefined) {
                localVarQueryParameter['defKey'] = defKey;
            }

            if (defVersion !== undefined) {
                localVarQueryParameter['defVersion'] = defVersion;
            }

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspaceId'] = workspaceId;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts a process of a workflow
         * @param {string} workspaceId the workspace where the process is
         * @param {string} processDefKey the process definition key
         * @param {StartWorkflowSubmission} startWorkflowSubmission 
         * @param {number} [procDefVersion] the process definition version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startWorkflowProcess(workspaceId: string, processDefKey: string, startWorkflowSubmission: StartWorkflowSubmission, procDefVersion?: number, options: any = {}): RequestArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling startWorkflowProcess.');
            }
            // verify required parameter 'processDefKey' is not null or undefined
            if (processDefKey === null || processDefKey === undefined) {
                throw new RequiredError('processDefKey','Required parameter processDefKey was null or undefined when calling startWorkflowProcess.');
            }
            // verify required parameter 'startWorkflowSubmission' is not null or undefined
            if (startWorkflowSubmission === null || startWorkflowSubmission === undefined) {
                throw new RequiredError('startWorkflowSubmission','Required parameter startWorkflowSubmission was null or undefined when calling startWorkflowProcess.');
            }
            const localVarPath = `/workspaces/{workspaceId}/processes/{processDefKey}/start`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"processDefKey"}}`, encodeURIComponent(String(processDefKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (procDefVersion !== undefined) {
                localVarQueryParameter['procDefVersion'] = procDefVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof startWorkflowSubmission !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(startWorkflowSubmission !== undefined ? startWorkflowSubmission : {}) : (startWorkflowSubmission || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Suspend process definition (all versions matching filters) and optionally related process instances
         * @param {string} workspaceId 
         * @param {string} engineId 
         * @param {string} [deploymentId] 
         * @param {string} [processDefinitionKey] 
         * @param {string} [processDefinitionVersion] 
         * @param {boolean} [suspendProcessInstances] If child process instances should be suspended too
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendProcessDefinition(workspaceId: string, engineId: string, deploymentId?: string, processDefinitionKey?: string, processDefinitionVersion?: string, suspendProcessInstances?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling suspendProcessDefinition.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling suspendProcessDefinition.');
            }
            const localVarPath = `/process/suspend`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspaceId'] = workspaceId;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (deploymentId !== undefined) {
                localVarQueryParameter['deploymentId'] = deploymentId;
            }

            if (processDefinitionKey !== undefined) {
                localVarQueryParameter['processDefinitionKey'] = processDefinitionKey;
            }

            if (processDefinitionVersion !== undefined) {
                localVarQueryParameter['processDefinitionVersion'] = processDefinitionVersion;
            }

            if (suspendProcessInstances !== undefined) {
                localVarQueryParameter['suspendProcessInstances'] = suspendProcessInstances;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessApi - functional programming interface
 * @export
 */
export const ProcessApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate process definition (all versions matching filters) and optionally related process instances
         * @param {string} workspaceId 
         * @param {string} engineId 
         * @param {string} [deploymentId] 
         * @param {string} [processDefinitionKey] 
         * @param {string} [processDefinitionVersion] 
         * @param {boolean} [activateProcessInstances] If child process instances should be activated too
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateProcessDefinition(workspaceId: string, engineId: string, deploymentId?: string, processDefinitionKey?: string, processDefinitionVersion?: string, activateProcessInstances?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProcessApiAxiosParamCreator(configuration).activateProcessDefinition(workspaceId, engineId, deploymentId, processDefinitionKey, processDefinitionVersion, activateProcessInstances, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Audit details of a process
         * @param {string} processDefinitionKey 
         * @param {string} tenantId 
         * @param {string} [engineId] 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {string} [processDefinitionVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessAudit(processDefinitionKey: string, tenantId: string, engineId?: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, processDefinitionVersion?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditLog>> {
            const localVarAxiosArgs = ProcessApiAxiosParamCreator(configuration).getProcessAudit(processDefinitionKey, tenantId, engineId, dateAfter, dateBefore, eventName, performerId, processDefinitionVersion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieves the BPMN 2.0 XML of a process definition.
         * @param {string} processDefinitionId The id of the process definition.
         * @param {string} engineId EngineId to communicate with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessDefinitionXml(processDefinitionId: string, engineId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessDefinitionModelData> {
            const localVarAxiosArgs = ProcessApiAxiosParamCreator(configuration).getProcessDefinitionXml(processDefinitionId, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve process\' start form
         * @param {string} workspaceId the workspace where the process is
         * @param {string} processDefKey the process definition key
         * @param {number} [procDefVersion] the process definition version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessStartForm(workspaceId: string, processDefKey: string, procDefVersion?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartWorkflowSpecification> {
            const localVarAxiosArgs = ProcessApiAxiosParamCreator(configuration).getProcessStartForm(workspaceId, processDefKey, procDefVersion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary search startable processes. Whenever version is not set, it will return the latest versions.
         * @param {string} [defKey] look for processes with the given key
         * @param {number} [defVersion] look for a specific process version. If set, the defKey parameter must be set too
         * @param {string} [workspaceId] look only for processes within a specific workspace
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchStartableProcesses(defKey?: string, defVersion?: number, workspaceId?: string, pageNumber?: number, pageSize?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfStartableProcess> {
            const localVarAxiosArgs = ProcessApiAxiosParamCreator(configuration).searchStartableProcesses(defKey, defVersion, workspaceId, pageNumber, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Starts a process of a workflow
         * @param {string} workspaceId the workspace where the process is
         * @param {string} processDefKey the process definition key
         * @param {StartWorkflowSubmission} startWorkflowSubmission 
         * @param {number} [procDefVersion] the process definition version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startWorkflowProcess(workspaceId: string, processDefKey: string, startWorkflowSubmission: StartWorkflowSubmission, procDefVersion?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartedWorkflowSubmission> {
            const localVarAxiosArgs = ProcessApiAxiosParamCreator(configuration).startWorkflowProcess(workspaceId, processDefKey, startWorkflowSubmission, procDefVersion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Suspend process definition (all versions matching filters) and optionally related process instances
         * @param {string} workspaceId 
         * @param {string} engineId 
         * @param {string} [deploymentId] 
         * @param {string} [processDefinitionKey] 
         * @param {string} [processDefinitionVersion] 
         * @param {boolean} [suspendProcessInstances] If child process instances should be suspended too
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendProcessDefinition(workspaceId: string, engineId: string, deploymentId?: string, processDefinitionKey?: string, processDefinitionVersion?: string, suspendProcessInstances?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ProcessApiAxiosParamCreator(configuration).suspendProcessDefinition(workspaceId, engineId, deploymentId, processDefinitionKey, processDefinitionVersion, suspendProcessInstances, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProcessApi - factory interface
 * @export
 */
export const ProcessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Activate process definition (all versions matching filters) and optionally related process instances
         * @param {string} workspaceId 
         * @param {string} engineId 
         * @param {string} [deploymentId] 
         * @param {string} [processDefinitionKey] 
         * @param {string} [processDefinitionVersion] 
         * @param {boolean} [activateProcessInstances] If child process instances should be activated too
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateProcessDefinition(workspaceId: string, engineId: string, deploymentId?: string, processDefinitionKey?: string, processDefinitionVersion?: string, activateProcessInstances?: boolean, options?: any): AxiosPromise<void> {
            return ProcessApiFp(configuration).activateProcessDefinition(workspaceId, engineId, deploymentId, processDefinitionKey, processDefinitionVersion, activateProcessInstances, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get Audit details of a process
         * @param {string} processDefinitionKey 
         * @param {string} tenantId 
         * @param {string} [engineId] 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {string} [processDefinitionVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessAudit(processDefinitionKey: string, tenantId: string, engineId?: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, processDefinitionVersion?: string, options?: any): AxiosPromise<Array<AuditLog>> {
            return ProcessApiFp(configuration).getProcessAudit(processDefinitionKey, tenantId, engineId, dateAfter, dateBefore, eventName, performerId, processDefinitionVersion, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieves the BPMN 2.0 XML of a process definition.
         * @param {string} processDefinitionId The id of the process definition.
         * @param {string} engineId EngineId to communicate with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessDefinitionXml(processDefinitionId: string, engineId: string, options?: any): AxiosPromise<ProcessDefinitionModelData> {
            return ProcessApiFp(configuration).getProcessDefinitionXml(processDefinitionId, engineId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve process\' start form
         * @param {string} workspaceId the workspace where the process is
         * @param {string} processDefKey the process definition key
         * @param {number} [procDefVersion] the process definition version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessStartForm(workspaceId: string, processDefKey: string, procDefVersion?: number, options?: any): AxiosPromise<StartWorkflowSpecification> {
            return ProcessApiFp(configuration).getProcessStartForm(workspaceId, processDefKey, procDefVersion, options)(axios, basePath);
        },
        /**
         * 
         * @summary search startable processes. Whenever version is not set, it will return the latest versions.
         * @param {string} [defKey] look for processes with the given key
         * @param {number} [defVersion] look for a specific process version. If set, the defKey parameter must be set too
         * @param {string} [workspaceId] look only for processes within a specific workspace
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchStartableProcesses(defKey?: string, defVersion?: number, workspaceId?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<PageOfStartableProcess> {
            return ProcessApiFp(configuration).searchStartableProcesses(defKey, defVersion, workspaceId, pageNumber, pageSize, options)(axios, basePath);
        },
        /**
         * 
         * @summary Starts a process of a workflow
         * @param {string} workspaceId the workspace where the process is
         * @param {string} processDefKey the process definition key
         * @param {StartWorkflowSubmission} startWorkflowSubmission 
         * @param {number} [procDefVersion] the process definition version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startWorkflowProcess(workspaceId: string, processDefKey: string, startWorkflowSubmission: StartWorkflowSubmission, procDefVersion?: number, options?: any): AxiosPromise<StartedWorkflowSubmission> {
            return ProcessApiFp(configuration).startWorkflowProcess(workspaceId, processDefKey, startWorkflowSubmission, procDefVersion, options)(axios, basePath);
        },
        /**
         * 
         * @summary Suspend process definition (all versions matching filters) and optionally related process instances
         * @param {string} workspaceId 
         * @param {string} engineId 
         * @param {string} [deploymentId] 
         * @param {string} [processDefinitionKey] 
         * @param {string} [processDefinitionVersion] 
         * @param {boolean} [suspendProcessInstances] If child process instances should be suspended too
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendProcessDefinition(workspaceId: string, engineId: string, deploymentId?: string, processDefinitionKey?: string, processDefinitionVersion?: string, suspendProcessInstances?: boolean, options?: any): AxiosPromise<void> {
            return ProcessApiFp(configuration).suspendProcessDefinition(workspaceId, engineId, deploymentId, processDefinitionKey, processDefinitionVersion, suspendProcessInstances, options)(axios, basePath);
        },
    };
};

/**
 * ProcessApi - object-oriented interface
 * @export
 * @class ProcessApi
 * @extends {BaseAPI}
 */
export class ProcessApi extends BaseAPI {
    /**
     * 
     * @summary Activate process definition (all versions matching filters) and optionally related process instances
     * @param {string} workspaceId 
     * @param {string} engineId 
     * @param {string} [deploymentId] 
     * @param {string} [processDefinitionKey] 
     * @param {string} [processDefinitionVersion] 
     * @param {boolean} [activateProcessInstances] If child process instances should be activated too
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessApi
     */
    public activateProcessDefinition(workspaceId: string, engineId: string, deploymentId?: string, processDefinitionKey?: string, processDefinitionVersion?: string, activateProcessInstances?: boolean, options?: any) {
        return ProcessApiFp(this.configuration).activateProcessDefinition(workspaceId, engineId, deploymentId, processDefinitionKey, processDefinitionVersion, activateProcessInstances, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get Audit details of a process
     * @param {string} processDefinitionKey 
     * @param {string} tenantId 
     * @param {string} [engineId] 
     * @param {Date} [dateAfter] 
     * @param {Date} [dateBefore] 
     * @param {string} [eventName] 
     * @param {string} [performerId] 
     * @param {string} [processDefinitionVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessApi
     */
    public getProcessAudit(processDefinitionKey: string, tenantId: string, engineId?: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, processDefinitionVersion?: string, options?: any) {
        return ProcessApiFp(this.configuration).getProcessAudit(processDefinitionKey, tenantId, engineId, dateAfter, dateBefore, eventName, performerId, processDefinitionVersion, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieves the BPMN 2.0 XML of a process definition.
     * @param {string} processDefinitionId The id of the process definition.
     * @param {string} engineId EngineId to communicate with
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessApi
     */
    public getProcessDefinitionXml(processDefinitionId: string, engineId: string, options?: any) {
        return ProcessApiFp(this.configuration).getProcessDefinitionXml(processDefinitionId, engineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve process\' start form
     * @param {string} workspaceId the workspace where the process is
     * @param {string} processDefKey the process definition key
     * @param {number} [procDefVersion] the process definition version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessApi
     */
    public getProcessStartForm(workspaceId: string, processDefKey: string, procDefVersion?: number, options?: any) {
        return ProcessApiFp(this.configuration).getProcessStartForm(workspaceId, processDefKey, procDefVersion, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary search startable processes. Whenever version is not set, it will return the latest versions.
     * @param {string} [defKey] look for processes with the given key
     * @param {number} [defVersion] look for a specific process version. If set, the defKey parameter must be set too
     * @param {string} [workspaceId] look only for processes within a specific workspace
     * @param {number} [pageNumber] Number of the page
     * @param {number} [pageSize] Number of elements in the page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessApi
     */
    public searchStartableProcesses(defKey?: string, defVersion?: number, workspaceId?: string, pageNumber?: number, pageSize?: number, options?: any) {
        return ProcessApiFp(this.configuration).searchStartableProcesses(defKey, defVersion, workspaceId, pageNumber, pageSize, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Starts a process of a workflow
     * @param {string} workspaceId the workspace where the process is
     * @param {string} processDefKey the process definition key
     * @param {StartWorkflowSubmission} startWorkflowSubmission 
     * @param {number} [procDefVersion] the process definition version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessApi
     */
    public startWorkflowProcess(workspaceId: string, processDefKey: string, startWorkflowSubmission: StartWorkflowSubmission, procDefVersion?: number, options?: any) {
        return ProcessApiFp(this.configuration).startWorkflowProcess(workspaceId, processDefKey, startWorkflowSubmission, procDefVersion, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Suspend process definition (all versions matching filters) and optionally related process instances
     * @param {string} workspaceId 
     * @param {string} engineId 
     * @param {string} [deploymentId] 
     * @param {string} [processDefinitionKey] 
     * @param {string} [processDefinitionVersion] 
     * @param {boolean} [suspendProcessInstances] If child process instances should be suspended too
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessApi
     */
    public suspendProcessDefinition(workspaceId: string, engineId: string, deploymentId?: string, processDefinitionKey?: string, processDefinitionVersion?: string, suspendProcessInstances?: boolean, options?: any) {
        return ProcessApiFp(this.configuration).suspendProcessDefinition(workspaceId, engineId, deploymentId, processDefinitionKey, processDefinitionVersion, suspendProcessInstances, options)(this.axios, this.basePath);
    }

}


/**
 * RobotAPIApi - axios parameter creator
 * @export
 */
export const RobotAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Start a Workflow using client credentials
         * @param {string} workflowId 
         * @param {StartWorkflowSubmission} startWorkflowSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startRobotWorkflow(workflowId: string, startWorkflowSubmission: StartWorkflowSubmission, options: any = {}): RequestArgs {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new RequiredError('workflowId','Required parameter workflowId was null or undefined when calling startRobotWorkflow.');
            }
            // verify required parameter 'startWorkflowSubmission' is not null or undefined
            if (startWorkflowSubmission === null || startWorkflowSubmission === undefined) {
                throw new RequiredError('startWorkflowSubmission','Required parameter startWorkflowSubmission was null or undefined when calling startRobotWorkflow.');
            }
            const localVarPath = `/workflow/{workflowId}/start-by-robot`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1", "api.sg-workflow-workspaces.robot.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof startWorkflowSubmission !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(startWorkflowSubmission !== undefined ? startWorkflowSubmission : {}) : (startWorkflowSubmission || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RobotAPIApi - functional programming interface
 * @export
 */
export const RobotAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Start a Workflow using client credentials
         * @param {string} workflowId 
         * @param {StartWorkflowSubmission} startWorkflowSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startRobotWorkflow(workflowId: string, startWorkflowSubmission: StartWorkflowSubmission, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartedWorkflowSubmission> {
            const localVarAxiosArgs = RobotAPIApiAxiosParamCreator(configuration).startRobotWorkflow(workflowId, startWorkflowSubmission, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RobotAPIApi - factory interface
 * @export
 */
export const RobotAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Start a Workflow using client credentials
         * @param {string} workflowId 
         * @param {StartWorkflowSubmission} startWorkflowSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startRobotWorkflow(workflowId: string, startWorkflowSubmission: StartWorkflowSubmission, options?: any): AxiosPromise<StartedWorkflowSubmission> {
            return RobotAPIApiFp(configuration).startRobotWorkflow(workflowId, startWorkflowSubmission, options)(axios, basePath);
        },
    };
};

/**
 * RobotAPIApi - object-oriented interface
 * @export
 * @class RobotAPIApi
 * @extends {BaseAPI}
 */
export class RobotAPIApi extends BaseAPI {
    /**
     * 
     * @summary Start a Workflow using client credentials
     * @param {string} workflowId 
     * @param {StartWorkflowSubmission} startWorkflowSubmission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotAPIApi
     */
    public startRobotWorkflow(workflowId: string, startWorkflowSubmission: StartWorkflowSubmission, options?: any) {
        return RobotAPIApiFp(this.configuration).startRobotWorkflow(workflowId, startWorkflowSubmission, options)(this.axios, this.basePath);
    }

}


/**
 * UserSettingsApi - axios parameter creator
 * @export
 */
export const UserSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create User Settings
         * @param {UserSettings} userSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserSettings(userSettings: UserSettings, options: any = {}): RequestArgs {
            // verify required parameter 'userSettings' is not null or undefined
            if (userSettings === null || userSettings === undefined) {
                throw new RequiredError('userSettings','Required parameter userSettings was null or undefined when calling createUserSettings.');
            }
            const localVarPath = `/user-settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof userSettings !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userSettings !== undefined ? userSettings : {}) : (userSettings || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a User Setting
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserSettings(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserSettings.');
            }
            const localVarPath = `/user-settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the list of User Settings
         * @param {Array<string>} [userIdIn] Keep only user Settings with a userId (URL friendly name) present in that list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserSettings(userIdIn?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/user-settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (userIdIn) {
                localVarQueryParameter['userIdIn'] = userIdIn;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a Users Settings
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUserSettingsById(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling retrieveUserSettingsById.');
            }
            const localVarPath = `/user-settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates favorite processes
         * @param {string} id 
         * @param {FavoriteProcessesPatch} favoriteProcessesPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFavoriteProcesses(id: string, favoriteProcessesPatch: FavoriteProcessesPatch, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateFavoriteProcesses.');
            }
            // verify required parameter 'favoriteProcessesPatch' is not null or undefined
            if (favoriteProcessesPatch === null || favoriteProcessesPatch === undefined) {
                throw new RequiredError('favoriteProcessesPatch','Required parameter favoriteProcessesPatch was null or undefined when calling updateFavoriteProcesses.');
            }
            const localVarPath = `/user-settings/{id}/favorite-processes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof favoriteProcessesPatch !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(favoriteProcessesPatch !== undefined ? favoriteProcessesPatch : {}) : (favoriteProcessesPatch || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a User Settings
         * @param {string} id 
         * @param {UserSettings} userSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserSettings(id: string, userSettings: UserSettings, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateUserSettings.');
            }
            // verify required parameter 'userSettings' is not null or undefined
            if (userSettings === null || userSettings === undefined) {
                throw new RequiredError('userSettings','Required parameter userSettings was null or undefined when calling updateUserSettings.');
            }
            const localVarPath = `/user-settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof userSettings !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userSettings !== undefined ? userSettings : {}) : (userSettings || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSettingsApi - functional programming interface
 * @export
 */
export const UserSettingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create User Settings
         * @param {UserSettings} userSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserSettings(userSettings: UserSettings, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettings> {
            const localVarAxiosArgs = UserSettingsApiAxiosParamCreator(configuration).createUserSettings(userSettings, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a User Setting
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserSettings(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserSettingsApiAxiosParamCreator(configuration).deleteUserSettings(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve the list of User Settings
         * @param {Array<string>} [userIdIn] Keep only user Settings with a userId (URL friendly name) present in that list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserSettings(userIdIn?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserSettings>> {
            const localVarAxiosArgs = UserSettingsApiAxiosParamCreator(configuration).listUserSettings(userIdIn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a Users Settings
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUserSettingsById(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettings> {
            const localVarAxiosArgs = UserSettingsApiAxiosParamCreator(configuration).retrieveUserSettingsById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates favorite processes
         * @param {string} id 
         * @param {FavoriteProcessesPatch} favoriteProcessesPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFavoriteProcesses(id: string, favoriteProcessesPatch: FavoriteProcessesPatch, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserSettingsApiAxiosParamCreator(configuration).updateFavoriteProcesses(id, favoriteProcessesPatch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a User Settings
         * @param {string} id 
         * @param {UserSettings} userSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserSettings(id: string, userSettings: UserSettings, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettings> {
            const localVarAxiosArgs = UserSettingsApiAxiosParamCreator(configuration).updateUserSettings(id, userSettings, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserSettingsApi - factory interface
 * @export
 */
export const UserSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create User Settings
         * @param {UserSettings} userSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserSettings(userSettings: UserSettings, options?: any): AxiosPromise<UserSettings> {
            return UserSettingsApiFp(configuration).createUserSettings(userSettings, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a User Setting
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserSettings(id: string, options?: any): AxiosPromise<void> {
            return UserSettingsApiFp(configuration).deleteUserSettings(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve the list of User Settings
         * @param {Array<string>} [userIdIn] Keep only user Settings with a userId (URL friendly name) present in that list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserSettings(userIdIn?: Array<string>, options?: any): AxiosPromise<Array<UserSettings>> {
            return UserSettingsApiFp(configuration).listUserSettings(userIdIn, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve a Users Settings
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveUserSettingsById(id: string, options?: any): AxiosPromise<UserSettings> {
            return UserSettingsApiFp(configuration).retrieveUserSettingsById(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Updates favorite processes
         * @param {string} id 
         * @param {FavoriteProcessesPatch} favoriteProcessesPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFavoriteProcesses(id: string, favoriteProcessesPatch: FavoriteProcessesPatch, options?: any): AxiosPromise<void> {
            return UserSettingsApiFp(configuration).updateFavoriteProcesses(id, favoriteProcessesPatch, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update a User Settings
         * @param {string} id 
         * @param {UserSettings} userSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserSettings(id: string, userSettings: UserSettings, options?: any): AxiosPromise<UserSettings> {
            return UserSettingsApiFp(configuration).updateUserSettings(id, userSettings, options)(axios, basePath);
        },
    };
};

/**
 * UserSettingsApi - object-oriented interface
 * @export
 * @class UserSettingsApi
 * @extends {BaseAPI}
 */
export class UserSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Create User Settings
     * @param {UserSettings} userSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public createUserSettings(userSettings: UserSettings, options?: any) {
        return UserSettingsApiFp(this.configuration).createUserSettings(userSettings, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a User Setting
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public deleteUserSettings(id: string, options?: any) {
        return UserSettingsApiFp(this.configuration).deleteUserSettings(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve the list of User Settings
     * @param {Array<string>} [userIdIn] Keep only user Settings with a userId (URL friendly name) present in that list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public listUserSettings(userIdIn?: Array<string>, options?: any) {
        return UserSettingsApiFp(this.configuration).listUserSettings(userIdIn, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve a Users Settings
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public retrieveUserSettingsById(id: string, options?: any) {
        return UserSettingsApiFp(this.configuration).retrieveUserSettingsById(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Updates favorite processes
     * @param {string} id 
     * @param {FavoriteProcessesPatch} favoriteProcessesPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public updateFavoriteProcesses(id: string, favoriteProcessesPatch: FavoriteProcessesPatch, options?: any) {
        return UserSettingsApiFp(this.configuration).updateFavoriteProcesses(id, favoriteProcessesPatch, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update a User Settings
     * @param {string} id 
     * @param {UserSettings} userSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public updateUserSettings(id: string, userSettings: UserSettings, options?: any) {
        return UserSettingsApiFp(this.configuration).updateUserSettings(id, userSettings, options)(this.axios, this.basePath);
    }

}


/**
 * WorkflowApi - axios parameter creator
 * @export
 */
export const WorkflowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate a workflow and optionally related process instances
         * @param {string} workflowId 
         * @param {boolean} [cascadeToProcessInstances] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateWorkflow(workflowId: string, cascadeToProcessInstances?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new RequiredError('workflowId','Required parameter workflowId was null or undefined when calling activateWorkflow.');
            }
            const localVarPath = `/workflows/{workflowId}/activate`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (cascadeToProcessInstances !== undefined) {
                localVarQueryParameter['cascadeToProcessInstances'] = cascadeToProcessInstances;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Workflow
         * @param {Workflow} workflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflow(workflow: Workflow, options: any = {}): RequestArgs {
            // verify required parameter 'workflow' is not null or undefined
            if (workflow === null || workflow === undefined) {
                throw new RequiredError('workflow','Required parameter workflow was null or undefined when calling createWorkflow.');
            }
            const localVarPath = `/workflows`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflow !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflow !== undefined ? workflow : {}) : (workflow || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Workflow
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflow(workflowId: string, options: any = {}): RequestArgs {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new RequiredError('workflowId','Required parameter workflowId was null or undefined when calling deleteWorkflow.');
            }
            const localVarPath = `/workflows/{workflowId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deploy a Workflow
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deployWorkflow(workflowId: string, options: any = {}): RequestArgs {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new RequiredError('workflowId','Required parameter workflowId was null or undefined when calling deployWorkflow.');
            }
            const localVarPath = `/workflows/{workflowId}/deploy`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the BPMN 2.0 XML of a process definition.
         * @param {string} processDefinitionId The id of the process definition.
         * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeprecatedProcessDefinitionXml(processDefinitionId: string, engineId: string, options: any = {}): RequestArgs {
            // verify required parameter 'processDefinitionId' is not null or undefined
            if (processDefinitionId === null || processDefinitionId === undefined) {
                throw new RequiredError('processDefinitionId','Required parameter processDefinitionId was null or undefined when calling getDeprecatedProcessDefinitionXml.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling getDeprecatedProcessDefinitionXml.');
            }
            const localVarPath = `/workflows/{processDefinitionId}/xml`
                .replace(`{${"processDefinitionId"}}`, encodeURIComponent(String(processDefinitionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Audit details of a workflow
         * @param {string} workflowId 
         * @param {string} workspaceId 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {string} [workflowSlug] 
         * @param {string} [workflowTitle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowAudit(workflowId: string, workspaceId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, workflowSlug?: string, workflowTitle?: string, options: any = {}): RequestArgs {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new RequiredError('workflowId','Required parameter workflowId was null or undefined when calling getWorkflowAudit.');
            }
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling getWorkflowAudit.');
            }
            const localVarPath = `/workflows/{workflowId}/audits`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (dateAfter !== undefined) {
                localVarQueryParameter['dateAfter'] = (dateAfter as any instanceof Date) ?
                    (dateAfter as any).toISOString() :
                    dateAfter;
            }

            if (dateBefore !== undefined) {
                localVarQueryParameter['dateBefore'] = (dateBefore as any instanceof Date) ?
                    (dateBefore as any).toISOString() :
                    dateBefore;
            }

            if (eventName !== undefined) {
                localVarQueryParameter['eventName'] = eventName;
            }

            if (performerId !== undefined) {
                localVarQueryParameter['performerId'] = performerId;
            }

            if (workflowSlug !== undefined) {
                localVarQueryParameter['workflowSlug'] = workflowSlug;
            }

            if (workflowTitle !== undefined) {
                localVarQueryParameter['workflowTitle'] = workflowTitle;
            }

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspaceId'] = workspaceId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the list of Workflows
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {UserType} [userType] Type of users: starters or mangers
         * @param {Array<string>} [workspaceIdIn] Keep only workflows with a workspaceId present in that list
         * @param {Array<string>} [slugIn] Keep only workflows with a slug (URL friendly name) present in that list
         * @param {number} [weight] Keep only workflows with a specific weight (deprecated it should be replaced by version)
         * @param {number} [version] Retrieves only workflows with a specific version
         * @param {string} [deploymentId] Retrieves only workflows with a specific deploymentId 
         * @param {boolean} [showDisabled] Workflow enabled (true|false). By Default retrieves only enabled workflow 
         * @param {boolean} [showOnlyLaunchable] Only launchable Workflow (true|false). By Default retrieves all workflow
         * @param {boolean} [showPublic] Set to true if you want to also include public workflows. By Default only non-public workflows are returned.
         * @param {string} [engineId] Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflows(pageNumber?: number, pageSize?: number, sort?: Array<string>, userType?: UserType, workspaceIdIn?: Array<string>, slugIn?: Array<string>, weight?: number, version?: number, deploymentId?: string, showDisabled?: boolean, showOnlyLaunchable?: boolean, showPublic?: boolean, engineId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/workflows`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (userType !== undefined) {
                localVarQueryParameter['userType'] = userType;
            }

            if (workspaceIdIn) {
                localVarQueryParameter['workspaceIdIn'] = workspaceIdIn;
            }

            if (slugIn) {
                localVarQueryParameter['slugIn'] = slugIn;
            }

            if (weight !== undefined) {
                localVarQueryParameter['weight'] = weight;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deploymentId !== undefined) {
                localVarQueryParameter['deploymentId'] = deploymentId;
            }

            if (showDisabled !== undefined) {
                localVarQueryParameter['showDisabled'] = showDisabled;
            }

            if (showOnlyLaunchable !== undefined) {
                localVarQueryParameter['showOnlyLaunchable'] = showOnlyLaunchable;
            }

            if (showPublic !== undefined) {
                localVarQueryParameter['showPublic'] = showPublic;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve specification to start a Workflow
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveStartWorkflowById(workflowId: string, options: any = {}): RequestArgs {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new RequiredError('workflowId','Required parameter workflowId was null or undefined when calling retrieveStartWorkflowById.');
            }
            const localVarPath = `/workflows/{workflowId}/start`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a Workflow
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWorkflowById(workflowId: string, options: any = {}): RequestArgs {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new RequiredError('workflowId','Required parameter workflowId was null or undefined when calling retrieveWorkflowById.');
            }
            const localVarPath = `/workflows/{workflowId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start a Workflow
         * @param {string} workflowId 
         * @param {StartWorkflowSubmission} startWorkflowSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startWorkflow(workflowId: string, startWorkflowSubmission: StartWorkflowSubmission, options: any = {}): RequestArgs {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new RequiredError('workflowId','Required parameter workflowId was null or undefined when calling startWorkflow.');
            }
            // verify required parameter 'startWorkflowSubmission' is not null or undefined
            if (startWorkflowSubmission === null || startWorkflowSubmission === undefined) {
                throw new RequiredError('startWorkflowSubmission','Required parameter startWorkflowSubmission was null or undefined when calling startWorkflow.');
            }
            const localVarPath = `/workflows/{workflowId}/start`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof startWorkflowSubmission !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(startWorkflowSubmission !== undefined ? startWorkflowSubmission : {}) : (startWorkflowSubmission || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Suspend a workflow and optionally related process instances
         * @param {string} workflowId 
         * @param {boolean} [cascadeToProcessInstances] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendWorkflow(workflowId: string, cascadeToProcessInstances?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new RequiredError('workflowId','Required parameter workflowId was null or undefined when calling suspendWorkflow.');
            }
            const localVarPath = `/workflows/{workflowId}/suspend`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (cascadeToProcessInstances !== undefined) {
                localVarQueryParameter['cascadeToProcessInstances'] = cascadeToProcessInstances;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Workflow
         * @param {string} workflowId 
         * @param {Workflow} workflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflow(workflowId: string, workflow: Workflow, options: any = {}): RequestArgs {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new RequiredError('workflowId','Required parameter workflowId was null or undefined when calling updateWorkflow.');
            }
            // verify required parameter 'workflow' is not null or undefined
            if (workflow === null || workflow === undefined) {
                throw new RequiredError('workflow','Required parameter workflow was null or undefined when calling updateWorkflow.');
            }
            const localVarPath = `/workflows/{workflowId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflow !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflow !== undefined ? workflow : {}) : (workflow || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update some Workflow data
         * @param {string} workflowId 
         * @param {WorkflowUpdateData} workflowUpdateData All fields are optional, but at least one must be given. Only given fields will be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowData(workflowId: string, workflowUpdateData: WorkflowUpdateData, options: any = {}): RequestArgs {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new RequiredError('workflowId','Required parameter workflowId was null or undefined when calling updateWorkflowData.');
            }
            // verify required parameter 'workflowUpdateData' is not null or undefined
            if (workflowUpdateData === null || workflowUpdateData === undefined) {
                throw new RequiredError('workflowUpdateData','Required parameter workflowUpdateData was null or undefined when calling updateWorkflowData.');
            }
            const localVarPath = `/workflows/{workflowId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowUpdateData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowUpdateData !== undefined ? workflowUpdateData : {}) : (workflowUpdateData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowApi - functional programming interface
 * @export
 */
export const WorkflowApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate a workflow and optionally related process instances
         * @param {string} workflowId 
         * @param {boolean} [cascadeToProcessInstances] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateWorkflow(workflowId: string, cascadeToProcessInstances?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WorkflowApiAxiosParamCreator(configuration).activateWorkflow(workflowId, cascadeToProcessInstances, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a Workflow
         * @param {Workflow} workflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflow(workflow: Workflow, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workflow> {
            const localVarAxiosArgs = WorkflowApiAxiosParamCreator(configuration).createWorkflow(workflow, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a Workflow
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflow(workflowId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WorkflowApiAxiosParamCreator(configuration).deleteWorkflow(workflowId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deploy a Workflow
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deployWorkflow(workflowId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workflow> {
            const localVarAxiosArgs = WorkflowApiAxiosParamCreator(configuration).deployWorkflow(workflowId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieves the BPMN 2.0 XML of a process definition.
         * @param {string} processDefinitionId The id of the process definition.
         * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeprecatedProcessDefinitionXml(processDefinitionId: string, engineId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessDefinitionModelData> {
            const localVarAxiosArgs = WorkflowApiAxiosParamCreator(configuration).getDeprecatedProcessDefinitionXml(processDefinitionId, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Audit details of a workflow
         * @param {string} workflowId 
         * @param {string} workspaceId 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {string} [workflowSlug] 
         * @param {string} [workflowTitle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowAudit(workflowId: string, workspaceId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, workflowSlug?: string, workflowTitle?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditLog>> {
            const localVarAxiosArgs = WorkflowApiAxiosParamCreator(configuration).getWorkflowAudit(workflowId, workspaceId, dateAfter, dateBefore, eventName, performerId, workflowSlug, workflowTitle, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve the list of Workflows
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {UserType} [userType] Type of users: starters or mangers
         * @param {Array<string>} [workspaceIdIn] Keep only workflows with a workspaceId present in that list
         * @param {Array<string>} [slugIn] Keep only workflows with a slug (URL friendly name) present in that list
         * @param {number} [weight] Keep only workflows with a specific weight (deprecated it should be replaced by version)
         * @param {number} [version] Retrieves only workflows with a specific version
         * @param {string} [deploymentId] Retrieves only workflows with a specific deploymentId 
         * @param {boolean} [showDisabled] Workflow enabled (true|false). By Default retrieves only enabled workflow 
         * @param {boolean} [showOnlyLaunchable] Only launchable Workflow (true|false). By Default retrieves all workflow
         * @param {boolean} [showPublic] Set to true if you want to also include public workflows. By Default only non-public workflows are returned.
         * @param {string} [engineId] Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflows(pageNumber?: number, pageSize?: number, sort?: Array<string>, userType?: UserType, workspaceIdIn?: Array<string>, slugIn?: Array<string>, weight?: number, version?: number, deploymentId?: string, showDisabled?: boolean, showOnlyLaunchable?: boolean, showPublic?: boolean, engineId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfWorkflow> {
            const localVarAxiosArgs = WorkflowApiAxiosParamCreator(configuration).listWorkflows(pageNumber, pageSize, sort, userType, workspaceIdIn, slugIn, weight, version, deploymentId, showDisabled, showOnlyLaunchable, showPublic, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve specification to start a Workflow
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveStartWorkflowById(workflowId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartWorkflowSpecification> {
            const localVarAxiosArgs = WorkflowApiAxiosParamCreator(configuration).retrieveStartWorkflowById(workflowId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a Workflow
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWorkflowById(workflowId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workflow> {
            const localVarAxiosArgs = WorkflowApiAxiosParamCreator(configuration).retrieveWorkflowById(workflowId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Start a Workflow
         * @param {string} workflowId 
         * @param {StartWorkflowSubmission} startWorkflowSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startWorkflow(workflowId: string, startWorkflowSubmission: StartWorkflowSubmission, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartedWorkflowSubmission> {
            const localVarAxiosArgs = WorkflowApiAxiosParamCreator(configuration).startWorkflow(workflowId, startWorkflowSubmission, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Suspend a workflow and optionally related process instances
         * @param {string} workflowId 
         * @param {boolean} [cascadeToProcessInstances] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendWorkflow(workflowId: string, cascadeToProcessInstances?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WorkflowApiAxiosParamCreator(configuration).suspendWorkflow(workflowId, cascadeToProcessInstances, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a Workflow
         * @param {string} workflowId 
         * @param {Workflow} workflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflow(workflowId: string, workflow: Workflow, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workflow> {
            const localVarAxiosArgs = WorkflowApiAxiosParamCreator(configuration).updateWorkflow(workflowId, workflow, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update some Workflow data
         * @param {string} workflowId 
         * @param {WorkflowUpdateData} workflowUpdateData All fields are optional, but at least one must be given. Only given fields will be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowData(workflowId: string, workflowUpdateData: WorkflowUpdateData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WorkflowApiAxiosParamCreator(configuration).updateWorkflowData(workflowId, workflowUpdateData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkflowApi - factory interface
 * @export
 */
export const WorkflowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Activate a workflow and optionally related process instances
         * @param {string} workflowId 
         * @param {boolean} [cascadeToProcessInstances] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateWorkflow(workflowId: string, cascadeToProcessInstances?: boolean, options?: any): AxiosPromise<void> {
            return WorkflowApiFp(configuration).activateWorkflow(workflowId, cascadeToProcessInstances, options)(axios, basePath);
        },
        /**
         * 
         * @summary Create a Workflow
         * @param {Workflow} workflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflow(workflow: Workflow, options?: any): AxiosPromise<Workflow> {
            return WorkflowApiFp(configuration).createWorkflow(workflow, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a Workflow
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflow(workflowId: string, options?: any): AxiosPromise<void> {
            return WorkflowApiFp(configuration).deleteWorkflow(workflowId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Deploy a Workflow
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deployWorkflow(workflowId: string, options?: any): AxiosPromise<Workflow> {
            return WorkflowApiFp(configuration).deployWorkflow(workflowId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieves the BPMN 2.0 XML of a process definition.
         * @param {string} processDefinitionId The id of the process definition.
         * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeprecatedProcessDefinitionXml(processDefinitionId: string, engineId: string, options?: any): AxiosPromise<ProcessDefinitionModelData> {
            return WorkflowApiFp(configuration).getDeprecatedProcessDefinitionXml(processDefinitionId, engineId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get Audit details of a workflow
         * @param {string} workflowId 
         * @param {string} workspaceId 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {string} [workflowSlug] 
         * @param {string} [workflowTitle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowAudit(workflowId: string, workspaceId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, workflowSlug?: string, workflowTitle?: string, options?: any): AxiosPromise<Array<AuditLog>> {
            return WorkflowApiFp(configuration).getWorkflowAudit(workflowId, workspaceId, dateAfter, dateBefore, eventName, performerId, workflowSlug, workflowTitle, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve the list of Workflows
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {UserType} [userType] Type of users: starters or mangers
         * @param {Array<string>} [workspaceIdIn] Keep only workflows with a workspaceId present in that list
         * @param {Array<string>} [slugIn] Keep only workflows with a slug (URL friendly name) present in that list
         * @param {number} [weight] Keep only workflows with a specific weight (deprecated it should be replaced by version)
         * @param {number} [version] Retrieves only workflows with a specific version
         * @param {string} [deploymentId] Retrieves only workflows with a specific deploymentId 
         * @param {boolean} [showDisabled] Workflow enabled (true|false). By Default retrieves only enabled workflow 
         * @param {boolean} [showOnlyLaunchable] Only launchable Workflow (true|false). By Default retrieves all workflow
         * @param {boolean} [showPublic] Set to true if you want to also include public workflows. By Default only non-public workflows are returned.
         * @param {string} [engineId] Engine ID: The ID of the engine that should receive the start request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflows(pageNumber?: number, pageSize?: number, sort?: Array<string>, userType?: UserType, workspaceIdIn?: Array<string>, slugIn?: Array<string>, weight?: number, version?: number, deploymentId?: string, showDisabled?: boolean, showOnlyLaunchable?: boolean, showPublic?: boolean, engineId?: string, options?: any): AxiosPromise<PageOfWorkflow> {
            return WorkflowApiFp(configuration).listWorkflows(pageNumber, pageSize, sort, userType, workspaceIdIn, slugIn, weight, version, deploymentId, showDisabled, showOnlyLaunchable, showPublic, engineId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve specification to start a Workflow
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveStartWorkflowById(workflowId: string, options?: any): AxiosPromise<StartWorkflowSpecification> {
            return WorkflowApiFp(configuration).retrieveStartWorkflowById(workflowId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve a Workflow
         * @param {string} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWorkflowById(workflowId: string, options?: any): AxiosPromise<Workflow> {
            return WorkflowApiFp(configuration).retrieveWorkflowById(workflowId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Start a Workflow
         * @param {string} workflowId 
         * @param {StartWorkflowSubmission} startWorkflowSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startWorkflow(workflowId: string, startWorkflowSubmission: StartWorkflowSubmission, options?: any): AxiosPromise<StartedWorkflowSubmission> {
            return WorkflowApiFp(configuration).startWorkflow(workflowId, startWorkflowSubmission, options)(axios, basePath);
        },
        /**
         * 
         * @summary Suspend a workflow and optionally related process instances
         * @param {string} workflowId 
         * @param {boolean} [cascadeToProcessInstances] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendWorkflow(workflowId: string, cascadeToProcessInstances?: boolean, options?: any): AxiosPromise<void> {
            return WorkflowApiFp(configuration).suspendWorkflow(workflowId, cascadeToProcessInstances, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update a Workflow
         * @param {string} workflowId 
         * @param {Workflow} workflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflow(workflowId: string, workflow: Workflow, options?: any): AxiosPromise<Workflow> {
            return WorkflowApiFp(configuration).updateWorkflow(workflowId, workflow, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update some Workflow data
         * @param {string} workflowId 
         * @param {WorkflowUpdateData} workflowUpdateData All fields are optional, but at least one must be given. Only given fields will be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowData(workflowId: string, workflowUpdateData: WorkflowUpdateData, options?: any): AxiosPromise<void> {
            return WorkflowApiFp(configuration).updateWorkflowData(workflowId, workflowUpdateData, options)(axios, basePath);
        },
    };
};

/**
 * WorkflowApi - object-oriented interface
 * @export
 * @class WorkflowApi
 * @extends {BaseAPI}
 */
export class WorkflowApi extends BaseAPI {
    /**
     * 
     * @summary Activate a workflow and optionally related process instances
     * @param {string} workflowId 
     * @param {boolean} [cascadeToProcessInstances] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public activateWorkflow(workflowId: string, cascadeToProcessInstances?: boolean, options?: any) {
        return WorkflowApiFp(this.configuration).activateWorkflow(workflowId, cascadeToProcessInstances, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Create a Workflow
     * @param {Workflow} workflow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public createWorkflow(workflow: Workflow, options?: any) {
        return WorkflowApiFp(this.configuration).createWorkflow(workflow, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a Workflow
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public deleteWorkflow(workflowId: string, options?: any) {
        return WorkflowApiFp(this.configuration).deleteWorkflow(workflowId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Deploy a Workflow
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public deployWorkflow(workflowId: string, options?: any) {
        return WorkflowApiFp(this.configuration).deployWorkflow(workflowId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieves the BPMN 2.0 XML of a process definition.
     * @param {string} processDefinitionId The id of the process definition.
     * @param {string} engineId Engine ID: The ID of the engine that should receive the start request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public getDeprecatedProcessDefinitionXml(processDefinitionId: string, engineId: string, options?: any) {
        return WorkflowApiFp(this.configuration).getDeprecatedProcessDefinitionXml(processDefinitionId, engineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get Audit details of a workflow
     * @param {string} workflowId 
     * @param {string} workspaceId 
     * @param {Date} [dateAfter] 
     * @param {Date} [dateBefore] 
     * @param {string} [eventName] 
     * @param {string} [performerId] 
     * @param {string} [workflowSlug] 
     * @param {string} [workflowTitle] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public getWorkflowAudit(workflowId: string, workspaceId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, workflowSlug?: string, workflowTitle?: string, options?: any) {
        return WorkflowApiFp(this.configuration).getWorkflowAudit(workflowId, workspaceId, dateAfter, dateBefore, eventName, performerId, workflowSlug, workflowTitle, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve the list of Workflows
     * @param {number} [pageNumber] Number of the page
     * @param {number} [pageSize] Number of elements in the page
     * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
     * @param {UserType} [userType] Type of users: starters or mangers
     * @param {Array<string>} [workspaceIdIn] Keep only workflows with a workspaceId present in that list
     * @param {Array<string>} [slugIn] Keep only workflows with a slug (URL friendly name) present in that list
     * @param {number} [weight] Keep only workflows with a specific weight (deprecated it should be replaced by version)
     * @param {number} [version] Retrieves only workflows with a specific version
     * @param {string} [deploymentId] Retrieves only workflows with a specific deploymentId 
     * @param {boolean} [showDisabled] Workflow enabled (true|false). By Default retrieves only enabled workflow 
     * @param {boolean} [showOnlyLaunchable] Only launchable Workflow (true|false). By Default retrieves all workflow
     * @param {boolean} [showPublic] Set to true if you want to also include public workflows. By Default only non-public workflows are returned.
     * @param {string} [engineId] Engine ID: The ID of the engine that should receive the start request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public listWorkflows(pageNumber?: number, pageSize?: number, sort?: Array<string>, userType?: UserType, workspaceIdIn?: Array<string>, slugIn?: Array<string>, weight?: number, version?: number, deploymentId?: string, showDisabled?: boolean, showOnlyLaunchable?: boolean, showPublic?: boolean, engineId?: string, options?: any) {
        return WorkflowApiFp(this.configuration).listWorkflows(pageNumber, pageSize, sort, userType, workspaceIdIn, slugIn, weight, version, deploymentId, showDisabled, showOnlyLaunchable, showPublic, engineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve specification to start a Workflow
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public retrieveStartWorkflowById(workflowId: string, options?: any) {
        return WorkflowApiFp(this.configuration).retrieveStartWorkflowById(workflowId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve a Workflow
     * @param {string} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public retrieveWorkflowById(workflowId: string, options?: any) {
        return WorkflowApiFp(this.configuration).retrieveWorkflowById(workflowId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Start a Workflow
     * @param {string} workflowId 
     * @param {StartWorkflowSubmission} startWorkflowSubmission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public startWorkflow(workflowId: string, startWorkflowSubmission: StartWorkflowSubmission, options?: any) {
        return WorkflowApiFp(this.configuration).startWorkflow(workflowId, startWorkflowSubmission, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Suspend a workflow and optionally related process instances
     * @param {string} workflowId 
     * @param {boolean} [cascadeToProcessInstances] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public suspendWorkflow(workflowId: string, cascadeToProcessInstances?: boolean, options?: any) {
        return WorkflowApiFp(this.configuration).suspendWorkflow(workflowId, cascadeToProcessInstances, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update a Workflow
     * @param {string} workflowId 
     * @param {Workflow} workflow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public updateWorkflow(workflowId: string, workflow: Workflow, options?: any) {
        return WorkflowApiFp(this.configuration).updateWorkflow(workflowId, workflow, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update some Workflow data
     * @param {string} workflowId 
     * @param {WorkflowUpdateData} workflowUpdateData All fields are optional, but at least one must be given. Only given fields will be updated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public updateWorkflowData(workflowId: string, workflowUpdateData: WorkflowUpdateData, options?: any) {
        return WorkflowApiFp(this.configuration).updateWorkflowData(workflowId, workflowUpdateData, options)(this.axios, this.basePath);
    }

}


/**
 * WorkflowJobApi - axios parameter creator
 * @export
 */
export const WorkflowJobApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Execute a job by id
         * @summary Execute a workflow job by id
         * @param {string} jobId Job identifier (runtime)
         * @param {string} engineId EngineId to communicate with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeWorkflowJobById(jobId: string, engineId: string, options: any = {}): RequestArgs {
            // verify required parameter 'jobId' is not null or undefined
            if (jobId === null || jobId === undefined) {
                throw new RequiredError('jobId','Required parameter jobId was null or undefined when calling executeWorkflowJobById.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling executeWorkflowJobById.');
            }
            const localVarPath = `/workflow-jobs/{jobId}/do-execute`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find message subscriptions with criteria
         * @summary Get message subscriptions with criteria
         * @param {string} messageName Message event name
         * @param {string} [businessKey] Business key of process
         * @param {Array<string>} [workspaceIds] Workspace Identifier
         * @param {Array<string>} [engineIds] Engine IDs filter (IMPORTANT: If engineIds size is greater than 1, sort and pagination will be sent to each engine)
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMessageSubscriptions(messageName: string, businessKey?: string, workspaceIds?: Array<string>, engineIds?: Array<string>, pageNumber?: number, pageSize?: number, options: any = {}): RequestArgs {
            // verify required parameter 'messageName' is not null or undefined
            if (messageName === null || messageName === undefined) {
                throw new RequiredError('messageName','Required parameter messageName was null or undefined when calling findMessageSubscriptions.');
            }
            const localVarPath = `/workflow-jobs/message-subscriptions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (businessKey !== undefined) {
                localVarQueryParameter['businessKey'] = businessKey;
            }

            if (messageName !== undefined) {
                localVarQueryParameter['messageName'] = messageName;
            }

            if (workspaceIds) {
                localVarQueryParameter['workspaceIds'] = workspaceIds;
            }

            if (engineIds) {
                localVarQueryParameter['engineIds'] = engineIds;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find workflow jobs with filters
         * @summary Find workflow jobs with filters, scope will be limited to workspaces managed by the user
         * @param {WorkflowJobState} state Filtering by workflow job state
         * @param {string} [businessKey] Business Key of the process if you want to target a specific one
         * @param {string} [eventType] Event type, you want to search|| timers and messages are the two event types available
         * @param {string} [processDefinitionKey] Process name if you want to target a specific one
         * @param {Array<string>} [workspaceIds] Workspace Identifier
         * @param {Array<string>} [engineIds] Engine IDs filter (IMPORTANT: If engineIds size is greater than 1, sort and pagination will be sent to each engine)
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorkflowJobs(state: WorkflowJobState, businessKey?: string, eventType?: string, processDefinitionKey?: string, workspaceIds?: Array<string>, engineIds?: Array<string>, pageNumber?: number, pageSize?: number, options: any = {}): RequestArgs {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state','Required parameter state was null or undefined when calling findWorkflowJobs.');
            }
            const localVarPath = `/workflow-jobs`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (businessKey !== undefined) {
                localVarQueryParameter['businessKey'] = businessKey;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['eventType'] = eventType;
            }

            if (processDefinitionKey !== undefined) {
                localVarQueryParameter['processDefinitionKey'] = processDefinitionKey;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (workspaceIds) {
                localVarQueryParameter['workspaceIds'] = workspaceIds;
            }

            if (engineIds) {
                localVarQueryParameter['engineIds'] = engineIds;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Increment the number of retries for failed jobs
         * @summary Set retries at 1 for all failed jobs
         * @param {WorkflowJobIncrementRetries} workflowJobIncrementRetries 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementRetriesForFailedJobs(workflowJobIncrementRetries: WorkflowJobIncrementRetries, options: any = {}): RequestArgs {
            // verify required parameter 'workflowJobIncrementRetries' is not null or undefined
            if (workflowJobIncrementRetries === null || workflowJobIncrementRetries === undefined) {
                throw new RequiredError('workflowJobIncrementRetries','Required parameter workflowJobIncrementRetries was null or undefined when calling incrementRetriesForFailedJobs.');
            }
            const localVarPath = `/workflow-jobs/do-increment-retries`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowJobIncrementRetries !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowJobIncrementRetries !== undefined ? workflowJobIncrementRetries : {}) : (workflowJobIncrementRetries || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send a message event to processes.
         * @summary Send a message event to processes
         * @param {MessageEventSendRequest} messageEventSendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessageEvent(messageEventSendRequest: MessageEventSendRequest, options: any = {}): RequestArgs {
            // verify required parameter 'messageEventSendRequest' is not null or undefined
            if (messageEventSendRequest === null || messageEventSendRequest === undefined) {
                throw new RequiredError('messageEventSendRequest','Required parameter messageEventSendRequest was null or undefined when calling sendMessageEvent.');
            }
            const localVarPath = `/workflow-jobs/do-send-message`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof messageEventSendRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(messageEventSendRequest !== undefined ? messageEventSendRequest : {}) : (messageEventSendRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowJobApi - functional programming interface
 * @export
 */
export const WorkflowJobApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Execute a job by id
         * @summary Execute a workflow job by id
         * @param {string} jobId Job identifier (runtime)
         * @param {string} engineId EngineId to communicate with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeWorkflowJobById(jobId: string, engineId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WorkflowJobApiAxiosParamCreator(configuration).executeWorkflowJobById(jobId, engineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Find message subscriptions with criteria
         * @summary Get message subscriptions with criteria
         * @param {string} messageName Message event name
         * @param {string} [businessKey] Business key of process
         * @param {Array<string>} [workspaceIds] Workspace Identifier
         * @param {Array<string>} [engineIds] Engine IDs filter (IMPORTANT: If engineIds size is greater than 1, sort and pagination will be sent to each engine)
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMessageSubscriptions(messageName: string, businessKey?: string, workspaceIds?: Array<string>, engineIds?: Array<string>, pageNumber?: number, pageSize?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfEventSubscription> {
            const localVarAxiosArgs = WorkflowJobApiAxiosParamCreator(configuration).findMessageSubscriptions(messageName, businessKey, workspaceIds, engineIds, pageNumber, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Find workflow jobs with filters
         * @summary Find workflow jobs with filters, scope will be limited to workspaces managed by the user
         * @param {WorkflowJobState} state Filtering by workflow job state
         * @param {string} [businessKey] Business Key of the process if you want to target a specific one
         * @param {string} [eventType] Event type, you want to search|| timers and messages are the two event types available
         * @param {string} [processDefinitionKey] Process name if you want to target a specific one
         * @param {Array<string>} [workspaceIds] Workspace Identifier
         * @param {Array<string>} [engineIds] Engine IDs filter (IMPORTANT: If engineIds size is greater than 1, sort and pagination will be sent to each engine)
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorkflowJobs(state: WorkflowJobState, businessKey?: string, eventType?: string, processDefinitionKey?: string, workspaceIds?: Array<string>, engineIds?: Array<string>, pageNumber?: number, pageSize?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfWorkflowJob> {
            const localVarAxiosArgs = WorkflowJobApiAxiosParamCreator(configuration).findWorkflowJobs(state, businessKey, eventType, processDefinitionKey, workspaceIds, engineIds, pageNumber, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Increment the number of retries for failed jobs
         * @summary Set retries at 1 for all failed jobs
         * @param {WorkflowJobIncrementRetries} workflowJobIncrementRetries 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementRetriesForFailedJobs(workflowJobIncrementRetries: WorkflowJobIncrementRetries, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WorkflowJobApiAxiosParamCreator(configuration).incrementRetriesForFailedJobs(workflowJobIncrementRetries, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Send a message event to processes.
         * @summary Send a message event to processes
         * @param {MessageEventSendRequest} messageEventSendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessageEvent(messageEventSendRequest: MessageEventSendRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WorkflowJobApiAxiosParamCreator(configuration).sendMessageEvent(messageEventSendRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkflowJobApi - factory interface
 * @export
 */
export const WorkflowJobApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Execute a job by id
         * @summary Execute a workflow job by id
         * @param {string} jobId Job identifier (runtime)
         * @param {string} engineId EngineId to communicate with
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeWorkflowJobById(jobId: string, engineId: string, options?: any): AxiosPromise<void> {
            return WorkflowJobApiFp(configuration).executeWorkflowJobById(jobId, engineId, options)(axios, basePath);
        },
        /**
         * Find message subscriptions with criteria
         * @summary Get message subscriptions with criteria
         * @param {string} messageName Message event name
         * @param {string} [businessKey] Business key of process
         * @param {Array<string>} [workspaceIds] Workspace Identifier
         * @param {Array<string>} [engineIds] Engine IDs filter (IMPORTANT: If engineIds size is greater than 1, sort and pagination will be sent to each engine)
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMessageSubscriptions(messageName: string, businessKey?: string, workspaceIds?: Array<string>, engineIds?: Array<string>, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<PageOfEventSubscription> {
            return WorkflowJobApiFp(configuration).findMessageSubscriptions(messageName, businessKey, workspaceIds, engineIds, pageNumber, pageSize, options)(axios, basePath);
        },
        /**
         * Find workflow jobs with filters
         * @summary Find workflow jobs with filters, scope will be limited to workspaces managed by the user
         * @param {WorkflowJobState} state Filtering by workflow job state
         * @param {string} [businessKey] Business Key of the process if you want to target a specific one
         * @param {string} [eventType] Event type, you want to search|| timers and messages are the two event types available
         * @param {string} [processDefinitionKey] Process name if you want to target a specific one
         * @param {Array<string>} [workspaceIds] Workspace Identifier
         * @param {Array<string>} [engineIds] Engine IDs filter (IMPORTANT: If engineIds size is greater than 1, sort and pagination will be sent to each engine)
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWorkflowJobs(state: WorkflowJobState, businessKey?: string, eventType?: string, processDefinitionKey?: string, workspaceIds?: Array<string>, engineIds?: Array<string>, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<PageOfWorkflowJob> {
            return WorkflowJobApiFp(configuration).findWorkflowJobs(state, businessKey, eventType, processDefinitionKey, workspaceIds, engineIds, pageNumber, pageSize, options)(axios, basePath);
        },
        /**
         * Increment the number of retries for failed jobs
         * @summary Set retries at 1 for all failed jobs
         * @param {WorkflowJobIncrementRetries} workflowJobIncrementRetries 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementRetriesForFailedJobs(workflowJobIncrementRetries: WorkflowJobIncrementRetries, options?: any): AxiosPromise<void> {
            return WorkflowJobApiFp(configuration).incrementRetriesForFailedJobs(workflowJobIncrementRetries, options)(axios, basePath);
        },
        /**
         * Send a message event to processes.
         * @summary Send a message event to processes
         * @param {MessageEventSendRequest} messageEventSendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessageEvent(messageEventSendRequest: MessageEventSendRequest, options?: any): AxiosPromise<void> {
            return WorkflowJobApiFp(configuration).sendMessageEvent(messageEventSendRequest, options)(axios, basePath);
        },
    };
};

/**
 * WorkflowJobApi - object-oriented interface
 * @export
 * @class WorkflowJobApi
 * @extends {BaseAPI}
 */
export class WorkflowJobApi extends BaseAPI {
    /**
     * Execute a job by id
     * @summary Execute a workflow job by id
     * @param {string} jobId Job identifier (runtime)
     * @param {string} engineId EngineId to communicate with
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowJobApi
     */
    public executeWorkflowJobById(jobId: string, engineId: string, options?: any) {
        return WorkflowJobApiFp(this.configuration).executeWorkflowJobById(jobId, engineId, options)(this.axios, this.basePath);
    }

    /**
     * Find message subscriptions with criteria
     * @summary Get message subscriptions with criteria
     * @param {string} messageName Message event name
     * @param {string} [businessKey] Business key of process
     * @param {Array<string>} [workspaceIds] Workspace Identifier
     * @param {Array<string>} [engineIds] Engine IDs filter (IMPORTANT: If engineIds size is greater than 1, sort and pagination will be sent to each engine)
     * @param {number} [pageNumber] Number of the page
     * @param {number} [pageSize] Number of elements in the page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowJobApi
     */
    public findMessageSubscriptions(messageName: string, businessKey?: string, workspaceIds?: Array<string>, engineIds?: Array<string>, pageNumber?: number, pageSize?: number, options?: any) {
        return WorkflowJobApiFp(this.configuration).findMessageSubscriptions(messageName, businessKey, workspaceIds, engineIds, pageNumber, pageSize, options)(this.axios, this.basePath);
    }

    /**
     * Find workflow jobs with filters
     * @summary Find workflow jobs with filters, scope will be limited to workspaces managed by the user
     * @param {WorkflowJobState} state Filtering by workflow job state
     * @param {string} [businessKey] Business Key of the process if you want to target a specific one
     * @param {string} [eventType] Event type, you want to search|| timers and messages are the two event types available
     * @param {string} [processDefinitionKey] Process name if you want to target a specific one
     * @param {Array<string>} [workspaceIds] Workspace Identifier
     * @param {Array<string>} [engineIds] Engine IDs filter (IMPORTANT: If engineIds size is greater than 1, sort and pagination will be sent to each engine)
     * @param {number} [pageNumber] Number of the page
     * @param {number} [pageSize] Number of elements in the page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowJobApi
     */
    public findWorkflowJobs(state: WorkflowJobState, businessKey?: string, eventType?: string, processDefinitionKey?: string, workspaceIds?: Array<string>, engineIds?: Array<string>, pageNumber?: number, pageSize?: number, options?: any) {
        return WorkflowJobApiFp(this.configuration).findWorkflowJobs(state, businessKey, eventType, processDefinitionKey, workspaceIds, engineIds, pageNumber, pageSize, options)(this.axios, this.basePath);
    }

    /**
     * Increment the number of retries for failed jobs
     * @summary Set retries at 1 for all failed jobs
     * @param {WorkflowJobIncrementRetries} workflowJobIncrementRetries 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowJobApi
     */
    public incrementRetriesForFailedJobs(workflowJobIncrementRetries: WorkflowJobIncrementRetries, options?: any) {
        return WorkflowJobApiFp(this.configuration).incrementRetriesForFailedJobs(workflowJobIncrementRetries, options)(this.axios, this.basePath);
    }

    /**
     * Send a message event to processes.
     * @summary Send a message event to processes
     * @param {MessageEventSendRequest} messageEventSendRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowJobApi
     */
    public sendMessageEvent(messageEventSendRequest: MessageEventSendRequest, options?: any) {
        return WorkflowJobApiFp(this.configuration).sendMessageEvent(messageEventSendRequest, options)(this.axios, this.basePath);
    }

}


/**
 * WorkflowMetadataApi - axios parameter creator
 * @export
 */
export const WorkflowMetadataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Workflow Metadata
         * @param {WorkflowMetadataCreation} workflowMetadataCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowMetadata(workflowMetadataCreation: WorkflowMetadataCreation, options: any = {}): RequestArgs {
            // verify required parameter 'workflowMetadataCreation' is not null or undefined
            if (workflowMetadataCreation === null || workflowMetadataCreation === undefined) {
                throw new RequiredError('workflowMetadataCreation','Required parameter workflowMetadataCreation was null or undefined when calling createWorkflowMetadata.');
            }
            const localVarPath = `/workflow-metadatas`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowMetadataCreation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowMetadataCreation !== undefined ? workflowMetadataCreation : {}) : (workflowMetadataCreation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Workflow Metadata
         * @param {string} metadataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowMetadata(metadataId: string, options: any = {}): RequestArgs {
            // verify required parameter 'metadataId' is not null or undefined
            if (metadataId === null || metadataId === undefined) {
                throw new RequiredError('metadataId','Required parameter metadataId was null or undefined when calling deleteWorkflowMetadata.');
            }
            const localVarPath = `/workflow-metadatas/{metadataId}`
                .replace(`{${"metadataId"}}`, encodeURIComponent(String(metadataId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the list of Workflow Metadata
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {Array<string>} [workspaceIdIn] Keep only workflows with a workspaceId present in that list
         * @param {string} [workflowName] The Name of the workflow
         * @param {string} [engineId] Engine ID: The ID of the engine that should receive the start request
         * @param {Array<WorkflowLifeCycle>} [lifeCycle] The life cycle state of the workflows
         * @param {boolean} [showOnlyManaged] Only managed workflows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflowMetadatas(pageNumber?: number, pageSize?: number, sort?: Array<string>, workspaceIdIn?: Array<string>, workflowName?: string, engineId?: string, lifeCycle?: Array<WorkflowLifeCycle>, showOnlyManaged?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/workflow-metadatas`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (workspaceIdIn) {
                localVarQueryParameter['workspaceIdIn'] = workspaceIdIn;
            }

            if (workflowName !== undefined) {
                localVarQueryParameter['workflowName'] = workflowName;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (lifeCycle) {
                localVarQueryParameter['lifeCycle'] = lifeCycle;
            }

            if (showOnlyManaged !== undefined) {
                localVarQueryParameter['showOnlyManaged'] = showOnlyManaged;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a Workflow Metadata
         * @param {string} metadataId 
         * @param {WorkflowMetadata} workflowMetadata 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceWorkflowMetadata(metadataId: string, workflowMetadata: WorkflowMetadata, options: any = {}): RequestArgs {
            // verify required parameter 'metadataId' is not null or undefined
            if (metadataId === null || metadataId === undefined) {
                throw new RequiredError('metadataId','Required parameter metadataId was null or undefined when calling replaceWorkflowMetadata.');
            }
            // verify required parameter 'workflowMetadata' is not null or undefined
            if (workflowMetadata === null || workflowMetadata === undefined) {
                throw new RequiredError('workflowMetadata','Required parameter workflowMetadata was null or undefined when calling replaceWorkflowMetadata.');
            }
            const localVarPath = `/workflow-metadatas/{metadataId}`
                .replace(`{${"metadataId"}}`, encodeURIComponent(String(metadataId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowMetadata !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowMetadata !== undefined ? workflowMetadata : {}) : (workflowMetadata || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a Workflow Metadata
         * @param {string} metadataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWorkflowMetadataById(metadataId: string, options: any = {}): RequestArgs {
            // verify required parameter 'metadataId' is not null or undefined
            if (metadataId === null || metadataId === undefined) {
                throw new RequiredError('metadataId','Required parameter metadataId was null or undefined when calling retrieveWorkflowMetadataById.');
            }
            const localVarPath = `/workflow-metadatas/{metadataId}`
                .replace(`{${"metadataId"}}`, encodeURIComponent(String(metadataId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Workflow Metadata
         * @param {string} metadataId 
         * @param {WorkflowUpdateMetadata} workflowUpdateMetadata All fields are optional, but at least one must be given. Only given fields will be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowMetadata(metadataId: string, workflowUpdateMetadata: WorkflowUpdateMetadata, options: any = {}): RequestArgs {
            // verify required parameter 'metadataId' is not null or undefined
            if (metadataId === null || metadataId === undefined) {
                throw new RequiredError('metadataId','Required parameter metadataId was null or undefined when calling updateWorkflowMetadata.');
            }
            // verify required parameter 'workflowUpdateMetadata' is not null or undefined
            if (workflowUpdateMetadata === null || workflowUpdateMetadata === undefined) {
                throw new RequiredError('workflowUpdateMetadata','Required parameter workflowUpdateMetadata was null or undefined when calling updateWorkflowMetadata.');
            }
            const localVarPath = `/workflow-metadatas/{metadataId}`
                .replace(`{${"metadataId"}}`, encodeURIComponent(String(metadataId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowUpdateMetadata !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowUpdateMetadata !== undefined ? workflowUpdateMetadata : {}) : (workflowUpdateMetadata || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowMetadataApi - functional programming interface
 * @export
 */
export const WorkflowMetadataApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Workflow Metadata
         * @param {WorkflowMetadataCreation} workflowMetadataCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowMetadata(workflowMetadataCreation: WorkflowMetadataCreation, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowMetadata> {
            const localVarAxiosArgs = WorkflowMetadataApiAxiosParamCreator(configuration).createWorkflowMetadata(workflowMetadataCreation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a Workflow Metadata
         * @param {string} metadataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowMetadata(metadataId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WorkflowMetadataApiAxiosParamCreator(configuration).deleteWorkflowMetadata(metadataId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve the list of Workflow Metadata
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {Array<string>} [workspaceIdIn] Keep only workflows with a workspaceId present in that list
         * @param {string} [workflowName] The Name of the workflow
         * @param {string} [engineId] Engine ID: The ID of the engine that should receive the start request
         * @param {Array<WorkflowLifeCycle>} [lifeCycle] The life cycle state of the workflows
         * @param {boolean} [showOnlyManaged] Only managed workflows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflowMetadatas(pageNumber?: number, pageSize?: number, sort?: Array<string>, workspaceIdIn?: Array<string>, workflowName?: string, engineId?: string, lifeCycle?: Array<WorkflowLifeCycle>, showOnlyManaged?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfWorkflowMetadata> {
            const localVarAxiosArgs = WorkflowMetadataApiAxiosParamCreator(configuration).listWorkflowMetadatas(pageNumber, pageSize, sort, workspaceIdIn, workflowName, engineId, lifeCycle, showOnlyManaged, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Replace a Workflow Metadata
         * @param {string} metadataId 
         * @param {WorkflowMetadata} workflowMetadata 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceWorkflowMetadata(metadataId: string, workflowMetadata: WorkflowMetadata, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowMetadata> {
            const localVarAxiosArgs = WorkflowMetadataApiAxiosParamCreator(configuration).replaceWorkflowMetadata(metadataId, workflowMetadata, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a Workflow Metadata
         * @param {string} metadataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWorkflowMetadataById(metadataId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowMetadata> {
            const localVarAxiosArgs = WorkflowMetadataApiAxiosParamCreator(configuration).retrieveWorkflowMetadataById(metadataId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Workflow Metadata
         * @param {string} metadataId 
         * @param {WorkflowUpdateMetadata} workflowUpdateMetadata All fields are optional, but at least one must be given. Only given fields will be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowMetadata(metadataId: string, workflowUpdateMetadata: WorkflowUpdateMetadata, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WorkflowMetadataApiAxiosParamCreator(configuration).updateWorkflowMetadata(metadataId, workflowUpdateMetadata, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkflowMetadataApi - factory interface
 * @export
 */
export const WorkflowMetadataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a Workflow Metadata
         * @param {WorkflowMetadataCreation} workflowMetadataCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowMetadata(workflowMetadataCreation: WorkflowMetadataCreation, options?: any): AxiosPromise<WorkflowMetadata> {
            return WorkflowMetadataApiFp(configuration).createWorkflowMetadata(workflowMetadataCreation, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a Workflow Metadata
         * @param {string} metadataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowMetadata(metadataId: string, options?: any): AxiosPromise<void> {
            return WorkflowMetadataApiFp(configuration).deleteWorkflowMetadata(metadataId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve the list of Workflow Metadata
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {Array<string>} [workspaceIdIn] Keep only workflows with a workspaceId present in that list
         * @param {string} [workflowName] The Name of the workflow
         * @param {string} [engineId] Engine ID: The ID of the engine that should receive the start request
         * @param {Array<WorkflowLifeCycle>} [lifeCycle] The life cycle state of the workflows
         * @param {boolean} [showOnlyManaged] Only managed workflows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflowMetadatas(pageNumber?: number, pageSize?: number, sort?: Array<string>, workspaceIdIn?: Array<string>, workflowName?: string, engineId?: string, lifeCycle?: Array<WorkflowLifeCycle>, showOnlyManaged?: boolean, options?: any): AxiosPromise<PageOfWorkflowMetadata> {
            return WorkflowMetadataApiFp(configuration).listWorkflowMetadatas(pageNumber, pageSize, sort, workspaceIdIn, workflowName, engineId, lifeCycle, showOnlyManaged, options)(axios, basePath);
        },
        /**
         * 
         * @summary Replace a Workflow Metadata
         * @param {string} metadataId 
         * @param {WorkflowMetadata} workflowMetadata 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceWorkflowMetadata(metadataId: string, workflowMetadata: WorkflowMetadata, options?: any): AxiosPromise<WorkflowMetadata> {
            return WorkflowMetadataApiFp(configuration).replaceWorkflowMetadata(metadataId, workflowMetadata, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve a Workflow Metadata
         * @param {string} metadataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWorkflowMetadataById(metadataId: string, options?: any): AxiosPromise<WorkflowMetadata> {
            return WorkflowMetadataApiFp(configuration).retrieveWorkflowMetadataById(metadataId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update Workflow Metadata
         * @param {string} metadataId 
         * @param {WorkflowUpdateMetadata} workflowUpdateMetadata All fields are optional, but at least one must be given. Only given fields will be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowMetadata(metadataId: string, workflowUpdateMetadata: WorkflowUpdateMetadata, options?: any): AxiosPromise<void> {
            return WorkflowMetadataApiFp(configuration).updateWorkflowMetadata(metadataId, workflowUpdateMetadata, options)(axios, basePath);
        },
    };
};

/**
 * WorkflowMetadataApi - object-oriented interface
 * @export
 * @class WorkflowMetadataApi
 * @extends {BaseAPI}
 */
export class WorkflowMetadataApi extends BaseAPI {
    /**
     * 
     * @summary Create a Workflow Metadata
     * @param {WorkflowMetadataCreation} workflowMetadataCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowMetadataApi
     */
    public createWorkflowMetadata(workflowMetadataCreation: WorkflowMetadataCreation, options?: any) {
        return WorkflowMetadataApiFp(this.configuration).createWorkflowMetadata(workflowMetadataCreation, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a Workflow Metadata
     * @param {string} metadataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowMetadataApi
     */
    public deleteWorkflowMetadata(metadataId: string, options?: any) {
        return WorkflowMetadataApiFp(this.configuration).deleteWorkflowMetadata(metadataId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve the list of Workflow Metadata
     * @param {number} [pageNumber] Number of the page
     * @param {number} [pageSize] Number of elements in the page
     * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
     * @param {Array<string>} [workspaceIdIn] Keep only workflows with a workspaceId present in that list
     * @param {string} [workflowName] The Name of the workflow
     * @param {string} [engineId] Engine ID: The ID of the engine that should receive the start request
     * @param {Array<WorkflowLifeCycle>} [lifeCycle] The life cycle state of the workflows
     * @param {boolean} [showOnlyManaged] Only managed workflows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowMetadataApi
     */
    public listWorkflowMetadatas(pageNumber?: number, pageSize?: number, sort?: Array<string>, workspaceIdIn?: Array<string>, workflowName?: string, engineId?: string, lifeCycle?: Array<WorkflowLifeCycle>, showOnlyManaged?: boolean, options?: any) {
        return WorkflowMetadataApiFp(this.configuration).listWorkflowMetadatas(pageNumber, pageSize, sort, workspaceIdIn, workflowName, engineId, lifeCycle, showOnlyManaged, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Replace a Workflow Metadata
     * @param {string} metadataId 
     * @param {WorkflowMetadata} workflowMetadata 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowMetadataApi
     */
    public replaceWorkflowMetadata(metadataId: string, workflowMetadata: WorkflowMetadata, options?: any) {
        return WorkflowMetadataApiFp(this.configuration).replaceWorkflowMetadata(metadataId, workflowMetadata, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve a Workflow Metadata
     * @param {string} metadataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowMetadataApi
     */
    public retrieveWorkflowMetadataById(metadataId: string, options?: any) {
        return WorkflowMetadataApiFp(this.configuration).retrieveWorkflowMetadataById(metadataId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update Workflow Metadata
     * @param {string} metadataId 
     * @param {WorkflowUpdateMetadata} workflowUpdateMetadata All fields are optional, but at least one must be given. Only given fields will be updated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowMetadataApi
     */
    public updateWorkflowMetadata(metadataId: string, workflowUpdateMetadata: WorkflowUpdateMetadata, options?: any) {
        return WorkflowMetadataApiFp(this.configuration).updateWorkflowMetadata(metadataId, workflowUpdateMetadata, options)(this.axios, this.basePath);
    }

}


/**
 * WorkflowVersionApi - axios parameter creator
 * @export
 */
export const WorkflowVersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Workflow Version
         * @param {string} metadataId 
         * @param {WorkflowVersionCreation} workflowVersionCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowVersion(metadataId: string, workflowVersionCreation: WorkflowVersionCreation, options: any = {}): RequestArgs {
            // verify required parameter 'metadataId' is not null or undefined
            if (metadataId === null || metadataId === undefined) {
                throw new RequiredError('metadataId','Required parameter metadataId was null or undefined when calling createWorkflowVersion.');
            }
            // verify required parameter 'workflowVersionCreation' is not null or undefined
            if (workflowVersionCreation === null || workflowVersionCreation === undefined) {
                throw new RequiredError('workflowVersionCreation','Required parameter workflowVersionCreation was null or undefined when calling createWorkflowVersion.');
            }
            const localVarPath = `/workflow-metadatas/{metadataId}/versions`
                .replace(`{${"metadataId"}}`, encodeURIComponent(String(metadataId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowVersionCreation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowVersionCreation !== undefined ? workflowVersionCreation : {}) : (workflowVersionCreation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import a Workflow Version
         * @param {string} metadataId 
         * @param {WorkflowVersionImportParams} params 
         * @param {any} [file] Required if the model content is imported from a file (single file or zip)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importWorkflowVersion(metadataId: string, params: WorkflowVersionImportParams, file?: any, options: any = {}): RequestArgs {
            // verify required parameter 'metadataId' is not null or undefined
            if (metadataId === null || metadataId === undefined) {
                throw new RequiredError('metadataId','Required parameter metadataId was null or undefined when calling importWorkflowVersion.');
            }
            // verify required parameter 'params' is not null or undefined
            if (params === null || params === undefined) {
                throw new RequiredError('params','Required parameter params was null or undefined when calling importWorkflowVersion.');
            }
            const localVarPath = `/workflow-metadatas/{metadataId}/do-import-version`
                .replace(`{${"metadataId"}}`, encodeURIComponent(String(metadataId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (params !== undefined) { 
                localVarFormParams.append('params', params as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the list of Workflow Versions
         * @param {string} metadataId 
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {number} [version] Retrieves only workflow versions with a specific version
         * @param {string} [deploymentId] Retrieves only workflow versions with a specific deploymentId 
         * @param {boolean} [showDisabled] Workflow version enabled (true|false). By Default retrieves only enabled workflow version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflowVersions(metadataId: string, pageNumber?: number, pageSize?: number, sort?: Array<string>, version?: number, deploymentId?: string, showDisabled?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'metadataId' is not null or undefined
            if (metadataId === null || metadataId === undefined) {
                throw new RequiredError('metadataId','Required parameter metadataId was null or undefined when calling listWorkflowVersions.');
            }
            const localVarPath = `/workflow-metadatas/{metadataId}/versions`
                .replace(`{${"metadataId"}}`, encodeURIComponent(String(metadataId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (deploymentId !== undefined) {
                localVarQueryParameter['deploymentId'] = deploymentId;
            }

            if (showDisabled !== undefined) {
                localVarQueryParameter['showDisabled'] = showDisabled;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a Workflow Version
         * @param {string} workflowVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWorkflowVersionById(workflowVersionId: string, options: any = {}): RequestArgs {
            // verify required parameter 'workflowVersionId' is not null or undefined
            if (workflowVersionId === null || workflowVersionId === undefined) {
                throw new RequiredError('workflowVersionId','Required parameter workflowVersionId was null or undefined when calling retrieveWorkflowVersionById.');
            }
            const localVarPath = `/workflow-versions/{workflowVersionId}`
                .replace(`{${"workflowVersionId"}}`, encodeURIComponent(String(workflowVersionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update some Workflow Version data
         * @param {string} workflowVersionId 
         * @param {WorkflowVersionUpdateData} workflowVersionUpdateData All fields are optional, but at least one must be given. Only given fields will be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowVersionData(workflowVersionId: string, workflowVersionUpdateData: WorkflowVersionUpdateData, options: any = {}): RequestArgs {
            // verify required parameter 'workflowVersionId' is not null or undefined
            if (workflowVersionId === null || workflowVersionId === undefined) {
                throw new RequiredError('workflowVersionId','Required parameter workflowVersionId was null or undefined when calling updateWorkflowVersionData.');
            }
            // verify required parameter 'workflowVersionUpdateData' is not null or undefined
            if (workflowVersionUpdateData === null || workflowVersionUpdateData === undefined) {
                throw new RequiredError('workflowVersionUpdateData','Required parameter workflowVersionUpdateData was null or undefined when calling updateWorkflowVersionData.');
            }
            const localVarPath = `/workflow-versions/{workflowVersionId}`
                .replace(`{${"workflowVersionId"}}`, encodeURIComponent(String(workflowVersionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workflowVersionUpdateData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workflowVersionUpdateData !== undefined ? workflowVersionUpdateData : {}) : (workflowVersionUpdateData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowVersionApi - functional programming interface
 * @export
 */
export const WorkflowVersionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Workflow Version
         * @param {string} metadataId 
         * @param {WorkflowVersionCreation} workflowVersionCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowVersion(metadataId: string, workflowVersionCreation: WorkflowVersionCreation, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowVersion> {
            const localVarAxiosArgs = WorkflowVersionApiAxiosParamCreator(configuration).createWorkflowVersion(metadataId, workflowVersionCreation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Import a Workflow Version
         * @param {string} metadataId 
         * @param {WorkflowVersionImportParams} params 
         * @param {any} [file] Required if the model content is imported from a file (single file or zip)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importWorkflowVersion(metadataId: string, params: WorkflowVersionImportParams, file?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowVersion> {
            const localVarAxiosArgs = WorkflowVersionApiAxiosParamCreator(configuration).importWorkflowVersion(metadataId, params, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve the list of Workflow Versions
         * @param {string} metadataId 
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {number} [version] Retrieves only workflow versions with a specific version
         * @param {string} [deploymentId] Retrieves only workflow versions with a specific deploymentId 
         * @param {boolean} [showDisabled] Workflow version enabled (true|false). By Default retrieves only enabled workflow version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflowVersions(metadataId: string, pageNumber?: number, pageSize?: number, sort?: Array<string>, version?: number, deploymentId?: string, showDisabled?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfWorkflowVersion> {
            const localVarAxiosArgs = WorkflowVersionApiAxiosParamCreator(configuration).listWorkflowVersions(metadataId, pageNumber, pageSize, sort, version, deploymentId, showDisabled, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a Workflow Version
         * @param {string} workflowVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWorkflowVersionById(workflowVersionId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowVersion> {
            const localVarAxiosArgs = WorkflowVersionApiAxiosParamCreator(configuration).retrieveWorkflowVersionById(workflowVersionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update some Workflow Version data
         * @param {string} workflowVersionId 
         * @param {WorkflowVersionUpdateData} workflowVersionUpdateData All fields are optional, but at least one must be given. Only given fields will be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowVersionData(workflowVersionId: string, workflowVersionUpdateData: WorkflowVersionUpdateData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WorkflowVersionApiAxiosParamCreator(configuration).updateWorkflowVersionData(workflowVersionId, workflowVersionUpdateData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkflowVersionApi - factory interface
 * @export
 */
export const WorkflowVersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a Workflow Version
         * @param {string} metadataId 
         * @param {WorkflowVersionCreation} workflowVersionCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowVersion(metadataId: string, workflowVersionCreation: WorkflowVersionCreation, options?: any): AxiosPromise<WorkflowVersion> {
            return WorkflowVersionApiFp(configuration).createWorkflowVersion(metadataId, workflowVersionCreation, options)(axios, basePath);
        },
        /**
         * 
         * @summary Import a Workflow Version
         * @param {string} metadataId 
         * @param {WorkflowVersionImportParams} params 
         * @param {any} [file] Required if the model content is imported from a file (single file or zip)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importWorkflowVersion(metadataId: string, params: WorkflowVersionImportParams, file?: any, options?: any): AxiosPromise<WorkflowVersion> {
            return WorkflowVersionApiFp(configuration).importWorkflowVersion(metadataId, params, file, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve the list of Workflow Versions
         * @param {string} metadataId 
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {number} [version] Retrieves only workflow versions with a specific version
         * @param {string} [deploymentId] Retrieves only workflow versions with a specific deploymentId 
         * @param {boolean} [showDisabled] Workflow version enabled (true|false). By Default retrieves only enabled workflow version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkflowVersions(metadataId: string, pageNumber?: number, pageSize?: number, sort?: Array<string>, version?: number, deploymentId?: string, showDisabled?: boolean, options?: any): AxiosPromise<PageOfWorkflowVersion> {
            return WorkflowVersionApiFp(configuration).listWorkflowVersions(metadataId, pageNumber, pageSize, sort, version, deploymentId, showDisabled, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve a Workflow Version
         * @param {string} workflowVersionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWorkflowVersionById(workflowVersionId: string, options?: any): AxiosPromise<WorkflowVersion> {
            return WorkflowVersionApiFp(configuration).retrieveWorkflowVersionById(workflowVersionId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update some Workflow Version data
         * @param {string} workflowVersionId 
         * @param {WorkflowVersionUpdateData} workflowVersionUpdateData All fields are optional, but at least one must be given. Only given fields will be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowVersionData(workflowVersionId: string, workflowVersionUpdateData: WorkflowVersionUpdateData, options?: any): AxiosPromise<void> {
            return WorkflowVersionApiFp(configuration).updateWorkflowVersionData(workflowVersionId, workflowVersionUpdateData, options)(axios, basePath);
        },
    };
};

/**
 * WorkflowVersionApi - object-oriented interface
 * @export
 * @class WorkflowVersionApi
 * @extends {BaseAPI}
 */
export class WorkflowVersionApi extends BaseAPI {
    /**
     * 
     * @summary Create a Workflow Version
     * @param {string} metadataId 
     * @param {WorkflowVersionCreation} workflowVersionCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowVersionApi
     */
    public createWorkflowVersion(metadataId: string, workflowVersionCreation: WorkflowVersionCreation, options?: any) {
        return WorkflowVersionApiFp(this.configuration).createWorkflowVersion(metadataId, workflowVersionCreation, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Import a Workflow Version
     * @param {string} metadataId 
     * @param {WorkflowVersionImportParams} params 
     * @param {any} [file] Required if the model content is imported from a file (single file or zip)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowVersionApi
     */
    public importWorkflowVersion(metadataId: string, params: WorkflowVersionImportParams, file?: any, options?: any) {
        return WorkflowVersionApiFp(this.configuration).importWorkflowVersion(metadataId, params, file, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve the list of Workflow Versions
     * @param {string} metadataId 
     * @param {number} [pageNumber] Number of the page
     * @param {number} [pageSize] Number of elements in the page
     * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
     * @param {number} [version] Retrieves only workflow versions with a specific version
     * @param {string} [deploymentId] Retrieves only workflow versions with a specific deploymentId 
     * @param {boolean} [showDisabled] Workflow version enabled (true|false). By Default retrieves only enabled workflow version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowVersionApi
     */
    public listWorkflowVersions(metadataId: string, pageNumber?: number, pageSize?: number, sort?: Array<string>, version?: number, deploymentId?: string, showDisabled?: boolean, options?: any) {
        return WorkflowVersionApiFp(this.configuration).listWorkflowVersions(metadataId, pageNumber, pageSize, sort, version, deploymentId, showDisabled, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve a Workflow Version
     * @param {string} workflowVersionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowVersionApi
     */
    public retrieveWorkflowVersionById(workflowVersionId: string, options?: any) {
        return WorkflowVersionApiFp(this.configuration).retrieveWorkflowVersionById(workflowVersionId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update some Workflow Version data
     * @param {string} workflowVersionId 
     * @param {WorkflowVersionUpdateData} workflowVersionUpdateData All fields are optional, but at least one must be given. Only given fields will be updated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowVersionApi
     */
    public updateWorkflowVersionData(workflowVersionId: string, workflowVersionUpdateData: WorkflowVersionUpdateData, options?: any) {
        return WorkflowVersionApiFp(this.configuration).updateWorkflowVersionData(workflowVersionId, workflowVersionUpdateData, options)(this.axios, this.basePath);
    }

}


/**
 * WorkspaceApi - axios parameter creator
 * @export
 */
export const WorkspaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Workspaces
         * @param {Workspace} workspace 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspace(workspace: Workspace, options: any = {}): RequestArgs {
            // verify required parameter 'workspace' is not null or undefined
            if (workspace === null || workspace === undefined) {
                throw new RequiredError('workspace','Required parameter workspace was null or undefined when calling createWorkspace.');
            }
            const localVarPath = `/workspaces`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workspace !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workspace !== undefined ? workspace : {}) : (workspace || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Workspace
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspace(workspaceId: string, options: any = {}): RequestArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling deleteWorkspace.');
            }
            const localVarPath = `/workspaces/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  Retrieve statistics on workspace 
         * @param {string} workspaceName 
         * @param {WorkspaceStatisticsAggregationType} aggregationType Aggregation type, workspace or workflow
         * @param {Array<string>} [workflowNameIn] Keep only workflow with the specified workflow names present in that list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistics(workspaceName: string, aggregationType: WorkspaceStatisticsAggregationType, workflowNameIn?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'workspaceName' is not null or undefined
            if (workspaceName === null || workspaceName === undefined) {
                throw new RequiredError('workspaceName','Required parameter workspaceName was null or undefined when calling getStatistics.');
            }
            // verify required parameter 'aggregationType' is not null or undefined
            if (aggregationType === null || aggregationType === undefined) {
                throw new RequiredError('aggregationType','Required parameter aggregationType was null or undefined when calling getStatistics.');
            }
            const localVarPath = `/workspaces/do-compute-statistics`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (workspaceName !== undefined) {
                localVarQueryParameter['workspaceName'] = workspaceName;
            }

            if (workflowNameIn) {
                localVarQueryParameter['workflowNameIn'] = workflowNameIn;
            }

            if (aggregationType !== undefined) {
                localVarQueryParameter['aggregationType'] = aggregationType;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Audit details of a workspace
         * @param {string} workspaceId 
         * @param {string} [engineId] 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceAudit(workspaceId: string, engineId?: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling getWorkspaceAudit.');
            }
            const localVarPath = `/workspaces/{workspaceId}/audits`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (dateAfter !== undefined) {
                localVarQueryParameter['dateAfter'] = (dateAfter as any instanceof Date) ?
                    (dateAfter as any).toISOString() :
                    dateAfter;
            }

            if (dateBefore !== undefined) {
                localVarQueryParameter['dateBefore'] = (dateBefore as any instanceof Date) ?
                    (dateBefore as any).toISOString() :
                    dateBefore;
            }

            if (eventName !== undefined) {
                localVarQueryParameter['eventName'] = eventName;
            }

            if (performerId !== undefined) {
                localVarQueryParameter['performerId'] = performerId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the list of Workspaces
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {Array<string>} [slugIn] Keep only workspaces with a slug (URL friendly name) present in that list
         * @param {boolean} [showDisabled] Workspace enabled (true|false). By Default retrieves only enabled workspaces
         * @param {boolean} [showHiddenInDefaultUI] Workspace of tasks hidden on the SG-Workflow UI (true|false). By Default retrieves all workspaces
         * @param {boolean} [syncUser] Should the user be synchronized
         * @param {boolean} [showManagedWorkspaces] Workspace managed by user (true|false). By Default retrieves all workspaces
         * @param {Array<string>} [workspaceIdIn] Keep only workspaces with a workspaceIds present in that list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspaces(pageNumber?: number, pageSize?: number, sort?: Array<string>, slugIn?: Array<string>, showDisabled?: boolean, showHiddenInDefaultUI?: boolean, syncUser?: boolean, showManagedWorkspaces?: boolean, workspaceIdIn?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/workspaces`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (slugIn) {
                localVarQueryParameter['slugIn'] = slugIn;
            }

            if (showDisabled !== undefined) {
                localVarQueryParameter['showDisabled'] = showDisabled;
            }

            if (showHiddenInDefaultUI !== undefined) {
                localVarQueryParameter['showHiddenInDefaultUI'] = showHiddenInDefaultUI;
            }

            if (syncUser !== undefined) {
                localVarQueryParameter['syncUser'] = syncUser;
            }

            if (showManagedWorkspaces !== undefined) {
                localVarQueryParameter['showManagedWorkspaces'] = showManagedWorkspaces;
            }

            if (workspaceIdIn) {
                localVarQueryParameter['workspaceIdIn'] = workspaceIdIn;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the list of Workspaces without URL length limitation
         * @param {WorkspacesFindPostRequest} workspacesFindPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspacesPostRequest(workspacesFindPostRequest: WorkspacesFindPostRequest, options: any = {}): RequestArgs {
            // verify required parameter 'workspacesFindPostRequest' is not null or undefined
            if (workspacesFindPostRequest === null || workspacesFindPostRequest === undefined) {
                throw new RequiredError('workspacesFindPostRequest','Required parameter workspacesFindPostRequest was null or undefined when calling listWorkspacesPostRequest.');
            }
            const localVarPath = `/workspaces/find`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workspacesFindPostRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workspacesFindPostRequest !== undefined ? workspacesFindPostRequest : {}) : (workspacesFindPostRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a Workspace
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWorkspaceById(workspaceId: string, options: any = {}): RequestArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling retrieveWorkspaceById.');
            }
            const localVarPath = `/workspaces/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Workspace
         * @param {string} workspaceId 
         * @param {Workspace} workspace 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspace(workspaceId: string, workspace: Workspace, options: any = {}): RequestArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling updateWorkspace.');
            }
            // verify required parameter 'workspace' is not null or undefined
            if (workspace === null || workspace === undefined) {
                throw new RequiredError('workspace','Required parameter workspace was null or undefined when calling updateWorkspace.');
            }
            const localVarPath = `/workspaces/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workspace !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workspace !== undefined ? workspace : {}) : (workspace || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update some Workspace data
         * @param {string} workspaceId 
         * @param {WorkspaceUpdateData} workspaceUpdateData All fields are optional, but at least one must be given. Only given fields will be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceData(workspaceId: string, workspaceUpdateData: WorkspaceUpdateData, options: any = {}): RequestArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling updateWorkspaceData.');
            }
            // verify required parameter 'workspaceUpdateData' is not null or undefined
            if (workspaceUpdateData === null || workspaceUpdateData === undefined) {
                throw new RequiredError('workspaceUpdateData','Required parameter workspaceUpdateData was null or undefined when calling updateWorkspaceData.');
            }
            const localVarPath = `/workspaces/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workspaceUpdateData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workspaceUpdateData !== undefined ? workspaceUpdateData : {}) : (workspaceUpdateData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspaceApi - functional programming interface
 * @export
 */
export const WorkspaceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Workspaces
         * @param {Workspace} workspace 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspace(workspace: Workspace, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workspace> {
            const localVarAxiosArgs = WorkspaceApiAxiosParamCreator(configuration).createWorkspace(workspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a Workspace
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspace(workspaceId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WorkspaceApiAxiosParamCreator(configuration).deleteWorkspace(workspaceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary  Retrieve statistics on workspace 
         * @param {string} workspaceName 
         * @param {WorkspaceStatisticsAggregationType} aggregationType Aggregation type, workspace or workflow
         * @param {Array<string>} [workflowNameIn] Keep only workflow with the specified workflow names present in that list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistics(workspaceName: string, aggregationType: WorkspaceStatisticsAggregationType, workflowNameIn?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceStatisticsAggregation> {
            const localVarAxiosArgs = WorkspaceApiAxiosParamCreator(configuration).getStatistics(workspaceName, aggregationType, workflowNameIn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Audit details of a workspace
         * @param {string} workspaceId 
         * @param {string} [engineId] 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceAudit(workspaceId: string, engineId?: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditLog>> {
            const localVarAxiosArgs = WorkspaceApiAxiosParamCreator(configuration).getWorkspaceAudit(workspaceId, engineId, dateAfter, dateBefore, eventName, performerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve the list of Workspaces
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {Array<string>} [slugIn] Keep only workspaces with a slug (URL friendly name) present in that list
         * @param {boolean} [showDisabled] Workspace enabled (true|false). By Default retrieves only enabled workspaces
         * @param {boolean} [showHiddenInDefaultUI] Workspace of tasks hidden on the SG-Workflow UI (true|false). By Default retrieves all workspaces
         * @param {boolean} [syncUser] Should the user be synchronized
         * @param {boolean} [showManagedWorkspaces] Workspace managed by user (true|false). By Default retrieves all workspaces
         * @param {Array<string>} [workspaceIdIn] Keep only workspaces with a workspaceIds present in that list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspaces(pageNumber?: number, pageSize?: number, sort?: Array<string>, slugIn?: Array<string>, showDisabled?: boolean, showHiddenInDefaultUI?: boolean, syncUser?: boolean, showManagedWorkspaces?: boolean, workspaceIdIn?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfWorkspace> {
            const localVarAxiosArgs = WorkspaceApiAxiosParamCreator(configuration).listWorkspaces(pageNumber, pageSize, sort, slugIn, showDisabled, showHiddenInDefaultUI, syncUser, showManagedWorkspaces, workspaceIdIn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve the list of Workspaces without URL length limitation
         * @param {WorkspacesFindPostRequest} workspacesFindPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspacesPostRequest(workspacesFindPostRequest: WorkspacesFindPostRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfWorkspace> {
            const localVarAxiosArgs = WorkspaceApiAxiosParamCreator(configuration).listWorkspacesPostRequest(workspacesFindPostRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a Workspace
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWorkspaceById(workspaceId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workspace> {
            const localVarAxiosArgs = WorkspaceApiAxiosParamCreator(configuration).retrieveWorkspaceById(workspaceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a Workspace
         * @param {string} workspaceId 
         * @param {Workspace} workspace 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspace(workspaceId: string, workspace: Workspace, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workspace> {
            const localVarAxiosArgs = WorkspaceApiAxiosParamCreator(configuration).updateWorkspace(workspaceId, workspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update some Workspace data
         * @param {string} workspaceId 
         * @param {WorkspaceUpdateData} workspaceUpdateData All fields are optional, but at least one must be given. Only given fields will be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceData(workspaceId: string, workspaceUpdateData: WorkspaceUpdateData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WorkspaceApiAxiosParamCreator(configuration).updateWorkspaceData(workspaceId, workspaceUpdateData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkspaceApi - factory interface
 * @export
 */
export const WorkspaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a Workspaces
         * @param {Workspace} workspace 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkspace(workspace: Workspace, options?: any): AxiosPromise<Workspace> {
            return WorkspaceApiFp(configuration).createWorkspace(workspace, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a Workspace
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspace(workspaceId: string, options?: any): AxiosPromise<void> {
            return WorkspaceApiFp(configuration).deleteWorkspace(workspaceId, options)(axios, basePath);
        },
        /**
         * 
         * @summary  Retrieve statistics on workspace 
         * @param {string} workspaceName 
         * @param {WorkspaceStatisticsAggregationType} aggregationType Aggregation type, workspace or workflow
         * @param {Array<string>} [workflowNameIn] Keep only workflow with the specified workflow names present in that list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistics(workspaceName: string, aggregationType: WorkspaceStatisticsAggregationType, workflowNameIn?: Array<string>, options?: any): AxiosPromise<WorkspaceStatisticsAggregation> {
            return WorkspaceApiFp(configuration).getStatistics(workspaceName, aggregationType, workflowNameIn, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get Audit details of a workspace
         * @param {string} workspaceId 
         * @param {string} [engineId] 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceAudit(workspaceId: string, engineId?: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, options?: any): AxiosPromise<Array<AuditLog>> {
            return WorkspaceApiFp(configuration).getWorkspaceAudit(workspaceId, engineId, dateAfter, dateBefore, eventName, performerId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve the list of Workspaces
         * @param {number} [pageNumber] Number of the page
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {Array<string>} [slugIn] Keep only workspaces with a slug (URL friendly name) present in that list
         * @param {boolean} [showDisabled] Workspace enabled (true|false). By Default retrieves only enabled workspaces
         * @param {boolean} [showHiddenInDefaultUI] Workspace of tasks hidden on the SG-Workflow UI (true|false). By Default retrieves all workspaces
         * @param {boolean} [syncUser] Should the user be synchronized
         * @param {boolean} [showManagedWorkspaces] Workspace managed by user (true|false). By Default retrieves all workspaces
         * @param {Array<string>} [workspaceIdIn] Keep only workspaces with a workspaceIds present in that list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspaces(pageNumber?: number, pageSize?: number, sort?: Array<string>, slugIn?: Array<string>, showDisabled?: boolean, showHiddenInDefaultUI?: boolean, syncUser?: boolean, showManagedWorkspaces?: boolean, workspaceIdIn?: Array<string>, options?: any): AxiosPromise<PageOfWorkspace> {
            return WorkspaceApiFp(configuration).listWorkspaces(pageNumber, pageSize, sort, slugIn, showDisabled, showHiddenInDefaultUI, syncUser, showManagedWorkspaces, workspaceIdIn, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve the list of Workspaces without URL length limitation
         * @param {WorkspacesFindPostRequest} workspacesFindPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspacesPostRequest(workspacesFindPostRequest: WorkspacesFindPostRequest, options?: any): AxiosPromise<PageOfWorkspace> {
            return WorkspaceApiFp(configuration).listWorkspacesPostRequest(workspacesFindPostRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve a Workspace
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWorkspaceById(workspaceId: string, options?: any): AxiosPromise<Workspace> {
            return WorkspaceApiFp(configuration).retrieveWorkspaceById(workspaceId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update a Workspace
         * @param {string} workspaceId 
         * @param {Workspace} workspace 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspace(workspaceId: string, workspace: Workspace, options?: any): AxiosPromise<Workspace> {
            return WorkspaceApiFp(configuration).updateWorkspace(workspaceId, workspace, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update some Workspace data
         * @param {string} workspaceId 
         * @param {WorkspaceUpdateData} workspaceUpdateData All fields are optional, but at least one must be given. Only given fields will be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkspaceData(workspaceId: string, workspaceUpdateData: WorkspaceUpdateData, options?: any): AxiosPromise<void> {
            return WorkspaceApiFp(configuration).updateWorkspaceData(workspaceId, workspaceUpdateData, options)(axios, basePath);
        },
    };
};

/**
 * WorkspaceApi - object-oriented interface
 * @export
 * @class WorkspaceApi
 * @extends {BaseAPI}
 */
export class WorkspaceApi extends BaseAPI {
    /**
     * 
     * @summary Create a Workspaces
     * @param {Workspace} workspace 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public createWorkspace(workspace: Workspace, options?: any) {
        return WorkspaceApiFp(this.configuration).createWorkspace(workspace, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a Workspace
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public deleteWorkspace(workspaceId: string, options?: any) {
        return WorkspaceApiFp(this.configuration).deleteWorkspace(workspaceId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary  Retrieve statistics on workspace 
     * @param {string} workspaceName 
     * @param {WorkspaceStatisticsAggregationType} aggregationType Aggregation type, workspace or workflow
     * @param {Array<string>} [workflowNameIn] Keep only workflow with the specified workflow names present in that list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public getStatistics(workspaceName: string, aggregationType: WorkspaceStatisticsAggregationType, workflowNameIn?: Array<string>, options?: any) {
        return WorkspaceApiFp(this.configuration).getStatistics(workspaceName, aggregationType, workflowNameIn, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get Audit details of a workspace
     * @param {string} workspaceId 
     * @param {string} [engineId] 
     * @param {Date} [dateAfter] 
     * @param {Date} [dateBefore] 
     * @param {string} [eventName] 
     * @param {string} [performerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public getWorkspaceAudit(workspaceId: string, engineId?: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, options?: any) {
        return WorkspaceApiFp(this.configuration).getWorkspaceAudit(workspaceId, engineId, dateAfter, dateBefore, eventName, performerId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve the list of Workspaces
     * @param {number} [pageNumber] Number of the page
     * @param {number} [pageSize] Number of elements in the page
     * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
     * @param {Array<string>} [slugIn] Keep only workspaces with a slug (URL friendly name) present in that list
     * @param {boolean} [showDisabled] Workspace enabled (true|false). By Default retrieves only enabled workspaces
     * @param {boolean} [showHiddenInDefaultUI] Workspace of tasks hidden on the SG-Workflow UI (true|false). By Default retrieves all workspaces
     * @param {boolean} [syncUser] Should the user be synchronized
     * @param {boolean} [showManagedWorkspaces] Workspace managed by user (true|false). By Default retrieves all workspaces
     * @param {Array<string>} [workspaceIdIn] Keep only workspaces with a workspaceIds present in that list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public listWorkspaces(pageNumber?: number, pageSize?: number, sort?: Array<string>, slugIn?: Array<string>, showDisabled?: boolean, showHiddenInDefaultUI?: boolean, syncUser?: boolean, showManagedWorkspaces?: boolean, workspaceIdIn?: Array<string>, options?: any) {
        return WorkspaceApiFp(this.configuration).listWorkspaces(pageNumber, pageSize, sort, slugIn, showDisabled, showHiddenInDefaultUI, syncUser, showManagedWorkspaces, workspaceIdIn, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve the list of Workspaces without URL length limitation
     * @param {WorkspacesFindPostRequest} workspacesFindPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public listWorkspacesPostRequest(workspacesFindPostRequest: WorkspacesFindPostRequest, options?: any) {
        return WorkspaceApiFp(this.configuration).listWorkspacesPostRequest(workspacesFindPostRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve a Workspace
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public retrieveWorkspaceById(workspaceId: string, options?: any) {
        return WorkspaceApiFp(this.configuration).retrieveWorkspaceById(workspaceId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update a Workspace
     * @param {string} workspaceId 
     * @param {Workspace} workspace 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public updateWorkspace(workspaceId: string, workspace: Workspace, options?: any) {
        return WorkspaceApiFp(this.configuration).updateWorkspace(workspaceId, workspace, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update some Workspace data
     * @param {string} workspaceId 
     * @param {WorkspaceUpdateData} workspaceUpdateData All fields are optional, but at least one must be given. Only given fields will be updated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public updateWorkspaceData(workspaceId: string, workspaceUpdateData: WorkspaceUpdateData, options?: any) {
        return WorkspaceApiFp(this.configuration).updateWorkspaceData(workspaceId, workspaceUpdateData, options)(this.axios, this.basePath);
    }

}


/**
 * WorkspaceRuntimeConfigurationApi - axios parameter creator
 * @export
 */
export const WorkspaceRuntimeConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a Workspace runtime configuration by id
         * @param {string} workspaceId 
         * @param {string} configId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspaceRuntimeConfigurationById(workspaceId: string, configId: string, options: any = {}): RequestArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling deleteWorkspaceRuntimeConfigurationById.');
            }
            // verify required parameter 'configId' is not null or undefined
            if (configId === null || configId === undefined) {
                throw new RequiredError('configId','Required parameter configId was null or undefined when calling deleteWorkspaceRuntimeConfigurationById.');
            }
            const localVarPath = `/workspaces/{workspaceId}/runtime-configurations/{configId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the list of Workspace runtime configurations
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspaceRuntimeConfigurations(workspaceId: string, options: any = {}): RequestArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling listWorkspaceRuntimeConfigurations.');
            }
            const localVarPath = `/workspaces/{workspaceId}/runtime-configurations`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or Update a workspace configuration by id
         * @param {string} workspaceId 
         * @param {string} configId 
         * @param {WorkspaceRuntimeConfiguration} workspaceRuntimeConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceWorkspaceRuntimeConfigurationById(workspaceId: string, configId: string, workspaceRuntimeConfiguration: WorkspaceRuntimeConfiguration, options: any = {}): RequestArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling replaceWorkspaceRuntimeConfigurationById.');
            }
            // verify required parameter 'configId' is not null or undefined
            if (configId === null || configId === undefined) {
                throw new RequiredError('configId','Required parameter configId was null or undefined when calling replaceWorkspaceRuntimeConfigurationById.');
            }
            // verify required parameter 'workspaceRuntimeConfiguration' is not null or undefined
            if (workspaceRuntimeConfiguration === null || workspaceRuntimeConfiguration === undefined) {
                throw new RequiredError('workspaceRuntimeConfiguration','Required parameter workspaceRuntimeConfiguration was null or undefined when calling replaceWorkspaceRuntimeConfigurationById.');
            }
            const localVarPath = `/workspaces/{workspaceId}/runtime-configurations/{configId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof workspaceRuntimeConfiguration !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(workspaceRuntimeConfiguration !== undefined ? workspaceRuntimeConfiguration : {}) : (workspaceRuntimeConfiguration || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a workspace configuration by id
         * @param {string} workspaceId 
         * @param {string} configId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWorkspaceRuntimeConfigurationById(workspaceId: string, configId: string, options: any = {}): RequestArgs {
            // verify required parameter 'workspaceId' is not null or undefined
            if (workspaceId === null || workspaceId === undefined) {
                throw new RequiredError('workspaceId','Required parameter workspaceId was null or undefined when calling retrieveWorkspaceRuntimeConfigurationById.');
            }
            // verify required parameter 'configId' is not null or undefined
            if (configId === null || configId === undefined) {
                throw new RequiredError('configId','Required parameter configId was null or undefined when calling retrieveWorkspaceRuntimeConfigurationById.');
            }
            const localVarPath = `/workspaces/{workspaceId}/runtime-configurations/{configId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-workspaces.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspaceRuntimeConfigurationApi - functional programming interface
 * @export
 */
export const WorkspaceRuntimeConfigurationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a Workspace runtime configuration by id
         * @param {string} workspaceId 
         * @param {string} configId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspaceRuntimeConfigurationById(workspaceId: string, configId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WorkspaceRuntimeConfigurationApiAxiosParamCreator(configuration).deleteWorkspaceRuntimeConfigurationById(workspaceId, configId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve the list of Workspace runtime configurations
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspaceRuntimeConfigurations(workspaceId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: WorkspaceRuntimeConfiguration; }> {
            const localVarAxiosArgs = WorkspaceRuntimeConfigurationApiAxiosParamCreator(configuration).listWorkspaceRuntimeConfigurations(workspaceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create or Update a workspace configuration by id
         * @param {string} workspaceId 
         * @param {string} configId 
         * @param {WorkspaceRuntimeConfiguration} workspaceRuntimeConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceWorkspaceRuntimeConfigurationById(workspaceId: string, configId: string, workspaceRuntimeConfiguration: WorkspaceRuntimeConfiguration, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WorkspaceRuntimeConfigurationApiAxiosParamCreator(configuration).replaceWorkspaceRuntimeConfigurationById(workspaceId, configId, workspaceRuntimeConfiguration, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a workspace configuration by id
         * @param {string} workspaceId 
         * @param {string} configId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWorkspaceRuntimeConfigurationById(workspaceId: string, configId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceRuntimeConfiguration> {
            const localVarAxiosArgs = WorkspaceRuntimeConfigurationApiAxiosParamCreator(configuration).retrieveWorkspaceRuntimeConfigurationById(workspaceId, configId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkspaceRuntimeConfigurationApi - factory interface
 * @export
 */
export const WorkspaceRuntimeConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete a Workspace runtime configuration by id
         * @param {string} workspaceId 
         * @param {string} configId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspaceRuntimeConfigurationById(workspaceId: string, configId: string, options?: any): AxiosPromise<void> {
            return WorkspaceRuntimeConfigurationApiFp(configuration).deleteWorkspaceRuntimeConfigurationById(workspaceId, configId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve the list of Workspace runtime configurations
         * @param {string} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWorkspaceRuntimeConfigurations(workspaceId: string, options?: any): AxiosPromise<{ [key: string]: WorkspaceRuntimeConfiguration; }> {
            return WorkspaceRuntimeConfigurationApiFp(configuration).listWorkspaceRuntimeConfigurations(workspaceId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Create or Update a workspace configuration by id
         * @param {string} workspaceId 
         * @param {string} configId 
         * @param {WorkspaceRuntimeConfiguration} workspaceRuntimeConfiguration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceWorkspaceRuntimeConfigurationById(workspaceId: string, configId: string, workspaceRuntimeConfiguration: WorkspaceRuntimeConfiguration, options?: any): AxiosPromise<void> {
            return WorkspaceRuntimeConfigurationApiFp(configuration).replaceWorkspaceRuntimeConfigurationById(workspaceId, configId, workspaceRuntimeConfiguration, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve a workspace configuration by id
         * @param {string} workspaceId 
         * @param {string} configId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveWorkspaceRuntimeConfigurationById(workspaceId: string, configId: string, options?: any): AxiosPromise<WorkspaceRuntimeConfiguration> {
            return WorkspaceRuntimeConfigurationApiFp(configuration).retrieveWorkspaceRuntimeConfigurationById(workspaceId, configId, options)(axios, basePath);
        },
    };
};

/**
 * WorkspaceRuntimeConfigurationApi - object-oriented interface
 * @export
 * @class WorkspaceRuntimeConfigurationApi
 * @extends {BaseAPI}
 */
export class WorkspaceRuntimeConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Delete a Workspace runtime configuration by id
     * @param {string} workspaceId 
     * @param {string} configId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRuntimeConfigurationApi
     */
    public deleteWorkspaceRuntimeConfigurationById(workspaceId: string, configId: string, options?: any) {
        return WorkspaceRuntimeConfigurationApiFp(this.configuration).deleteWorkspaceRuntimeConfigurationById(workspaceId, configId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve the list of Workspace runtime configurations
     * @param {string} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRuntimeConfigurationApi
     */
    public listWorkspaceRuntimeConfigurations(workspaceId: string, options?: any) {
        return WorkspaceRuntimeConfigurationApiFp(this.configuration).listWorkspaceRuntimeConfigurations(workspaceId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Create or Update a workspace configuration by id
     * @param {string} workspaceId 
     * @param {string} configId 
     * @param {WorkspaceRuntimeConfiguration} workspaceRuntimeConfiguration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRuntimeConfigurationApi
     */
    public replaceWorkspaceRuntimeConfigurationById(workspaceId: string, configId: string, workspaceRuntimeConfiguration: WorkspaceRuntimeConfiguration, options?: any) {
        return WorkspaceRuntimeConfigurationApiFp(this.configuration).replaceWorkspaceRuntimeConfigurationById(workspaceId, configId, workspaceRuntimeConfiguration, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve a workspace configuration by id
     * @param {string} workspaceId 
     * @param {string} configId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceRuntimeConfigurationApi
     */
    public retrieveWorkspaceRuntimeConfigurationById(workspaceId: string, configId: string, options?: any) {
        return WorkspaceRuntimeConfigurationApiFp(this.configuration).retrieveWorkspaceRuntimeConfigurationById(workspaceId, configId, options)(this.axios, this.basePath);
    }

}


