/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Task, TaskApiFactory, TaskAuditLogDto } from '../../../projects/tasks-api-axios';
import { loadDqHubAttachments } from './action-dqhub';
import { SGWorkflowConfig } from '../config';
import store from './store';
import { TaskSearchSort, UserTask, CanDoActionOnTask } from '../models';
import {
  WorkflowApiFactory,
  WorkspaceApiFactory,
  InstanceApiFactory,
  ProcessDefinitionModelData,
  InstanceState,
  WorkflowJobApiFactory,
  WorkflowJobIncrementRetries,
} from '../../../projects/workspaces-api-axios';
import { alertUtils } from './actions-alerts';
import axios from 'axios';

//@ts-ignore
import Analytics from '../../analytics/analytics.service';
import { getErrorMessage } from '../utility/utility';
// @ts-ignore
import i18n from '../intl/intl.service.js';
import { ProcessApiFactory } from '../../../projects/workspaces-api-axios';

const {
  service: { formatMessage },
} = i18n;

const EVENT_TASK_FORM_COMPLETED_START = 'task-completed-start';
const EVENT_TASK_FORM_COMPLETED_END = 'task-completed-end';

const startEvent = new CustomEvent(EVENT_TASK_FORM_COMPLETED_START, {
  bubbles: true,
  detail: { isSubmitting: true },
});

const endEvent = new CustomEvent(EVENT_TASK_FORM_COMPLETED_END, {
  bubbles: true,
  detail: { isSubmitting: false },
});

const CancelToken = axios.CancelToken;
let loadTaskCall = CancelToken.source();

export const loadTaskById = (taskId: string, engineId: string) => {
  const {
    sgWorkflowConfig: { taskApiBasePath },
    accessToken,
  } = store.getState();

  const taskApi = TaskApiFactory({ accessToken: accessToken }, taskApiBasePath);
  const options = { headers: { Authorization: accessToken } };
  return taskApi.findTaskById(taskId, engineId, true, true, undefined, options).then(({ data }) => data);
};

export const retryJob = (jobId: string, workspaceId: string) => {
  const {
    sgWorkflowConfig: { workspaceApiBasePath },
    accessToken,
  } = store.getState();

  const workflowJobApi = WorkflowJobApiFactory({ accessToken: accessToken }, workspaceApiBasePath);
  const options = { headers: { Authorization: accessToken } };

  const payload: WorkflowJobIncrementRetries = {
    jobIds: [jobId],
    workspaceId,
    increment: 1,
  };
  return workflowJobApi.incrementRetriesForFailedJobs(payload, options).then((response) => response);
};

export const loadTask = async ({
  sgWorkflowConfig,
  accessToken,
  selectedTaskId,
  selectedTaskEngineId,
}: {
  accessToken?: string;
  selectedTaskId: string;
  sgWorkflowConfig: SGWorkflowConfig;
  selectedTaskEngineId: string;
}) => {
  // Start Analytics Transaction
  const analyticsInstance = Analytics.getInstance();

  if (analyticsInstance) {
    analyticsInstance.startTransaction(
      Analytics.constants.middlewareInteraction.actions.openTask,
      Analytics.constants.transactions.type.customInteractions,
      { managed: true }
    );
  }

  if (selectedTaskId && selectedTaskEngineId && accessToken) {
    store.setState({
      selectedTaskLoadingStatus: 'loading',
      selectedLoadedTask: undefined,
      formioSubmission: undefined,
      processDefinitionId: undefined,
      processInstanceId: undefined,
      canDoActionOnTask: undefined,
    });

    loadTaskCall.cancel('Cancel of old transaction');
    loadTaskCall = CancelToken.source();
    try {
      // TODO: add analytics for single task fetch
      const api = TaskApiFactory({ accessToken: accessToken }, sgWorkflowConfig.taskApiBasePath);
      let selectedProcessInstance = store.getState().selectedProcessInstance;
      let curProcessDefinitionId = selectedProcessInstance ? selectedProcessInstance.processDefinitionId : undefined;
      const taskPromise = await api.findTaskById(selectedTaskId, selectedTaskEngineId, true, true, curProcessDefinitionId, {
        headers: { Authorization: accessToken },
        cancelToken: loadTaskCall.token,
      });

      const task = taskPromise.data as Task;

      if (task.id === selectedTaskId) {
        if (task.formSubmission) {
          await loadDqHubAttachments({ accessToken: accessToken, sgWorkflowConfig: sgWorkflowConfig, task: task });
        }

        return {
          selectedTaskLoadingStatus: 'loaded',
          selectedLoadedTask: { ...task },
          processInstanceId: task.processInstanceId,
          processDefinitionId: task.processDefinitionId,
          selectedTaskEngineId: task.clusterId,
        };
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        return {
          selectedTaskLoadingStatus: 'loading',
          selectedLoadedTask: undefined,
          processInstanceId: undefined,
          processDefinitionId: undefined,
          canDoActionOnTask: undefined,
          selectedTaskEngineId: undefined,
        };
      }
      return {
        selectedTaskLoadingStatus: 'error',
        selectedLoadedTask: undefined,
        processInstanceId: undefined,
        processDefinitionId: undefined,
        canDoActionOnTask: undefined,
        selectedTaskEngineId: undefined,
      };
    }
  } else {
    return {};
  }
};

export const getWorkspaceInfo = async (tenantId: string) => {
  const {
    sgWorkflowConfig: { workspaceApiBasePath },
    accessToken,
  } = store.getState();

  try {
    const workspaceApi = WorkspaceApiFactory({ accessToken: accessToken }, workspaceApiBasePath);
    const options = { headers: { Authorization: accessToken } };

    const response = await workspaceApi.retrieveWorkspaceById(tenantId, options);
    store.setState({
      selectedTaskWorkspaceInformation: response.data,
    });
  } catch (err) {
    alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
  }
};

export const addCandidate = async (
  {
    sgWorkflowConfig,
    accessToken,
    user,
  }: {
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken: string;
    user: string;
  },
  selectedTaskId: string,
  selectedTaskEngineId: string,
  assignee: any
) => {
  try {
    const api = TaskApiFactory({ accessToken: accessToken }, sgWorkflowConfig.taskApiBasePath, undefined);
    const addedCandidate = assignee ? assignee : user;
    const taskPromise = await api.addCandidate(
      selectedTaskId,
      selectedTaskEngineId,
      { candidateUsers: [addedCandidate] },
      { headers: { Authorization: accessToken } }
    );

    if (taskPromise) {
      return {
        selectedTaskUpdateStatus: 'loaded',
      };
    }
  } catch (err) {
    alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
    return {
      selectedTaskUpdateStatus: 'error',
    };
  }
};

export const assignTask = (
  {
    sgWorkflowConfig,
    accessToken,
    user,
  }: {
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken: string;
    user: string;
  },
  selectedTaskId: string,
  selectedTaskEngineId: string,
  assignee: any
) => {
  const api = TaskApiFactory({ accessToken: accessToken }, sgWorkflowConfig.taskApiBasePath, undefined);
  const assignedUser = assignee ? assignee : user;

  const taskPromise = api.assignTask(selectedTaskId, selectedTaskEngineId, { userId: assignedUser }, { headers: { Authorization: accessToken } });

  return taskPromise
    .then(async (data: any) => {
      store.setState({ assignee: assignedUser });
      return {
        selectedTaskUpdateStatus: 'loaded',
      };
    })
    .catch((err: any) => {
      alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
      return {
        selectedTaskUpdateStatus: 'error',
      };
    });
};

export const unAssignTask = async (
  {
    sgWorkflowConfig,
    accessToken,
    user,
  }: {
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken: string;
    user: string;
  },
  selectedTaskId: string,
  selectedTaskEngineId: string
) => {
  try {
    const api = TaskApiFactory({ accessToken: accessToken }, sgWorkflowConfig.taskApiBasePath, undefined);

    const apiResponse = await api.unassignTask(selectedTaskId, selectedTaskEngineId, {
      headers: { Authorization: accessToken },
    });
    if (apiResponse) {
      store.setState({ assignee: undefined });
      return {
        selectedTaskUpdateStatus: 'loaded',
      };
    }
  } catch (err) {
    alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
    return {};
  }
};

export const completeTask = (
  {
    sgWorkflowConfig,
    accessToken,
  }: {
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken: string;
  },
  formioSubmission: any,
  selectedTaskId: string,
  selectedTaskEngineId: string
) => {
  document.dispatchEvent(startEvent);
  store.setState({
    selectedTaskUpdateStatus: 'loading',
  });
  const api = TaskApiFactory({ accessToken: accessToken }, sgWorkflowConfig.taskApiBasePath, undefined);

  const taskPromise = api.completeTask(
    selectedTaskId,
    selectedTaskEngineId,
    { submission: formioSubmission },
    { headers: { Authorization: accessToken } }
  );

  return taskPromise
    .then(() => {
      document.dispatchEvent(endEvent);

      return {
        selectedTaskUpdateStatus: 'loaded',
      };
    })
    .catch((err: any) => {
      document.dispatchEvent(endEvent);
      alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));

      return {
        selectedTaskUpdateStatus: 'error',
      };
    });
};

export const saveTask = (
  {
    sgWorkflowConfig,
    accessToken,
  }: {
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken: string;
  },
  submission: any,
  selectedTaskId: string,
  selectedTaskEngineId: string
) => {
  const api = TaskApiFactory({ accessToken: accessToken }, sgWorkflowConfig.taskApiBasePath, undefined);

  const taskPromise = api.updateTask(selectedTaskId, selectedTaskEngineId, { submission }, { headers: { Authorization: accessToken } });

  return taskPromise
    .then(() => {
      return {
        selectedTaskUpdateStatus: 'loaded',
      };
    })
    .catch((err: any) => {
      alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
      return {
        selectedTaskUpdateStatus: 'error',
      };
    });
};

export const resetSelectedTaskUpdateStatus = () => {
  return {
    selectedTaskUpdateStatus: undefined,
  };
};

export const updateUserId = (
  {
    selectedTab,
    taskSearchSort,
    sgWorkflowConfig,
    accessToken,
    user,
  }: {
    selectedTab: UserTask;
    taskSearchSort: TaskSearchSort;
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken?: string;
    user?: string;
  },
  userId?: string
) => {
  return {
    user: userId,
  };
};

export const updateAccessToken = (
  {
    selectedTab,
    taskSearchSort,
    sgWorkflowConfig,
    accessToken,
    user,
  }: {
    selectedTab: UserTask;
    taskSearchSort: TaskSearchSort;
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken?: string;
    user?: string;
  },
  newAccessToken?: string
) => {
  return {
    accessToken: newAccessToken,
  };
};

export const loadDiagramForProcess = async (processInstanceId: any, processDefinitionId: any, selectedTaskEngineId: any) => {
  const {
    sgWorkflowConfig: { workspaceApiBasePath },
    accessToken,
  } = store.getState();

  const definitionId = processDefinitionId;
  if (definitionId && accessToken) {
    // Start Analytics Transaction
    let loadDiagram: any;
    const analyticsInstance = Analytics.getInstance();
    const engineId = selectedTaskEngineId;
    const instanceId = processInstanceId;

    if (analyticsInstance) {
      loadDiagram = analyticsInstance.startTransaction(
        Analytics.constants.middlewareInteraction.actions.displayDiagram,
        Analytics.constants.transactions.type.customInteractions,
        { managed: true }
      );
    }

    try {
      store.setState({
        processDiagramLoadingStatus: 'loading',
      });
      const options = { headers: { Authorization: accessToken } };
      const config = { accessToken: accessToken };

      const processApi = ProcessApiFactory(config, workspaceApiBasePath, undefined);
      const processModelResponse = await processApi.getProcessDefinitionXml(definitionId, engineId, options);
      const processModel = processModelResponse.data as ProcessDefinitionModelData;

      let instanceState = undefined;

      if (instanceId && processModel) {
        const instanceApi = InstanceApiFactory(config, workspaceApiBasePath, undefined);
        const instanceApiResponse = await instanceApi.getInstanceStateById(instanceId, engineId, options);
        instanceState = instanceApiResponse.data as InstanceState;
      }

      store.setState({
        processModel: processModel,
        processDiagramLoadingStatus: 'loaded',
        instanceState: instanceState,
      });
    } catch (err) {
      alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
      store.setState({
        processModel: undefined,
        processDiagramLoadingStatus: 'error',
        instanceState: undefined,
      });
    }
  }

  return {};
};

export const getSupportContactList = async (
  { sgWorkflowConfig, accessToken }: { sgWorkflowConfig: SGWorkflowConfig; accessToken: string },
  processInstanceId: string
) => {
  try {
    const options = { headers: { Authorization: accessToken } };
    const config = { accessToken: accessToken };

    const instanceApi = InstanceApiFactory(config, sgWorkflowConfig.workspaceApiBasePath, undefined);

    const instanceApiResponse = await instanceApi.getProcessSupportContactList(processInstanceId, options);

    store.setState({
      selectedTaskSupportContactInformation: instanceApiResponse.data,
    });
  } catch (err) {
    alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
  }
};

export const exportTaskAsHtml = async (
  {
    sgWorkflowConfig,
    accessToken,
  }: {
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken: string;
  },
  taskId: string,
  taskName: string,
  engineId: string,
  processDefinitionId?: string
) => {
  try {
    const options = { headers: { Authorization: accessToken } };
    const config = { accessToken: accessToken };

    const htmlTaskExportBasePath = TaskApiFactory(config, sgWorkflowConfig.taskApiBasePath);

    const htmlTaskExport: any = await htmlTaskExportBasePath.exportTaskAsHtml(taskId, engineId, processDefinitionId, options);

    const data = new Blob([htmlTaskExport.data], { type: 'text/html' });
    const htmlURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = htmlURL;

    let filename = `${taskName}.html`;
    let contentDisposition =
      htmlTaskExport.headers && htmlTaskExport.headers['content-disposition'] ? htmlTaskExport.headers['content-disposition'] : undefined;
    if (contentDisposition) {
      // inline; filename=_TRE__some_Multi_line_name.html
      let pos = contentDisposition.lastIndexOf('=');
      if (pos >= 0) {
        filename = contentDisposition.substring(pos + 1);
      }
    }

    tempLink.setAttribute('download', filename);
    tempLink.click();

    setTimeout(function() {
      window.URL.revokeObjectURL(htmlURL);
      tempLink.remove();
    }, 100);

    return {};
  } catch (err) {
    alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
  }
};

export const updateSelectedLoadedTask = ({}, selectedLoadedTask?: Task) => ({
  selectedLoadedTask,
});

export const updateCanDoActionOnTask = ({}, canDoActionOnTask: CanDoActionOnTask) => ({
  canDoActionOnTask,
});
