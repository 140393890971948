import * as pack from '../../package.json';

const constants = {
  transactions: {
    type: {
      customInteractions: 'custom-interactions',
      userInteraction: 'user-interaction',
      httpRequest: 'http-request',
      routeChange: 'route-change',
      pageLoad: 'page-load',
      custom: 'custom-transaction',
    },
  },
  userInteraction: {
    events: {
      clickButton: 'Click - button',
      clickCompleteTaskButton: 'Complete task',
      clickSaveTaskButton: 'Save Task as draft',
      clickSaveExportHTMLButton: 'Click - button["export-task-as-html"]',
      clickSaveExportProcessHTMLButton: 'Click - button["export-process-as-html"]',
      copyHyperLink: 'Click-button[copy-hyperlink-button]',
      launchProcess: 'Click-button[launch-process-button-from-process-view]',
    },
    labels: {
      workflowMetadata: 'workflow metadata: ',
      completedTaskMetadata: 'complete task metadata: ',
      saveDraftTaskMetadata: 'save draft task metadata: ',
      exportHTMLMetadata: 'export HTML metadata',
      copiedUrl: 'copied URL: ',
    },
  },
  middlewareInteraction: {
    labels: {
      packageName: `${pack.name} middleware`,
      fetchData: 'Fetch Data: ',
      task: 'Task',
      process: 'Process',
      model: 'Model',
    },
    actions: {
      launchProcess: 'Launch Process',
      completeTask: 'Complete Task',
      openTask: 'Open Task',
      loadTasks: 'Load Tasks',
      saveDraft: 'Save Draft',
      displayDiagram: 'Display Diagram',
      fetchProcessHistory: 'Load Process history',
      loadAvailableProcessToLaunch: 'Load Processes available to Launch',
    },
  },
};

export { constants };
