import task from '../../sg-workflow-task-form/locales/task.en.json';
import taskList from '../../sg-workflow-tasks-list/locales/task-summary.en.json';
import launchProcess from '../../sg-workflow-process-launch/locales/launch-process.en.json';
import process from '../../sg-workflow-process-list/locales/process.en.json';

import taskFr from '../../sg-workflow-task-form/locales/task.fr.json';
import taskListFr from '../../sg-workflow-tasks-list/locales/task-summary.fr.json';
import launchProcessFr from '../../sg-workflow-process-launch/locales/launch-process.fr.json';
import processFr from '../../sg-workflow-process-list/locales/process.fr.json';

const en = {
  ...task,
  ...taskList,
  ...launchProcess,
  ...process,
};

const fr = {
  ...taskFr,
  ...taskListFr,
  ...launchProcessFr,
  ...processFr,
};

export { en, fr };
