import { createIntl, createIntlCache, MessageDescriptor } from '@formatjs/intl';
import { get } from 'lodash';

// @ts-ignore
import { en, fr } from './messages';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';

const userInfo = get(window, 'SGWTWidgetConfiguration.bus.lastValues["sg-connect.user-info"]');

let userLanguage = 'en';
if (userInfo && userInfo['preferred_language']) {
  userLanguage = userInfo['preferred_language'];
}

const messages = userLanguage === 'fr' ? { ...fr } : { ...en };

// prevents memory leak
const cache = createIntlCache();

const service = createIntl(
  {
    locale: userLanguage,
    messages,
  },
  cache
);

const withDefaultRichTextElements = (obj) => {
  return { ...obj, bold: (str) => `<b>${str}</b>` };
};

const formatMessageAsString = (descriptor, values) => {
  return service.formatMessage(descriptor, withDefaultRichTextElements(values));
};

export default { service: { ...service, formatMessageAsString } };
