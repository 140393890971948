import { SGWorkflowConfig } from '../config';
import { UserSettingsApiFactory, UserSettings } from '../../../projects/workspaces-api-axios';
import store from './store';
import { alertUtils } from './actions-alerts';

export const fetchUserSettings = async ({
  sgWorkflowConfig,
  accessToken,
  user,
}: {
  accessToken?: string;
  sgWorkflowConfig: SGWorkflowConfig;
  user: string;
}) => {
  const userSettingsApi = UserSettingsApiFactory({ accessToken: accessToken }, sgWorkflowConfig.workspaceApiBasePath, undefined);

  store.setState({
    userSettings: {},
  });
  try {
    const response = await userSettingsApi.listUserSettings([user], { headers: { Authorization: accessToken } });

    return response.data[0];
  } catch (error) {
    alertUtils.publishAlertWarning('Warning', 'Error fetching user preferences. Default preferences will be applied.');
    return {};
  }
};

export const updateUserSettings = async (
  {
    sgWorkflowConfig,
    accessToken,
  }: {
    accessToken?: string;
    sgWorkflowConfig: SGWorkflowConfig;
  },
  userSettings: UserSettings
) => {
  try {
    const userSettingsApi = UserSettingsApiFactory({ accessToken: accessToken }, sgWorkflowConfig.workspaceApiBasePath, undefined);
    let response: any;

    if (userSettings.id) {
      response = await userSettingsApi.updateUserSettings(userSettings.id!, userSettings, { headers: { Authorization: accessToken } });
    } else {
      response = await userSettingsApi.createUserSettings(userSettings, { headers: { Authorization: accessToken } });
    }
    const userSettingsResult: any = { ...response.data };
    store.setState({
      userSettings: userSettingsResult,
    });
  } catch (err) {
    alertUtils.publishAlertDanger('Error', 'Error updating/ creating favorite workflows');
  }
};
