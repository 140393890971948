import * as createStore from 'redux-zero';
import { PageOfTask, Task } from '../../../projects/tasks-api-axios';
import {
  ApiCallStatus,
  TaskDisplay,
  TasksByProcessName,
  Filters,
  FormioSubmission,
  FormioState,
  ProcessHistoryFilters,
  ProcessViewTabDescriptor,
  TaskList,
  LaunchProcessTab,
  PageOfProcessInstances,
  Timeline,
  CanDoActionOnTask,
} from '../models';
import { SGWorkflowConfig } from '../config';
import { CONFIG } from '../config';
import { SgWorkflowAlert, TimelineEventType } from '../models';
import {
  Workflow,
  Workspace,
  StartWorkflowSpecification,
  StartWorkflowSubmission,
  HistoricActivityInstanceDto,
  HistoricIncidentDto,
  ProcessDefinitionModelData,
  InstanceState,
  Instance,
} from '../../../projects/workspaces-api-axios';

// @ts-ignore
import i18n from '../intl/intl.service.js';

const {
  service: { formatMessage },
} = i18n;

const sgWorkflowConfig = CONFIG.get();

export interface AlertStateInterface extends WidgetConfigsStateInterface {
  alerts: SgWorkflowAlert[];
}
export interface HandsontableStateInterface {
  handsontableConfig?: string;
  readOnly?: string;
  isRequired?: string;
  handsontableId?: string;
}

export interface BpmnDiagramStateInterface extends WidgetConfigsStateInterface {
  processInstanceId?: string;
  processDefinitionId?: string;
  processDiagramLoadingStatus?: ApiCallStatus;
  selectedTaskId?: string;
  processModel?: ProcessDefinitionModelData;
  instanceState?: InstanceState;
}

export interface ProcessHistoryStateInterface extends WidgetConfigsStateInterface {
  processInstanceId?: string;
  processHistoryLoadingStatus?: ApiCallStatus;
  timelineEvents?: Timeline[];
  processHistorySelectedTaskId?: string;
  selectedTaskType?: TimelineEventType;
  activityLoadingStatus?: ApiCallStatus;
  engineId?: string;
  selectedTabType?: number;
  messageCount?: number;
}

export interface ProcessInstancesInterface extends WidgetConfigsStateInterface, UserSettingsStateInterface, ProcessHistoryStateInterface {
  processInstances: PageOfProcessInstances;
  processLoadingStatus?: ApiCallStatus;
  selectedProcessInstance?: Instance;
  processHistoryFilters: ProcessHistoryFilters;
  processViewTabDescriptors: ProcessViewTabDescriptor[];
  showProcessFilter: boolean;
  userWorkspaces?: Workspace;
  isDownloadingCsv: Boolean;
  showProcessHistoryScreen: boolean;
  showCancelProcessInstanceModal: boolean;
  cancellingProcessInstanceStatus: ApiCallStatus;
  processUserSettingsFiltersStatus: ApiCallStatus;
  processInstanceEngineId: string;
  startedAfter?: string | any | Date;
  messageCount?: number;
}

export interface TaskStateInterface extends WidgetConfigsStateInterface {
  taskPage?: PageOfTask;
  filteredTasks: Task[];
  tasksByProcessName?: TasksByProcessName;
  filterInputTimeout?: number;
  tasksLoadingStatus?: ApiCallStatus;
  selectedDisplay: TaskDisplay;
  selectedTaskId?: string;
  selectedTaskEngineId?: string;
  selectedTaskLoadingStatus?: ApiCallStatus;
  selectedLoadedTask?: Task;
  formioState?: FormioState;
  selectedTaskUpdateStatus?: ApiCallStatus;
  selectedTasksToPrint: Task[];
  filters: Filters;
  selectedTaskWorkspaceInformation?: Workspace;
  showfilters: boolean;
  tasksGroupedByProcessName?: Map<string, TaskList>;
  groupMembers?: Map<string, string>;
  canDoActionOnTask?: CanDoActionOnTask;
  isTaskSelected: boolean;
  firstTask: Task | undefined;
}

export interface WidgetConfigsStateInterface {
  authorizedEngineIds?: string[];
  sgWorkflowConfig?: SGWorkflowConfig;
  accessToken?: string;
  user?: string;
}

export interface ActivityInstanceInterface extends WidgetConfigsStateInterface {
  processInstanceId?: string;
  selectedIncident?: HistoricIncidentDto;
  selectedActivityInstance?: HistoricActivityInstanceDto;
  activityId?: string;
  selectedTaskType?: TimelineEventType;
  selectedTaskWorkspaceInformation?: Workspace;
  activityLoadingStatus?: ApiCallStatus;
  processDefinitionId?: string;
  messageCount?: number;
}

export interface LaunchProcessStateInterface extends WidgetConfigsStateInterface, UserSettingsStateInterface {
  allWorkflows?: Workflow[];
  favWorkflows?: Workflow[];
  pubWorkflows?: Workflow[];
  workflowsLoadingStatus?: ApiCallStatus;
  workspaces?: Workspace[];
  allFilteredWorkflows?: Workflow[];
  favFilteredWorkflows?: Workflow[];
  pubFilteredWorkflows?: Workflow[];
  selectedWorkflow?: Workflow;
  launchProcessStatus?: ApiCallStatus;
  showStartProcessModal?: boolean;
  startWorflowSlug?: string;
  startWorkspaceId?: string;
  startWorkflowSpec?: StartWorkflowSpecification;
  fetchStartWorkflowLoadingStatus?: ApiCallStatus;
  launchProcessErrorMessage?: string;
  startWorkflowResponse?: StartWorkflowSubmission;
  redirectToTask?: ApiCallStatus;
  startWorkflowFormioSubmission?: FormioSubmission;
  launchProcessSelectedTab: LaunchProcessTab;
}

export interface UserSettingsStateInterface {
  userSettings?: any;
}

export interface StoreStateInterface
  extends TaskStateInterface,
    BpmnDiagramStateInterface,
    LaunchProcessStateInterface,
    UserSettingsStateInterface,
    AlertStateInterface,
    ProcessHistoryStateInterface,
    HandsontableStateInterface,
    ProcessInstancesInterface,
    ActivityInstanceInterface {}

const initAlertState: AlertStateInterface = {
  alerts: [],
};

const initStoreState: StoreStateInterface = {
  taskPage: undefined,
  filters: {
    selectedTab: 'user',
    filterValue: '',
    taskSearchSort: '-created',
  },
  filteredTasks: [],
  selectedDisplay: 'split',
  selectedTasksToPrint: [],
  showfilters: true,
  isTaskSelected: true,
  alerts: [],
  firstTask: undefined,
  groupMembers: undefined,
  processUserSettingsFiltersStatus: 'loading',
  processHistoryFilters: {
    status: ['active'],
    processHistorySearch: 'MY_PROCESS',
    processHistoryTypeView: 'MY_PROCESS',
    page: 0,
    pageSize: 30,
    sortBy: 'startTime',
    sortOrder: 'desc',
    userWorkspaces: undefined,
    processHistoryFilterEngineId: sgWorkflowConfig.defaultEngineId,
  },
  showProcessFilter: false,
  isDownloadingCsv: false,
  showProcessHistoryScreen: false,
  showCancelProcessInstanceModal: false,
  cancellingProcessInstanceStatus: 'loaded',
  processInstances: {
    instances: [],
    total: -1,
  },
  launchProcessSelectedTab: 'fav',
  processViewTabDescriptors: [
    {
      id: 'MY_PROCESS',
      typeView: 'MY_PROCESS',
      label: formatMessage({ id: 'process.table.filter.my.processes.involved' }),
      engineId: sgWorkflowConfig.defaultEngineId,
      visible: false,
    },
    {
      id: 'ADMIN',
      typeView: 'ADMIN',
      label: formatMessage({ id: 'process.table.filter.my.processes.admin' }),
      engineId: sgWorkflowConfig.defaultEngineId,
      visible: false,
    },
    {
      id: 'VIEWER',
      typeView: 'VIEWER',
      label: formatMessage({ id: 'process.table.filter.my.processes.viewer' }),
      engineId: sgWorkflowConfig.defaultEngineId,
      visible: false,
    },
    {
      id: 'PARTNER',
      typeView: 'MY_PROCESS',
      label: formatMessage({ id: 'process.table.filter.partner.involved' }),
      engineId: CONFIG.get().internetExposureEngineId,
      visible: true,
    },
    {
      id: 'PARTNER_ADMIN',
      typeView: 'ADMIN',
      label: formatMessage({ id: 'process.table.filter.partner.admin' }),
      engineId: sgWorkflowConfig.internetExposureEngineId,
      visible: false,
    },
    {
      id: 'PARTNER_VIEWER',
      typeView: 'VIEWER',
      label: formatMessage({ id: 'process.table.filter.partner.viewer' }),
      engineId: sgWorkflowConfig.internetExposureEngineId,
      visible: false,
    },
  ],
  processInstanceEngineId: sgWorkflowConfig.defaultEngineId,
};

const store = (createStore as any)(initStoreState);

export default store;
