import { SGWorkflowConfig } from '../config';
import { EngineApiFactory } from '../../../projects/workspaces-api-axios';

export const getAuthorizedEngineIds = async ({ sgWorkflowConfig, accessToken }: { sgWorkflowConfig: SGWorkflowConfig; accessToken: string }) => {
  if (accessToken) {
    try {
      const options = { headers: { Authorization: accessToken } };
      const config = { accessToken: accessToken };

      const enginesFactory = EngineApiFactory(config, sgWorkflowConfig.workspaceApiBasePath, undefined);
      const enginesApiResponse = await enginesFactory.listEngines(options);
      const enginesList = enginesApiResponse.data as Array<String>;
      return {
        authorizedEngineIds: enginesList,
      };
    } catch (err) {
      if (err && err.response && err.response.status === 403) {
        window.location.href = sgWorkflowConfig.unauthorizedPageUrl;
      }
    }
  }

  return {};
};
