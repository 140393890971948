// tslint:disable
/**
 * SG|Workflow - Tasks API
 * SG|Workflow - Tasks API
 *
 * The version of the OpenAPI document: 0.1
 * Contact: list.world-dds-sgworkflow@socgen.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddCandidateRequest
 */
export interface AddCandidateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddCandidateRequest
     */
    candidateGroups?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddCandidateRequest
     */
    candidateUsers?: Array<string>;
}
/**
 * 
 * @export
 * @interface AuditLog
 */
export interface AuditLog {
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    eventName?: string;
    /**
     * 
     * @type {Date}
     * @memberof AuditLog
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    engineId?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AuditLog
     */
    eventDetails?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    eventCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    authId?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    requestId?: string;
}
/**
 * 
 * @export
 * @interface BusinessDataDto
 */
export interface BusinessDataDto {
    /**
     * 
     * @type {string}
     * @memberof BusinessDataDto
     */
    attributeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessDataDto
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface Cluster
 */
export interface Cluster {
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface CompleteTaskSpecification
 */
export interface CompleteTaskSpecification {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CompleteTaskSpecification
     */
    spec?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CompleteTaskSpecification
     */
    submission?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface CreateTaskInstance
 */
export interface CreateTaskInstance {
    /**
     * 
     * @type {string}
     * @memberof CreateTaskInstance
     */
    taskId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskInstance
     */
    requestId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskInstance
     */
    businessId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskInstance
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskInstance
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateTaskInstance
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateTaskInstance
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskInstance
     */
    taskName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTaskInstance
     */
    groups?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskInstance
     */
    assignee?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskInstance
     */
    assigneeOrganization?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskInstance
     */
    taskState?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateTaskInstance
     */
    lastStateUpdatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateTaskInstance
     */
    dueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskInstance
     */
    endPoint?: string;
    /**
     * 
     * @type {Array<BusinessDataDto>}
     * @memberof CreateTaskInstance
     */
    businessDataList?: Array<BusinessDataDto>;
    /**
     * 
     * @type {JsonNotificationDto}
     * @memberof CreateTaskInstance
     */
    notification?: JsonNotificationDto;
}
/**
 * 
 * @export
 * @interface FileUploadKeyRequest
 */
export interface FileUploadKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof FileUploadKeyRequest
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof FileUploadKeyRequest
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof FileUploadKeyRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface FileUploadKeyResponse
 */
export interface FileUploadKeyResponse {
    /**
     * 
     * @type {string}
     * @memberof FileUploadKeyResponse
     */
    bucket?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof FileUploadKeyResponse
     */
    data?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof FileUploadKeyResponse
     */
    signed?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUploadKeyResponse
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface Form
 */
export interface Form {
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    type?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Form
     */
    spec?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Form
     */
    options?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    key: string;
    /**
     * 
     * @type {any}
     * @memberof InlineObject
     */
    file: any;
}
/**
 * 
 * @export
 * @interface JsonNotificationDto
 */
export interface JsonNotificationDto {
    /**
     * 
     * @type {string}
     * @memberof JsonNotificationDto
     */
    payload: string;
    /**
     * 
     * @type {string}
     * @memberof JsonNotificationDto
     */
    producerCode: string;
    /**
     * 
     * @type {string}
     * @memberof JsonNotificationDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof JsonNotificationDto
     */
    url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonNotificationDto
     */
    userEmails: Array<string>;
}
/**
 * 
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface PageOfAuditLog
 */
export interface PageOfAuditLog {
    /**
     * 
     * @type {number}
     * @memberof PageOfAuditLog
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfAuditLog
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfAuditLog
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<AuditLog>}
     * @memberof PageOfAuditLog
     */
    auditLogs?: Array<AuditLog>;
}
/**
 * 
 * @export
 * @interface PageOfAuditLogAllOf
 */
export interface PageOfAuditLogAllOf {
    /**
     * 
     * @type {Array<AuditLog>}
     * @memberof PageOfAuditLogAllOf
     */
    auditLogs?: Array<AuditLog>;
}
/**
 * 
 * @export
 * @interface PageOfCluster
 */
export interface PageOfCluster {
    /**
     * 
     * @type {number}
     * @memberof PageOfCluster
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfCluster
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfCluster
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<Cluster>}
     * @memberof PageOfCluster
     */
    clusters?: Array<Cluster>;
}
/**
 * 
 * @export
 * @interface PageOfClusterAllOf
 */
export interface PageOfClusterAllOf {
    /**
     * 
     * @type {Array<Cluster>}
     * @memberof PageOfClusterAllOf
     */
    clusters?: Array<Cluster>;
}
/**
 * 
 * @export
 * @interface PageOfTask
 */
export interface PageOfTask {
    /**
     * 
     * @type {number}
     * @memberof PageOfTask
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfTask
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfTask
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<Task>}
     * @memberof PageOfTask
     */
    tasks?: Array<Task>;
}
/**
 * 
 * @export
 * @interface PageOfTaskAllOf
 */
export interface PageOfTaskAllOf {
    /**
     * 
     * @type {Array<Task>}
     * @memberof PageOfTaskAllOf
     */
    tasks?: Array<Task>;
}
/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    assignee?: string;
    /**
     * 
     * @type {Date}
     * @memberof Task
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Task
     */
    due?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Task
     */
    followUp?: Date;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    delegationState?: TaskDelegationStateEnum;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    deleteReason?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    executionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    processDefinitionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    processInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    caseExecutionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    caseDefinitionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    caseInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    taskDefinitionKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    suspended?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    formKey?: string;
    /**
     * 
     * @type {Form}
     * @memberof Task
     */
    form?: Form;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    tenantId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    concernsUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    completed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    clusterId?: string;
    /**
     * 
     * @type {TaskType}
     * @memberof Task
     */
    taskType?: TaskType;
    /**
     * 
     * @type {Array<TaskVariable>}
     * @memberof Task
     */
    variables?: Array<TaskVariable>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Task
     */
    formSubmission?: { [key: string]: object; };
    /**
     * 
     * @type {Array<string>}
     * @memberof Task
     */
    candidateUsers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Task
     */
    candidateGroups?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    processDefinitionKey?: string;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    processVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    processDefinitionName?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    processInstanceBusinessKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    viewerMode?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum TaskDelegationStateEnum {
    RESOLVED = 'RESOLVED',
    PENDING = 'PENDING'
}

/**
 * 
 * @export
 * @interface TaskAuditLogDto
 */
export interface TaskAuditLogDto {
    /**
     * 
     * @type {string}
     * @memberof TaskAuditLogDto
     */
    eventName: string;
    /**
     * 
     * @type {Date}
     * @memberof TaskAuditLogDto
     */
    creationDate: Date;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TaskAuditLogDto
     */
    variables: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface TaskSubmission
 */
export interface TaskSubmission {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TaskSubmission
     */
    submission: { [key: string]: object; };
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TaskType {
    Camunda = 'camunda'
}

/**
 * 
 * @export
 * @interface TaskVariable
 */
export interface TaskVariable {
    /**
     * 
     * @type {string}
     * @memberof TaskVariable
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TaskVariable
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof TaskVariable
     */
    type: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TaskVariable
     */
    valueInfo?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface TaskVariables
 */
export interface TaskVariables {
    /**
     * 
     * @type {Array<TaskVariable>}
     * @memberof TaskVariables
     */
    variables: Array<TaskVariable>;
}
/**
 * 
 * @export
 * @interface TaskVariablesModification
 */
export interface TaskVariablesModification {
    /**
     * 
     * @type {TaskVariables}
     * @memberof TaskVariablesModification
     */
    modifications?: TaskVariables;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskVariablesModification
     */
    deletions?: Array<string>;
}
/**
 * 
 * @export
 * @interface TaskVariablesRequest
 */
export interface TaskVariablesRequest {
    /**
     * 
     * @type {TaskVariables}
     * @memberof TaskVariablesRequest
     */
    variables: TaskVariables;
}
/**
 * 
 * @export
 * @interface UserTaskActionRequest
 */
export interface UserTaskActionRequest {
    /**
     * 
     * @type {string}
     * @memberof UserTaskActionRequest
     */
    userId: string;
}

/**
 * ClusterApi - axios parameter creator
 * @export
 */
export const ClusterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve the list of Cluster
         * @param {number} [page] Number of the page, starting at 1
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusters(page?: number, pageSize?: number, sort?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/clusters`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClusterApi - functional programming interface
 * @export
 */
export const ClusterApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve the list of Cluster
         * @param {number} [page] Number of the page, starting at 1
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusters(page?: number, pageSize?: number, sort?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfCluster> {
            const localVarAxiosArgs = ClusterApiAxiosParamCreator(configuration).listClusters(page, pageSize, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClusterApi - factory interface
 * @export
 */
export const ClusterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Retrieve the list of Cluster
         * @param {number} [page] Number of the page, starting at 1
         * @param {number} [pageSize] Number of elements in the page
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusters(page?: number, pageSize?: number, sort?: Array<string>, options?: any): AxiosPromise<PageOfCluster> {
            return ClusterApiFp(configuration).listClusters(page, pageSize, sort, options)(axios, basePath);
        },
    };
};

/**
 * ClusterApi - object-oriented interface
 * @export
 * @class ClusterApi
 * @extends {BaseAPI}
 */
export class ClusterApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve the list of Cluster
     * @param {number} [page] Number of the page, starting at 1
     * @param {number} [pageSize] Number of elements in the page
     * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApi
     */
    public listClusters(page?: number, pageSize?: number, sort?: Array<string>, options?: any) {
        return ClusterApiFp(this.configuration).listClusters(page, pageSize, sort, options)(this.axios, this.basePath);
    }

}


/**
 * TaskApi - axios parameter creator
 * @export
 */
export const TaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add candidate user or group
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {AddCandidateRequest} addCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCandidate(taskId: string, clusterId: string, addCandidateRequest: AddCandidateRequest, options: any = {}): RequestArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling addCandidate.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling addCandidate.');
            }
            // verify required parameter 'addCandidateRequest' is not null or undefined
            if (addCandidateRequest === null || addCandidateRequest === undefined) {
                throw new RequiredError('addCandidateRequest','Required parameter addCandidateRequest was null or undefined when calling addCandidate.');
            }
            const localVarPath = `/tasks/{taskId}/add-candidate`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof addCandidateRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(addCandidateRequest !== undefined ? addCandidateRequest : {}) : (addCandidateRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign or claim a Task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {UserTaskActionRequest} userTaskActionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignTask(taskId: string, clusterId: string, userTaskActionRequest: UserTaskActionRequest, options: any = {}): RequestArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling assignTask.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling assignTask.');
            }
            // verify required parameter 'userTaskActionRequest' is not null or undefined
            if (userTaskActionRequest === null || userTaskActionRequest === undefined) {
                throw new RequiredError('userTaskActionRequest','Required parameter userTaskActionRequest was null or undefined when calling assignTask.');
            }
            const localVarPath = `/tasks/{taskId}/assign`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof userTaskActionRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userTaskActionRequest !== undefined ? userTaskActionRequest : {}) : (userTaskActionRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete a Task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {TaskSubmission} taskSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeTask(taskId: string, clusterId: string, taskSubmission: TaskSubmission, options: any = {}): RequestArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling completeTask.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling completeTask.');
            }
            // verify required parameter 'taskSubmission' is not null or undefined
            if (taskSubmission === null || taskSubmission === undefined) {
                throw new RequiredError('taskSubmission','Required parameter taskSubmission was null or undefined when calling completeTask.');
            }
            const localVarPath = `/tasks/{taskId}/complete`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof taskSubmission !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(taskSubmission !== undefined ? taskSubmission : {}) : (taskSubmission || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Task in Aggregator
         * @param {string} clusterId 
         * @param {CreateTaskInstance} createTaskInstance 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskInAggregator(clusterId: string, createTaskInstance: CreateTaskInstance, options: any = {}): RequestArgs {
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling createTaskInAggregator.');
            }
            // verify required parameter 'createTaskInstance' is not null or undefined
            if (createTaskInstance === null || createTaskInstance === undefined) {
                throw new RequiredError('createTaskInstance','Required parameter createTaskInstance was null or undefined when calling createTaskInAggregator.');
            }
            const localVarPath = `/tasks/aggregator`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createTaskInstance !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createTaskInstance !== undefined ? createTaskInstance : {}) : (createTaskInstance || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download content for a given task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFromS3UsingGET(taskId: string, clusterId: string, key: string, options: any = {}): RequestArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling downloadFromS3UsingGET.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling downloadFromS3UsingGET.');
            }
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling downloadFromS3UsingGET.');
            }
            const localVarPath = `/tasks/{taskId}/storage/s3/raw`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the html export of a task by id
         * @param {string} taskId 
         * @param {string} engineId 
         * @param {string} [reqProcessDefinitionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTaskAsHtml(taskId: string, engineId: string, reqProcessDefinitionId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling exportTaskAsHtml.');
            }
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling exportTaskAsHtml.');
            }
            const localVarPath = `/tasks/{taskId}/export`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (reqProcessDefinitionId !== undefined) {
                localVarQueryParameter['reqProcessDefinitionId'] = reqProcessDefinitionId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a Task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {boolean} [includeForm] 
         * @param {boolean} [includeVariables] 
         * @param {string} [reqProcessDefinitionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTaskById(taskId: string, clusterId: string, includeForm?: boolean, includeVariables?: boolean, reqProcessDefinitionId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling findTaskById.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling findTaskById.');
            }
            const localVarPath = `/tasks/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (includeForm !== undefined) {
                localVarQueryParameter['includeForm'] = includeForm;
            }

            if (includeVariables !== undefined) {
                localVarQueryParameter['includeVariables'] = includeVariables;
            }

            if (reqProcessDefinitionId !== undefined) {
                localVarQueryParameter['reqProcessDefinitionId'] = reqProcessDefinitionId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the list of tasks a user is associated with (own task, team task or all tasks)
         * @param {string} userId 
         * @param {'user' | 'team' | 'all'} taskSearchType 
         * @param {Array<string>} [tenantIds] 
         * @param {boolean} [showHiddenInDefaultUI] Tasks hidden on the SG-Workflow UI (true|false). By Default retrieves all tasks
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {boolean} [useNewQuery] whether to use the optimized query for task lists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserTasks(userId: string, taskSearchType: 'user' | 'team' | 'all', tenantIds?: Array<string>, showHiddenInDefaultUI?: boolean, sort?: Array<string>, useNewQuery?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling findUserTasks.');
            }
            // verify required parameter 'taskSearchType' is not null or undefined
            if (taskSearchType === null || taskSearchType === undefined) {
                throw new RequiredError('taskSearchType','Required parameter taskSearchType was null or undefined when calling findUserTasks.');
            }
            const localVarPath = `/tasks`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (taskSearchType !== undefined) {
                localVarQueryParameter['taskSearchType'] = taskSearchType;
            }

            if (tenantIds) {
                localVarQueryParameter['tenantIds'] = tenantIds;
            }

            if (showHiddenInDefaultUI !== undefined) {
                localVarQueryParameter['showHiddenInDefaultUI'] = showHiddenInDefaultUI;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (useNewQuery !== undefined) {
                localVarQueryParameter['useNewQuery'] = useNewQuery;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve specification to complete a Task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompleteTaskForm(taskId: string, clusterId: string, options: any = {}): RequestArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling getCompleteTaskForm.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling getCompleteTaskForm.');
            }
            const localVarPath = `/tasks/{taskId}/complete`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Task audit lines
         * @param {string} engineId 
         * @param {string} taskId 
         * @param {string} tenantId 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskAudit(engineId: string, taskId: string, tenantId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'engineId' is not null or undefined
            if (engineId === null || engineId === undefined) {
                throw new RequiredError('engineId','Required parameter engineId was null or undefined when calling getTaskAudit.');
            }
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling getTaskAudit.');
            }
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling getTaskAudit.');
            }
            const localVarPath = `/tasks/{taskId}/audits`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (engineId !== undefined) {
                localVarQueryParameter['engineId'] = engineId;
            }

            if (dateAfter !== undefined) {
                localVarQueryParameter['dateAfter'] = (dateAfter as any instanceof Date) ?
                    (dateAfter as any).toISOString() :
                    dateAfter;
            }

            if (dateBefore !== undefined) {
                localVarQueryParameter['dateBefore'] = (dateBefore as any instanceof Date) ?
                    (dateBefore as any).toISOString() :
                    dateBefore;
            }

            if (eventName !== undefined) {
                localVarQueryParameter['eventName'] = eventName;
            }

            if (performerId !== undefined) {
                localVarQueryParameter['performerId'] = performerId;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves Tasks based on processInstance Id
         * @param {string} processInstanceId 
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTasksForInstance(processInstanceId: string, clusterId: string, options: any = {}): RequestArgs {
            // verify required parameter 'processInstanceId' is not null or undefined
            if (processInstanceId === null || processInstanceId === undefined) {
                throw new RequiredError('processInstanceId','Required parameter processInstanceId was null or undefined when calling listTasksForInstance.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling listTasksForInstance.');
            }
            const localVarPath = `/instance/{processInstanceId}/tasks`
                .replace(`{${"processInstanceId"}}`, encodeURIComponent(String(processInstanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Task in Aggregator
         * @param {string} appId 
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTaskInAggregator(appId: string, taskId: string, clusterId: string, requestBody: { [key: string]: string; }, options: any = {}): RequestArgs {
            // verify required parameter 'appId' is not null or undefined
            if (appId === null || appId === undefined) {
                throw new RequiredError('appId','Required parameter appId was null or undefined when calling patchTaskInAggregator.');
            }
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling patchTaskInAggregator.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling patchTaskInAggregator.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling patchTaskInAggregator.');
            }
            const localVarPath = `/tasks/aggregator/{appId}/{taskId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request to upload content for given task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {FileUploadKeyRequest} fileUploadKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestUploadToS3UsingPOST(taskId: string, clusterId: string, fileUploadKeyRequest: FileUploadKeyRequest, options: any = {}): RequestArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling requestUploadToS3UsingPOST.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling requestUploadToS3UsingPOST.');
            }
            // verify required parameter 'fileUploadKeyRequest' is not null or undefined
            if (fileUploadKeyRequest === null || fileUploadKeyRequest === undefined) {
                throw new RequiredError('fileUploadKeyRequest','Required parameter fileUploadKeyRequest was null or undefined when calling requestUploadToS3UsingPOST.');
            }
            const localVarPath = `/tasks/{taskId}/storage/s3`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof fileUploadKeyRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fileUploadKeyRequest !== undefined ? fileUploadKeyRequest : {}) : (fileUploadKeyRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restarts a task
         * @param {string} jobDefinitionId 
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartTask(jobDefinitionId: string, clusterId: string, options: any = {}): RequestArgs {
            // verify required parameter 'jobDefinitionId' is not null or undefined
            if (jobDefinitionId === null || jobDefinitionId === undefined) {
                throw new RequiredError('jobDefinitionId','Required parameter jobDefinitionId was null or undefined when calling restartTask.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling restartTask.');
            }
            const localVarPath = `/tasks/{jobDefinitionId}/restart`
                .replace(`{${"jobDefinitionId"}}`, encodeURIComponent(String(jobDefinitionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activates a boundary task signal
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {string} signalName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTaskSignal(taskId: string, clusterId: string, signalName: string, options: any = {}): RequestArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling sendTaskSignal.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling sendTaskSignal.');
            }
            // verify required parameter 'signalName' is not null or undefined
            if (signalName === null || signalName === undefined) {
                throw new RequiredError('signalName','Required parameter signalName was null or undefined when calling sendTaskSignal.');
            }
            const localVarPath = `/tasks/{taskId}/signal`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (signalName !== undefined) {
                localVarQueryParameter['signalName'] = signalName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unassign a Task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignTask(taskId: string, clusterId: string, options: any = {}): RequestArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling unassignTask.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling unassignTask.');
            }
            const localVarPath = `/tasks/{taskId}/unassign`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Task (variables)
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {TaskSubmission} taskSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask(taskId: string, clusterId: string, taskSubmission: TaskSubmission, options: any = {}): RequestArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling updateTask.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling updateTask.');
            }
            // verify required parameter 'taskSubmission' is not null or undefined
            if (taskSubmission === null || taskSubmission === undefined) {
                throw new RequiredError('taskSubmission','Required parameter taskSubmission was null or undefined when calling updateTask.');
            }
            const localVarPath = `/tasks/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof taskSubmission !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(taskSubmission !== undefined ? taskSubmission : {}) : (taskSubmission || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload content to a given task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {string} key 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadToS3UsingPOST(taskId: string, clusterId: string, key: string, file: any, options: any = {}): RequestArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling uploadToS3UsingPOST.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling uploadToS3UsingPOST.');
            }
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling uploadToS3UsingPOST.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadToS3UsingPOST.');
            }
            const localVarPath = `/tasks/{taskId}/storage/s3/raw`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication sgconnect required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("sgconnect", ["profile", "openid", "api.sg-workflow-tasks.v1"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


            if (key !== undefined) { 
                localVarFormParams.append('key', key as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskApi - functional programming interface
 * @export
 */
export const TaskApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add candidate user or group
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {AddCandidateRequest} addCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCandidate(taskId: string, clusterId: string, addCandidateRequest: AddCandidateRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).addCandidate(taskId, clusterId, addCandidateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Assign or claim a Task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {UserTaskActionRequest} userTaskActionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignTask(taskId: string, clusterId: string, userTaskActionRequest: UserTaskActionRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).assignTask(taskId, clusterId, userTaskActionRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Complete a Task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {TaskSubmission} taskSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeTask(taskId: string, clusterId: string, taskSubmission: TaskSubmission, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskSubmission> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).completeTask(taskId, clusterId, taskSubmission, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create Task in Aggregator
         * @param {string} clusterId 
         * @param {CreateTaskInstance} createTaskInstance 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskInAggregator(clusterId: string, createTaskInstance: CreateTaskInstance, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).createTaskInAggregator(clusterId, createTaskInstance, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Download content for a given task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFromS3UsingGET(taskId: string, clusterId: string, key: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).downloadFromS3UsingGET(taskId, clusterId, key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the html export of a task by id
         * @param {string} taskId 
         * @param {string} engineId 
         * @param {string} [reqProcessDefinitionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTaskAsHtml(taskId: string, engineId: string, reqProcessDefinitionId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).exportTaskAsHtml(taskId, engineId, reqProcessDefinitionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a Task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {boolean} [includeForm] 
         * @param {boolean} [includeVariables] 
         * @param {string} [reqProcessDefinitionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTaskById(taskId: string, clusterId: string, includeForm?: boolean, includeVariables?: boolean, reqProcessDefinitionId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).findTaskById(taskId, clusterId, includeForm, includeVariables, reqProcessDefinitionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve the list of tasks a user is associated with (own task, team task or all tasks)
         * @param {string} userId 
         * @param {'user' | 'team' | 'all'} taskSearchType 
         * @param {Array<string>} [tenantIds] 
         * @param {boolean} [showHiddenInDefaultUI] Tasks hidden on the SG-Workflow UI (true|false). By Default retrieves all tasks
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {boolean} [useNewQuery] whether to use the optimized query for task lists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserTasks(userId: string, taskSearchType: 'user' | 'team' | 'all', tenantIds?: Array<string>, showHiddenInDefaultUI?: boolean, sort?: Array<string>, useNewQuery?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfTask> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).findUserTasks(userId, taskSearchType, tenantIds, showHiddenInDefaultUI, sort, useNewQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve specification to complete a Task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompleteTaskForm(taskId: string, clusterId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteTaskSpecification> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).getCompleteTaskForm(taskId, clusterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets Task audit lines
         * @param {string} engineId 
         * @param {string} taskId 
         * @param {string} tenantId 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskAudit(engineId: string, taskId: string, tenantId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditLog>> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).getTaskAudit(engineId, taskId, tenantId, dateAfter, dateBefore, eventName, performerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieves Tasks based on processInstance Id
         * @param {string} processInstanceId 
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTasksForInstance(processInstanceId: string, clusterId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Task>> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).listTasksForInstance(processInstanceId, clusterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Patch Task in Aggregator
         * @param {string} appId 
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTaskInAggregator(appId: string, taskId: string, clusterId: string, requestBody: { [key: string]: string; }, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).patchTaskInAggregator(appId, taskId, clusterId, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Request to upload content for given task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {FileUploadKeyRequest} fileUploadKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestUploadToS3UsingPOST(taskId: string, clusterId: string, fileUploadKeyRequest: FileUploadKeyRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadKeyResponse> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).requestUploadToS3UsingPOST(taskId, clusterId, fileUploadKeyRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Restarts a task
         * @param {string} jobDefinitionId 
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartTask(jobDefinitionId: string, clusterId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).restartTask(jobDefinitionId, clusterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Activates a boundary task signal
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {string} signalName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTaskSignal(taskId: string, clusterId: string, signalName: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).sendTaskSignal(taskId, clusterId, signalName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Unassign a Task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignTask(taskId: string, clusterId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).unassignTask(taskId, clusterId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a Task (variables)
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {TaskSubmission} taskSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask(taskId: string, clusterId: string, taskSubmission: TaskSubmission, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).updateTask(taskId, clusterId, taskSubmission, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Upload content to a given task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {string} key 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadToS3UsingPOST(taskId: string, clusterId: string, key: string, file: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TaskApiAxiosParamCreator(configuration).uploadToS3UsingPOST(taskId, clusterId, key, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TaskApi - factory interface
 * @export
 */
export const TaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Add candidate user or group
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {AddCandidateRequest} addCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCandidate(taskId: string, clusterId: string, addCandidateRequest: AddCandidateRequest, options?: any): AxiosPromise<void> {
            return TaskApiFp(configuration).addCandidate(taskId, clusterId, addCandidateRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Assign or claim a Task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {UserTaskActionRequest} userTaskActionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignTask(taskId: string, clusterId: string, userTaskActionRequest: UserTaskActionRequest, options?: any): AxiosPromise<void> {
            return TaskApiFp(configuration).assignTask(taskId, clusterId, userTaskActionRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Complete a Task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {TaskSubmission} taskSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeTask(taskId: string, clusterId: string, taskSubmission: TaskSubmission, options?: any): AxiosPromise<TaskSubmission> {
            return TaskApiFp(configuration).completeTask(taskId, clusterId, taskSubmission, options)(axios, basePath);
        },
        /**
         * 
         * @summary Create Task in Aggregator
         * @param {string} clusterId 
         * @param {CreateTaskInstance} createTaskInstance 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskInAggregator(clusterId: string, createTaskInstance: CreateTaskInstance, options?: any): AxiosPromise<void> {
            return TaskApiFp(configuration).createTaskInAggregator(clusterId, createTaskInstance, options)(axios, basePath);
        },
        /**
         * 
         * @summary Download content for a given task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFromS3UsingGET(taskId: string, clusterId: string, key: string, options?: any): AxiosPromise<any> {
            return TaskApiFp(configuration).downloadFromS3UsingGET(taskId, clusterId, key, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the html export of a task by id
         * @param {string} taskId 
         * @param {string} engineId 
         * @param {string} [reqProcessDefinitionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTaskAsHtml(taskId: string, engineId: string, reqProcessDefinitionId?: string, options?: any): AxiosPromise<any> {
            return TaskApiFp(configuration).exportTaskAsHtml(taskId, engineId, reqProcessDefinitionId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve a Task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {boolean} [includeForm] 
         * @param {boolean} [includeVariables] 
         * @param {string} [reqProcessDefinitionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTaskById(taskId: string, clusterId: string, includeForm?: boolean, includeVariables?: boolean, reqProcessDefinitionId?: string, options?: any): AxiosPromise<Task> {
            return TaskApiFp(configuration).findTaskById(taskId, clusterId, includeForm, includeVariables, reqProcessDefinitionId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve the list of tasks a user is associated with (own task, team task or all tasks)
         * @param {string} userId 
         * @param {'user' | 'team' | 'all'} taskSearchType 
         * @param {Array<string>} [tenantIds] 
         * @param {boolean} [showHiddenInDefaultUI] Tasks hidden on the SG-Workflow UI (true|false). By Default retrieves all tasks
         * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
         * @param {boolean} [useNewQuery] whether to use the optimized query for task lists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserTasks(userId: string, taskSearchType: 'user' | 'team' | 'all', tenantIds?: Array<string>, showHiddenInDefaultUI?: boolean, sort?: Array<string>, useNewQuery?: boolean, options?: any): AxiosPromise<PageOfTask> {
            return TaskApiFp(configuration).findUserTasks(userId, taskSearchType, tenantIds, showHiddenInDefaultUI, sort, useNewQuery, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieve specification to complete a Task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompleteTaskForm(taskId: string, clusterId: string, options?: any): AxiosPromise<CompleteTaskSpecification> {
            return TaskApiFp(configuration).getCompleteTaskForm(taskId, clusterId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Gets Task audit lines
         * @param {string} engineId 
         * @param {string} taskId 
         * @param {string} tenantId 
         * @param {Date} [dateAfter] 
         * @param {Date} [dateBefore] 
         * @param {string} [eventName] 
         * @param {string} [performerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskAudit(engineId: string, taskId: string, tenantId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, options?: any): AxiosPromise<Array<AuditLog>> {
            return TaskApiFp(configuration).getTaskAudit(engineId, taskId, tenantId, dateAfter, dateBefore, eventName, performerId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retrieves Tasks based on processInstance Id
         * @param {string} processInstanceId 
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTasksForInstance(processInstanceId: string, clusterId: string, options?: any): AxiosPromise<Array<Task>> {
            return TaskApiFp(configuration).listTasksForInstance(processInstanceId, clusterId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Patch Task in Aggregator
         * @param {string} appId 
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTaskInAggregator(appId: string, taskId: string, clusterId: string, requestBody: { [key: string]: string; }, options?: any): AxiosPromise<void> {
            return TaskApiFp(configuration).patchTaskInAggregator(appId, taskId, clusterId, requestBody, options)(axios, basePath);
        },
        /**
         * 
         * @summary Request to upload content for given task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {FileUploadKeyRequest} fileUploadKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestUploadToS3UsingPOST(taskId: string, clusterId: string, fileUploadKeyRequest: FileUploadKeyRequest, options?: any): AxiosPromise<FileUploadKeyResponse> {
            return TaskApiFp(configuration).requestUploadToS3UsingPOST(taskId, clusterId, fileUploadKeyRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Restarts a task
         * @param {string} jobDefinitionId 
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartTask(jobDefinitionId: string, clusterId: string, options?: any): AxiosPromise<void> {
            return TaskApiFp(configuration).restartTask(jobDefinitionId, clusterId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Activates a boundary task signal
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {string} signalName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTaskSignal(taskId: string, clusterId: string, signalName: string, options?: any): AxiosPromise<void> {
            return TaskApiFp(configuration).sendTaskSignal(taskId, clusterId, signalName, options)(axios, basePath);
        },
        /**
         * 
         * @summary Unassign a Task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignTask(taskId: string, clusterId: string, options?: any): AxiosPromise<void> {
            return TaskApiFp(configuration).unassignTask(taskId, clusterId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update a Task (variables)
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {TaskSubmission} taskSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask(taskId: string, clusterId: string, taskSubmission: TaskSubmission, options?: any): AxiosPromise<void> {
            return TaskApiFp(configuration).updateTask(taskId, clusterId, taskSubmission, options)(axios, basePath);
        },
        /**
         * 
         * @summary Upload content to a given task
         * @param {string} taskId 
         * @param {string} clusterId 
         * @param {string} key 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadToS3UsingPOST(taskId: string, clusterId: string, key: string, file: any, options?: any): AxiosPromise<void> {
            return TaskApiFp(configuration).uploadToS3UsingPOST(taskId, clusterId, key, file, options)(axios, basePath);
        },
    };
};

/**
 * TaskApi - object-oriented interface
 * @export
 * @class TaskApi
 * @extends {BaseAPI}
 */
export class TaskApi extends BaseAPI {
    /**
     * 
     * @summary Add candidate user or group
     * @param {string} taskId 
     * @param {string} clusterId 
     * @param {AddCandidateRequest} addCandidateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public addCandidate(taskId: string, clusterId: string, addCandidateRequest: AddCandidateRequest, options?: any) {
        return TaskApiFp(this.configuration).addCandidate(taskId, clusterId, addCandidateRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Assign or claim a Task
     * @param {string} taskId 
     * @param {string} clusterId 
     * @param {UserTaskActionRequest} userTaskActionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public assignTask(taskId: string, clusterId: string, userTaskActionRequest: UserTaskActionRequest, options?: any) {
        return TaskApiFp(this.configuration).assignTask(taskId, clusterId, userTaskActionRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Complete a Task
     * @param {string} taskId 
     * @param {string} clusterId 
     * @param {TaskSubmission} taskSubmission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public completeTask(taskId: string, clusterId: string, taskSubmission: TaskSubmission, options?: any) {
        return TaskApiFp(this.configuration).completeTask(taskId, clusterId, taskSubmission, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Create Task in Aggregator
     * @param {string} clusterId 
     * @param {CreateTaskInstance} createTaskInstance 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public createTaskInAggregator(clusterId: string, createTaskInstance: CreateTaskInstance, options?: any) {
        return TaskApiFp(this.configuration).createTaskInAggregator(clusterId, createTaskInstance, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Download content for a given task
     * @param {string} taskId 
     * @param {string} clusterId 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public downloadFromS3UsingGET(taskId: string, clusterId: string, key: string, options?: any) {
        return TaskApiFp(this.configuration).downloadFromS3UsingGET(taskId, clusterId, key, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the html export of a task by id
     * @param {string} taskId 
     * @param {string} engineId 
     * @param {string} [reqProcessDefinitionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public exportTaskAsHtml(taskId: string, engineId: string, reqProcessDefinitionId?: string, options?: any) {
        return TaskApiFp(this.configuration).exportTaskAsHtml(taskId, engineId, reqProcessDefinitionId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve a Task
     * @param {string} taskId 
     * @param {string} clusterId 
     * @param {boolean} [includeForm] 
     * @param {boolean} [includeVariables] 
     * @param {string} [reqProcessDefinitionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public findTaskById(taskId: string, clusterId: string, includeForm?: boolean, includeVariables?: boolean, reqProcessDefinitionId?: string, options?: any) {
        return TaskApiFp(this.configuration).findTaskById(taskId, clusterId, includeForm, includeVariables, reqProcessDefinitionId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve the list of tasks a user is associated with (own task, team task or all tasks)
     * @param {string} userId 
     * @param {'user' | 'team' | 'all'} taskSearchType 
     * @param {Array<string>} [tenantIds] 
     * @param {boolean} [showHiddenInDefaultUI] Tasks hidden on the SG-Workflow UI (true|false). By Default retrieves all tasks
     * @param {Array<string>} [sort] Sorting criteria in the format: [-]property. Default sort order is ascending, &#x60;-&#x60; prefix indicate descending. Multiple sort criteria are supported.
     * @param {boolean} [useNewQuery] whether to use the optimized query for task lists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public findUserTasks(userId: string, taskSearchType: 'user' | 'team' | 'all', tenantIds?: Array<string>, showHiddenInDefaultUI?: boolean, sort?: Array<string>, useNewQuery?: boolean, options?: any) {
        return TaskApiFp(this.configuration).findUserTasks(userId, taskSearchType, tenantIds, showHiddenInDefaultUI, sort, useNewQuery, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieve specification to complete a Task
     * @param {string} taskId 
     * @param {string} clusterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public getCompleteTaskForm(taskId: string, clusterId: string, options?: any) {
        return TaskApiFp(this.configuration).getCompleteTaskForm(taskId, clusterId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Gets Task audit lines
     * @param {string} engineId 
     * @param {string} taskId 
     * @param {string} tenantId 
     * @param {Date} [dateAfter] 
     * @param {Date} [dateBefore] 
     * @param {string} [eventName] 
     * @param {string} [performerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public getTaskAudit(engineId: string, taskId: string, tenantId: string, dateAfter?: Date, dateBefore?: Date, eventName?: string, performerId?: string, options?: any) {
        return TaskApiFp(this.configuration).getTaskAudit(engineId, taskId, tenantId, dateAfter, dateBefore, eventName, performerId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retrieves Tasks based on processInstance Id
     * @param {string} processInstanceId 
     * @param {string} clusterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public listTasksForInstance(processInstanceId: string, clusterId: string, options?: any) {
        return TaskApiFp(this.configuration).listTasksForInstance(processInstanceId, clusterId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Patch Task in Aggregator
     * @param {string} appId 
     * @param {string} taskId 
     * @param {string} clusterId 
     * @param {{ [key: string]: string; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public patchTaskInAggregator(appId: string, taskId: string, clusterId: string, requestBody: { [key: string]: string; }, options?: any) {
        return TaskApiFp(this.configuration).patchTaskInAggregator(appId, taskId, clusterId, requestBody, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Request to upload content for given task
     * @param {string} taskId 
     * @param {string} clusterId 
     * @param {FileUploadKeyRequest} fileUploadKeyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public requestUploadToS3UsingPOST(taskId: string, clusterId: string, fileUploadKeyRequest: FileUploadKeyRequest, options?: any) {
        return TaskApiFp(this.configuration).requestUploadToS3UsingPOST(taskId, clusterId, fileUploadKeyRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Restarts a task
     * @param {string} jobDefinitionId 
     * @param {string} clusterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public restartTask(jobDefinitionId: string, clusterId: string, options?: any) {
        return TaskApiFp(this.configuration).restartTask(jobDefinitionId, clusterId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Activates a boundary task signal
     * @param {string} taskId 
     * @param {string} clusterId 
     * @param {string} signalName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public sendTaskSignal(taskId: string, clusterId: string, signalName: string, options?: any) {
        return TaskApiFp(this.configuration).sendTaskSignal(taskId, clusterId, signalName, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Unassign a Task
     * @param {string} taskId 
     * @param {string} clusterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public unassignTask(taskId: string, clusterId: string, options?: any) {
        return TaskApiFp(this.configuration).unassignTask(taskId, clusterId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update a Task (variables)
     * @param {string} taskId 
     * @param {string} clusterId 
     * @param {TaskSubmission} taskSubmission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public updateTask(taskId: string, clusterId: string, taskSubmission: TaskSubmission, options?: any) {
        return TaskApiFp(this.configuration).updateTask(taskId, clusterId, taskSubmission, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Upload content to a given task
     * @param {string} taskId 
     * @param {string} clusterId 
     * @param {string} key 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public uploadToS3UsingPOST(taskId: string, clusterId: string, key: string, file: any, options?: any) {
        return TaskApiFp(this.configuration).uploadToS3UsingPOST(taskId, clusterId, key, file, options)(this.axios, this.basePath);
    }

}


