import { SGWorkflowConfig } from '../config';
import { alertUtils } from './actions-alerts';
import store from './store';
import { SelectedTaskInfos, Timeline } from '../models';
import { BUS_SELECTED_TASK_AND_CLUSTER } from './actions';
import {
  ActivityInstanceApiFactory,
  HistoricActivityInstanceDto,
  IncidentApiFactory,
  HistoricIncidentDto,
  SortByIncidentHistory,
  SortByActivityInstanceHistory,
} from '../../../projects/workspaces-api-axios';
import { getWorkspaceInfo } from './actions-task';
import { fetchHistoricProcess } from './action-processInstance';
import { getErrorMessage } from '../utility/utility';

export const getTimelineEvents = async (
  {
    sgWorkflowConfig,
    accessToken,
  }: {
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken: string;
  },
  processInstanceId: string,
  engineId: string,
  taskId?: string
) => {
  if (processInstanceId && accessToken) {
    try {
      const options = { headers: { Authorization: accessToken } };
      const config = { accessToken: accessToken };

      store.setState({
        processHistoryLoadingStatus: 'loading',
        processInstanceId: processInstanceId,
        activityLoadingStatus: 'loading',
      });
      const activityInstanceApi = ActivityInstanceApiFactory(config, sgWorkflowConfig.workspaceApiBasePath, undefined);
      const activtyInstanceApiResponse = await activityInstanceApi.getHistoricActivityInstance(
        processInstanceId,
        engineId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        SortByActivityInstanceHistory.StartTime,
        'desc',
        undefined,
        undefined,
        options
      );
      const activityInstances = activtyInstanceApiResponse.data as HistoricActivityInstanceDto[];

      const incidentApi = IncidentApiFactory(config, sgWorkflowConfig.workspaceApiBasePath, undefined);
      const incidentResponse = await incidentApi.getHistoricIncidents(
        processInstanceId,
        engineId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        SortByIncidentHistory.CreateTime,
        'desc',
        options
      );
      const incidents = incidentResponse.data as HistoricIncidentDto[];

      let timelineEvents: Timeline[] = [];

      activityInstances.forEach(function(activityInstance: HistoricActivityInstanceDto) {
        if (
          activityInstance.activityType === 'userTask' ||
          activityInstance.activityType === 'serviceTask' ||
          activityInstance.activityType === 'scriptTask'
        ) {
          let timeline: Timeline = {
            id: activityInstance.id,
            activityInstanceId: activityInstance.id,
            activityId: activityInstance.activityId,
            taskId: activityInstance.taskId,
            assignee: activityInstance.assignee,
            name: activityInstance.activityType === 'userTask' ? activityInstance.taskName : activityInstance.activityName,
            status: activityInstance.endTime ? 'Completed' : 'In Progress',
            creationDate: new Date(activityInstance.startTime!),
            completionDate: activityInstance.endTime ? new Date(activityInstance.endTime) : '',
            type: activityInstance.activityType,
            cancelled: activityInstance.canceled,
          } as Timeline;
          timelineEvents.push(timeline);
        }
      });

      incidents.forEach(function(incident: HistoricIncidentDto) {
        let timeline: Timeline = {
          id: incident.id,
          activityInstanceId: undefined,
          activityId: incident.activityId,
          taskId: undefined,
          assignee: undefined,
          name: incident.activityId,
          status: incident.open ? 'open' : '',
          creationDate: new Date(incident.createTime!),
          completionDate: incident.endTime ? new Date(incident.endTime) : '',
          type: 'incident',
        } as Timeline;
        timelineEvents.push(timeline);
      });

      timelineEvents.sort(function(a, b) {
        return b.creationDate.getTime() - a.creationDate.getTime();
      });

      if (timelineEvents!.length === 0) {
        let selectedtaskInfos: SelectedTaskInfos = {
          selectedTaskId: undefined,
          selectedEngineId: undefined,
          messageSummaryTaskForm: 'noresult',
        };
        const widgetConfiguration = (window as any).SGWTWidgetConfiguration;
        widgetConfiguration.bus.publish(BUS_SELECTED_TASK_AND_CLUSTER, selectedtaskInfos);
        return {
          timelineEvents: timelineEvents,
          processHistoryLoadingStatus: 'noresult',
        };
      } else {
        let taskIndex = 0;
        if (taskId) {
          let foundIndex = timelineEvents!.findIndex((timelineEvent) => timelineEvent.taskId! === taskId || timelineEvent.id === taskId);
          if (foundIndex != -1) taskIndex = foundIndex;
        }

        let processHistorySelectedTaskId = '';

        processHistorySelectedTaskId = timelineEvents![taskIndex].taskId ? timelineEvents![taskIndex].taskId! : timelineEvents![taskIndex].id;

        let selectedtaskInfos: SelectedTaskInfos = {
          selectedTaskId: processHistorySelectedTaskId,
          selectedEngineId: engineId,
          messageSummaryTaskForm: undefined,
        };

        if (timelineEvents![taskIndex].type === 'userTask') {
          const widgetConfiguration = (window as any).SGWTWidgetConfiguration;
          widgetConfiguration.bus.publish(BUS_SELECTED_TASK_AND_CLUSTER, selectedtaskInfos);
        }

        return {
          processHistorySelectedTaskId: processHistorySelectedTaskId,
          selectedTaskType: timelineEvents![taskIndex].type,
          timelineEvents: timelineEvents,
          processHistoryLoadingStatus: 'loaded',
        };
      }
    } catch (err) {
      alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
      if (store.getState().showProcessHistoryScreen && err.response.data.type === 'ForbiddenException') {
        fetchHistoricProcess(
          {
            sgWorkflowConfig,
            accessToken,
          },
          store.getState().processHistoryFilters
        );
        let newRoute = window.location.href.substring(0, window.location.href.indexOf('/processes/')) + '/processes';
        window.history.pushState('Process', 'Process', newRoute);
        return {
          showProcessHistoryModal: false,
          showProcessFilter: true,
          showProcessHistoryScreen: false,
        };
      } else {
        let selectedtaskInfos: SelectedTaskInfos = {
          selectedTaskId: undefined,
          selectedEngineId: undefined,
          messageSummaryTaskForm: 'error',
        };
        const widgetConfiguration = (window as any).SGWTWidgetConfiguration;
        widgetConfiguration.bus.publish(BUS_SELECTED_TASK_AND_CLUSTER, selectedtaskInfos);
        return {
          processHistoryLoadingStatus: 'error',
        };
      }
    }
  }

  return {};
};

export const getHistoricActivityInstance = async (
  {
    sgWorkflowConfig,
    accessToken,
  }: {
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken: string;
  },
  activityId: string,
  processInstanceId: string,
  processInstanceEngineId: string
) => {
  store.setState({
    activityLoadingStatus: 'loading',
  });
  const options = { headers: { Authorization: accessToken } };
  const config = { accessToken: accessToken };

  try {
    const activityInstanceApi = ActivityInstanceApiFactory(config, sgWorkflowConfig.workspaceApiBasePath, undefined);
    const activityInstanceApiResponse = await activityInstanceApi.getHistoricActivityInstance(
      processInstanceId,
      processInstanceEngineId,
      activityId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      SortByActivityInstanceHistory.StartTime,
      'desc',
      undefined,
      1,
      options
    );
    const activityInstances = activityInstanceApiResponse.data as HistoricActivityInstanceDto[];

    if (activityInstances[0]) {
      await getWorkspaceInfo(activityInstances[0].tenantId!);
      return {
        selectedActivityInstance: activityInstances[0],
        activityLoadingStatus: 'loaded',
        processDefinitionId: activityInstances[0].processDefinitionId,
      };
    } else {
      alertUtils.publishAlertDanger('Error', 'No activity found with id ' + activityId + ' for process instance ' + processInstanceId);
      return {
        activityLoadingStatus: 'error',
      };
    }
  } catch (error) {
    alertUtils.publishAlertDanger('Error', getErrorMessage(error.response && error.response.data));
    return {
      activityLoadingStatus: 'error',
    };
  }
};

export const getHistoricIncident = async (
  {
    sgWorkflowConfig,
    accessToken,
  }: {
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken: string;
  },
  incidentId: string,
  processInstanceId: string,
  processInstanceEngineId: string
) => {
  store.setState({
    activityLoadingStatus: 'loading',
  });
  const options = { headers: { Authorization: accessToken } };
  const config = { accessToken: accessToken };

  try {
    const incidentApi = IncidentApiFactory(config, sgWorkflowConfig.workspaceApiBasePath, undefined);
    const incidentResponse = await incidentApi.getHistoricIncidents(
      processInstanceId,
      processInstanceEngineId,
      incidentId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      SortByIncidentHistory.CreateTime,
      'desc',
      options
    );

    const incidents = incidentResponse.data as HistoricIncidentDto[];

    if (incidents[0]) {
      await getWorkspaceInfo(incidents[0].tenantId!);
      return {
        selectedIncident: incidents[0],
        activityLoadingStatus: 'loaded',
        processDefinitionId: incidents[0].processDefinitionId,
      };
    } else {
      alertUtils.publishAlertDanger('Error', 'No incident found with id ' + incidentId + ' for process instance ' + processInstanceId);
      return {
        activityLoadingStatus: 'error',
      };
    }
  } catch (error) {
    alertUtils.publishAlertDanger('Error', getErrorMessage(error.response && error.response.data));
    return {
      activityLoadingStatus: 'error',
    };
  }
};

export const updateProcessInstanceId = ({}, processInstanceId?: string) => ({
  processInstanceId,
});
