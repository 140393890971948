import { Task } from '../../projects/tasks-api-axios';
import { Workspace } from '../../projects/workspaces-api-axios';

export type TaskSearchSort = '-created' | 'name';
export type ProcessHistorySearch = 'MY_PROCESS' | 'ADMIN' | 'VIEWER' | 'PARTNER' | 'PARTNER_ADMIN' | 'PARTNER_VIEWER';
export type ProcessHistorySortBy =
  // | 'instanceId'
  | 'definitionId'
  // | 'definitionKey'
  // | 'definitionName'
  // | 'definitionVersion'
  | 'businessKey'
  | 'startTime'
  | 'endTime'
  | 'duration';
// | 'tenantId';
export type ProcessHistoryStatus = 'active' | 'all' | 'completed' | 'suspended' | 'cancelled';
export type UserTask = 'user' | 'team' | 'all';
export type ApiCallStatus = 'loading' | 'loaded' | 'error' | 'noresult';
export type TaskDisplay = 'card' | 'table' | 'split';
export type AssignOption = 'claim' | 'assign' | 'unassign' | 'assignAnyone';
export type LaunchProcessTab = 'fav' | 'all' | 'pub';
export type SortType = 'asc' | 'desc';
export type TimelineEventType = 'userTask' | 'scriptTask' | 'serviceTask' | 'incident';

export type CanDoActionOnTask = {
  canSubmit: boolean;
  formIsValid: boolean;
};

export type LaunchProcessSort = {
  name: {
    type: SortType;
    active: boolean;
  };
  creationDate: {
    type: SortType;
    active: boolean;
  };
};

export enum AlertType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  DEFAULT = 'info',
}

export type TasksByProcessName = {
  [processName: string]: Task[];
};

export class Filters {
  selectedTab?: UserTask;
  filterValue?: string;
  taskSearchSort?: TaskSearchSort;
}

export class SelectedTaskInfos {
  selectedTaskId?: string;
  selectedEngineId?: string;
  messageSummaryTaskForm?: ApiCallStatus;
}

export type SgWorkflowAlert = {
  _id?: string;
  title: string;
  message: string;
  type?: AlertType;
};

export type FormioSubmission = {
  isValid?: boolean;
  data?: any;
};

export type FormioState = {
  formioRef?: any;
  alreadyChanged: any;
};

export type ProcessHistoryFilters = {
  page: number;
  pageSize: number;
  processName?: string;
  processId?: string;
  status?: ProcessHistoryStatus[];
  startedBefore?: Date;
  startedAfter?: any;
  finishedBefore?: Date;
  finishedAfter?: Date;
  processHistorySearch: ProcessHistorySearch;
  sortBy: ProcessHistorySortBy;
  sortOrder: SortType;
  userWorkspaces?: Workspace;
  processHistoryFilterEngineId: string;
  [key: string]: any;
  withIncidents?: boolean;
};

export type UserSettingsProcessHistoryFilters = {
  processName?: string[];
  processId?: string[];
  status?: ProcessHistoryStatus[];
  startedBefore?: Date[];
  startedAfter?: Date[];
  finishedBefore?: Date[];
  finishedAfter?: Date[];
  processHistorySearch: string;
  processHistoryTypeView: ProcessHistorySearch;
  userWorkspaces?: string[];
  [key: string]: any;
};

export type LaunchProcessFilters = {
  searchFilter: string;
  selectFilter: string;
};

export type TaskList = {
  displayed: boolean;
  taskList: string[];
};

export type Timeline = {
  id: string;
  activityInstanceId?: string;
  activityId: string;
  taskId?: string;
  assignee?: string;
  name: string;
  status: string;
  creationDate: Date;
  completionDate: Date;
  type: TimelineEventType;
  cancelled: Boolean;
};

export const IconInfo = {
  scriptTask: {
    backgroundMedium: 'bg-spicy-mix-alpha-md mt-1',
    backgroundSmall: 'bg-spicy-mix-alpha-sm',
    iconColor: 'text-spicy-mix',
    icon: 'code',
  },
  serviceTask: {
    backgroundMedium: 'bg-victoria-alpha-md mt-1',
    backgroundSmall: 'bg-victoria-alpha-sm',
    iconColor: 'text-victoria',
    icon: 'open_in_browser',
  },
  userTask: {
    backgroundMedium: 'bg-faded-jade-alpha-md mt-1',
    backgroundSmall: 'bg-faded-jade-alpha-sm',
    iconColor: 'text-faded-jade',
    icon: 'person',
  },
  incident: {
    backgroundMedium: '',
    backgroundSmall: 'visibility-hidden',
    iconColor: '',
    icon: '',
  },
};

export interface PageOfProcessInstances {
  instances?: ProcessInstance[];
  total?: number;
  nbPages?: number;
}

export interface ProcessInstance {
  id?: string;
  businessKey?: string;
  duration?: string;
  startTime?: string;
  endTime?: string;
  initiator?: string;
  lastTaskAssignee?: string;
  lastTaskName?: string;
  processDefinitionId?: string;
  processDefinitionKey?: string;
  processDefinitionVersion?: number;
  processDefinitionName?: string;
  state?: string;
  tenantId?: string;
  variables?: ProcessInstanceVariable[];
  engineId?: string;
  numberOfIncidents?: number;
  workspaceTitle?: string;
}

export interface instanceStatusSelectOption {
  name: string;
  status: string;
}

export interface ProcessInstanceVariable {
  name?: string;
  type?: ProcessInstanceVariableTypeEnum;
  value?: string;
}

export enum ProcessInstanceVariableTypeEnum {
  STRING = 'STRING',
  INTEGER = 'INTEGER',
  DECIMAL = 'DECIMAL',
  BOOLEAN = 'BOOLEAN',
  LONG = 'LONG',
  DATE = 'DATE',
  TIMESTAMP = 'TIMESTAMP',
  JSON = 'JSON',
  OBJECT = 'OBJECT',
}

export type ProcessViewTabDescriptor = {
  // Unique identifier
  id: string;
  // Kind of view (admin or related to user)
  typeView: ProcessHistorySearch;
  // Tab label
  label: string;
  // Engine Id related to the view
  engineId: string;
  // Not in dom (useful for LAN or not)
  visible: boolean;
};
