import { h } from 'preact';
import { Connect } from 'redux-zero/preact';
import { TaskStateInterface, StoreStateInterface } from './store';

export default function(actions = {}, storeKeys: (keyof StoreStateInterface)[]): any {
  return (Child: any) => (props: any) => (
    <Connect
      actions={actions}
      mapToProps={(s: any) => {
        const newProps = Object.keys(s)
          .filter((key) => storeKeys.indexOf(key as keyof StoreStateInterface) >= 0)
          .reduce((obj2: any, key) => ((obj2[key] = s[key]), obj2), {});
        return newProps;
      }}>
      {(mappedProps: any) => <Child {...mappedProps} {...props} />}
    </Connect>
  );
}
