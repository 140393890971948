import * as moment from 'moment';
import 'moment/min/locales';
import * as _ from 'lodash';

import { SGWorkflowConfig } from '../config';
import {
  WorkspaceApiFactory,
  WorkflowApiFactory,
  Workflow,
  Workspace,
  StartWorkflowSubmission,
  UserSettings,
  WorkspacesFindPostRequest,
} from '../../../projects/workspaces-api-axios';
import store from './store';
import { alertUtils } from './actions-alerts';
import { LaunchProcessTab, LaunchProcessSort } from './../../common/models';
import { fetchUserSettings } from './actions-user-settings';
import { Task } from '../../../projects/tasks-api-axios';
import { InstanceApiFactory } from '../../../projects/workspaces-api-axios';

//@ts-ignore
import Analytics from '../../analytics/analytics.service';
import { getErrorMessage } from '../utility/utility';

const userInfo = _.get(window, 'SGWTWidgetConfiguration.bus.lastValues["sg-connect.user-info"]');

moment.locale('en');
if (userInfo && userInfo['preferred_language']) {
  userInfo['preferred_language'] ? moment.locale(userInfo['preferred_language']) : moment.locale('en');
}

export const fetchProcess = async ({
  sgWorkflowConfig,
  accessToken,
  user,
}: {
  accessToken?: string;
  sgWorkflowConfig: SGWorkflowConfig;
  user: string;
}) => {
  // Start Analytics Transaction
  const analyticsInstance = Analytics.getInstance();

  if (analyticsInstance) {
    analyticsInstance.startTransaction(
      Analytics.constants.middlewareInteraction.actions.loadAvailableProcessToLaunch,
      Analytics.constants.transactions.type.customInteractions,
      { managed: true }
    );
  }

  try {
    const workspaceApi = WorkflowApiFactory({ accessToken: accessToken }, sgWorkflowConfig.workspaceApiBasePath, undefined);
    store.setState({
      workflows: [],
      workspaces: [],
      workflowsLoadingStatus: 'loading',
    });

    const response = await workspaceApi.listWorkflows(
      undefined,
      10000,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined, // deploymentId
      undefined, // showDisabled
      true,
      true,
      undefined,
      {
        headers: { Authorization: accessToken },
      }
    );
    let workflows = response.data.content as Workflow[];
    let workspaces: Workspace[] = [];

    let workspaceIds: string[] = [];

    if (workflows.length > 0) {
      workflows.forEach(function(workflow) {
        workspaceIds.push(workflow.workspaceId);
      });
      if (workspaceIds.length > 0) {
        await fetchWorkspace(
          {
            sgWorkflowConfig,
            accessToken,
          },
          false,
          undefined,
          workspaceIds.filter((v, i, a) => a.indexOf(v) === i)
        );
      }
      workspaces = store.getState().userWorkspaces;

      let userSettings = {
        userId: user,
        settings: {
          favWorkflows: [],
        },
      } as UserSettings;
      try {
        userSettings = (await fetchUserSettings({
          sgWorkflowConfig,
          accessToken,
          user,
        })) as UserSettings;

        if (!userSettings) {
          userSettings = {
            userId: user,
            settings: {
              favWorkflows: [],
            },
          } as UserSettings;
        }
      } catch (err) {
        alertUtils.publishAlertDanger('Error', 'could not fetch favorite workflows');
      }

      const workflowMap = _filterWorkflows(workflows, userSettings);
      const sort = {
        name: {
          type: 'desc',
          active: false,
        },
        creationDate: {
          type: 'desc',
          active: true,
        },
      } as LaunchProcessSort;
      const favWorkflows = _sortWorkflows(sort, workflowMap!.get('favWorkflows') as Workflow[]);
      const allWorkflows = _sortWorkflows(sort, workflowMap!.get('allWorkflows') as Workflow[]);
      const pubWorkflows = _sortWorkflows(sort, workflowMap!.get('pubWorkflows') as Workflow[]);

      let sortedWorkspaces = workspaces.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
      sortedWorkspaces.unshift({
        id: 'ALL',
        title: 'ALL',
      } as Workspace);

      const data = {
        selectedWorkflow: workflowMap!.get('selectedStartWorkflowFromUrl'),
        favFilteredWorkflows: [...favWorkflows!],
        allFilteredWorkflows: [...allWorkflows!],
        pubFilteredWorkflows: [...pubWorkflows!],
        userSettings: userSettings,
        allWorkflows: [...allWorkflows!],
        favWorkflows: [...favWorkflows!],
        pubWorkflows: [...pubWorkflows!],
        workspaces: sortedWorkspaces,
        workflowsLoadingStatus: 'loaded',
        launchProcessSelectedTab: favWorkflows.length > 0 ? 'fav' : 'all',
      };

      // Send response to middleware
      return data;
    } else {
      return {
        workflowsLoadingStatus: 'noresult',
      };
    }
  } catch (err) {
    alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
    return {
      workflowsLoadingStatus: 'error',
    };
  }
};

const sortVersionDesc = function(a: Workflow, b: Workflow) {
  return b.version! - a.version!;
};

export const _filterWorkflows = (wfs: Workflow[], userSettings: UserSettings) => {
  let workflowMap = new Map();
  let favWorkflows: Workflow[] = [];
  let allWorkflows: Workflow[] = [];
  let pubWorkflows: Workflow[] = [];
  let selectedWorkflows: Workflow[] = [];
  if (userSettings.settings) {
    const settings: any = userSettings.settings;
    const favSlugList: any = settings['favWorkflows'];

    wfs.forEach(function(wf) {
      if (favSlugList && favSlugList.indexOf(wf.id) > -1) {
        favWorkflows.push(wf);
        wf.isPublic ? pubWorkflows.push(wf) : allWorkflows.push(wf);
      } else {
        wf.isPublic ? pubWorkflows.push(wf) : allWorkflows.push(wf);
      }
      if (
        store.getState().startWorflowSlug &&
        store.getState().startWorkspaceId &&
        wf.workspaceId === store.getState().startWorkspaceId &&
        wf.slug === store.getState().startWorflowSlug
      ) {
        selectedWorkflows.push(wf);
      }
    });

    if (selectedWorkflows && selectedWorkflows.length > 0) {
      const sortedWorkflowsByversion: Workflow[] = selectedWorkflows.sort(sortVersionDesc);
      workflowMap.set('selectedStartWorkflowFromUrl', sortedWorkflowsByversion[0]);
    } else {
      workflowMap.set('selectedStartWorkflowFromUrl', undefined);
    }
    workflowMap.set('favWorkflows', favWorkflows);
    workflowMap.set('allWorkflows', allWorkflows);
    workflowMap.set('pubWorkflows', pubWorkflows);
    return workflowMap;
  }
};

export const _sortWorkflows = (sort: LaunchProcessSort, wfs: Workflow[]) => {
  if (sort.name.active) {
    if (sort.name.type === 'asc') {
      return wfs.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
    } else {
      return wfs.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1));
    }
  } else {
    if (sort.creationDate.type === 'asc') {
      return wfs.sort((a, b) => (moment.utc(a.createdAt) > moment.utc(b.createdAt) ? 1 : -1));
    } else {
      return wfs.sort((a, b) => (moment.utc(a.createdAt) > moment.utc(b.createdAt) ? -1 : 1));
    }
  }
};

export const fetchWorkspace = async (
  {
    sgWorkflowConfig,
    accessToken,
  }: {
    accessToken?: string;
    sgWorkflowConfig: SGWorkflowConfig;
  },
  showManagedWorkspaces: boolean,
  showHiddenInDefaultUI: boolean | undefined,
  workspaceIds: string[]
) => {
  try {
    const workspaceApi = WorkspaceApiFactory({ accessToken: accessToken }, sgWorkflowConfig.workspaceApiBasePath, undefined);
    let workspacesFindPostRequest: WorkspacesFindPostRequest = {
      pageNumber: undefined,
      pageSize: 100000,
      sort: undefined,
      slugIn: undefined,
      showDisabled: undefined,
      showHiddenInDefaultUI: showHiddenInDefaultUI,
      syncUser: false,
      showManagedWorkspaces: showManagedWorkspaces,
      workspaceIdIn: workspaceIds,
    };
    const response = await workspaceApi.listWorkspacesPostRequest(workspacesFindPostRequest, {
      headers: { Authorization: accessToken },
    });

    let userRoles = store.getState().userRoles;
    if (!userRoles && response && response.headers && response.headers['user-roles']) {
      store.setState({
        userRoles: response.headers['user-roles'],
      });
    }

    store.setState({
      userWorkspaces: response.data.content,
    });
  } catch (error) {
    alertUtils.publishAlertDanger('Warning', 'Error fetching user workspaces');
  }

  return {};
};

export const updateFilteredWorkflows = ({}, sort: LaunchProcessSort, workflows: Workflow[], tab: LaunchProcessTab) => {
  if (tab === 'fav') {
    store.setState({
      favFilteredWorkflows: _sortWorkflows(sort, workflows),
    });
  } else if (tab === 'all') {
    store.setState({
      allFilteredWorkflows: _sortWorkflows(sort, workflows),
    });
  } else {
    store.setState({
      pubFilteredWorkflows: _sortWorkflows(sort, workflows),
    });
  }
};

export const launchProcess = async (
  {
    sgWorkflowConfig,
    accessToken,
  }: {
    accessToken?: string;
    sgWorkflowConfig: SGWorkflowConfig;
  },
  selectedWorkflow: Workflow,
  startWorkflowSubmission: StartWorkflowSubmission
) => {
  try {
    store.setState({
      launchProcessStatus: 'loading',
      selectedWorkflow: selectedWorkflow,
      startWorflowSlug: selectedWorkflow.slug,
      startWorkspaceId: selectedWorkflow.workspaceId,
    });

    const workspaceApi = WorkflowApiFactory({ accessToken: accessToken }, sgWorkflowConfig.workspaceApiBasePath, undefined);
    const response = await workspaceApi.startWorkflow(selectedWorkflow.id!, startWorkflowSubmission, { headers: { Authorization: accessToken } });

    return {
      startWorkflowResponse: response.data,
      launchProcessStatus: 'loaded',
    };
  } catch (err) {
    return {
      launchProcessStatus: 'error',
      launchProcessErrorMessage: getErrorMessage(err.response && err.response.data),
    };
  }
};

export const _extractTheFirstTaskAssignableToUser = (tasks: Task[]) => {
  const filteredTasksWhichCanBeAssignedToUser = tasks.filter((t) => t.concernsUser);

  return filteredTasksWhichCanBeAssignedToUser.shift();
};

export const closeLaunchModal = ({}) => {
  store.setState({
    launchProcessErrorMessage: undefined,
    startWorkflowSpec: undefined,
    launchProcessStatus: undefined,
    showStartProcessModal: false,
  });
};

export const openLaunchProcessModal = async (
  {
    sgWorkflowConfig,
    accessToken,
  }: {
    accessToken?: string;
    sgWorkflowConfig: SGWorkflowConfig;
  },
  selectedWorkflow: Workflow
) => {
  let launchProcessTransaction;
  const analyticsInstance = Analytics.getInstance();
  try {
    store.setState({
      fetchStartWorkflowLoadingStatus: 'loading',
      selectedWorkflow: selectedWorkflow,
      startWorflowSlug: selectedWorkflow.slug,
      startWorkspaceId: selectedWorkflow.workspaceId,
    });
    const workspaceApi = WorkflowApiFactory({ accessToken: accessToken }, sgWorkflowConfig.workspaceApiBasePath, undefined);

    const response = await workspaceApi.retrieveStartWorkflowById(selectedWorkflow.id!, { headers: { Authorization: accessToken } });

    if (analyticsInstance) {
      const transactionType = Analytics.constants.transactions.type.customInteractions;
      const transactionName = Analytics.constants.middlewareInteraction.actions.launchProcess;

      launchProcessTransaction = Analytics.getCurrentTransaction();
      launchProcessTransaction.name = transactionName;
      launchProcessTransaction.type = transactionType;
      Analytics.endTransaction(launchProcessTransaction);
    }

    return {
      startWorkflowSpec: response.data,
      fetchStartWorkflowLoadingStatus: 'loaded',
      showStartProcessModal: true,
    };
  } catch (err) {
    if (analyticsInstance) {
      Analytics.endTransaction(launchProcessTransaction);
    }

    alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
    return {
      fetchStartWorkflowLoadingStatus: 'error',
    };
  }
};

export const exportProcessAsHtml = async (
  {
    sgWorkflowConfig,
    accessToken,
  }: {
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken: string;
  },
  processInstanceId: string,
  engineId: string,
  realmType: 'sglan' | 'sgmarkets'
) => {
  try {
    const options = { headers: { Authorization: accessToken } };
    const config = { accessToken: accessToken };
    const instanceApi = InstanceApiFactory(config, sgWorkflowConfig.workspaceApiBasePath);
    const htmlProcessExport: any = await instanceApi.exportProcessAsHtml(processInstanceId, engineId, realmType, options);

    const data = new Blob([htmlProcessExport.data], { type: 'text/html' });
    const htmlURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = htmlURL;

    let filename = `${engineId}_${processInstanceId}.html`;
    let contentDisposition =
      htmlProcessExport.headers && htmlProcessExport.headers['content-disposition'] ? htmlProcessExport.headers['content-disposition'] : undefined;

    if (contentDisposition) {
      // inline; filename=_TRE__some_Multi_line_name.html
      let pos = contentDisposition.lastIndexOf('=');
      if (pos >= 0) {
        filename = contentDisposition.substring(pos + 1);
      }
    }

    tempLink.setAttribute('download', filename);
    tempLink.click();

    new Promise((resolve, reject) => {
      window.URL.revokeObjectURL(htmlURL);
      tempLink.remove();
      resolve('Operation complete');
    })
      .then((result) => {})
      .catch((error) => {});

    return {};
  } catch (err) {
    alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
  }
};
