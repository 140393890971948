import { IncidentControllerApiFactory } from '../../../projects/dqhub-api-axios';
import { alertUtils } from './actions-alerts';
import { SGWorkflowConfig } from '../config';
import { Form, Task, TaskVariable } from '../../../projects/tasks-api-axios';

export const uploadFile = async ({
  accessToken,
  sgWorkflowConfig,
  file,
  taskId,
  incidentId,
}: {
  accessToken: string;
  sgWorkflowConfig: SGWorkflowConfig;
  file: File;
  taskId: string;
  incidentId: string;
}) => {
  const api = IncidentControllerApiFactory({ accessToken: accessToken }, sgWorkflowConfig.dqhubApiBasePath, undefined);

  try {
    await api.uploadDqHubFileUsingPOST(incidentId, file, undefined, _getOptionsToCallDqHub(accessToken));

    return {
      storage: 'dqhub',
      name: file.name,
      taskId: taskId,
      size: file.size,
      type: file.type,
      incidentId: incidentId,
    };
  } catch (error) {
    let msg = error.error || error.message || error;
    alertUtils.publishAlertDanger('File upload failed', msg);
    throw msg;
  }
};

export const downloadFile = async ({
  accessToken,
  sgWorkflowConfig,
  file,
}: {
  accessToken: string;
  sgWorkflowConfig: SGWorkflowConfig;
  file: FileDescriptor;
}) => {
  const api = IncidentControllerApiFactory({ accessToken: accessToken }, sgWorkflowConfig.dqhubApiBasePath, undefined);
  const options = {
    headers: {
      Accept: 'application/octet-stream',
      Authorization: accessToken,
    },
  };

  try {
    let incidentId = file.incidentId;

    let response = await api.getDqHubAttachmentsUsingGET(incidentId, file.name, options);

    const blObj = new Blob([new Buffer(response.data)], { type: file.type });

    return { ...file, storage: 'base64', url: blObj };
  } catch (error) {
    let msg = error.error || error.message || error;
    alertUtils.publishAlertDanger('File download failed', msg);
    throw msg;
  }
};

export const loadDqHubAttachments = async ({
  accessToken,
  sgWorkflowConfig,
  task,
}: {
  accessToken: string;
  sgWorkflowConfig: SGWorkflowConfig;
  task: Task;
}) => {
  let fileComponents = _findDqHubFileComponents(task.form as Form);

  if (fileComponents.length > 0) {
    for (const fileComponent of fileComponents) {
      let incidentId = _findIncidentIdForComponent(fileComponent, task);
      let allAttachments = (await _findAllAttachmentsForIncident(incidentId, accessToken, sgWorkflowConfig)) as Array<string>;

      if (allAttachments.length > 0) {
        let fileComponentKey = fileComponent.key;

        let currentAttachmentsInTask = _findAttachmentsAlreadyInVariables(fileComponentKey, task);
        let missingAttachmentsInTask = _removeAttachmentsAlreadyInTask(allAttachments, currentAttachmentsInTask);

        if (missingAttachmentsInTask.length > 0) {
          let fileDescriptors = _generateFileDescriptors(task, fileComponentKey, missingAttachmentsInTask);

          _addIncidentIdInFileDescriptors(fileDescriptors, incidentId);

          _updateFormSubmissionWithMissingAttachments(task, fileComponentKey, fileDescriptors);
        }
      }
    }
  }
};

export const extractIncidentIdFromTask = ({ task, incidentIdVariableName }: { task: Task; incidentIdVariableName: string }) => {
  if (incidentIdVariableName) {
    let variable = task.variables!!.find((value) => value.name === incidentIdVariableName);

    if (variable) {
      return variable.value;
    } else {
      alertUtils.publishAlertDanger('Missing Incident Id', `Process was launch without variable ${incidentIdVariableName} set`);
      throw `Process was launch without variable ${incidentIdVariableName} set`;
    }
  } else {
    alertUtils.publishAlertDanger('Missing IncidentId Variable Name', 'No IncidentID variable name was set in model');
    throw 'No IncidentID variable name was set in model';
  }
};

let _getOptionsToCallDqHub = function(accessToken: string) {
  return {
    headers: { Authorization: accessToken },
  };
};

let _findDqHubFileComponents = function(form: Form): Array<FileComponent> {
  let dqHubStorageComponents: Array<FileComponent> = [];

  if (form && form.spec) {
    // @ts-ignore
    let components: Array<FileComponent> = form.spec['components'] as Array<FileComponent>;

    if (components) {
      components.forEach(function(component) {
        if (component.storage == 'dqhub') {
          dqHubStorageComponents.push(component);
        }
      });
    }
  }
  return dqHubStorageComponents;
};

let _findIncidentIdForComponent = function(fileComponent: FileComponent, task: Task): string {
  let incidentVariableName = fileComponent.dir;

  return extractIncidentIdFromTask({ task: task, incidentIdVariableName: incidentVariableName });
};

let _findAllAttachmentsForIncident = async function(incidentId: string, accessToken: string, sgWorkflowConfig: SGWorkflowConfig) {
  try {
    const api = IncidentControllerApiFactory({ accessToken: accessToken }, sgWorkflowConfig.dqhubApiBasePath, undefined);
    let response = await api.getIncidentUsingGET(incidentId, _getOptionsToCallDqHub(accessToken));

    // @ts-ignore
    let attachments = response.data.Attachments || [];

    return attachments;
  } catch (error) {
    let msg = error.error || error.message || error;
    alertUtils.publishAlertDanger('Find all incident attachments failed', msg);
    throw msg;
  }
};

let _findAttachmentsAlreadyInVariables = function(fileComponentName: string, task: Task) {
  let attachments: Array<string> = [];
  let variable = task.variables!!.find((v) => v.name === fileComponentName);

  if (variable && variable.value) {
    let descriptors: Array<FileDescriptor> = JSON.parse(variable.value);

    descriptors.forEach(function(descriptor) {
      if (descriptor.storage == 'dqhub') {
        attachments.push(descriptor.name);
      }
    });
  }

  return attachments;
};

let _removeAttachmentsAlreadyInTask = function(allAttachments: Array<string>, attachmentsAlreadyInTask: Array<string>) {
  let filteredAttachments: Array<string> = [];

  allAttachments.forEach(function(attachment) {
    if (!attachmentsAlreadyInTask.find((value) => value == attachment)) {
      filteredAttachments.push(attachment);
    }
  });

  return filteredAttachments;
};

let _generateFileDescriptors = function(task: Task, fileComponentName: string, missingAttachments: Array<string>): Array<FileDescriptor> {
  let variable = task.variables!!.find((v) => v.name === fileComponentName);
  let descriptors: Array<FileDescriptor> = [];

  if (variable && variable.value) {
    descriptors = JSON.parse(variable.value);
  }

  missingAttachments.forEach(function(attachment) {
    let descriptor: FileDescriptor = {
      storage: 'dqhub',
      name: attachment,
      size: NaN,
      type: _findExtensionType(attachment),
      originalName: attachment,
    } as FileDescriptor;

    descriptors.push(descriptor);
  });

  return descriptors;
};

let _addIncidentIdInFileDescriptors = function(descriptors: Array<FileDescriptor>, incidentId: string) {
  descriptors.forEach(function(descriptor) {
    descriptor.incidentId = incidentId;
  });
};

let _updateFormSubmissionWithMissingAttachments = function(task: Task, fileComponentName: string, descriptors: Array<FileDescriptor>) {
  // @ts-ignore
  task.formSubmission[fileComponentName] = descriptors;
};

let _findExtensionType = function(filename: string): string {
  const regex = /(?:\.([^.]+))?$/;

  // @ts-ignore
  const fileExtension = regex.exec(filename)[1];
  const extension = extensions.find((value) => value.extension === fileExtension);

  if (extension) {
    return extension.associatedType;
  } else {
    return 'text/plain';
  }
};

interface FileDescriptor {
  storage: string;
  name: string;
  originalName: string;
  type: string;
  size: number;
  incidentId: string;
}

interface FileComponent {
  dir: string;
  key: string;
  label: string;
  storage: string;
}

type Extension = { extension: string; associatedType: string };

let extensions: Extension[] = [
  { extension: 'doc', associatedType: 'application/msword' },
  { extension: 'docx', associatedType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  { extension: 'ods', associatedType: 'application/vnd.oasis.opendocument.spreadsheet' },
  { extension: 'odt', associatedType: 'application/vnd.oasis.opendocument.text' },
  { extension: 'pdf', associatedType: 'application/pdf' },
  { extension: 'ppt', associatedType: 'application/vnd.ms-powerpoint' },
  { extension: 'pptx', associatedType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
  { extension: 'xls', associatedType: 'application/vnd.ms-excel' },
  { extension: 'xlsx', associatedType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  { extension: 'xlsb', associatedType: 'application/vnd.ms-excel.sheet.binary.macroEnabled' },
  { extension: 'xlsm', associatedType: 'application/vnd.ms-excel.sheet.macroEnabled' },
  { extension: 'gif', associatedType: 'image/gif' },
  { extension: 'jpg', associatedType: 'image/jpeg' },
  { extension: 'png', associatedType: 'image/png' },
  { extension: 'svg', associatedType: 'image/svg+xml' },
  { extension: 'txt', associatedType: 'text/plain' },
  { extension: 'msg', associatedType: 'text/plain' },
];
