import { AlertType, SgWorkflowAlert } from '../models';

export const BUS_ALERTS_TOPIC = 'sg-workflow-alerts-handler.refresh_alerts';

export const alertUtils = {
  publish(alert: SgWorkflowAlert) {
    if (window.SGWTWidgetConfiguration) {
      window.SGWTWidgetConfiguration.bus.publish(BUS_ALERTS_TOPIC, alert);
    } else {
      console.error('Widget SgWorkflowAlertsHandler was not mount in the current page. Alert cannot be displayed');
    }
  },
  publishAlertInfo(title: string, message: string) {
    const alert: SgWorkflowAlert = {
      title: title,
      message: message,
      type: AlertType.INFO,
    };
    this.publish(alert);
  },
  publishAlertSuccess(title: string, message: string) {
    const alert: SgWorkflowAlert = {
      title: title,
      message: message,
      type: AlertType.SUCCESS,
    };
    this.publish(alert);
  },
  publishAlertWarning(title: string, message: string) {
    const alert: SgWorkflowAlert = {
      title: title,
      message: message,
      type: AlertType.WARNING,
    };
    this.publish(alert);
  },
  publishAlertDanger(title: string, message: string) {
    const alert: SgWorkflowAlert = {
      title: title,
      message: message,
      type: AlertType.DANGER,
    };
    this.publish(alert);
  },
};
