import axios from 'axios';
import * as _ from 'lodash';

import { SGWorkflowConfig } from '../config';
import { alertUtils } from './actions-alerts';
import store from './store';
import {
  PageOfProcessInstances,
  ProcessHistoryFilters,
  ProcessHistoryStatus,
  ProcessInstance,
  UserSettingsProcessHistoryFilters,
  ProcessViewTabDescriptor,
  ProcessHistorySearch,
} from '../models';
import { fetchUserSettings } from '../../common/state/actions-user-settings';
import { fetchWorkspace } from '../../common/state/actions-process';
import {
  Workspace,
  UserSettings,
  InstanceApiFactory as InstanceApiFactoryV4,
  PageOfInstance,
  Instance,
  InstanceTypeView,
  InstanceStatus,
  SortByInstanceHistory,
} from '../../../projects/workspaces-api-axios';
import { TaskApiFactory } from '../../../projects/tasks-api-axios';
//@ts-ignore
import Analytics from '../../analytics/analytics.service';
import { getErrorMessage } from '../utility/utility';
import { WorkspaceApiFactory } from '../../../projects/workspaces-api-axios';
import {
  getDate,
  getEngineId,
  getIncidentsFlag,
  getProcessId,
  getProcessName,
  getSortDirection,
  getSortOrder,
  getStatus,
  getTenantId,
  getViewType,
  hasProcessViewerRole,
} from '../utils';

const CancelToken = axios.CancelToken;
let loadHistoricProcessesCall = CancelToken.source();

const instanceHistorySortBy: Map<string, SortByInstanceHistory> = new Map<string, SortByInstanceHistory>([
  ['startTime', SortByInstanceHistory.StartTime],
  ['endTime', SortByInstanceHistory.EndTime],
  ['duration', SortByInstanceHistory.Duration],
  ['definitionId', SortByInstanceHistory.DefinitionId],
  ['businessKey', SortByInstanceHistory.BusinessKey],
]);

export const getWorkSpaceInfo = (workspaceId: string): Promise<any> => {
  const {
    sgWorkflowConfig: { workspaceApiBasePath },
    accessToken,
  } = store.getState();

  const options = { headers: { Authorization: accessToken } };
  const workspaceApi = WorkspaceApiFactory({ accessToken }, workspaceApiBasePath);

  return workspaceApi.retrieveWorkspaceById(workspaceId, options).then((response) => response.data);
};

export const getTaskByProcessId = (id: any, engineId: any) => {
  if (!id || !engineId) return;

  const {
    sgWorkflowConfig: { taskApiBasePath },
    accessToken,
  } = store.getState();

  const options = { headers: { Authorization: accessToken } };
  const taskApi = TaskApiFactory({ accessToken }, taskApiBasePath);

  return taskApi
    .listTasksForInstance(id, engineId, options)
    .then((response) => response.data)
    .catch(() => alertUtils.publishAlertDanger('Error', `Cant get task using the Process Id: ${id}`));
};

export const fetchHistoricProcessWithUserSettings = async (
  {
    sgWorkflowConfig,
    accessToken,
    user,
    processViewTabDescriptors,
  }: {
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken: string;
    user: string;
    processViewTabDescriptors: ProcessViewTabDescriptor[];
  },
  isUserInLan: boolean
) => {
  if (user && accessToken) {
    store.setState({
      processUserSettingsFiltersStatus: 'loading',
    });

    let userSettings: UserSettings = {} as UserSettings;
    let userWorkspaces: Workspace[] = store.getState().userWorkspaces;
    let processHistoryFilters: ProcessHistoryFilters = {
      status: ['active'],
      processHistorySearch: isUserInLan ? 'MY_PROCESS' : 'PARTNER',
      page: 0,
      pageSize: 30,
      sortBy: 'startTime',
      sortOrder: 'desc',
      userWorkspaces: undefined,
      startedAfter: new Date((new Date() as any) - 1000 * 60 * 60 * 24 * 60),
      processHistoryFilterEngineId: isUserInLan ? sgWorkflowConfig.defaultEngineId : sgWorkflowConfig.internetExposureEngineId,
    };

    if (!store.getState().userSettings) {
      userSettings = (await fetchUserSettings({
        sgWorkflowConfig,
        accessToken,
        user,
      })) as UserSettings;
    } else {
      userSettings = store.getState().userSettings;
    }
    if (isUserInLan && !userWorkspaces) {
      await fetchWorkspace(
        {
          sgWorkflowConfig,
          accessToken,
        },
        true,
        false,
        []
      );
    }
    userWorkspaces = store.getState().userWorkspaces;
    let favProcessFilters: UserSettingsProcessHistoryFilters | undefined = undefined;
    if (userSettings && userSettings!.settings) {
      const settings: any = userSettings!.settings;
      favProcessFilters = settings ? settings['favProcessFilters'] : undefined;
    }

    if (favProcessFilters) {
      // Add fav value to the filters
      processHistoryFilters.status = favProcessFilters.status ? favProcessFilters.status : ['active'];
      processHistoryFilters.sortBy = favProcessFilters.sortBy ? favProcessFilters.sortBy : 'startTime';
      processHistoryFilters.sortOrder = favProcessFilters.sortOrder ? favProcessFilters.sortOrder : 'desc';
      processHistoryFilters.processName =
        favProcessFilters.processName && favProcessFilters.processName.length > 0 ? favProcessFilters.processName![0] : undefined;
      processHistoryFilters.processId =
        favProcessFilters.processId && favProcessFilters.processId.length > 0 ? favProcessFilters.processId![0] : undefined;

      processHistoryFilters.startedAfter =
        favProcessFilters.startedAfter && favProcessFilters.startedAfter.length > 0
          ? favProcessFilters.startedAfter![0]
          : new Date((new Date() as any) - 1000 * 60 * 60 * 24 * 60);
      processHistoryFilters.startedBefore =
        favProcessFilters.startedBefore && favProcessFilters.startedBefore.length > 0 ? favProcessFilters.startedBefore![0] : undefined;
      processHistoryFilters.finishedAfter =
        favProcessFilters.finishedAfter && favProcessFilters.finishedAfter.length > 0 ? favProcessFilters.finishedAfter![0] : undefined;
      processHistoryFilters.finishedBefore =
        favProcessFilters.finishedBefore && favProcessFilters.finishedBefore.length > 0 ? favProcessFilters.finishedBefore![0] : undefined;

      // Add fav engine and tab value to the filters
      let favProcessHistorySearch: ProcessHistorySearch = 'PARTNER';
      if (isUserInLan) {
        favProcessHistorySearch =
          isUserInLan && favProcessFilters.processHistorySearch && favProcessFilters.processHistorySearch.length > 0
            ? (favProcessFilters.processHistorySearch[0] as ProcessHistorySearch)
            : 'MY_PROCESS';
        processHistoryFilters.processHistorySearch = favProcessHistorySearch;
      } else {
        processHistoryFilters.processHistorySearch = 'PARTNER';
      }

      processHistoryFilters.processHistoryFilterEngineId = favProcessHistorySearch.includes('PARTNER')
        ? sgWorkflowConfig.internetExposureEngineId
        : sgWorkflowConfig.defaultEngineId;

      // Add fav workspace value to the filters
      if (isUserInLan && favProcessFilters.userWorkspaces && favProcessFilters.userWorkspaces.length > 0) {
        processHistoryFilters.userWorkspaces = userWorkspaces.find((x) => x.id === favProcessFilters!.userWorkspaces![0]);
      } else {
        processHistoryFilters.userWorkspaces = undefined;
      }
    } else {
      // Add fav engine and tab value to the filters
      let favProcessHistorySearch: ProcessHistorySearch = isUserInLan ? 'MY_PROCESS' : 'PARTNER';
      processHistoryFilters.processHistorySearch = favProcessHistorySearch;

      processHistoryFilters.processHistoryFilterEngineId = favProcessHistorySearch.includes('PARTNER')
        ? sgWorkflowConfig.internetExposureEngineId
        : sgWorkflowConfig.defaultEngineId;
    }
    // Set up which tabs are visible on the UI
    let newProcessViewTabDescriptors = processViewTabDescriptors;
    const processTab = processViewTabDescriptors.find((it) => it.id === 'MY_PROCESS');
    const adminTab = processViewTabDescriptors.find((it) => it.id === 'ADMIN');
    const viewerTab = processViewTabDescriptors.find((it) => it.id === 'VIEWER');
    const partnerAdminTab = processViewTabDescriptors.find((it) => it.id === 'PARTNER_ADMIN');
    const partnerViewerTab = processViewTabDescriptors.find((it) => it.id === 'PARTNER_VIEWER');
    if (processTab) {
      processTab.visible = isUserInLan;
      processTab.engineId = sgWorkflowConfig.defaultEngineId;
    }
    if (adminTab) {
      adminTab.visible = isUserInLan && userWorkspaces.length > 0;
      adminTab.engineId = sgWorkflowConfig.defaultEngineId;
    }

    if (viewerTab) {
      viewerTab.visible = isUserInLan && hasProcessViewerRole();
      viewerTab.engineId = sgWorkflowConfig.defaultEngineId;
    }
    if (partnerAdminTab) {
      partnerAdminTab.visible = isUserInLan && userWorkspaces.length > 0 && isUserAdminOfPartnerWorkspace(userWorkspaces);
    }

    if (partnerViewerTab) {
      partnerViewerTab.visible = isUserInLan && hasProcessViewerRole();
    }

    await fetchHistoricProcess(
      {
        sgWorkflowConfig,
        accessToken,
      },
      processHistoryFilters
    );

    store.setState({
      processHistoryFilters: processHistoryFilters,
      userSettings: userSettings,
      processViewTabDescriptors: newProcessViewTabDescriptors,
      processUserSettingsFiltersStatus: 'loaded',
    });
  }
};

export const fetchHistoricProcess = async (
  {
    sgWorkflowConfig,
    accessToken,
  }: {
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken: string;
  },
  processHistoryFilters: ProcessHistoryFilters
) => {
  // Start Analytics Transaction
  const analyticsInstance = Analytics.getInstance();

  try {
    store.setState({ processLoadingStatus: 'loading' });
    loadHistoricProcessesCall.cancel('Cancel of old transaction');
    loadHistoricProcessesCall = CancelToken.source();

    if (processHistoryFilters) {
      if (analyticsInstance) {
        analyticsInstance.startTransaction(
          `${Analytics.constants.middlewareInteraction.actions.fetchProcessHistory}
          ${processHistoryFilters.processHistorySearch.toLowerCase()}`,
          Analytics.constants.transactions.type.customInteractions,
          { managed: true }
        );
      }

      const options = {
        headers: { Authorization: accessToken },
        cancelToken: loadHistoricProcessesCall.token,
      };
      const config = { accessToken: accessToken };

      let processHistoryTypeView: any = getViewType();
      const instanceApiFp = InstanceApiFactoryV4(config, sgWorkflowConfig.workspaceApiBasePath, undefined);

      const calling = await instanceApiFp.getInstancesHistory(
        processHistoryTypeView,
        getStatus() || (processHistoryFilters!.status ? defineInstanceStatus(processHistoryFilters!.status) : ['In progress']),
        processHistoryFilters!.page ? processHistoryFilters!.page : 0,
        processHistoryFilters!.pageSize ? processHistoryFilters!.pageSize : 30,
        getSortDirection(),
        getSortOrder(),
        getProcessName(processHistoryFilters),
        getProcessId(processHistoryFilters), // processInstanceBusinessKey
        getProcessId(processHistoryFilters),
        undefined, // ProcessDefinitionKey
        undefined, // processInstanceIds
        getDate('startedBefore'),
        getDate('startedAfter'),
        getDate('finishedBefore'),
        getDate('finishedAfter'),
        getEngineId(),
        getTenantId() || undefined,
        ['_process_label', '__cancelledReason', '__cancelledBy'],
        undefined,
        getIncidentsFlag(processHistoryFilters),
        undefined,
        options
      );
      const result: PageOfInstance = calling.data as PageOfInstance;
      const pageOfProcessInstances: PageOfProcessInstances = {
        total: result.totalElements,
        nbPages: result.totalPages,
        instances: result.content,
      } as PageOfProcessInstances;

      return store.setState({
        processLoadingStatus: 'loaded',
        processInstances: pageOfProcessInstances,
      });
    }
  } catch (err) {
    if (axios.isCancel(err)) {
      return {
        selectedTaskLoadingStatus: 'loading',
        selectedLoadedTask: undefined,
        processInstanceId: undefined,
        processDefinitionId: undefined,
        canDoActionOnTask: undefined,
      };
    }
    return {};
  }
};

export const defineInstanceStatus = (status: ProcessHistoryStatus[] | undefined): Array<InstanceStatus> => {
  var instanceStatus: Array<InstanceStatus> = [];

  if (status!.includes('all')) {
    instanceStatus.push(InstanceStatus.All);
  }
  if (status!.includes('active')) {
    instanceStatus.push(InstanceStatus.InProgress);
  }
  if (status!.includes('cancelled')) {
    instanceStatus.push(InstanceStatus.Cancelled);
  }
  if (status!.includes('completed')) {
    instanceStatus.push(InstanceStatus.Completed);
  }
  if (status!.includes('suspended')) {
    instanceStatus.push(InstanceStatus.Suspended);
  }

  return instanceStatus;
};

export const defineInstanceHistorySort = (sortType: string | SortByInstanceHistory.StartTime): SortByInstanceHistory => {
  let sortTypeFound = instanceHistorySortBy.get(sortType); // SortByInstanceHistory | undefined
  return sortTypeFound ? (sortTypeFound as SortByInstanceHistory) : SortByInstanceHistory.StartTime;
};

export const exportHistoryTableAsCsv = async ({
  sgWorkflowConfig,
  accessToken,
  processHistoryFilters,
}: {
  sgWorkflowConfig: SGWorkflowConfig;
  accessToken: string;
  processHistoryFilters: ProcessHistoryFilters;
}) => {
  try {
    const options = { headers: { Authorization: accessToken } };
    const config = { accessToken: accessToken };

    store.setState({
      isDownloadingCsv: true,
    });

    const instanceApiFp = InstanceApiFactoryV4(config, sgWorkflowConfig.workspaceApiBasePath, undefined);
    let processHistoryTypeView: any = getViewType();

    const csv: any = await instanceApiFp.getInstancesHistoryAsCsv(
      processHistoryTypeView,
      defineInstanceStatus(processHistoryFilters!.status),
      defineInstanceHistorySort(processHistoryFilters!.sortBy),
      processHistoryFilters!.sortOrder ? processHistoryFilters!.sortOrder : 'desc',
      processHistoryFilters!.processName ? processHistoryFilters!.processName : undefined,
      processHistoryFilters!.processId ? processHistoryFilters!.processId : undefined,
      processHistoryFilters!.processId ? processHistoryFilters!.processId : undefined,
      undefined, // processDefinitionKey
      undefined, // processInstanceIds
      processHistoryFilters!.startedBefore ? processHistoryFilters!.startedBefore : undefined,
      getDate('startedAfter'),
      processHistoryFilters!.finishedBefore ? processHistoryFilters!.finishedBefore : undefined,
      processHistoryFilters!.finishedAfter ? processHistoryFilters!.finishedAfter : undefined,
      0, // page
      500, // pageSize
      processHistoryFilters!.processHistoryFilterEngineId
        ? [processHistoryFilters!.processHistoryFilterEngineId]
        : [sgWorkflowConfig.defaultEngineId],
      processHistoryFilters!.userWorkspaces ? Array.of(processHistoryFilters!.userWorkspaces.id!!) : undefined,
      ['_process_label', '__cancelledReason', '__cancelledBy'],
      undefined,
      processHistoryFilters!.withIncidents ? processHistoryFilters!.withIncidents : false,
      undefined,
      options
    );

    let data = new Blob([csv.data], { type: 'text/csv' });
    let csvURL = window.URL.createObjectURL(data);
    let tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'SGW_Processes_export.csv');
    tempLink.click();

    setTimeout(function() {
      window.URL.revokeObjectURL(csvURL);
      tempLink.remove();
    }, 100);

    return {
      isDownloadingCsv: false,
    };
  } catch (err) {
    alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
  }
};

export const getPreSelectedInstance = async (
  {
    sgWorkflowConfig,
    accessToken,
  }: {
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken: string;
  },
  selectedInstanceId: string
) => {
  if (!selectedInstanceId) return;

  try {
    store.setState({ processLoadingStatus: 'loading' });

    const options = { headers: { Authorization: accessToken } };
    const config = { accessToken: accessToken };
    const instanceApi = InstanceApiFactoryV4(config, sgWorkflowConfig.workspaceApiBasePath, undefined);
    const instanceApiResponse = await instanceApi.getProcessInstance(
      selectedInstanceId,
      undefined,
      false, // includeActiveTasks
      false, // IncludeHistoricTasks
      undefined, // includeActivityTypes
      options
    );

    if (instanceApiResponse) {
      const instanceFetch = instanceApiResponse.data as Instance;
      const selectedInstance: ProcessInstance = {
        id: instanceFetch.id,
        processDefinitionName: instanceFetch.processDefinitionName,
        startTime: instanceFetch.startTime!.toString(),
        businessKey: instanceFetch.businessKey,
        duration: instanceFetch.duration,
        endTime: instanceFetch.endTime ? instanceFetch.endTime.toString() : undefined,
        initiator: instanceFetch.initiator,
        lastTaskAssignee: instanceFetch.lastTaskAssignee,
        lastTaskName: instanceFetch.lastTaskName,
        processDefinitionId: instanceFetch.processDefinitionId,
        processDefinitionKey: instanceFetch.processDefinitionKey,
        processDefinitionVersion: instanceFetch.processDefinitionVersion,
        state: instanceFetch.state,
        tenantId: instanceFetch.tenantId,
        engineId: instanceFetch.engineId,
      };
      return {
        selectedProcessInstance: selectedInstance,
        processLoadingStatus: 'loaded',
      };
    }
  } catch (err) {
    return alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
  }
};

export const cancelProcessInstance = async (
  {
    sgWorkflowConfig,
    accessToken,
  }: {
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken: string;
  },
  selectedInstance: ProcessInstance,
  cancelReason: string
) => {
  try {
    store.setState({
      cancellingProcessInstanceStatus: 'loading',
    });

    const options = { headers: { Authorization: accessToken } };
    const config = { accessToken: accessToken };

    const instanceApi = InstanceApiFactoryV4(config, sgWorkflowConfig.workspaceApiBasePath, undefined);
    const instanceApiResponse = await instanceApi.deleteProcessInstance(selectedInstance.id!, cancelReason, selectedInstance.engineId!, options);

    alertUtils.publishAlertSuccess('Success', 'Process instance ' + selectedInstance.processDefinitionName + ' has been cancelled.');

    fetchHistoricProcess(
      {
        sgWorkflowConfig,
        accessToken,
      },
      store.getState().processHistoryFilters
    );

    return {
      cancellingProcessInstanceStatus: 'loaded',
      selectedProcessInstance: undefined,
      showCancelProcessInstanceModal: false,
    };
  } catch (err) {
    alertUtils.publishAlertDanger('Error', getErrorMessage(err.response && err.response.data));
    return {
      cancellingProcessInstanceStatus: 'error',
      selectedProcessInstance: undefined,
      showCancelProcessInstanceModal: false,
    };
  }
};

export const updateSelectedProcessInstance = ({}, selectedProcessInstance?: ProcessInstance) => ({
  selectedProcessInstance,
});

export const updateProcessHistoryFilters = ({}, processHistoryFilters?: ProcessHistoryFilters) => ({
  ...processHistoryFilters,
});

export const updateShowProcessFilter = ({}, showProcessFilter?: boolean) => ({
  showProcessFilter,
});

export const updateShowCancelProcessInstanceModal = ({}, showCancelProcessInstanceModal?: boolean) => ({
  showCancelProcessInstanceModal,
});

export const updateShowProcessHistoryScreen = ({}, showProcessHistoryScreen?: boolean) => ({
  showProcessHistoryScreen,
});

function isUserAdminOfPartnerWorkspace(userWorkspaces: Workspace[]) {
  for (let workspace of userWorkspaces) {
    if (workspace.engineId === 'partners') {
      return true;
    }
  }
  return false;
}
