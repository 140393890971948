import { Filters, ApiCallStatus, FormioSubmission, FormioState } from '../models';
import { SGWorkflowConfig } from '../config';

export const BUS_ACCESS_TOKEN = 'sg-connect.access-token';
export const BUS_USER_CONNECTION = 'sg-connect.user-connection';
export const BUS_USER_INFO = 'sg-connect.user-info';
export const BUS_REFRESH_TASK_LIST = 'sg-workflow-tasks.refresh_task_list';
export const BUS_TASK_LIST_FILTERS = 'sg-workflow-tasks.task_list_filters';
export const BUS_SELECTED_TASK_AND_CLUSTER = 'sg-workflow-tasks.selected_task_and_cluster';
export const BUS_TASK_UPDATED = 'sg-workflow-task.task_updated';
export const BUS_TOTAL_TASKS = 'sg-workflow-task.total_tasks';

export const EVENT_TASK_FORM_COMPLETED = 'sg-workflow-task-form--task-completed';
export const EVENT_TASK_FORM_SAVED = 'sg-workflow-task-form--task-saved';
export const EVENT_TASK_ASSIGNMENT_CHANGED = 'sg-workflow-task-form--task-assignment-changed';

export const updateUserId = (
  {
    filters,
    sgWorkflowConfig,
    accessToken,
    user,
  }: {
    filters: Filters;
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken?: string;
    user?: string;
  },
  userId?: string
) => {
  return {
    user: userId,
  };
};

export const updateFilters = ({}, filters?: Filters) => ({
  filters,
});

export const updateSelectedTaskId = ({}, selectedTaskId?: string) => ({
  selectedTaskId,
});

export const updateSelectedTaskLoadingStatus = ({}, selectedTaskLoadingStatus?: ApiCallStatus) => ({
  selectedTaskLoadingStatus,
});

export const updateSelectedTaskEngineId = ({}, selectedTaskEngineId?: string) => ({
  selectedTaskEngineId,
});

export const updateAccessToken = (
  {
    filters,
    sgWorkflowConfig,
    accessToken,
    user,
  }: {
    filters: Filters;
    sgWorkflowConfig: SGWorkflowConfig;
    accessToken?: string;
    user?: string;
  },
  newAccessToken?: string
) => {
  return {
    accessToken: newAccessToken,
  };
};

export const updateFormioState = ({}, formioState: FormioState) => ({
  formioState,
});
