import { h, initializeWidgetConfiguration, SgWidget, WidgetConfigurationProvider, WithGlobalCss } from '@sgwt-widget/core';
import { ComponentProps, props } from 'skatejs';
import { Provider } from 'redux-zero/preact';

import * as historyProcessCss from '../common/sgworkflow-process-history.less';
import store from '../common/state/store';
import { CONFIG } from '../common/config';
import SGWorkflowProcessHistoryComp from '../sg-workflow-process-list/components/SgWorkflowProcessHistory';
// @ts-ignore
import Analytics from '../analytics/analytics.service';

export interface ISgWorkflowProcessHistoryProps {
  user?: string;
  currenttoken?: string;
  selectedprocessinstanceid: string;
  engineid: string;
}

export class SgWorkflowProcesshistory extends SgWidget<ISgWorkflowProcessHistoryProps> {
  public static is = 'sg-workflow-process-history';

  public static props: ComponentProps<ISgWorkflowProcessHistoryProps> = {
    user: props.string,
    currenttoken: props.string,
    selectedprocessinstanceid: props.string,
    engineid: props.string,
  };

  public connectedCallback() {
    super.connectedCallback();
    if (Analytics.getInstance()) {
      Analytics.addAnalyticsMetadata(SgWorkflowProcesshistory.is, this.widgetConfiguration);
    }
  }

  public render() {
    this.initWidgetConfiguration();
    return (
      <WidgetConfigurationProvider widgetConfiguration={this.widgetConfiguration}>
        <WithGlobalCss styles={historyProcessCss}>
          <Provider store={store}>
            <SGWorkflowProcessHistoryComp />
          </Provider>
        </WithGlobalCss>
      </WidgetConfigurationProvider>
    );
  }

  private initWidgetConfiguration() {
    const initState = {
      sgWorkflowConfig: CONFIG.get(),
      processInstanceId: this.props.selectedprocessinstanceid,
      engineId: this.props.engineid,
    };
    if (this.props.user) {
      // @ts-ignore
      initState.user = this.props.user;
    }
    if (this.props.currenttoken) {
      // @ts-ignore
      initState.accessToken = this.props.currenttoken;
    }
    if (this.props.engineid) {
      // @ts-ignore
      initState.engineId = this.props.engineid;
    }

    store.setState(initState);
  }
}

initializeWidgetConfiguration(SgWorkflowProcesshistory, {
  neverUseShadowDOM: true,
});
customElements.define(SgWorkflowProcesshistory.is, SgWorkflowProcesshistory);
